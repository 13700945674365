<template>
  <!--右侧画笔工具栏-->
  <div>
    <div class="tools" id="tool1">
      <ul>
        <li value="menu" :title="$t('toolbox.菜單')" class="active" @click="handleMenu()">
          <!--<li value="menu" title="菜单" class="active">-->
          <span class="fontbox">
            <svg-icon icon-class="hi" class="fill-icon" />
          </span>
        </li>

        <li value="check" :title="$t('toolbox.選擇')" class="vertical-tools" @click="setMode('check')">
          <span :class="['fontbox', mode == 'check' ? 'active' : '']">
            <font-awesome-icon v-if="this.$store.state.checkvalue == 'hand'" icon="hand-rock" class="handrock-icon" />
            <svg-icon v-else icon-class="select" class="stroke-icon" @click="tx = true" />
          </span>
        </li>
        <li value="undo" :title="$t('toolbox.回上一步')" class="active" @click="undo()">
          <span class="fontbox">
            <svg-icon icon-class="undo" class="stroke-icon" />
          </span>
        </li>

        <li value="pencil-alt" :title="$t('toolbox.畫筆')" class="pop_box" @click="setMode('pencil-alt')">
          <Poptip v-model="penPopVisible" content="content" placement="left-start" trigger="click">
            <span :class="['fontbox', mode == 'pencil-alt' ? 'active' : '']">
              <font-awesome-icon :icon="penType ? penType : 'pencil-alt'" @click="alert = true" />
            </span>
            <div slot="content">
              <ColorPicker></ColorPicker>
            </div>
          </Poptip>
        </li>

        <li value="shape" :title="$t('toolbox.圖形')" class="pop_box" @click="setMode('shape')">
          <Poptip v-model="shapePopVisible" content="content" placement="left-start" trigger="click">
            <span :class="['fontbox', mode == 'shape' ? 'active' : '']">
              <svg-icon v-if="shapeTypeIcon == 'shapes' || shapeTypeIcon == ''" icon-class="shap" class="stroke-icon" />
              <svg-icon v-if="shapeType == 'long-arrow-alt-right' && shapeTypeIcon != 'shapes'" icon-class="add_arrow2" class="fill-icon" />
              <q-icon v-else-if="shapeTypeIcon == 'trending_flat' || shapeTypeIcon == 'remove'" :name="shapeTypeIcon" style="transform: rotate(-45deg)" />
              <q-icon v-else-if="shapeType == 'ellipse'" style="transform: scaleX(1.2)" :name="shapeTypeIcon" />
              <q-icon v-if="shapeType != 'long-arrow-alt-right' && shapeType != 'ellipse' && shapeType != 'line'" :name="shapeTypeIcon" />
            </span>

            <div slot="content">
              <shapes></shapes>
            </div>
          </Poptip>
        </li>
        <li value="addImg" :title="$t('toolbox.插入圖片')" @click="setMode('addImg')">
          <!-- <label class="fontbox img_upload-btn" @click="addImgMode"> -->
          <span class="fontbox img_upload-btn">
            <svg-icon class="img_upload-icon" iconClass="ImgToolBox" />
          </span>
          <!-- <input @click="resetAddImageUploader()" ref="addImageUploader" type="file" id="addimgfile" accept="image/png, image/gif, image/x-png,image/jpeg" @change="addImgObj" />
          </label> -->
        </li>
        <li value="text" :title="$t('toolbox.文字')" @click="setMode('text')">
          <!--Poptip content="content" placement="left-start" trigger="click"-->
          <span :class="['fontbox', mode == 'text' ? 'active' : '']">
            <svg-icon icon-class="text" class="stroke-icon" />
          </span>
          <!-- <div slot="content">
            <TextPicker></TextPicker>
          </div>
        </Poptip> -->
        </li>
        <li value="eraser" :title="$t('toolbox.橡皮擦')" class="vertical-tools" @click="setMode('eraser')">
          <Poptip content="content" placement="left-start" trigger="click">
            <span :class="['fontbox', mode == 'eraser' ? 'active' : '']">
              <font-awesome-icon icon="eraser" @click="tx = true" />
            </span>
            <div slot="content">
              <Eraser></Eraser>
            </div>
          </Poptip>
        </li>
      </ul>

      <ul>
        <li :title="$t('toolbox.上一頁')">
          <span class="fontbox" @click="previousPage()">
            <svg-icon icon-class="prev" class="stroke-icon" />
          </span>
        </li>
        <li :title="$t('toolbox.新建一頁')" @click="addNew">
          <span class="fontbox">
            <svg-icon icon-class="new-page" class="stroke-icon" />
          </span>
        </li>
        <li :title="$t('toolbox.下一頁')">
          <span class="fontbox" @click="nextPage()">
            <svg-icon icon-class="next" class="stroke-icon" />
          </span>
        </li>
        <li value="zooms" :title="$t('toolbox.缩放工具')" class="pop_box" @click="setMode('zooms')">
          <Poptip v-model="zoomPopVisible" content="content" placement="left-start" trigger="click">
            <span :class="['fontbox', mode == 'zooms' ? 'active' : '']">
              <svg-icon icon-class="zoom" class="fill-icon" @click="tx = true" />
            </span>
            <div slot="content">
              <div>
                <span :title="$t('toolbox.回到原始比例')" class="fontbox zoomToDefault-btn" @click="zoomToDefault"><p>1:1</p></span>
              </div>
              <Zoom></Zoom>
            </div>
          </Poptip>
        </li>
        <li>
          <span class="fontbox" :title="$t('toolbox.推送圖片')" @click="pushStageImg()" v-show="currentstudents() != ''">
            <svg-icon icon-class="pushImg" class="stroke-icon pushImg-icon" />
          </span>
        </li>
        <li :title="$t('toolbox.IRS工具')" @click="setMode('irs')" class="irs-smallbtn">
          <Poptip content="content" placement="left-start" trigger="click" :popper-class="irsPop" v-model="irsToolPopVisible">
            <span class="fontbox">
              <svg-icon icon-class="interactive" class="irs-icon" />
            </span>
            <div slot="content">
              <ul>
                <li :title="$t('toolbox.IRS即問即答')" @click="irsstart('irs')">
                  <span class="fontbox">
                    <svg-icon icon-class="Ques" class="stroke-icon ques-icon" />
                  </span>
                </li>
                <li :title="$t('toolbox.IRS填空')" @click="irsstart('text')">
                  <span class="fontbox">
                    <!--<img src="@/statics/iconsvg/interaction/irstext.svg"/>-->
                    <!--<font-awesome-icon icon="fa-edit" />-->
                    <svg-icon icon-class="irs-text" class="stroke-icon" />
                  </span>
                </li>
                <li :title="$t('toolbox.IRS作品收集')" @click="irsstart('irsGather')">
                  <span class="fontbox">
                    <!--<img src="@/statics/iconsvg/interaction/irstext.svg"/>-->
                    <!--<font-awesome-icon icon="fa-edit" />-->
                    <svg-icon icon-class="collate" class="fill-icon" />
                  </span>
                </li>
                <li :title="$t('toolbox.IRS搶權')" @click="irsstart('irsBuzz')">
                  <span class="fontbox">
                    <svg-icon icon-class="buzz-new" class="stroke-icon buzz-icon" />
                  </span>
                </li>
                <li value="pickOne" :title="$t('toolbox.挑人')" @click="handlePick()">
                  <span class="fontbox">
                    <svg-icon icon-class="choose-student" class="stroke-icon" />
                  </span>
                </li>
                <li :title="$t('toolbox.倒數計時')" @click="handleTimer()">
                  <span class="fontbox">
                    <svg-icon icon-class="timer" class="stroke-icon" />
                  </span>
                </li>
              </ul>
            </div>
          </Poptip>
        </li>
      </ul>
      <ul class="irs-mainbtn">
        <li :title="$t('toolbox.IRS工具')" @click="setMode('irs')">
          <Poptip content="content" placement="left-start" trigger="click" :popper-class="irsPop" v-model="irsToolPopVisible">
            <span class="fontbox">
              <svg-icon icon-class="interactive" class="irs-icon" />
            </span>
            <div slot="content">
              <ul>
                <li :title="$t('toolbox.IRS即問即答')" @click="irsstart('irs')">
                  <span class="fontbox">
                    <svg-icon icon-class="Ques" class="stroke-icon ques-icon" />
                  </span>
                </li>
                <li :title="$t('toolbox.IRS填空')" @click="irsstart('text')">
                  <span class="fontbox">
                    <!--<img src="@/statics/iconsvg/interaction/irstext.svg"/>-->
                    <!--<font-awesome-icon icon="fa-edit" />-->
                    <svg-icon icon-class="irs-text" class="stroke-icon" />
                  </span>
                </li>
                <li :title="$t('toolbox.IRS作品收集')" @click="irsstart('irsGather')">
                  <span class="fontbox">
                    <!--<img src="@/statics/iconsvg/interaction/irstext.svg"/>-->
                    <!--<font-awesome-icon icon="fa-edit" />-->
                    <svg-icon icon-class="collate" class="fill-icon" />
                  </span>
                </li>
                <li :title="$t('toolbox.IRS搶權')" @click="irsstart('irsBuzz')">
                  <span class="fontbox">
                    <svg-icon icon-class="buzz-new" class="stroke-icon buzz-icon" />
                  </span>
                </li>
                <li value="pickOne" :title="$t('toolbox.挑人')" @click="handlePick()">
                  <span class="fontbox">
                    <svg-icon icon-class="choose-student" class="stroke-icon" />
                  </span>
                </li>
                <li :title="$t('toolbox.倒數計時')" @click="handleTimer()">
                  <span class="fontbox">
                    <svg-icon icon-class="timer" class="stroke-icon" />
                  </span>
                </li>
              </ul>
            </div>
          </Poptip>
        </li>
      </ul>

      <ul class="irs-subbtn">
        <li :title="$t('toolbox.IRS即問即答')" @click="irsstart('irs')">
          <span class="fontbox">
            <svg-icon icon-class="Ques" class="stroke-icon ques-icon" />
          </span>
        </li>
        <li :title="$t('toolbox.IRS填空')" @click="irsstart('text')">
          <span class="fontbox">
            <!--<img src="@/statics/iconsvg/interaction/irstext.svg"/>-->
            <!--<font-awesome-icon icon="fa-edit" />-->
            <svg-icon icon-class="irs-text" class="stroke-icon" />
          </span>
        </li>
        <li :title="$t('toolbox.IRS作品收集')" @click="irsstart('irsGather')">
          <span class="fontbox">
            <!--<img src="@/statics/iconsvg/interaction/irstext.svg"/>-->
            <!--<font-awesome-icon icon="fa-edit" />-->
            <svg-icon icon-class="collate" class="fill-icon" />
          </span>
        </li>
        <li :title="$t('toolbox.IRS搶權')" @click="irsstart('irsBuzz')">
          <span class="fontbox">
            <svg-icon icon-class="buzz-new" class="stroke-icon buzz-icon" />
          </span>
        </li>
        <li value="pickOne" :title="$t('toolbox.挑人')" @click="handlePick()">
          <span class="fontbox">
            <svg-icon icon-class="choose-student" class="stroke-icon" />
          </span>
        </li>
        <li :title="$t('toolbox.倒數計時')" @click="handleTimer()">
          <span class="fontbox">
            <svg-icon icon-class="timer" class="stroke-icon" />
          </span>
        </li>
      </ul>

      <div class="percentage-studentanswer-under" @click="controlView()" v-show="currentstudents() != '' && currentIRS() != 'normal'">
        <div class="percentage-irshint">
          <svg-icon v-show="currentIRS() == 'irsBuzz'" icon-class="buzz-new" class="stroke-icon buzz-icon" />
          <svg-icon v-show="currentIRS() == 'irsImmediately'" icon-class="Ques" class="stroke-icon ques-icon" />
          <svg-icon v-show="currentIRS() == 'irsText'" icon-class="irs-text" class="stroke-icon" />
          <svg-icon v-show="currentIRS() == 'irsGather'" icon-class="collate" class="fill-icon" />
        </div>
        <q-circular-progress show-value font-size="16px" :value="value" size="70px" :thickness="0.22" color="teal" track-color="grey-3" class="q-ma-md"> {{ value }}% </q-circular-progress>
        <div :class="{ 'close-animate': this.$store.state.isLightPieChart == true }"></div>
      </div>
    </div>

    <Card :styleObj="styleObj" :componentIndex="componentIndex" v-if="isShowCard" ref="card"></Card>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import SvgIcon from '../views/SvgIcon.vue'
import Card from './Card.vue'
import ColorPicker from './ColorPop/ColorPicker.vue'
import LaserPicker from './ColorPop/LaserPicker.vue'
import TextPicker from './ColorPop/TextPicker.vue'
import shapes from './ColorPop/Shapes.vue'
import Zoom from './ColorPop/Zoom.vue'
import Eraser from './ColorPop/Eraser.vue'
import Ques from './ColorPop/Ques.vue'
import CheckBox from './ColorPop/Checkbox.vue'

export default {
  name: 'toolBox',
  props: ['value', 'limitCollate'],
  components: {
    Card,
    ColorPicker,
    LaserPicker,
    TextPicker,
    shapes,
    Zoom,
    Eraser,
    Ques,
    CheckBox,
    SvgIcon
  },
  data() {
    return {
      mode: '',
      penType: this.$store.state.penType,
      shapeType: this.$store.state.shapeType,
      shapeTypeIcon: this.$store.state.shapeTypeIcon,
      irsPop: 'irsPop',
      color: '',
      stroke: '#0000ff',
      shapeColor: '#0000ff',
      shapeStroke: '#0000ff',
      componentIndex: 0,
      list: [],
      isShowCard: false,
      styleObj: {
        isShow: false,
        width: '0px',
        height: '0px',
        // maxWidth: "0px",
        // marginLeft: "0px",
        marginTop: '0px',
        backgroundColor: 'unset',
        boxShadow: 'unset'
      },
      carousel: this.$store.state.carousel,
      students: this.$store.state.students,
      irsModel: this.$store.state.irsModel,
      preventfastChangePage: false,
      preventfastChangePageTimeOut: '',
      irsToolPopVisible: false,
      zoomPopVisible: false,
      penPopVisible: false,
      shapePopVisible: false,
      isSendIRS: false, //避免doubled click,
      waitingCopyPageTimer: '',
      skipCopyPageAskbyTA: false,
      //存放Undo動作
      undoHistory:[],
      doUndoIndex:0,
      currentSelectElement: '',
    }
  },

  methods: {
    saveUndoHistory(mode,element){
      console.log(mode,element)
      //加入物件的undo
      switch(mode){
        case 'add': {
          this.undoHistory.push({ action: 'delete', item: JSON.parse(JSON.stringify(element)) })
          //新增前一動應提供該物件的初始化Attr
          this.undoHistory.push({ action: 'update', item: JSON.parse(JSON.stringify(element)) })
          break;
        }
        case 'update': {
          this.undoHistory.push({ action: 'update', item: JSON.parse(JSON.stringify(element)) })
          break;
        }
        case 'delete': {
          if(element.attrs.name!='selectionRectangle') //針對圈選刪除，不存圈選框
          this.undoHistory.push({ action: 'add', item: JSON.parse(JSON.stringify(element)) })
          break;
        }
      }
    },
    undo() {
      // this.setMode('undo')
      if (this.undoHistory == '') {
        alert(this.$t('toolbox["目前沒有動作可復原"]'))
        return
      }
      this.removeTransformer()
      // this.removeTextTransformer()
      this.doUndoIndex = this.undoHistory.length - 1
      if (this.undoHistory != '') this.updateStageObj(this.undoHistory[this.undoHistory.length - 1])
      this.undoHistory.pop()
    },

     removeTransformer() {
      const stage= this.$store.state.stage
      stage.find('Transformer').forEach(ele => {
        if (ele.attrs.name == 'default') {
          ele.destroy()
        }
      })
    },

    //接收到單一物件針對uuid與動作去更新
    async updateStageObj(targetAction) {
      console.log('單一物件渲染!', targetAction)
      let that = this
      let layer = this.$store.state.layer
      let objectIndex
      if (targetAction.action == 'add') {
        
        let normalClassName = ['Line', 'Circle', 'Rect', 'Star', 'Text', 'Path', 'Ellipse', 'RegularPolygon']
        if (normalClassName.includes(JSON.parse(targetAction.item).className)) {
          let KonvaItem = new Konva[JSON.parse(targetAction.item).className](JSON.parse(targetAction.item).attrs)
          layer.add(KonvaItem)
          if (JSON.parse(targetAction.item).className == 'Rect' && KonvaItem.attrs.name == 'bgRect') {
            KonvaItem.moveToBottom()
          }
          objectIndex=KonvaItem.attrs?.index?KonvaItem.attrs?.index:KonvaItem.index
          KonvaItem.zIndex(objectIndex)
           layer.batchDraw()
        }else if (JSON.parse(targetAction.item).className == 'Label') {
          //文字便利貼
          let label = new Konva.Label(JSON.parse(targetAction.item).attrs)
          JSON.parse(targetAction.item).children.forEach(item => {
            if (item.className == 'Tag') {
              let tag = new Konva.Tag(item.attrs)
              label.add(tag)
            }
            if (item.className == 'Text') {
              let text = new Konva.Text(item.attrs)
              label.add(text)
            }
          })
          layer.add(label)
          objectIndex=JSON.parse(targetAction.item).attrs?.index?JSON.parse(targetAction.item).attrs?.index:JSON.parse(targetAction.item)?.index
          label.zIndex(objectIndex)
          layer.batchDraw()
        } else if (JSON.parse(targetAction.item).className == 'Image') {
          await this.addUndoSingleImg(JSON.parse(targetAction.item), layer)
        } else if (JSON.parse(targetAction.item).className == 'Group') {
          let KonvaGroup = new Konva[JSON.parse(targetAction.item).className](JSON.parse(targetAction.item).attrs)

          JSON.parse(targetAction.item).children.forEach(async child => {
            let normalClassName = ['Line', 'Circle', 'Rect', 'Star', 'Text', 'Path', 'Ellipse', 'RegularPolygon']
            if (normalClassName.includes(child.className)) {
              KonvaGroup.add(
                new Konva[child.className]({
                  parent: KonvaGroup,
                  ...child.attrs
                })
              )
            }
            if (child.className == 'Image') {
             await this.addUndoSingleImg(child, KonvaGroup)
            }

            layer.add(KonvaGroup)
            objectIndex=KonvaGroup.attrs?.index?KonvaGroup.attrs?.index:KonvaGroup.index
            KonvaGroup.zIndex(objectIndex)
            layer.batchDraw()
            
          })
        }
        layer.children.sort((a,b)=>{
          return a.attrs.index-b.attrs.index
        })
         layer.children.forEach(child => {
            if (child.attrs.name == 'boardPdfBg') child.moveToBottom()
          })

         layer.batchDraw()

        console.log(layer)
      } else if (targetAction.action == 'update') {
        // console.log('更新物件', targetAction)
        let targetItem = typeof targetAction.item === 'object' ? targetAction.item : JSON.parse(targetAction.item)

        let childrenIndex = layer.children.map(x => x.attrs.uuid).indexOf(targetItem.attrs.uuid)
        //  console.log('更新物件', targetItem, childrenIndex)

        if (childrenIndex != -1) {

          layer.children[childrenIndex].setAttrs(targetItem.attrs)
          if (!targetItem.attrs.hasOwnProperty('x')) layer.children[childrenIndex].setAttrs({ x: 0 })
          if (!targetItem.attrs.hasOwnProperty('y')) layer.children[childrenIndex].setAttrs({ y: 0 })
          if (!targetItem.attrs.hasOwnProperty('rotation')) layer.children[childrenIndex].setAttrs({ rotation: 0 })
          if (!targetItem.attrs.hasOwnProperty('scaleX')) layer.children[childrenIndex].setAttrs({ scaleX: 1 })
          if (!targetItem.attrs.hasOwnProperty('scaleY')) layer.children[childrenIndex].setAttrs({ scaleY: 1 })
          if (layer.children[childrenIndex].className == 'Label' || layer.children[childrenIndex].className == 'Group') {
            layer.children[childrenIndex].children.forEach((item, i) => {
              item.setAttrs(targetItem.children[i].attrs)
            })
          }
          this.removeTransformer()
          // this.removeTextTransformer()
          let tr = new Konva.Transformer({
            anchorStroke: '#00a6ff',
            anchorFill: '#fff',
            anchorSize: 12,
            anchorCornerRadius: 5,
            anchorStrokeWidth: 2,
            borderStroke: '#6ac9fc',
            borderStrokeWidth: 2,
            borderDash: [3, 3],
            padding: 10,
            name: 'default'
          })
          layer.add(tr)
          tr.nodes([layer.children[childrenIndex]])
          this.$parent.addMenuBtnToTr(tr, layer.children[childrenIndex])
          layer.batchDraw()
        }
        //複合物件點擊目標待修
      } else if (targetAction.action == 'delete') {
        let childrenIndex = layer.children.findIndex(x => x.attrs.uuid == JSON.parse(targetAction.item).attrs.uuid)
        if (childrenIndex != -1) {
          layer.children[childrenIndex].destroy()
          layer.draw()
        }
      }
    },

     addUndoSingleImg(target, parent) {
      return new Promise(async(r,j)=>{
       let layer = this.$store.state.layer
      let blobUrl = this.classInfo.blob
      let finallink = target.attrs.src.includes(blobUrl.sas_read) ? target.attrs.src : target.attrs.src + '?' + blobUrl.sas_read

      let imageObj = new Image()
      imageObj.setAttribute('crossOrigin', 'Anonymous')
      imageObj.src = finallink
      imageObj.onload = await function() {
        Konva.Image.fromURL(finallink, function(image) {
          image.setAttrs({
            ...target.attrs,
            parent: parent
          })
          parent.add(image)
          image.zIndex(target.attrs.index >= parent.children.length ? parent.children.length - 1 : target.attrs.index)
          if (target.attrs.name == 'boardPdfBg') {
            image.moveToBottom()
          } else {
            if (image.zIndex() == 0) image.zIndex(1)
          }
          layer.batchDraw()
          r(200)
        })
      }
      imageObj.onerror =function(){
        j('err')
      }
      })
      
    },

    //紀錄Undo位移行為
    saveUndoUpdate() {
    //儲存undo歷史
    if (this.currentSelectElement !== '') {
      // console.log('偵測滑鼠點按物件放開', this.currentSelectElement.attrs.x, this.currentSelectElement.attrs.y)
      //選中物件調整細節判斷
      if (this.currentSelectElement.className == 'Rect' && this.currentSelectElement?.parent?.className == 'Transformer') {
        this.currentSelectElement = this.currentSelectElement.parent._nodes[0]
        // console.log(this.currentSelectElement, '調整縮放時選中的元素')
      }
      //便利貼, 挑人貼回名牌, 作品收集圖
      if ((this.currentSelectElement.className == 'Text' && this.currentSelectElement?.parent?.className == 'Label')|| (this.currentSelectElement?.attrs?.name=='pastTextPickName')|| (this.currentSelectElement?.attrs?.name=='pastImgContent')) {
        this.currentSelectElement = this.currentSelectElement.parent
      }
      console.log(this.currentSelectElement, '調整縮放時選中的元素')
      
      let finalhistoryItem = this.undoHistory[this.undoHistory.length - 1]
      if (!this.currentSelectElement.attrs.hasOwnProperty('x')) this.currentSelectElement.attrs.x = 0
      if (!this.currentSelectElement.attrs.hasOwnProperty('y')) this.currentSelectElement.attrs.y = 0
      if (!this.currentSelectElement.attrs.hasOwnProperty('rotation')) this.currentSelectElement.attrs.rotation = 0
      if (!this.currentSelectElement.attrs.hasOwnProperty('scaleX')) this.currentSelectElement.attrs.scaleX = 1
      if (!this.currentSelectElement.attrs.hasOwnProperty('scaleY')) this.currentSelectElement.attrs.scaleY = 1

     
        if (finalhistoryItem?.action != 'update') {
          this.saveUndoHistory('update',this.currentSelectElement)
        } else {
          let finalhistoryItemAttr = JSON.parse(this.undoHistory[this.undoHistory.length - 1].item)
          if (this.currentSelectElement.attrs.x != 0 && this.currentSelectElement.attrs.y != 0) {
            //判斷位移是否相同
            if (this.currentSelectElement.attrs.x != finalhistoryItemAttr.attrs.x || this.currentSelectElement.attrs.y != finalhistoryItemAttr.attrs.y) {
             this.saveUndoHistory('update',this.currentSelectElement)
            }
          }
        }
      
    }
  },

    async pushStageImg() {
      // this.$Message.info('推送圖片功能')

      //第一動：點擊舞台拍照上傳blob
      let blobUrl = this.classInfo.blob
      let stage = this.$store.state.stage
      let layer = this.$store.state.layer

      //幫舞台建立一個白底
      let background = new Konva.Rect({
        x: 0,
        y: 0,
        fill: 'white',
        width: window.innerWidth,
        height: window.innerHeight,
        illLinearGradientStartPoint: { x: 0, y: 0 },
        listening: false,
        name: 'whiteRect'
      })

      layer.add(background)
      background.moveToBottom()
      layer.draw()

      //發出推送圖片訊息
      let times = new Date().getTime()

      let pushImgFile = await this.dataURLtoFile(stage.toDataURL({ mimeType: 'image/jpeg', quality: 0.9 }), 'stageImg' + this.$parent.nowpageRender.nowpageIds + times + '.jpg')
      // console.log(pushImgFile)
      //拍完照就刪掉白色背景
      layer.children.forEach(item => {
        if (item.className == 'Rect' && item.attrs.name == 'whiteRect') {
          item.destroy()
        }
      })

      this.$parent.uploadResourceFile(pushImgFile, 'Host/Task').then(res => {
        //上傳推送圖片，配合WebIRS5同HT之blob路徑配置
        console.log(res.url + '?' + blobUrl.sas_read, '上傳推送圖片')
        let msgInitiative = {
          action: 'Stage.Push', //注意:調整對內運作的命名，避免跟收作品收集的 Page.Push 衝到，但送的時候給WebIRS5 也是跟HT一樣要叫Page.Push
          clientType: 'HiTeachCC',
          sender: sessionStorage.getItem('user').sub,
          timestamp: times,
          waitReturn: false,
          payload: ''
        }

        this.$parent.singnalRMessage(msgInitiative, '8888', '', '')
      })
    },

    //判斷是否要加推送畫面，上傳畫面，搭配作品收集,單選題文字題
    async genePushStageImg() {
      let layer = this.$store.state.layer
      if (layer.children.length > 0) {
        await this.pushStageImg()
      }
    },
    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    controlView() {
      if (this.styleObj.isShow) {
        this.closeFan()
      } else {
        this.popuphd()
      }
    },
    setMode(value) {
      this.removeTransformer()
      this.$store.state.touchHideMenu = true
      this.$store.state.mode = value
      //平板需另行控制iview poptip
      if (value == 'irs') {
        this.penPopVisible = false
        this.shapePopVisible = false
        this.zoomPopVisible = false
      } else if (value == 'pencil-alt') {
        this.zoomPopVisible = false
        this.shapePopVisible = false
        this.irsToolPopVisible = false
        this.hb()
      } else if (value == 'shape') {
        this.zoomPopVisible = false
        this.penPopVisible = false
        this.irsToolPopVisible = false
        this.hb()
      } else if (value == 'text') {
        this.zoomPopVisible = false
        this.penPopVisible = false
        this.shapePopVisible = false
        this.$parent.closeBuzzPop()
        this.$store.state.currentEditTextNode = ""
        this.$store.state.showtextInuptCard = true
        this.$store.state.isEditingTextNow = true
        this.hb()
      } else if (value == 'zooms') {
        this.penPopVisible = false
        this.shapePopVisible = false
        this.irsToolPopVisible = false
        this.$store.state.zoomMode = 'bar'
      } else if (value == 'addImg') {
        this.$store.state.showAddImgBox = true
        this.penPopVisible = false
        this.zoomPopVisible = false
        this.shapePopVisible = false
        this.irsToolPopVisible = false
      } else {
        this.penPopVisible = false
        this.zoomPopVisible = false
        this.shapePopVisible = false
        this.irsToolPopVisible = false
      }
      // console.log(this.$store.state.mode)
    },

    //IRS即问即答和填空
    currentstudents() {
      return this.$store.state.students
    },
    currentIRS() {
      return this.$store.state.irsModel
    },
    popuphd() {
      this.$emit('popuphd')
    },
    zoomToDefault() {
      this.$store.state.zoomType = 'same'
      if (this.$store.state.zoom == 1) {
        //Board原本用Watch無偵測改變，直接處理歸位
        let zoom = this.$store.state.zoom
        let stage = this.$store.state.stage
        stage.absolutePosition({
          x: (-stage.width() / 2) * (zoom - 1),
          y: (-stage.height() / 2) * (zoom - 1)
        })
        stage.position({
          x: (-stage.width() / 2) * (zoom - 1),
          y: (-stage.height() / 2) * (zoom - 1)
        })
        stage.batchDraw()
      } else {
        //跳到Board原本用Watch處理ZoomFunc
        this.$store.state.zoom = 1
      }
    },
    async irsstart(name) {
      // console.log(this.$parent.irshint)
      if (this.isSendIRS == false && this.currentstudents() != '') {
        if (this.$store.state.irsModel != 'normal') {
          if (name === 'irsGather' && this.$store.state.workNum == this.limitCollate) {
            this.$Message.info(this.$t("board['無法使用，已達您的權限作品收集數上限']") + ':' + this.limitCollate)
          } else {
            await this.addNew()
            this.$Message.info(this.$t("board.toolbox['此页已有题目，自動新增一页开启互动']"))
            if (!this.skipCopyPageAskbyTA) this.$store.state.isShowAskCopyPage = true
            this.$store.state.isShowAskCopyPageIRSName = name
            this.$store.state.isLightPieChart = false
            this.$parent.irshint = true
            // console.log(this.$store.state.imgArr)
            // this.irsstart(name)
            return
          }
        } else {
          this.$parent.opennewpage()

          let that = this
          let times = new Date().getTime()
          //关于irs即问即答和文字
          let msgInitiative = {
            action: 'State.Get',
            clientType: 'HiTeachCC',
            sender: sessionStorage.getItem('user').sub,
            timestamp: times,
            waitReturn: false,
            payload: ''
          }

          if (name === 'irs') {
            console.log(this.$parent, '父级内容')
            this.handleFan()

            this.$store.state.irsModel = 'irsImmediately'

            //新開重新校正條圖X軸作答長度
            this.$store.state.AnswerSelect = ['A', 'B', 'C', 'D']
            this.$store.state.AnswerNumselect = ['1', '2', '3', '4']
            // this.$parent.irsModel = 'irsImmediately'
            //  this.$store.state.beforeSaveStageGenID=true

            this.$parent.singnalRMessage(msgInitiative, '8888', '', 'irsImmediately')
            setTimeout(() => {
              that.genePushStageImg()
              that.$store.state.timeLineEvent.push({
                Time: (Math.floor(Date.now()) - that.$store.state.startTime) / 1000,
                Pgid: that.$parent.nowpageRender.nowpageIds,
                EventId: 786435,
                Event: 'PopQuesLoad',
                PopOpitonCount: 9
              })
            }, 1000)
            //儲存mqttIoTData，有使用互動
            this.$store.state.mqtt.mqttIoTData.IRSType_Interact = 1
            this.$store.dispatch('mqtt/saveMqttIoTsessionStorage')

            this.$parent.irshint = false
          } else if (name === 'text') {
            this.handleFan()

            this.$store.state.irsModel = 'irsText'
            // this.$parent.irsModel = 'irsText'
            // this.$store.state.beforeSaveStageGenID=true
            this.$parent.singnalRMessage(msgInitiative, '8888', '', 'irsText')
            setTimeout(() => {
              that.genePushStageImg()
              that.$store.state.timeLineEvent.push({
                Time: (Math.floor(Date.now()) - that.$store.state.startTime) / 1000,
                Pgid: that.$parent.nowpageRender.nowpageIds,
                EventId: 786435,
                Event: 'PopQuesLoad',
                PopOpitonCount: 9
              })
            }, 1000)
            //儲存mqttIoTData，有使用互動
            this.$store.state.mqtt.mqttIoTData.IRSType_Interact = 1
            this.$store.dispatch('mqtt/saveMqttIoTsessionStorage')

            this.$parent.irshint = false
          } else if (name === 'irsBuzz') {
            this.closeFan()
            this.$store.state.irsModel = 'irsBuzz'
            this.$parent.isOpenBuzzPop = true
            this.$store.state.buzzInStudents = []
            setTimeout(() => {
              that.$store.state.timeLineEvent.push({
                Time: (Math.floor(Date.now()) - that.$store.state.startTime) / 1000,
                Pgid: that.$parent.nowpageRender.nowpageIds,
                EventId: 786458,
                Event: 'BuzrAns'
              })
            }, 1000)
            this.$parent.singnalRMessage(msgInitiative, '8888', '', 'irsBuzz')
            // this.$store.state.beforeSaveStageGenID=true

            //儲存mqttIoTData，有使用互動
            this.$store.state.mqtt.mqttIoTData.IRSType_Interact = 1
            this.$store.dispatch('mqtt/saveMqttIoTsessionStorage')

            this.$parent.irshint = false
            console.log('irsBuzz')
          } else if (name === 'irsGather' && this.$store.state.workNum < this.limitCollate) {
            this.closeFan()
            this.$parent.workStatus = true
            this.$store.state.irsModel = 'irsGather'
            // this.$parent.irsModel = 'irsGather'
            this.$store.state.workNum = this.$store.state.workNum + 1
            sessionStorage.setItem('workNum', this.$store.state.workNum)
            this.$store.state.currentWorkNum = this.$store.state.workNum
            // this.$store.state.beforeSaveStageGenID = true

            this.$parent.singnalRMessage(msgInitiative, '8888', '', 'irsGather')
            setTimeout(() => {
              that.genePushStageImg()
              that.$store.state.timeLineEvent.push({
                Time: (Math.floor(Date.now()) - that.$store.state.startTime) / 1000,
                Pgid: that.$parent.nowpageRender.nowpageIds,
                EventId: 917505,
                Event: 'WrkSpaceLoad'
              })
            }, 1000)

            //儲存mqttIoTData，有使用作品任務
            this.$store.state.mqtt.mqttIoTData.IRSType_CollateTask = 1
            this.$store.dispatch('mqtt/saveMqttIoTsessionStorage')

            this.$parent.irshint = false
          } else if (name === 'irsGather' && this.$store.state.workNum == this.limitCollate) {
            this.$Message.info(this.$t("board['無法使用，已達您的權限作品收集數上限']") + ':' + this.limitCollate)
          }
          this.irsToolPopVisible = false
        }
        this.isSendIRS = true
        setTimeout(() => {
          this.isSendIRS = false
        }, 1000)
        clearTimeout(this.waitingCopyPageTimer)
      }
      if (this.currentstudents() == '') {
        this.$Message.info(this.$t("board.toolbox['IRS沒有學生提示訊息']"))
      }
    },

    // 菜单
    handleMenu() {
      // this.styleObj.isShow = true
      // this.styleObj.width = '1000px'
      // this.styleObj.height = '600px'
      // // this.styleObj.maxWidth = "400px";
      // // this.styleObj.marginLeft = "0px";
      //this.styleObj.marginLeft = '0px'
      // this.styleObj.marginTop = '0px'
      // this.styleObj.backgroundColor = '#fff'
      // this.componentIndex = 10
      // this.isShowCard = true
      this.$emit('HiisShow', true)
    },
    // 菜单
    irsNews(index) {
      this.styleObj.isShow = true
      this.styleObj.width = '1000px'
      this.styleObj.height = '600px'
      // this.styleObj.maxWidth = "400px";
      // this.styleObj.marginLeft = "0px";
      this.styleObj.marginTop = '-50px'
      this.styleObj.backgroundColor = '#fff'
      this.componentIndex = 11
      this.isShowCard = true
    },
    // 挑人
    handlePick() {
      this.$store.state.turnTableＭode = 'Normal'
      this.$emit('handleToolPick', 1)
      document.body.classList.remove('cursor-fdj')
      this.$parent.mode = 'check'
      this.$store.state.mode = 'check'
      this.$store.state.checkvalue = 'checkbtn'
    },
    // 倒计时
    handleTimer() {
      this.$emit('handleToolPick', 2)
      document.body.classList.remove('cursor-fdj')
    },
    // 翻牌
    handleRang() {
      this.styleObj.isShow = true
      this.styleObj.width = '1800px'
      this.styleObj.height = '400px'
      this.styleObj.maxWidth = '1800px'
      //this.styleObj.marginLeft = '0px'
      //this.styleObj.marginTop = '420px'
      this.styleObj.backgroundColor = '#f9f9f9'
      this.componentIndex = 7
      this.isShowCard = true
    },

    // 翻牌
    handleFan() {
      this.styleObj.isShow = true
      this.styleObj.width = '100%'
      this.styleObj.height = '300px'
      this.styleObj.maxWidth = '100%'
      this.styleObj.marginLeft = '0px'
      this.styleObj.marginTop = '0px'
      //this.styleObj.position= 'fixed'
      //this.styleObj.bottom='0px'
      this.styleObj.backgroundColor = '#EDEDED'
      this.styleObj.boxShadow = '0px 0px 10px 6px #707070'
      //this.styleObj.overflow='hidden'
      this.componentIndex = 1
      this.isShowCard = true
      this.$store.state.isShowCard = true
      this.$store.state.isLightPieChart = false
    },
    closeFan() {
      this.styleObj.isShow = false
      this.isShowCard = false
      this.$store.state.isShowCard = false
    },
    // 柱状图
    handleEchart() {
      this.styleObj.isShow = true
      this.styleObj.width = '1400px'
      this.styleObj.height = '400px'
      this.styleObj.maxWidth = '1400px'
      this.styleObj.marginLeft = '0px'
      this.styleObj.marginTop = '420px'
      this.styleObj.backgroundColor = '#f9f9f9'
      this.componentIndex = 4
      this.isShowCard = true
    },

    // 饼图
    handlePie() {
      this.styleObj.isShow = true
      this.styleObj.width = '1400px'
      this.styleObj.height = '400px'
      this.styleObj.maxWidth = '1400px'
      this.styleObj.marginLeft = '0px'
      this.styleObj.marginTop = '420px'
      this.styleObj.backgroundColor = '#f9f9f9'
      this.componentIndex = 5
      this.isShowCard = true
    },
    //下一页
    async nextPage() {
      let that = this
      if (this.preventfastChangePage) return
      if (this.$store.state.isRenderingPDFNow == false) {
        clearTimeout(this.preventfastChangePageTimeOut)
        this.closeFan()
        if (this.preventfastChangePage == false) {
          var cfilStatus = sessionStorage.getItem('fileType')
          var nPage = sessionStorage.getItem('slide')
          var allPage = this.$store.state.totalpage
          if (cfilStatus === 'pdf') {
            // this.carousel.next()
            if (this.$store.state.carouselSlide < this.$store.state.imgArr.length) {
              this.$store.state.carouselSlide = this.$store.state.carouselSlide + 1
            } else {
              this.$Message.warning(this.$t("board.toolbox['目前已經是最後一頁']"))
            }
          } else if (cfilStatus === 'ppt') {
            if (nPage !== allPage) {
              this.$store.state.carouselSlide = this.$store.state.carouselSlide + 1
            }
          }
          let index = this.$store.state.carouselSlide - 1
          this.$parent.nowpageRender.nowpageIds = await this.$store.state.imgArr.idArr[index].pageid
          this.$parent.reSendNormalState()
          this.preventfastChangePage = true
          this.$q.loading.show()
        }
        //控制最快只能1秒切一頁，太快切換會造成舞台渲染側邊欄縮圖不正確
        this.preventfastChangePageTimeOut = setTimeout(() => {
          that.$q.loading.hide()
          that.preventfastChangePage = false
        }, 600)
      }
    },
    //上一页
    async previousPage() {
      let that = this
      if (this.preventfastChangePage) return
      if (this.$store.state.isRenderingPDFNow == false) {
        clearTimeout(this.preventfastChangePageTimeOut)
        this.closeFan()

        if (this.preventfastChangePage == false) {
          var cfilStatus = sessionStorage.getItem('fileType')
          var nPage = sessionStorage.getItem('slide')
          if (cfilStatus === 'pdf') {
            // this.carousel.previous()
            console.log(this.$store.state.carouselSlide)
            if (this.$store.state.carouselSlide > 1) {
              this.$store.state.carouselSlide = this.$store.state.carouselSlide - 1
            }
          } else if (cfilStatus === 'ppt') {
            if (nPage > 1) {
              this.$store.state.carouselSlide = this.$store.state.carouselSlide - 1
            }
          }
          let index = this.$store.state.carouselSlide - 1
          this.$parent.nowpageRender.nowpageIds = await this.$store.state.imgArr.idArr[index].pageid
          this.$parent.reSendNormalState()
          this.preventfastChangePage = true
          this.$q.loading.show()
        }
        //控制最快只能1秒切一頁，太快切換會造成舞台渲染側邊欄縮圖不正確
        this.preventfastChangePageTimeOut = setTimeout(() => {
          that.$q.loading.hide()
          that.preventfastChangePage = false
        }, 600)
      }
    },
    addNew() {
      if (this.$store.state.isRenderingPDFNow == false) {
        this.closeFan()
        this.$store.state.addNew = true
        this.$parent.sendNormalInCurrentPage()
      }
    },
    hb() {
      document.body.classList.remove('cursor-fdj')
    },
    boardCtrlbyTA(BoardAction) {
      console.log(BoardAction, 'Board.Ctrl!')
      switch (BoardAction) {
        case 'Up': {
          this.previousPage()
          break
        }
        case 'Down': {
          this.nextPage()
          break
        }
        case 'Add': {
          this.addNew()
          break
        }
        case 'PagePush': {
          this.pushStageImg()
          break
        }
      }
    },
    irsCtrlbyTA(irsPayload) {
      console.log(irsPayload, 'IRS.Ctrl!')
      let irsName = ''
      let waitingPopupTimer
      clearTimeout(waitingPopupTimer)
      switch (irsPayload.action) {
        case 'Quiz': {
          irsName = 'irs'
          this.callIrsStartByTA(irsName)
          break
        }
        case 'Flip': {
          if (this.$store.state.irsModel == 'irsImmediately' && !this.styleObj.isShow) this.popuphd()
          waitingPopupTimer = setTimeout(() => {
            if (this?.$refs?.card?.$refs?.optionView) this?.$refs?.card?.$refs?.optionView.listbtn('default')
          }, 200)
          break
        }
        case 'Quiz_Buzz': {
          irsName = 'irsBuzz'
          if (irsPayload.buzzAction == 'none') this.callIrsStartByTA(irsName)
          else if (irsPayload.buzzAction == 'Start') {
            this.$parent.isOpenBuzzPop = true
            this.$store.state.answervalue != '' ? this.$parent.reStartBuzz() : this.$parent.startBuzz()
          }
          break
        }
        case 'PieChart': {
          if (this.$store.state.irsModel == 'irsImmediately' && !this.styleObj.isShow) this.popuphd()
          waitingPopupTimer = setTimeout(() => {
            if (this?.$refs?.card?.$refs?.optionView) this?.$refs?.card?.$refs?.optionView.listbtn('pie')
          }, 200)
          break
        }
        case 'BarChart': {
          if (this.$store.state.irsModel == 'irsImmediately' && !this.styleObj.isShow) this.popuphd()
          waitingPopupTimer = setTimeout(() => {
            if (this?.$refs?.card?.$refs?.optionView) this?.$refs?.card?.$refs?.optionView.listbtn('bar')
          }, 200)
          break
        }
        case 'SetAnswer': {
          if (this.$store.state.irsModel == 'irsImmediately' && !this.styleObj.isShow) this.popuphd()
          waitingPopupTimer = setTimeout(() => {
            if (this?.$refs?.card?.$refs?.optionView?.$refs.ansResult) {
              const numbtndata = this.$refs.card.$refs.optionView.$refs.ansResult.numbtndata
              const btnIndex = numbtndata.findIndex(item => this.$store.state.currentAnsOption=='En'?item.english == irsPayload.answerList[0]:item.num == irsPayload.answerList[0])
              console.log(irsPayload)
              this?.$refs?.card?.$refs?.optionView?.$refs.ansResult.ClickAnswer(this.$store.state.currentAnsOption=='En'?numbtndata[btnIndex].english:numbtndata[btnIndex].num, btnIndex, numbtndata[btnIndex].classname)
            }
          }, 200)
          break
        }
        case 'SwitchSize': {
          this.controlView()
          break
        }
      }
    },
    callIrsStartByTA(irsName) {
      if (this.$store.state.irsModel != 'normal') this.skipCopyPageAskbyTA = true
      this.irsstart(irsName)
      this.waitingCopyPageTimer = setTimeout(() => {
        if (this.skipCopyPageAskbyTA) {
          this.$parent.copyPageIRSAct()
          this.skipCopyPageAskbyTA = false
        }
      }, 200)
    }
  },
  created() {},
  mounted() {
     Vue.prototype.$toolbox = this
  },
  computed: {
    ...mapGetters({
      classInfo: 'classInfo/getInfo' // 取得課堂設定
    }),
    watchUrl() {
      return this.$store.state.penType
    },
    watchShapeType() {
      return this.$store.state.shapeType
    },
    watchCarousel() {
      return this.$store.state.carousel
    },
    watchMode() {
      return this.$store.state.mode
    },
    touchHideMenu() {
      return this.$store.state.touchHideMenu
    }
  },
  watch: {
    watchUrl(e) {
      if (e) {
        this.penType = this.$store.state.penType
      }
    },
    watchShapeType(e) {
      if (e) {
        console.log(e)
        this.shapeType = this.$store.state.shapeType
        this.shapeTypeIcon = this.$store.state.shapeTypeIcon
      }
    },
    watchCarousel(e) {
      if (e) {
        this.carousel = this.$store.state.carousel
      }
    },
    watchMode(e) {
      if (e) {
        this.mode = this.$store.state.mode
      }
    },
    touchHideMenu(value) {
      if (value == false) {
        this.penPopVisible = false
        this.zoomPopVisible = false
        this.shapePopVisible = false
        this.irsToolPopVisible = false
      }
    }
  }
}
</script>

<style lang="less">
.vertical-tools {
  .ivu-poptip-popper {
    left: -75px !important;
    min-width: 0 !important;
  }

  .ivu-radio-wrapper {
    display: block !important;
    margin-bottom: 5px !important;
  }
  .slider_box {
    width: 20px !important;
  }

  .secondary_title {
    text-align: left;
  }
  .shapes_icon {
    margin: 12px 0;
    &:hover {
      color: blue;
    }
  }
}
.irsPop {
  min-width: unset !important;
  top: unset !important;
  bottom: 5px !important;
  left: -75px !important;
  width: 54px !important;

  .ivu-poptip-inner {
    width: 54px !important;
    .ivu-poptip-body {
      padding: 8px 10px;
    }
  }
}
.irs-subbtn {
  @media (max-height: 959px) {
    display: none;
  }
}
.irs-mainbtn {
  @media (min-height: 960px) {
    display: none;
  }
  @media (max-height: 600px) {
    display: none;
  }
}
.irs-smallbtn {
  @media (min-height: 601px) {
    display: none;
  }
}

.q-color-picker__palette-rows--editable .q-color-picker__cube {
  border-radius: 50%;
  margin: 4px;
  width: 20px !important;
  height: 20px;
  border: 2px solid #fff;
}

.q-color-picker {
  box-shadow: none !important;
}

.shapes_icon {
  cursor: pointer;
}

.ivu-slider-button-wrap {
  top: -1px !important;
}

</style>
