<template>
  <div class="board-menu">
    <Tabs :animated="false">
      <TabPane label="基本信息">
        <div class="text-box">
          <h6>
            <img :src="headImg" alt="" class="headImg">
            <span>
              {{userName}}
            </span>
          </h6>
        </div>
        <div class="text-box">
          <h6 style="margin-bottom: 10px;">2016学年度1年智慧B</h6>
          <ul>
            <li>
              <div style="display: inline-block">
                <!--<b>教室编号</b>-->
                <p><img src="../../assets/img/QRcode.jpg" alt=""></p>
                <p class="tc">{{classNum}}</p>
              </div>

            </li>
            <!--<li>-->
              <!--<b>验证码</b>-->
              <!--&lt;!&ndash;<p>&#45;&#45;&#45;&#45;</p>&ndash;&gt;-->
              <!--<p><img src="../../assets/img/QRcode.jpg" alt=""></p>-->
            <!--</li>-->
          </ul>
        </div>
        <!--<div class="text-box">-->
          <!--&lt;!&ndash;<h5>扫码进入教室</h5>&ndash;&gt;-->
          <!--<ul>-->
            <!--<li>-->
              <!--<div style="display: inline-block;">-->
                <!--<img src="../../assets/img/QRcode.jpg" alt="">-->
                <!--<p class="tc"><span>扫码进入教室</span></p>-->
              <!--</div>-->

            <!--</li>-->
          <!--</ul>-->
        <!--</div>-->
      </TabPane>
      <TabPane label="上传文件">
          <!--<Upload action="/api/file/UploadDocument"
                  :on-success="handleUploadSuc"
                  :before-upload="before" />
          <Button icon="ios-cloud-upload-outline">选择文件</Button>-->
          <Upload :on-success="handleUploadSuc" 
                  accept="pdf" max-size="31457280" 
                  :on-exceeded-size="filesize"
                  :on-format-error="filetype"
                  :on-error="handleUploadError" 
         action="/api/file/UploadDocument" >
          <Button icon="ios-cloud-upload-outline">选择文件</Button>
          </Upload>
      </TabPane>
      <TabPane label="退出教室">
        <p><Button type="primary" @click="quit()">退出教室</Button></p><br/>
      </TabPane>
    </Tabs>
  </div>
</template>

<script>
  export default {
    name: "",
    data() {
      return {
        file: null,
        loadingStatus: false,
        userInfo:JSON.parse(sessionStorage.getItem('userInfo')),
        userName:'',
        headImg:'',
        classNum:sessionStorage.getItem('classNum'),
      }
    },
    methods: {
        handleUploadSuc(response) {
            console.log(response.result,'返回的数据')
            this.$store.state.uploadUrl = response.result.data.model.blobUrl;
            this.$store.state.totalpage = response.result.data.page
            //if (response.result.data.page > 50 && response.result.message === 'Error') {
            //    this.$Message.error('PDF页数超过限制！请重新选择上传');
            //} else if (response.result.message === 'Success') {
            //    this.$Message.success('PDF页数超过限制！请重新选择上传');
            //}
           this.$Message.success('上传成功');
            console.log('这是上传成功的返回')
        },
        handleUploadError() {
            this.$Message.error('上传失败！请查看文件大小或PDF 是否符合标准');
            console.log('这是上传失败的返回')
        },
        filesize() {
            this.$Message.error('上传失败！文件大小超过限制');
        },
        filetype() {
             this.$Message.error('上传失败！文件格式不是PDF,请上传PDF为类型的文件');
        },
      userInfoSet(){
        if(this.userInfo){
          this.userName=this.userInfo.fullName;
          this.headImg=this.userInfo.headImgUrl;
        }
        },
        before(file) {
            this.file = file
            const FileExt = this.file.name.replace(/.+\./, "");//取得文件的后缀名
            console.log(file.size, '文件大小')  //单位为Bytes
            if (file.size > 31457280) {          //限制文件的大小
                    this.$Message.error(file.name + '大小超过30M!')
                    this.file = null //超过大小将文件清空
                } else if (['pdf'].indexOf(FileExt.toLowerCase()) === -1) { //判断文件是否是pdf格式
                    this.$Message.error('请上传以pdf结尾的文件');
                    this.file=null
                }else{
                    const reader = new FileReader() //读取文件的字符流
                reader.readAsDataURL(file)//将文件读取为 DataURL 以data:开头的字符串
                reader.onload = e => {
                        // 读取到的图片base64 数据编码 将此编码字符串传给后台即可
                        const code = e.target.result;
                        this.algorithmData.videoFiles = code
                    }
                }
             return false
        },
        quit() {//退出教室
            var storage = window.sessionStorage;
            storage.clear()
            this.$router.push('/')
        }
    },
    mounted() {

    },
    created(){
      this.userInfoSet();
    }
  }
</script>

<style>

</style>
