<template>
  <div class="question-box">

    <div class="question-key">
      <p><i class="material-icons" style="color: #008000;font-size: 18px;">school</i> 题型：</p>
      <RadioGroup v-model="shape" type="button"  @on-change="questionType">
        <Radio label="单选"></Radio>
        <Radio label="多选"></Radio>
        <Radio label="判断"></Radio>
        <Radio label="简答"></Radio>
      </RadioGroup>
    </div>

    <!--单选题 -->
    <div class="question_content" v-if="questionname =='单选'">
      <div class="content_title">
        <p><i class="material-icons" style="color: #3993f1;font-size: 18px;">layers</i> 题目：</p>
        <q-input v-model="text" filled type="textarea"/>
        <!--<Input v-model="text" type="textarea" :autosize="true" placeholder="Enter something..." />-->
      </div>
      <div class="content_select">
        <p><i class="material-icons" style="color: #ff871c;font-size: 18px;">notes </i> 选项：</p>
        <div class="q-gutter-sm">
          <Input v-model="textselectA"  size="large" ><span slot="prepend">A</span></Input>
          <Input v-model="textselectB" size="large"><span slot="prepend">B</span></Input>
          <Input v-model="textselectC" size="large"><span slot="prepend">C</span></Input>
          <Input v-model="textselectD" size="large"><span slot="prepend">D</span></Input>
        </div>
      </div>
    </div>


    <!--多选-->
    <div class="question_content" v-if="questionname =='多选'">
      <div class="content_title">
        <p><i class="material-icons" style="color: #3993f1;font-size: 18px;">layers</i> 题目：</p>
        <q-input v-model="text" filled type="textarea"/>
      </div>
      <div class="content_select">
        <p><i class="material-icons" style="color: #ff871c;font-size: 18px;">notes </i> 选项：</p>
        <div class="q-gutter-sm">
          <Input v-model="textselectA" size="large"><span slot="prepend">A</span></Input>
          <Input v-model="textselectB" size="large"><span slot="prepend">B</span></Input>
          <Input v-model="textselectC" size="large"><span slot="prepend">C</span></Input>
          <Input v-model="textselectD" size="large"><span slot="prepend">D</span></Input>
        </div>
      </div>
    </div>


    <!--判断题-->
    <div class="question_content" v-if="questionname =='判断'">
    <div class="content_title">
      <p><i class="material-icons" style="color: #3993f1;font-size: 18px;">layers</i> 题目：</p>
    <q-input
    v-model="text"
    filled
    type="textarea"
    />
    </div>
    <div class="content_select">
      <p><i class="material-icons" style="color: #ff871c;font-size: 18px;">notes </i> 选项：</p>
    <div class="q-gutter-sm">
      <Input v-model="textselectA"  size="large"><span slot="prepend">是</span></Input>
      <Input v-model="textselectB"  size="large"><span slot="prepend">非</span></Input>
    </div>
    </div>
    </div>


    <!--简答题-->
    <div class="question_content" v-if="questionname =='简答'">
    <div class="content_title">
      <p><i class="material-icons" style="color: #3993f1;font-size: 18px;">layers</i> 题目：</p>
    <q-input
    v-model="text"
    filled
    type="textarea"
    />
    </div>
    </div>
    <div class="submit_btn tc" @click="submitQuestion"><Button type="primary" size="large">提交</Button></div>
  </div>
</template>

<script>
    export default {
        name: "question",
      data(){
          return{
            shape: '单选',
            questionname:'单选',
            text: '',
            textselectA:'',
            textselectB:'',
            textselectC:'',
            textselectD:'',
          }
      },
      methods:{
        questionType:function(value){
          this.questionname=value;
        },
        submitQuestion:function () {
          console.log(11)
          this.$store.state.question.submit='1';
          this.$store.state.question.text=this.text;
          this.$store.state.question.textselectA=this.textselectA;
          this.$store.state.question.textselectB=this.textselectB;
          this.$store.state.question.textselectC=this.textselectC;
          this.$store.state.question.textselectD=this.textselectD;
        }
      },
    }
</script>
<style>
  .q-textarea,.q-field__control{
    max-height:200px !important;
  }
  .q-field__native{
    max-height:195px !important;
  }
  .q-field__label{font-size: 14px !important;}
</style>
<style scoped>
  .ivu-radio-group-button .ivu-radio-wrapper{border:1px solid #ddd !important;margin:5px;border-radius: 5px !important;}
  .ivu-radio-group-button .ivu-radio-wrapper-checked{background:#10abe7;color:#fff;border-color: #10abe7 !important;}
.q-radio{margin-right:10px;}
.question-box{
  width:750px;
  height:700px;
  padding: 30px ;
}
.question-key{
}
.question_content{

}
.content_title>p,.content_select>p,.question-key>p{
  margin:20px 0;
}
.select_box{
  padding: 15px  20px 15px 20px;
  display: block;
}
 .submit_btn{
   /*float: right;*/
   margin-right: 50px;
   margin-top: 60px;
 }
  .submit_btn button{
    /*margin-left:5px;*/
    background: #10abe7;border-color: #10abe7;width: 200px;
  }
  /*.q-gutter-y-sm, .q-gutter-sm{margin-top:0;margin-left:0;}*/
</style>
<style>
  .q-field--filled .q-field__control{background: none !important;border:1px solid #ddd !important;}
  .ivu-input-group-prepend{width: 50px !important;}
  .ivu-input-group{margin:15px 0;}
</style>
