<template>
  <div class="board">
    <!--清空畫面提示訊息-->
    <div v-show='showCanvasClearDangerHint' class="textinput-view">>
      <div class="textinput-card">
        <p>{{ $t("board['清空畫布提示字']") }}</p>
        <div class="textinputbtn-group">
          <div class="textinput-btn" @click="confirmClear()">{{ $t("board['確定']") }}</div>
          <div class="textinput-btn" @click="cancelClear()">{{ $t("board['取消']") }}</div>
        </div>
      </div>
    </div>
     <!--清空作品收集提示訊息-->
     <div v-show='showWorksClearDangerHint' class="textinput-view">>
      <div class="textinput-card">
        <p>{{ $t("board['清空作品收集提示字']") }}</p>
        <div class="textinputbtn-group">
          <div class="textinput-btn" @click="clearWorkData()">{{ $t("board['確定']") }}</div>
          <div class="textinput-btn" @click="showWorksClearDangerHint=false">{{ $t("board['取消']") }}</div>
        </div>
      </div>
    </div>
    <!--閒置過長訊息-->
    <div v-show="showIdlelogout" class="textinput-view">
      <div class="textinput-card">
        <p>{{ $t("board['閒置視窗文字']") }}</p>
        <div class="textinputbtn-group">
          <div class="textinput-btn" @click="backToUse()">{{ $t("board['仍要使用']") }}</div>
        </div>
      </div>
    </div>
    <!--開頭上傳PDF的頁數選單-->
    <PDFInitPageSelect ref="PDFInitPageSelect" v-show="this.$store.state.showPDFInitPageSelect" :currentPDFInsertFile="currentPDFInitFile" />
    <!--開課後插入PDF的選單-->
    <PDFInsert v-show="this.$store.state.showPDFInsert" :currentPDFInsertFile="currentPDFInsertFile" />
    <!--圖片工具箱選單-->
    <AddImgBox v-show="this.$store.state.showAddImgBox" ref="addImgBox" />

    <!--簡易自訂名單介面-->
    <EditMemberList ref="editMemberList" />

    <!--GPT工具箱選單-->
    <GptBox v-show='showGptBox' ref="gptBox" />

    <!--文字輸入框介面-->
    <div class="textinput-view" v-show="this.$store.state.showtextInuptCard">
      <div class="textinput-card">
        <p class="textinput-title">{{ $t("board['文字工具']") }}</p>
        <div class="textinput-scrollarea">
          <textarea class="textinput" v-model="currentAddTextValue" :placeholder="$t('board.輸入文字')"></textarea>
          <div class="divide-wrap">
            <div class="divide-line" />
          </div>
          <TextPicker :updateTextPickerKey="updateTextPickerKey" />
          <div class="stickerbg-setting">
            <p class="menu-title">{{ $t("board['便利貼顏色']") }}</p>
            <div class="q-gutter-sm">
              <div class="color-btn" :class="{ 'select-color': currentStickerColor == item }" :style="{ 'background-color': item }" v-show="index < 4" v-for="(item, index) in stickerColorArr" :key="index + 'sticker'" @click="setStickerColor(item)" />
              <div class="transparent-btn" @click="setStickerColor('transparent')"><svg-icon icon-class="no-color" class="nocolor-icon" :class="{ 'set-nocolor': currentStickerColor == 'transparent' }" /></div>
            </div>
          </div>
        </div>
        <div class="textinputbtn-group">
          <div class="textinput-btn" @click="addTextBox()">{{ $t("board['確定']") }}</div>
          <div class="textinput-btn" @click="closeTextInputView()">{{ $t("board['取消']") }}</div>
        </div>
      </div>
    </div>
    <!--IRS複製頁面-->
    <div v-show="this.$store.state.isShowAskCopyPage" class="copyPage-view">
      <div class="confirm-card">
        <p>{{ $t("board['選擇新增活動時之頁面內容']") }}</p>
        <div class="confirmbtn-group">
          <div class="confirm-btn" @click="copyPageIRSAct()">{{ $t("board['複製前一頁面']") }}</div>
          <div class="confirm-btn" @click="emptyPageIRSAct()">{{ $t("board['空白頁面']") }}</div>
        </div>
      </div>
      <div class="trigger-area" @click="copyPageIRSAct()"></div>
    </div>
    <!--螢幕大小基本需求說明-->
    <div class="screen-note" v-show="showScreenNote">
      <svg-icon class="screen-note-icon" icon-class="screen-note"></svg-icon>{{ $t("board['螢幕大小基本需求說明']") }}
      <div class="close-btn" @click="showScreenNote = false"><svg-icon icon-class="Close" class="close-icon"></svg-icon></div>
    </div>
    <!--<BoardHead></BoardHead>-->
    <div v-show="this.$store.state.isRenderingPDFNow" class="render-info">{{ this.$store.state.PDFInitRenderInfo.renderCount + "/" + totalpage }}&nbsp;&nbsp;{{ $t("board['正在努力加载，请稍等...']") }}</div>
    <!--匯出PDF進度框-->
    <div class="pdfexport-view" v-show="this.$store.state.isExportPDFNow">
      <div class="pdfexport-card">
        <p class="pdfexport-title" v-show="exportPDFMode == 'downloadOnly' || exportPDFMode == 'addZip'">{{ $t("board['轉檔匯出中請稍候']") }}</p>
        <p class="pdfexport-page" v-show="exportPDFMode == 'downloadOnly' || exportPDFMode == 'addZip'">{{ this.$store.state.carouselSlide + "/" + totalpage }}</p>
        <svg-icon icon-class="IES5" v-show="exportPDFMode == 'upload'" class="ies5-icon" />
        <p class="pdfexport-title" v-show="exportPDFMode == 'upload'">
          {{ $t("board['匯出與上傳課堂數據到IES5']") }}
        </p>
        <p class="pdfexport-page" v-show="exportPDFMode == 'upload'">{{ this.$store.state.carouselSlide + "/" + totalpage }}</p>
        <div class="render-bar" v-show="!renderEachPage">
          <Progress :percent="isNaN(((this.$store.state.carouselSlide / totalpage) * 100).toFixed(0)) ? 0 : parseInt(((this.$store.state.carouselSlide / totalpage) * 100).toFixed(0))" hide-info />
        </div>
        <div class="pdfexport-group">
          <div class="pdfexport-btn" @click="cancelPdfExport()">{{ $t("board['取消']") }}</div>
        </div>
      </div>
    </div>
    <!--右上資訊區-->
    <div class="top-right-info">
      <!-- <p @click="sendIoTReportToMQTT()">sendIoTReportToMQTT()</p> 測試IoT使用 -->
      <p class="class-num" @click="showStudentList()" v-if="classNum && showClassNum"><svg-icon icon-class="qrcode" class="q-icon" style="margin-top: -6px; font-weight: bold; margin-left: 2px" />{{ classNum }}</p>
      <div class="pdfnum" @click="openSideBar()">
        <div class="pdftext"><svg-icon class="doc-type" :class="{ 'stroke-icon': filetypeImage != 'default-doc' }" :icon-class="filetypeImage" />{{ slidenum }}/{{ totalpage }}</div>
      </div>

      <div class="teachinfo" @click="showUserInfo()">
        <img class="teachimg" :src="teachImg" />
        <p class="teachname">{{ teachname }}</p>
      </div>
      <!-- <div class="total-studentnum" @click="showStudentList()"><svg-icon icon-class="students" class="students-icon" />{{ students.length }}</div> -->
      <!-- <div class="screenshot-btn" @click="takeScreenShot()"><svg-icon icon-class="screenshot" class="screenshot-icon" /></div> -->
    </div>

    <div class="board-main">
      <ToolBox v-show="isViewWorkImg == false" @handleToolPick="handleToolPick" @HiisShow="Hibtn" ref="hd" :value="StudentPercentage" @popuphd="popuphd" :limitCollate="limitCollate"></ToolBox>
      <!-- <div class="percentage-studentanswer-under" @click="popuphd()" v-show="students != ''">
        <q-circular-progress show-value font-size="8px" :value="StudentPercentage" size="45px" :thickness="0.22" color="teal" track-color="grey-3" class="q-ma-md"> {{ StudentPercentage }}% </q-circular-progress>
      </div> -->
      <div style="position: fixed; z-index: 1; background-color: #ececec">
        <br />
        <Modal v-model="modalUpload" :mask-closable="false" class-name="open-card">
          <div slot="footer">
            <div class="screen-title-top">
              <div class="screen-title-icon"><svg-icon icon-class="htc_cc" /></div>
              <!-- {{ $t("board['歡迎使用HiTeachCC來進行互動']") }}<br /-->{{ !showMemberlist ? $t("board['請選擇開始的動作']") : $t("memberlist['請選擇學生名單']") }}
              <div class="memberlist-page" v-show="showMemberlist&&isAuthAccount()!=0">
                <!-- <div class="previusImgBtn2" ><svg-icon icon-class="previusImgBtn" class="ImgBtn-icon" /></div> -->
                <div class="memberfreebtn" @click="freeAttend()" :class="{ 'memberfreebtn-light': Object.keys(currentMemberList).length == 0&&!isUseIES5studentList }">{{$t("memberlist['自由加入']")}}</div>
                <div class="memberfreebtn" @click="openEditMemberListAtInit()" v-if="customMemberlist==''">{{$t("memberlist['創建自訂學生名單']")}}</div>
                
                <div class="memberlist-wrap" v-if="customMemberlist!=''">
                  <div class="listitems">
                    <div class="listitem" v-for="(list, index) in customMemberlist" :key="list.listName + index" @click="setCurrentMemberList(list)" :class="{ 'listitem-light': currentMemberList.listID == list.listID&&!isUseIES5studentList }">
                      {{ list.listName }}
                    </div>
                  </div>
                  <div class="listDetail">
                    <div class="list-stu" v-show="Object.keys(currentMemberList).length >= 0" v-for="(stu, index) in currentMemberList.list" :key="index + stu.seatID + stu.memberName">{{ stu.seatID }} {{ stu.memberName }}</div>
                    <div class="list-info" v-show="Object.keys(currentMemberList).length == 0">
                      <p>{{ $t("memberlist['點選左側指定可加入的學生名單']") }}</p>
                      <p>{{ $t("memberlist['點選Hi進行編輯']") }}</p>
                    </div>
                  </div>
                </div>
                <div class="memberfreebtn" @click="isUseIES5studentList=true" v-if="showIES5studentListBtn" :class="{ 'memberfreebtn-light': isUseIES5studentList }">{{$t("memberlist['使用IES5課程名單']")}}</div>
                <div class="page-btn" @click="setStudentsFromList()" >{{$t("memberlist['下一步']")}}</div>
              </div>
            </div>
            <div v-show="!showMemberlist">
              <div class="upload-box">
                <Button class="fl" @click="uploading('IES5PDF')" v-if="classInfo.materialUrl != ''">
                  <svg-icon icon-class="IES5" class="ies5-icon" /><br />
                  {{ $t("board['開啟IES5所選教材']") }}
                </Button>
                <Upload action="" :on-success="handleUploadSuc" class="fl" :style="'display: ' + pdfRow" :on-error="handleUploadError" :accept="Accept" :format="Format" :on-exceeded-size="handleMaxSize" :on-format-error="handleFormatError" :before-upload="uploading">
                  <Button class="fl" @click="uploadPDF">
                    <i class="far fa-file-pdf" style="font-size: 40px; margin-bottom: 5px"></i><br />
                    {{ $t("board['開啟PDF檔案']") }}
                  </Button>
                </Upload>

                <!--<input type="file" id="fileExport" @change="" ref="inputer" class="f1">-->
                <!--<Button @click="uploadPPT" class="gray">
                              <i class="far fa-file-powerpoint" style="font-size: 40px;margin-bottom: 5px;"></i><br>
                              载入PPT
                          </Button>-->
                <Button :loading="modal_loading" @click="openNew">
                  <font-awesome-icon icon="plus-square" style="font-size: 40px; margin-bottom: 5px" v-if="!modal_loading" />
                  <p>{{ $t("board['直接使用畫板']") }}</p>
                </Button>
              </div>
              <div class="defaultpdf-title" @click="uploadingDefaultPDF()">
                <p><svg-icon icon-class="attachment" class="attach-icon" />{{ $t("board['參閱使用範例PDF']") }}</p>
              </div>
              <div class="divide-wrap">
                <div class="divide-line" />
              </div>
              <div class="setting-title">
                <p>{{ $t("board['IRS單選題選項設置']") }}</p>
                <div>
                  <div class="setting-btn" :class="{ 'setting-btn-light': this.$store.state.currentAnsOption == 'En' }" @click="setAnsOption('En')"><svg-icon icon-class="SetAnsEn" /></div>
                  <div class="setting-btn" :class="{ 'setting-btn-light': this.$store.state.currentAnsOption == 'Num' }" @click="setAnsOption('Num')"><svg-icon icon-class="SetAns" /></div>
                </div>
                <div class="page-btn cancel-btn" @click="backChooseMemberList()" v-show="isAuthAccount()!=0&&!isAuthOnlyPureScan()">{{$t("memberlist['上一步']")}}</div>
              </div>
            </div>
            <div class="limit-text">
              {{ $t("board['您的使用權限']") }}：{{ $t("board['學生連線數']") }}：<span class="limit-num">{{ limitClient }}</span
              >, {{ $t("board['作品收集數']") }}：<span class="limit-num">{{ limitCollate }} </span>
              <p class="limit-num" v-show="limitTime != 0">&nbsp;&nbsp;({{ $t("board['權限到期']") + "：" + limitTime }})</p>
              <p class="limit-num" v-show="aigptTime != 0">&nbsp;&nbsp;({{ $t("board['aigptEXP']") + "：" + aigptTime }})</p>
              <p v-if="!schoolName" class="limit-note">{{ $t("board['權益說明']") }}</p>
              <p v-if="schoolName" class="limit-note">{{ schoolName }}</p>
            </div>

            <!-- <p class="screen-title" :style="{ color: isfullnow ? 'blue' : 'gray' }" @click="screenbtn('success')"><svg-icon icon-class="full-screen" class="fullscreen-icon" />{{ $t("board['全屏幕']") }}</p> -->
          </div>
        </Modal>
      </div>
      <div class="board-right">
        <div class="boardArea">

        <div id="gpt-drag" @click="dragElement('gpt-drag')" v-drag  v-show="canShowGptDragBall&&!showGptBox&&$refs.gptBox.currentText!=''" > 
          <div  class='gpt-smallbtn'  @click="showGptBox=true">Gpt</div> 
        </div>

          <!--pdf生成的png和轮播-->
          <Pdf @LoadingPdf="PdfshowLoading" @LoadingPdfPage="LoadingPage" ref="Pdf" style="z-index: 99"></Pdf>
          <!--konva容器-->
          <div id="lastPagebox" v-show="lastpageShow"></div>
          <div id="container"></div>
          <div id="nextPagebox" v-show="nextpageShow"></div>
          <div id="readyRenderBox" v-show="readyBox"></div>
          <!--iframe-->
          <!-- <iframe id="iframeEchart" :srcdoc="iframeValue"></iframe> -->
          <!--题目内容-->
          <div class="exercise-container" v-if="topics">
            <p v-html="topics.question" class="item-question" id="itemQuestion" @click="onStemClick(topics.question)"></p>
            <div v-for="(item, index) in topics.option" :key="index" class="item-option" draggable="true">
              <span class="item-order">{{ item.code }} :</span>
              <span class="item-text">{{ item.value }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--挑人-->
    <div id="turnTable" class="drag-wrap picker-drag" v-drag v-if="toolIndexList.indexOf(1) > -1" @click="dragElement('turnTable')">
      <div class="tool-icon-close" @click="handleCloseTool(1)"><svg-icon icon-class="Close" class="close-icon" /></div>
      <TurnTable ref="TurnTable" />
    </div>

    <!--定时器新-->
    <div id="timer" class="drag-wrap-button-timer timer-drag" v-drag v-if="toolIndexList.indexOf(2) > -1" @click="dragElement('timer')" :class="{ 'timer-small': isTimerStart() == true }">
      <ButtonTimer ref="ButtonTimer" />
      <div class="close-btn" @click="handleCloseTool(2)"><svg-icon icon-class="Close" class="close-icon" /></div>
    </div>

    <!--Hi界面-->
    <div class="q-pa-md row items-start q-gutter-md" v-if="Hishow">
      <q-card class="hi-card">
        <div class="hicard-title-icon"><svg-icon icon-class="htc_cc" /></div>
        <div class="section">
          <!--Upload action="" :on-success="handleUploadSuc" :accept="Accept" :format="Format" :on-exceeded-size="handleMaxSize" :on-format-error="handleFormatError" :before-upload="" class="hicard-btn" @click="uploadPDF"-->
          <!--<div class="open-other">-->
          <!--svg-icon icon-class="open-file" class="hicard-icon" />
            <p>開啟文件</p-->
          <!--q-btn color="primary" label="开启其他文件并重启互动" size="15px" /-->
          <!--</div>-->
          <!--/Upload-->
          <div class="hicard-btn" @click="eliminatepage">
            <svg-icon icon-class="new-file" class="hicard-icon" />
            <p>{{ $t("board['重啟課堂']") }}</p>
            <!-- <q-btn color="secondary" label="清除页面重启互动" size="15px" /> -->
          </div>

          <div class="hicard-btn" @click="quitbtns">
            <svg-icon icon-class="log_out" class="hicard-icon" />
            <p>{{ $t("board['結束登出']") }}</p>
            <!-- <q-btn label="退出教室" size="15px" style="background: #FF0080;color: white" /> -->
          </div>

          <div class="hicard-btn" @click="exportPDF('downloadOnly')" v-show="!this.isAuthAccount()||(this.isAuthAccount() != 0&&isAuthOnlyPureScan())">
            <svg-icon icon-class="download" class="hicard-icon download-icon" />
            <p>{{ $t("board['匯出PDF']") }}</p>
            <!-- <q-btn label="退出教室" size="15px" style="background: #FF0080;color: white" /> -->
          </div>
          <div class="hicard-btn" v-show="isAuthAccount() != 0&&!isAuthOnlyPureScan()" @click="exportRecordZip()">
            <svg-icon icon-class="download" class="hicard-icon download-icon" />
            <p>{{ $t("board['匯出課堂記錄']") }}</p>
          </div>
          <label class="hicard-btn" v-if="showInsertPDFBtn">
            <i class="far fa-file-pdf" style="padding: 5px; font-size: 40px" />
            <p>{{ $t("board['插入PDF']") }}</p>
            <input @click="resetpdfUploader()" ref="pdfUploader" type="file" id="addpdfFile" accept=".pdf" @change="openPDFInsert" />
          </label>
          <div class="hicard-btn" v-show="isAuthAccount() != 0&&!isAuthOnlyPureScan()" @click="openEditMemberList()">
            <svg-icon icon-class="memberlist" class="hicard-icon memberlist-icon" />
            <p>{{ $t("board['自訂學生名單']") }}</p>
          </div>
          <div class="closebtn" @click="closeHi"><img src="@/statics/iconsvg/interaction/Close.svg" class="q-icon" /></div>
        </div>
      </q-card>
    </div>
    <!--PDF加载过度动画-->
    <div class="q-pa-md row items-start q-gutter-md" v-show="topicedit">
      <q-card class="hi-card">
        <q-card-section>
          <div id="editor">
            <div ref="editor" style="text-align: left"></div>
            <p>{{ EditContent }}</p>
          </div>
          <div class="closebtn" @click="closeHi('topic')"><img src="@/statics/iconsvg/interaction/Close.svg" class="q-icon" /></div>
          <div class="cancelbtn" @click="closeHi('topic')"><img src="@/assets/img/topic-cancel.png" class="q-icon" /></div>
          <div class="okbtn" @click="topicalter()"><img src="@/assets/img/topic-ok.png" class="q-icon" /></div>
        </q-card-section>
      </q-card>
    </div>
    <!--作品收集-->
    <div class="q-pa-md row items-start q-gutter-md workCollect" v-if="workStatus == true" :class="{ 'workCollect-full': isViewWorkImg == true }">
      <div v-show="isShowAskPasteWhichPage" class="askPasteWhichPage-view">
        <div class="confirm-card">
          <p>{{ $t("board['選擇貼回之頁面']") }}</p>
          <div class="confirmbtn-group">
            <div class="confirm-btn" @click="pasteWorks()">{{ $t("board['貼到本頁']") }}</div>
            <div class="confirm-btn" @click="pasteWorksToNewPage()">{{ $t("board['貼到新增的空白頁']") }}</div>
          </div>
        </div>
      </div>
      <div class="workheader" @click="isViewWorkImg = false">
        <div class="workname"><svg-icon icon-class="collate" class="fill-icon collate-icon" />{{ $t("board['作品收集']") }}{{ currentWorkNum() }}</div>
        <!-- {{ currentCollateItem().mode }} -->
        <div class="workbtn-group">
          <div class="workbtn" v-show="isViewWorkImg == false" @click="sortWorkData()" :class="{ 'starbtn-on': this.$store.state.isSortWork == true }"><svg-icon icon-class="sortNum" class="sort-icon" /></div>
          <div class="workbtn" v-show="isViewWorkImg == false" @click="pasteTargetMode()">
            <svg-icon icon-class="HTPaste" class="htpaste-icon" /><span class="work-badge" v-show="currentCheckToPasteItem.length > 0">{{ currentCheckToPasteItem.length }}</span>
          </div>
          <div class="workbtn" v-show="isViewWorkImg == false" @click="hideName()" :class="{ 'starbtn-on': this.$store.state.hideStudentName == true }">
            <svg-icon :icon-class="this.$store.state.hideStudentName == false ? 'eye-show' : 'eye-hide white'" />
          </div>
          <div class="workbtn" v-show="isViewWorkImg == false" @click="filterCheckedwork()" :class="{ 'starbtn-on': this.$store.state.isFilterCheckwork == true }"><svg-icon icon-class="star" /></div>
          <div class="workbtn" v-show="isViewWorkImg == false" @click="showWorksClearDangerHint=true"><svg-icon icon-class="trash" class="trash-icon" /></div>
          <div class="workbtn" v-show="isViewWorkImg == false" @click="stopwork"><svg-icon :icon-class="currentIcon()" :class="{ 'stop-icon': this.currentCollateItem().mode == 'Collate_Start' }" /></div>
          <div class="workbtn" v-show="isViewWorkImg == true" @click="isViewWorkImg = false"><svg-icon icon-class="grid" /></div>
        </div>
      </div>
      <div class="workArea">
        <div class="work-wrapper" v-show="isViewWorkImg == false">
          <div class="workDetails" v-for="(item, index) in currentCollateWorkData()" :key="index + forceUpdateNum" :id="currentWorkNum() + '_collatework' + item.seat" :class="{ 'student-info-hidebgc': isPasteImg == true }">
            <div class="workImg" @click="viewWorkImg(item, index)"><img :src="item.url" /></div>
            <div class="student-info">
              <div class="num">{{ item.seat }}</div>
              <div class="workImgname">{{ hideStudentName() == false ? item.name : "" }}</div>
              <div class="work-star" @click="setworkChecked(item)" :class="{ 'workstar-light': item.isChecked == true }"><svg-icon icon-class="star" /></div>
              <div v-show="isPasteImg == false" class="work-checkbox" @click="setworkCheckedToPaste(item)" :class="{ 'work-checkboxlight': item.isCheckedToPaste == true }">
                <svg-icon v-show="!item.isCheckedToPaste" icon-class="check" /> <p v-show="item.isCheckedToPaste">{{ item.checkNum }}</p>
              </div> 
            </div>
          </div>
        </div>
        <div v-show="isViewWorkImg == true">
          <div class="workImg-view">
            <div class="left-button">
              <div class="previusImgBtn" @click="viewWorkPrevImg()"><svg-icon icon-class="previusImgBtn" class="ImgBtn-icon" /></div>
              <div class="nextImgBtn" @click="viewWorkNextImg()"><svg-icon icon-class="nextImgBtn" class="ImgBtn-icon" /></div>
            </div>
            <div class="right-button">
              <div class="previusImgBtn2" @click="viewWorkPrevImg()"><svg-icon icon-class="previusImgBtn" class="ImgBtn-icon" /></div>
              <div class="nextImgBtn2" @click="viewWorkNextImg()"><svg-icon icon-class="nextImgBtn" class="ImgBtn-icon" /></div>
            </div>
            <img :src="workImgData.url" />
            <div class="workImg-info">
              <div class="num">{{ workImgData.seat }}</div>
              <div class="workImgname">{{ workImgData.name }}</div>
            </div>
          </div>
        </div>
        <div class="noData" v-show="currentCollateWorkData().length == 0">
          <p><svg-icon icon-class="empty-data" class="empty-icon" /><br />{{ $t("board['暫無數據']") }}</p>
        </div>
      </div>
      <div v-show="isViewWorkImg == false" class="workclose" @click="closeWorkCollate()"><svg-icon class="hideIRSArrow-icon" icon-class="hideIRSArrow" /></div>
    </div>
    <!--作品收集end-->
    <!--搶答視窗-->
    <div class="buzzin-card" v-show="isOpenBuzzPop == true && irsModel == 'irsBuzz'">
      <audio id="audio3" src="../assets/audio/cymbal001.wav"></audio>
      <div class="buzzin-cardleft">
        <p class="buzz-btn-title" v-show="isWaitingBuzz == false && this.$store.state.answervalue == ''">{{ $t("board['請按鈕開始接收搶權']") }}</p>
        <div class="buzz-btn" @click="startBuzz()" v-show="isWaitingBuzz == false && this.$store.state.answervalue == ''">
          <svg-icon icon-class="buzzin" class="buzz-icon" />
        </div>
        <div class="buzz-waiting" v-show="isWaitingBuzz == true && this.$store.state.answervalue == ''">
          <img src="../assets/img/buzzloading.svg" class="buzzloaging-icon" />
          <img src="../assets/img/buzzloading2.svg" class="buzzloaging2-icon" />
          <p class="buzz-waiting-text">{{ $t("board['等待搶權']") }}</p>
        </div>
        <div v-show="this.$store.state.answervalue != ''" class="pick-target">
          <img v-if="this.$store.state.answervalue.headImg != ''" :src="this.$store.state.answervalue.headImg" alt="" />
          <div v-else class="pick-default-img"></div>
          <div class="pick-info">
            <div class="pick-sort">{{ this.$store.state.answervalue.sort }}</div>
            <span v-show="this.$store.state.answervalue.studentName != ''" class="pick-text">{{ this.$store.state.answervalue.studentName }}</span>
          </div>
          <div class="refresh-block" @click="reStartBuzz()">
            <div class="refresh-btn"><svg-icon icon-class="refresh" class="refresh-icon" /></div>
            <p class="refresh-text">{{ $t("board['繼續搶權']") }}</p>
          </div>
        </div>
      </div>
      <div class="buzzin-cardright" v-show="this.$store.state.buzzInStudents != ''">
        <div v-for="(item, index) in this.$store.state.buzzInStudents" :key="item.accountid + index" class="student-card">
          <div class="student-leftimg">
            <div class="student-idnumber">{{ item.sort }}</div>
            <div class="student-portrait"><img :src="item.headImg" /></div>
          </div>
          <div class="student-rightname">{{ item.studentName }}</div>
        </div>
      </div>
      <div v-show="this.$store.state.buzzInStudents != ''" class="trash-btn" @click="clearBuzzInStudents()"><svg-icon icon-class="trash" class="trash-icon" /></div>
      <div class="close-btn" @click="closeBuzzPop()"><svg-icon class="hideIRSArrow-icon" icon-class="hideIRSArrow" /></div>
    </div>

    <!--新版Qrcode與學生名單彈窗-->
    <div class="student-List" v-show="isShowStudentList == true && !modalUpload">
      <div v-if="students.length != 0" class="student-length"><svg-icon icon-class="students" class="students-icon" />{{ onlineSs + "/" + students.length }}</div>
      <div class="limit-text" v-if="students.length != 0">
        {{ $t("board['您的使用權限']") }}：{{ $t("board['學生連線數']") }}：<span class="limit-num">{{ limitClient }}</span
        >, {{ $t("board['作品收集數']") }}：<span class="limit-num">{{ limitCollate }}</span>
      </div>
      <div class="col-wrap">
        <div class="qrcode-group" :class="{ 'qrcode-groupleft': students.length != 0 }">
          <h5 v-if="students.length == 0">{{ $t("board.qrcodePop['請掃碼QRcode 用瀏覽器參與課堂互動']") }}</h5>
          <h5 class="qrcode-left" v-if="students.length != 0">{{ $t("board.qrcodePop['請掃碼QRcode']") }}<br />{{ $t("board.qrcodePop['用瀏覽器參與課堂互動']") }}</h5>
          <div class="visitor-switch"><i-switch style="margin-right: 8px" v-model="allowVisitor" />{{ $t("board.qrcodePop['allowVisitor']") }}</div>
          <p v-if="classInfo.className != ''" class="qrcode-classname">{{ classInfo.className }}</p>
          <div id="qrcode" ref="qrcode" align="center" :class="{ 'qrcodeimg-groupleft': students.length != 0 }"></div>
          <div class="qrcode-num" @click="copyToClipboard()">
            <span id="qrcode-num">{{ classNum }}:8888</span><span class="copy-btn"><svg-icon class="copy-icon" iconClass="copy" /></span>
          </div>
          <div class="qrcode-note-wrap">
            <p class="qrcode-note">{{ $t("board.qrcodePop['或直接在瀏覽器上輸入下列網址']") }}</p>
            <p class="irs-link">{{ qrcodeIRS5link }}</p>
            <p class="qrcode-note-bottom">{{ $t("board.qrcodePop['提示：許多手機掃碼工具內建的瀏覽器功能受限，建議掃碼後另開系統瀏覽器']") }}</p>
          </div>
        </div>
        <div class="student-group" v-show="students.length != 0">
          <div v-for="item in students" :key="item.accountid" class="student-card">
            <div class="student-leftimg">
              <div class="student-idnumber" :style="item.status == 'offline' ? 'background-color: #c0c0c0' : ''">{{ item.sort }}</div>
              <div class="student-portrait"><img :src="item.headImg ? item.headImg : require('@/assets/img/profile-user.svg')" /></div>
            </div>
            <div class="student-rightname">{{ item.studentName }}</div>
          </div>
        </div>
        <!-- <div class="noData" >
          <p>暂无数据</p>
        </div> -->
        <div class="close-btn" @click="closeStudentList()"><svg-icon icon-class="Close" class="close-icon" /></div>
      </div>
    </div>
    <div class="textPicker-tool" v-show="this.$store.state.showtextInuptCard == false && isEditingTextNow && (textPickerToolpos.x != 0) & (textPickerToolpos.y != 0)" :style="{ left: textPickerToolpos.x + 'px', top: textPickerToolpos.y + 'px' }">
      <TextPicker :updateTextPickerKey="updateTextPickerKey" />
    </div>
    <div class="obj-menu" v-show="showObjMenu" :style="{ left: objMenuPos.x + 'px', top: objMenuPos.y + 'px' }">
      <p @click="deleteObjByObjMenu()">{{ $t("board.objMenu.刪除物件") }}</p>
      <p @click="copyPasteByObjMenu()">{{ $t("board.objMenu.複製") }}</p>
      <p @click="moveToTopByObjMenu()">{{ $t("board.objMenu.移到最上層") }}</p>
      <p @click="moveToBottomByObjMenu()">{{ $t("board.objMenu.移到最下層") }}</p>
      <p @click="toImageByObjMenu()">{{ $t("board.objMenu.將物件複製為圖片到剪貼簿") }}</p>
      <p @click="toTextByObjMenu()" v-show="showObjMenuTextCopyOption">{{ $t("board.objMenu.將物件複製為文字到剪貼簿") }}</p>
      <p @click="sendTextToIRSByObjMenu()" v-show="showObjMenuTextCopyOption">{{ $t("board.objMenu.發送文字") }}</p>
      <p @click="openGptBox()" v-show="showObjMenuTextCopyOption&&isAuthOpenAIAccount()">{{$t('gptBox["Gpt工具"]')}}</p>
    </div>
    <div class="obj-menu" v-show="showRightClickMenu"  :style="{ left: rightClickMenuPos.x + 'px', top: rightClickMenuPos.y + 'px' }">
      <p @click="pasteFromClipboard()">{{ $t("board.rightClickMenu.從剪貼簿貼上") }}</p>
    </div> 
    <saveIES savePath="IES" />
  </div>
</template>
<script>
/* eslint-disable */
import Vue from "vue";
import { mapGetters } from "vuex";
import QRCode from "qrcodejs2";
import screenfull from "screenfull";
import Pdf from "@/views/pdf.vue";
import html2canvas from "html2canvas";
import moment from "moment";
import BoardHead from "@/components/Common/BoardHead.vue";
import BoardLeft from "@/components/Common/BoardLeft.vue";
import ToolBox from "@/components/ToolBox.vue";
import Card from "../components/Card.vue";
import Pop from "../components/ColorPop/ColorPicker.vue";
import shapes from "../components/ColorPop/Shapes.vue";
import saveIES from "../components/saveIES.vue";

import ButtonTimer from "../components/Tools/ButtonTimer.vue";
import BasePick from "../components/Tools/BasePick.vue";
import TurnTable from "../components/Tools/turnTable.vue";
import TextPicker from "../components/ColorPop/TextPicker.vue";
import PDFInsert from "../components/PDFInsert.vue";
import PDFInitPageSelect from "../components/PDFInitPageSelect.vue";
import AddImgBox from "../components/AddImgBox.vue";
import EditMemberList from "../components/EditMemberList.vue";
import GptBox from '../components/GptBox.vue'

import PDFJS from "pdfjs-dist";
import RenderContent from "../assets/test/test.json";
import { jsPDF } from "jspdf";
import { drawRect, drawArc, drawEllipse, drawImage, drawPath, drawLine, drawTable, drawEchart, drawMath } from "../plugin/PublicRenderer.js";
import E from "wangeditor";
import Upblob from "../plugin/blobTool.js";
import jwt_decode from "jwt-decode";
// import E from "@/plugin/wangEditor.js"
import moreoage from "../assets/99page/htex.json";
import { signalRInfo, sendMessage, onConnected, ImData } from "../plugin/signalr.js";
import { locale } from "core-js";
import profile from "@/utils/profile";
const width = window.innerWidth;
const height = window.innerHeight;
let vm = {};
const signalR = require("@microsoft/signalr");
const de = require("@/utils/lib1.js");
import enc from "@/utils/enc.js";
import JSZip from "jszip";
import * as XLSX from "xlsx/xlsx.mjs";
import * as fs from "fs";
import { Readable } from "stream";
import * as cpexcel from "xlsx/dist/cpexcel.full.mjs";
import { saveAs } from "file-saver";

XLSX.set_fs(fs);
XLSX.stream.set_readable(Readable);
XLSX.set_cptable(cpexcel);
import * as clipboard from "clipboard-polyfill";
import { parse } from 'path';

export default {
  components: {
    Pdf,
    BoardHead,
    BoardLeft,
    ToolBox,
    Card,
    Pop,
    shapes,
    ButtonTimer,
    BasePick,
    TurnTable,
    QRCode,
    TextPicker,
    PDFInsert,
    PDFInitPageSelect,
    AddImgBox,
    saveIES,
    EditMemberList,
    GptBox
  },
  data() {
    return {
      // activityRecordNumberS: sessionStorage.getItem('activityRecordNumberS') ? sessionStorage.getItem('activityRecordNumberS') : 'HiCC-' + this.$jsFn.getUUID(),
      activityRecordNumberS: "",
      carousel: this.$store.state.carousel,
      modalUpload: true,
      modalUploadType: true,
      modal_loading: false,
      openNewWord: "开启新画板",
      whiteBG: require("../assets/img/white.jpg"),
      addBlank: false,
      deletePage: false,
      uploadPptText: false,
      showPPT: true,
      pdfRow: "inline-block",
      isOpen: true,
      students: this.$store.state.students, // 学生名单
      onlineSs: 0, //上線數
      memberList: [],
      verifyMode: "Dynamic",
      className: "HiTeachCC",
      schoolId: "",
      schoolName: "",
      allowVisitor: true,
      allowSitin: true,
      slide: 1,
      saveArr: [],
      dataURL: "",
      canvasImg: require("../assets/img/QRcode.jpg"),
      classNum: sessionStorage.getItem("classNum"),
      icon: true,
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      alert: false,
      tx: false,
      value1: 0,
      componentIndex: 0,
      list: [],
      isShowCard: true,
      configKonva: {
        width: width,
        height: height,
      },
      styleObj: {
        isShow: false,
        width: "0px",
        height: "0px",
        maxWidth: "0px",
        marginLeft: "0px",
        marginTop: "0px",
        backgroundColor: "unset",
        boxShadow: "unset",
      },
      filetypeImage: "default-doc",
      stage: null,
      layer: null,
      info: "",
      client: null,
      mqmsg: "这是一条测试信息",
      directMethod: "",
      boardImg: {},
      toolIndexList: [],
      loginBack: {
        ticket: "",
        name: "",
        teamModelId: "",
      },

      /*回应者ID*/
      responderId: "",
      /*是否全屏*/
      isFullscreen: "",
      isfullnow: false,
      inception: true,
      secondDialog: false,
      ScreenSuccess: require("../assets/img/screenfull-Dsuccess.png"),
      ScreenError: require("../assets/img/screenfull-Derror.png"),
      /*文字模式*/
      irstext: this.$store.state.irsBack,
      /*学生完成度value值*/
      answernum: 0,
      StudentPercentage: 0,
      /*PDF页数相关值*/
      slidenum: 1,
      totalpage: this.$store.state.totalpage,
      /*PDF相关*/
      allPages: null,
      startNew: 1,
      /*HI界面呈现*/
      Hishow: false,
      /*文件类型过滤*/
      Accept: ".pdf",
      Format: ["pdf"],
      loadingpage: "0",
      pdfloadingpage: "",
      /*XY坐标*/
      oldonex: "",
      oldoney: "",
      newPoints: [],
      //题目
      topics: null,
      topicedit: false,
      editorinfo: null,
      //判断是否题目值
      TypeVerdict: "",
      //当时编辑内容
      EditContent: "",
      //答案选项下标
      OptionNum: "",
      Editorinfo: "",
      layersinfo: "",
      //关于blob上传
      blobinit: "",
      uploadedList: [],
      blobPath: "",
      //快捷画布
      //FastLayer: '',
      //预加载
      lastpage: "",
      lastpageShow: false,
      nextpage: "",
      nextpageShow: false,
      //截图新的背景图片
      newBackgroundima: "",
      //iframe值
      iframeValue: [],
      //预备补全缩略图
      readyBox: false,
      //原始page总数
      beginPage: 0,
      //文字模式
      isText: false,
      //IM相关连接数据
      imConnect: {
        access_token: "",
        channel_api: "",
        channel_num: "",
        channel_rest_api: "",
        channel_url: "",
      },
      IMcounter: 0,
      //當前最大座位号
      seatNum: 0,
      //加入学生单个信息
      studentAlone: {},
      nowpageRender: {
        //当前页的题目类型以及ID各种资讯
        nowpageType: "", //当前页的题目类型
        nowpageIds: "",
      },
      //IRS互动状态
      irshint: true,
      irsModel: "normal",
      //解析出来原始的高的值
      primevalHeight: 0,
      primevalWidth: 0,
      primevalScale: 0,
      primevalInit: {
        width: 0,
        height: 0,
        scale: 0,
        x: 0,
      },
      //作品收集状态
      workStatus: false,
      worksData: [
        //{ id: 1, seatnum: 1, name: '高校校', imgUrl: require('../assets/img/head2.jpg') },
      ],
      teachImg: JSON.parse(sessionStorage.getItem("user")).picture,
      teachname: JSON.parse(sessionStorage.getItem("user")).name,
      //作品收集开关
      workSwitch: false,
      workInsidedata: [],

      //停止收集的icon和文字
      workinfo: { icon: "stop", text: "停止收集" },
      isShowStudentList: false,
      forceUpdateNum: 0,
      isOpenBuzzPop: false,
      isWaitingBuzz: false,

      shareQRInfo: {
        gn: "",
        pin: "",
        host: "",
      },
      shareQRCode: "",
      jointUrl: "",
      textColor: this.$store.state.textColor,
      textSize: this.$store.state.textSize,
      updateTextPickerKey: 0,
      textPickerToolpos: {
        x: 0,
        y: 0,
      },
      isViewWorkImg: false,
      workImgData: {
        seat: "",
        name: "",
        url: "",
      },
      workImgIndex: 0,
      isRequestReBuild: false, //主動重建教室
      isFilterCheckwork: this.$store.state.isFilterCheckwork,
      currentCheckToPasteItem: [],
      isPasteImg: false,
      sendEndMsgID: "",
      sendEndMsgTimes: 0,
      qrcodeIRS5link: "",
      pagePushHistory: [],
      messagePushHistory: [],
      //數量限制
      limitCollate: 0,
      limitClient: 0,
      limitTime: 0,
      aigptTime: 0,
      //限制只提示一次
      limitClientHint: false,
      isShowAskPasteWhichPage: false, //詢問要貼本頁或新增到一頁空白頁貼回,
      showScreenNote: true,
      currentAddTextValue: "", //文字框加入
      currentPDFInsertFile: "",
      currentPDFInitFile: "",
      showInsertPDFBtn: false,
      isCancelExportPDF: false,
      exportPDFMode: "downloadOnly",
      renderEachPage: false,
      currentStickerColor: "transparent", //目前文字工具的便利貼顏色
      stickerColorArr: ["#ffcdd2", "#BBDEFB", "#C8E6C9", "#FFF9C4", "transparent"],
      //記錄課堂時間,回傳給mqttIoTData
      classOpTime: 0,
      //檢測使用者是否有閒置，閒置太久直接登出
      idleLogoutTimer: "",
      showIdlelogout: false,
      connection: "",
      showObjMenu: false,
      objMenuPos: { x: 0, y: 0 },
      currentMenuObj: {},
      showObjMenuTextCopyOption: false,
      currentObjMenuCopyText: "",
      //加入
      showClassNum: false,
      showMemberlist: true,
      currentMemberList: {},
      customMemberlist: [], //最後存到偏好設定的自訂名單,
      currentTA:'',
      showGptBox:false,
      startUseGptTool:false,
      showCanvasClearDangerHint:false,
      showWorksClearDangerHint:false,
      showRightClickMenu:false,
      rightClickMenuPos: { x: 0, y: 0 },
      //使用IES5過來的名單
      showIES5studentListBtn:false, //ies5重啟要秀這個來源
      isUseIES5studentList:false, //ies5重啟要秀這個來源
      isEditMemberListAtInit:false,
      canShowGptDragBall:false,
      isWaitingGptFeedbackDone:false,
    };
  },
  methods: {
    getStorageSize(type) {
      let storage = type == "local" ? localStorage : sessionStorage;

      let _lsTotal = 0,
        _xLen,
        _x;
      for (_x in storage) {
        if (!storage.hasOwnProperty(_x)) {
          continue;
        }
        _xLen = (storage[_x].length + _x.length) * 2;
        _lsTotal += _xLen;
        // console.log(_x.substr(0, 50) + ' = ' + (_xLen / 1024).toFixed(2) + ' KB')
      }
      console.log(type + "Total = " + (_lsTotal / 1024).toFixed(2) + " KB");
    },
    isTimerStart() {
      return this.$store.state.timerIsStart;
    },
    hideStudentName() {
      return this.$store.state.hideStudentName;
    },
    resetpdfUploader() {
      this.$refs.pdfUploader.value = "";
      this.currentPDFInsertFile = "";
    },
    openPDFInsert(e) {
      this.$store.state.showPDFInsert = true;
      this.currentPDFInsertFile = e.target.files[0];
      this.Hishow = false;
    },
    setStickerColor(item) {
      this.currentStickerColor = item;
    },
    addSticker(mode) {
      let layer = this.$store.state.layer;
      //新增便利貼物件
      let x, y;
      let textNode = this.$store.state.currentEditTextNode;
      x = mode == "new" ? this.stage.width() * 0.1 + Math.floor(Math.random() * 200) : textNode.attrs.x;
      y = mode == "new" ? this.stage.height() * 0.2 + Math.floor(Math.random() * 200) : textNode.attrs.y;
      let uuid=this.$jsFn.getUUID()
      let currentIndex=layer?.children ? JSON.parse(JSON.stringify(layer?.children)).length : 1
      let pastText = new Konva.Label({
        x: x,
        y: y,
        name: "pastText",
        draggable: true,
        listening: true,
        text: this.currentAddTextValue,
        uuid:uuid,
        index:currentIndex
      });
      pastText.add(
        new Konva.Tag({
          fill: this.currentStickerColor,
          shadowColor: "black",
          shadowBlur: 10,
          shadowOffsetX: 10,
          shadowOffsetY: 10,
          shadowOpacity: 0.2,
          name: "pastTextContent",
          draggable: false,
          listening: true,
          uuid:uuid
        })
      );
      pastText.add(
        new Konva.Text({
          text: this.currentAddTextValue,
          fontSize: this.$store.state.textSize,
          fill: this.$store.state.textColor,
          padding: 20,
          lineHeight: 1,
          width: 200,
          height: 200, //height 設為'auto' 可符合文字大小，但拉伸時抓不到實際高度
          name: "pastTextContent",
          draggable: false,
          listening: true,
          uuid:uuid
        })
      );

      let tr = new Konva.Transformer({
        anchorStroke: "#00a6ff",
        anchorFill: "#fff",
        anchorSize: 12,
        anchorCornerRadius: 5,
        anchorStrokeWidth: 2,
        borderStroke: "#6ac9fc",
        borderStrokeWidth: 2,
        borderDash: [3, 3],
        padding: 10,
        name: "default",
      });
      this.layer.add(pastText);
      this.$toolbox.saveUndoHistory('add',pastText)//儲存undo記錄

      if (mode == "edit") {
        //殺掉原本的文字物件
        textNode.destroy();
        //殺掉原本的Transformer
        this.stage.find("Transformer").forEach(function (ele) {
          ele.destroy();
        });
      }
      this.layer.add(tr);
      tr.nodes([pastText]);
      this.addMenuBtnToTr(tr, pastText);
      console.log('新增便利貼物件',pastText)
    },
    addPureText(mode) {
      let layer = this.$store.state.layer;
      //新增便利貼物件
      let x, y;
      let pastText = this.$store.state.currentEditTextNode;
      let currentIndex=layer?.children ? JSON.parse(JSON.stringify(layer?.children)).length : 1
      x = mode == "new" ? window.innerWidth / 2 - 100 : pastText.attrs.x;
      y = mode == "new" ? window.innerHeight / 2 - 100 : pastText.attrs.y;
      //新增純文字物件
      let textNode = new Konva.Text({
        text: this.currentAddTextValue,
        x: x,
        y: y,
        fontSize: this.$store.state.textSize,
        fill: this.$store.state.textColor,
        draggable: true,
        width: 300,
        height: "auto",
        name: "text",
        uuid:this.$jsFn.getUUID(),
        index:currentIndex
      });

      let tr = new Konva.Transformer({
        anchorStroke: "#00a6ff",
        anchorFill: "#fff",
        anchorSize: 12,
        anchorCornerRadius: 5,
        anchorStrokeWidth: 2,
        borderStroke: "#6ac9fc",
        borderStrokeWidth: 2,
        borderDash: [3, 3],
        padding: 10,
        name: "default",
      });
      this.layer.add(textNode);
      this.$toolbox.saveUndoHistory('add',textNode)//儲存undo記錄

      if (mode == "edit") {
        pastText.destroy();
        //殺掉原本的Transformer
        this.stage.find("Transformer").forEach(function (ele) {
          ele.destroy();
        });
      }

      this.layer.add(tr);
      tr.nodes([textNode]);
      this.addMenuBtnToTr(tr, textNode);
    },
    addTextBox() {
      this.$store.state.showtextInuptCard = false;
      this.$store.state.isEditingTextNow = false;
      document.body.classList.remove("cursor-fdj");

      if (this.$store.state.currentEditTextNode == "") {
        this.currentStickerColor != "transparent" ? this.addSticker("new") : this.addPureText("new");
      } else {
        //編輯物件
        if (this.$store.state.currentEditTextNode.className == "Text") {
          if (this.currentStickerColor == "transparent") {
            let textNode = this.$store.state.currentEditTextNode;
            textNode.setAttrs({ text: this.currentAddTextValue, fontSize: this.$store.state.textSize, fill: this.$store.state.textColor });
          } else this.addSticker("edit"); //文字轉便利貼
        } else {
          if (this.currentStickerColor == "transparent") this.addPureText("edit"); //便利貼轉文字
          else {
            let pastText = this.$store.state.currentEditTextNode;
            //Label
            pastText.setAttrs({ text: this.currentAddTextValue });
            //Tag
            pastText.children[0].setAttrs({ fill: this.currentStickerColor,text: this.currentAddTextValue });
            //Text
            pastText.children[1].setAttrs({ text: this.currentAddTextValue, fontSize: this.$store.state.textSize, fill: this.$store.state.textColor });
          }
        }
      }
      this.layer.draw();
      this.$store.state.mode = "check";
      this.currentAddTextValue = "";
      this.$store.state.currentEditTextNode = "";
      this.currentStickerColor = "transparent";
    },
    closeTextInputView() {
      this.$store.state.showtextInuptCard = false;
      this.$store.state.isEditingTextNow = false;
      this.currentAddTextValue = "";
      this.$store.state.mode = "check";
    },
    async copyPageIRSAct() {
      //呼叫Toolbox中的function
      this.$refs.hd.closeFan();
      this.irshint = true;
      await this.rendererinof(this.$store.state.carouselSlide - 2);
      await this.$store.state.imgArr.splice(this.$store.state.carouselSlide - 1, 1, this.$store.state.imgArr[this.$store.state.carouselSlide - 2]);
      sessionStorage.setItem("imgArr", JSON.stringify(this.$store.state.imgArr));
      this.$store.state.isShowAskCopyPage = false;
      await this.$refs.hd.irsstart(this.$store.state.isShowAskCopyPageIRSName);
    },
    async emptyPageIRSAct() {
      //呼叫Toolbox中的function
      this.$refs.hd.closeFan();
      this.irshint = true;
      this.$store.state.isShowAskCopyPage = false;
      await this.$refs.hd.irsstart(this.$store.state.isShowAskCopyPageIRSName);
    },
    copyToClipboard() {
      // Create an auxiliary hidden input
      this.$Message.info(this.$t("board.qrcodePop['已複製到剪貼簿']") + "!");
      var aux = document.createElement("input");

      // Get the text from the element passed into the input
      aux.setAttribute("value", this.shareQRCode);

      // Append the aux input to the body
      document.body.appendChild(aux);

      // Highlight the content
      aux.select();

      // Execute the copy command
      document.execCommand("copy");

      // Remove the input from the body
      document.body.removeChild(aux);

      this.isCopy = true;
      setTimeout(() => {
        this.isCopy = false;
      }, 2000);
    },
    playAudio(id) {
      var audio = document.getElementById(id);
      var isPlaying = audio.currentTime > 0 && !audio.paused && !audio.ended;

      if (!isPlaying) {
        audio.play();
      }
    },
    stopAudio(id) {
      var audio = document.getElementById(id);

      var isPlaying = audio.currentTime > 0 && !audio.paused && !audio.ended;

      if (isPlaying) {
        audio.pause();
        audio.currentTime = 0;
      }
    },
    dragElement(elmnt) {
      let pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;

      let passiveSupported = false;

      try {
        var options = Object.defineProperty({}, "passive", {
          get: function () {
            passiveSupported = true;
          },
        });
        window.addEventListener("test", null, options);
      } catch (err) {}

      if (document.getElementById(elmnt)) {
        document.getElementById(elmnt).addEventListener("touchstart", dragMouseDown, passiveSupported ? { passive: true } : false);

        function dragMouseDown(e) {
          document.getElementById(elmnt).addEventListener("touchmove", elementDrag, passiveSupported ? { passive: true } : false);
        }

        function elementDrag(e) {
          // console.log(e)
          // set the element's new position:
          document.getElementById(elmnt).style.top = e.targetTouches[0].clientY + "px";
          document.getElementById(elmnt).style.left = e.targetTouches[0].clientX + "px";
        }
      }
    },
    openSideBar() {
      if (this.$store.state.isRenderingPDFNow == false) {
        if (this.$store.state.isShowCard == false) {
          this.$store.state.clickPdfnumToOpenSideBar = !this.$store.state.clickPdfnumToOpenSideBar;
        } else {
          this.$refs.hd.closeFan();
          this.$store.state.clickPdfnumToOpenSideBar = !this.$store.state.clickPdfnumToOpenSideBar;
        }
      }
    },
    setAnsOption(type) {
      // console.log(type)
      if (type == "") type = "En";
      this.$store.state.currentAnsOption = type == "En" ? "En" : "Num";
      this.$store.state.answerpattern = type == "En" ? "letter" : "number";
      sessionStorage.setItem("currentAnsOption", this.$store.state.currentAnsOption);
    },
    async pasteWorksToNewPage() {
      this.$q.loading.show();
      this.$store.state.addNew = await true;
      //會有生成頁面非同步的問題，需等候上面savePage()處理完，這邊臨時解決辦法做一個時間延遲
      setTimeout(() => {
        this.pasteWorks();
        this.$q.loading.hide();
      }, 1500);
    },

    async pasteWorks() {
      this.isPasteImg = true;
      console.log("work comparing paste back", this.currentCheckToPasteItem);
      let works = [];
      this.currentCheckToPasteItem.forEach((item) => {
        if (item.url) works.push("/Clients/" + item.url.split("?")[0].split("/Clients/")[1]);
      });
      this.$store.state.timeLineEvent.push({
        Time: (Math.floor(Date.now()) - this.$store.state.startTime) / 1000,
        Pgid: this.nowpageRender.nowpageIds,
        EventId: 917529,
        Event: "WrkCmp",
        WrkCmpSrcTypeId: 655438,
        WrkCmpSrcType: "WrkSpaceCmp",
        WrkCmpCount: this.currentCheckToPasteItem.length,
        WrkType: 1,
        Works: works,
      });
      await this.currentCheckToPasteItem.forEach((item, index) => {
        this.pasteTargetByKonvaObj(item, index,this.currentCheckToPasteItem.length);
      });
      this.isPasteImg = false;
      this.setAllWorkCheckedToPasteFalse();
      this.currentCheckToPasteItem = [];
      this.isShowAskPasteWhichPage = false;

      if (this.currentCollateItem().mode == "Collate_Start") {
        this.workSwitch = false;
        this.$store.state.workswitch = false;
        var times = new Date().getTime();
        var msgInitiative = {
          action: "State.Get",
          clientType: "HiTeachCC",
          sender: sessionStorage.getItem("user").sub,
          timestamp: times,
          waitReturn: false,
          payload: "",
        };
        this.singnalRMessage(msgInitiative, "8888", "", "irsGatherEnd");
      }
    },
    async pasteTargetMode() {
      if (this.currentCheckToPasteItem.length > 0) {
        // this.$Message.loading(this.$t("board['物件轉換中請稍候']"))
        this.isShowAskPasteWhichPage = true;
      } else {
        this.$Message.warning(this.$t("board['請先勾選']"));
      }
    },
    pasteTargetByKonvaObj(item, i,length) {
      let stage = this.$store.state.stage;
      let layer = this.$store.state.layer;
      let currentIndex=layer?.children ? JSON.parse(JSON.stringify(layer?.children)).length : 1
      this.$store.state.textColor = "black";
      let text = item.seat + "\t\t\t" + (this.$store.state.hideStudentName == false ? item.name : "");

      let that = this;
      let uuid = this.$jsFn.getUUID()

      let colume
      let row = Math.ceil(length / 3)

      if (length == 1) colume = 1 
      else if (length == 4 || length == 2) colume = 2
      else colume = 3

      let stageX = parseInt(stage.width() / colume)
      let stageY = parseInt(stage.height() / row)

      let mockLayer = {
        width: () => { return stageX },
        height: () => { return stageY }
      }



      Konva.Image.fromURL(item.url, function (image) {
        // image is Konva.Image instance
        let scaleRatio = that.$refs.addImgBox.ratioCalc(image, mockLayer)

        let scaleX = Number(20+ stageX/2-image.width() * scaleRatio/2 + (i % colume) * stageX)
        let scaleY = Number(40+ stageY/2-image.height() * scaleRatio/2 + parseInt(i /(length == 4?2:3)) * stageY)

        image.setAttrs({
          x: 0,
          y: 20,
          width: image.attrs.image.naturalWidth * scaleRatio * 0.8,
          height: image.attrs.image.naturalHeight * scaleRatio * 0.8,
          src: item.url,
          stroke: "gray",
          strokeWidth: 1,
          draggable: false,
          listenning: false,
          name: "pastImgContent",
          uuid:uuid
        });
        var circle2 = new Konva.Circle({
          radius: 16,
          x: image.attrs.image.naturalWidth * scaleRatio * 0.8-10,
          y: -5,
          fill: "#079dda",
          draggable: false,
          listening: false,
          name: "pastImgContent",
          uuid:uuid
        });


        var circle = new Konva.Circle({
          radius: 16,
          x: 32,
          y: -5,
          fill: "#ffb929",
          draggable: false,
          listening: false,
          name: "pastImgContent",
          uuid:uuid
        });
        var rect = new Konva.Rect({
          radius: 20,
          cornerRadius: 5,
          width: 180,
          height: 40,
          x: 0,
          y: -25,
          fill: "#d8d8d8",
          draggable: false,
          listening: false,
          name: "pastImgContent",
          uuid:uuid 
        });
     
        var group = new Konva.Group({
          x: stage.getAbsoluteTransform().copy().invert().point({ x: scaleX, y: scaleY }).x,
          y: stage.getAbsoluteTransform().copy().invert().point({ x: scaleX, y: scaleY }).y,
          text: text,
          draggable: true,
          listening: true,
          uuid:uuid ,
          index:currentIndex
        });

        group.add(image);
        group.add(rect);
        group.add(circle);
        group.add(circle2);

        group.add(
          new Konva.Text({
            x: image.attrs.image.naturalWidth * scaleRatio * 0.8-25,
            y: -25,
            text: i+1,
            fontSize: 18,
            fill: "white",
            fontStyle: "bold",
            padding: 10,
            lineHeight: 1,
            wrap: "none",
            ellipsis: true,
            width: 50,
            verticalAlign: "Top",
            scaleX: 1,
            scaleY: 1,
            draggable: false,
            listenning: false,
            name: "pastImgContent",
            uuid:uuid
          })
        );

        group.add(
          new Konva.Text({
            x: 15,
            y: -25,
            text: text,
            fontSize: 18,
            fill: "black",
            fontStyle: "bold",
            padding: 10,
            lineHeight: 1,
            wrap: "none",
            ellipsis: true,
            width: 180,
            verticalAlign: "Top",
            scaleX: 1,
            scaleY: 1,
            draggable: false,
            listenning: false,
            name: "pastImgContent",
            uuid:uuid
          })
        );
        group.on("mouseover", function () {
          document.body.style.cursor = "pointer";
        });
        group.on("mouseout", function () {
          document.body.style.cursor = "default";
        });

        layer.add(group);
        that.$toolbox.saveUndoHistory('add',group)//儲存undo記錄
     
        let tr = new Konva.Transformer({
          anchorStroke: "#00a6ff",
          anchorFill: "#fff",
          anchorSize: 12,
          anchorCornerRadius: 5,
          anchorStrokeWidth: 2,
          borderStroke: "#6ac9fc",
          borderStrokeWidth: 2,
          borderDash: [3, 3],
          padding: 10,
          name: "default",
        });
        stage.find("Transformer").forEach(function (ele, i) {
          if (ele.attrs.name == "default") {
            ele.destroy();
          }
        });
        layer.add(tr);
        tr.nodes([group]);
        that.addMenuBtnToTr(tr, group);
        that.$parent.mode = "check";
        that.$store.state.mode = "check";
        that.$store.state.checkvalue = "checkbtn";

        layer.batchDraw();
      });

      this.$store.state.textColor = "red";
      this.currentSelectedTextItems = [];
      // console.log(stage)
    },
    hideName() {
      this.$store.state.hideStudentName = !this.$store.state.hideStudentName;
    },
    pasteTarget(id) {
      let that = this;

      html2canvas(document.getElementById(id), {
        useCORS: true,
      }).then((canvas) => {
        let imageObj = new Image();
        imageObj.setAttribute("crossOrigin", "Anonymous");
        imageObj.src = canvas.toDataURL("image/jpeg", 0.8);
        imageObj.onload = function () {
          let stage = that.$store.state.stage;
          let layer = that.$store.state.layer;

          Konva.Image.fromURL(imageObj.src, function (image) {
            // image is Konva.Image instance

            image.setAttrs({
              x: stage.width() * 0.1 + Math.floor(Math.random() * 100),
              y: stage.height() * 0.2 + Math.floor(Math.random() * 100),
              width: image.width(),
              height: image.height(),
              draggable: true,
              src: imageObj.src,
            });

            // stage.find('Transformer').destroy()
            let tr = new Konva.Transformer({
              anchorStroke: "#00a6ff",
              anchorFill: "#fff",
              anchorSize: 12,
              anchorCornerRadius: 5,
              anchorStrokeWidth: 2,
              borderStroke: "#6ac9fc",
              borderStrokeWidth: 2,
              borderDash: [3, 3],
              padding: 10,
              name: "default",
            });
            stage.find("Transformer").forEach(function (ele, i) {
              if (ele.attrs.name == "default") {
                ele.destroy();
              }
            });
            layer.add(tr);
            tr.nodes([image]);
            that.addMenuBtnToTr(tr, image);
            that.$parent.mode = "check";
            that.$store.state.mode = "check";
            that.$store.state.checkvalue = "checkbtn";

            layer.add(image);
            layer.draw();
          });
        };
      });
    },
    viewWorkImg(item, index) {
      this.isViewWorkImg = true;
      this.workImgData = {
        seat: item.seat,
        name: this.$store.state.hideStudentName == false ? item.name : "",
        url: item.url,
      };
      this.workImgIndex = index;
    },
    viewWorkPrevImg() {
      if (this.workImgIndex - 1 >= 0) {
        let item = this.currentCollateWorkData()[this.workImgIndex - 1];
        this.workImgData = {
          seat: item.seat,
          name: this.$store.state.hideStudentName == false ? item.name : "",
          url: item.url,
        };
        this.workImgIndex--;
      } else {
        let item = this.currentCollateWorkData()[this.currentCollateWorkData().length - 1];
        this.workImgData = {
          seat: item.seat,
          name: this.$store.state.hideStudentName == false ? item.name : "",
          url: item.url,
        };
        this.workImgIndex = this.currentCollateWorkData().length - 1;
      }
    },
    viewWorkNextImg() {
      if (this.workImgIndex + 1 <= this.currentCollateWorkData().length - 1) {
        let item = this.currentCollateWorkData()[this.workImgIndex + 1];
        this.workImgData = {
          seat: item.seat,
          name: this.$store.state.hideStudentName == false ? item.name : "",
          url: item.url,
        };
        this.workImgIndex++;
      } else {
        let item = this.currentCollateWorkData()[0];
        this.workImgData = {
          seat: item.seat,
          name: this.$store.state.hideStudentName == false ? item.name : "",
          url: item.url,
        };
        this.workImgIndex = 0;
      }
    },
    filterCheckedwork() {
      this.$store.state.isFilterCheckwork = !this.$store.state.isFilterCheckwork;
      this.isFilterCheckwork = this.$store.state.isFilterCheckwork;
    },
    closeStudentList() {
      this.isShowStudentList = false;
      this.closeCode();
    },
    showStudentList() {
      this.isShowStudentList = !this.isShowStudentList;
      this.closeCode();
      this.payOrder();
    },
    payOrder() {
      let classnum = sessionStorage.getItem("classNum");
      // let classname = sessionStorage.getItem('classname')
      // let TeachDeviceId = sessionStorage.getItem('deviceId')
      this.shareQRInfo.gn = classnum //教室編號
      this.shareQRInfo.pin = '8888' //4數字驗證碼
      this.shareQRInfo.ip = '' 
      let serverLocation = this.$jsFn.isCN() ? 'cn' : 'global'
      this.shareQRCode = profile.irs5Url[serverLocation]
      this.shareQRCode += '/?s=' + this.encode64(JSON.stringify(this.shareQRInfo)) //大陸數據中心, 全球就要另外換
      this.qrcodeIRS5link = profile.irs5Url[serverLocation].replace('https://', '')
      this.innerVisible = true
      // 二维码内容 地址內容
      this.qrcode = this.shareQRCode;
      // 使用$nextTick确保数据渲染
      this.$nextTick(() => {
        this.crateQrcode();
      });
    },
    crateQrcode() {
      this.qr = new QRCode("qrcode", {
        width: window.innerHeight > 600 ? window.innerHeight * 0.4 : window.innerHeight * 0.3,
        height: window.innerHeight > 600 ? window.innerHeight * 0.4 : window.innerHeight * 0.3, // 高度
        text: this.qrcode, // 二维码内容
        // render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
        // background: '#f0f'
        // foreground: '#ff0'
        correctLevel: QRCode.CorrectLevel.Q,
      });
    },
    // 关闭弹框,清除已经生成的二维码
    closeCode() {
      this.$refs.qrcode.innerHTML = "";
    },
    //base64
    decode64(input) {
      try {
        return decodeURIComponent(escape(window.atob(unescape(input))));
      } catch (e) {
        return "";
      }
    },
    encode64(input) {
      try {
        return escape(window.btoa(unescape(encodeURIComponent(input))));
      } catch (e) {
        return "";
      }
    },

    //Qrcode-End
    startBuzz() {
      let times = new Date().getTime();
      let msgInitiative = {
        action: "State.Get",
        clientType: "HiTeachCC",
        sender: sessionStorage.getItem("user").sub,
        timestamp: times,
        waitReturn: false,
        payload: "",
      };
      this.singnalRMessage(msgInitiative, "8888", "", "irsBuzz");
      this.isWaitingBuzz = true;
    },
    reStartBuzz() {
      // this.isWaitingBuzz = false
      this.startBuzz();
      this.$store.state.answervalue = "";
      this.StudentPercentage = 0;
      // this.studentPercentages()
      // setTimeout(()=>{
      //   this.startBuzz()
      // },1000)
    },
    closeBuzzPop() {
      this.isOpenBuzzPop = false;
      this.$store.state.isLightPieChart = true;
      setTimeout(() => {
        this.$store.state.isLightPieChart = false;
      }, 2000);
    },
    closeWorkCollate() {
      this.workStatus = false;
      this.$store.state.isLightPieChart = true;
      setTimeout(() => {
        this.$store.state.isLightPieChart = false;
      }, 2000);
    },
    workNum() {
      return this.$store.state.workNum;
    },
    setworkCheckedToPaste(targetItem) {
      let index = 0,
        subIndex = 0;

      this.$store.state.collateCheckTable.forEach((item, i) => {
        if (item.collatejobNum == this.currentWorkNum()) {
          index = i;
          item.workData.forEach((subitem, j) => {
            if (subitem.id == targetItem.id) {
              subIndex = j;
            }
          });
        }
      });
      if (this.currentCheckToPasteItem.length < 6 && this.$store.state.collateCheckTable[index].workData[subIndex].isCheckedToPaste == false) {
        this.$store.state.collateCheckTable[index].workData[subIndex].isCheckedToPaste = true;
        this.$store.state.collateCheckTable[index].workData[subIndex].checkNum=this.currentCheckToPasteItem.length+1
      } else if (this.$store.state.collateCheckTable[index].workData[subIndex].isCheckedToPaste == true) {
        this.$store.state.collateCheckTable[index].workData[subIndex].isCheckedToPaste = false;
        this.$store.state.collateCheckTable[index].workData.forEach(item=>{
          if(item.checkNum> this.$store.state.collateCheckTable[index].workData[subIndex].checkNum){
            item.checkNum--
          }
        })
        this.$store.state.collateCheckTable[index].workData[subIndex].checkNum=0
      } else {
        this.$Message.warning(this.$t("board['一次勾選貼回最多6張']"));
      }
      this.currentCheckToPasteItem = [];
      this.$store.state.collateCheckTable[index].workData.forEach((item,index) => {
        if (item.isCheckedToPaste == true) {
          this.currentCheckToPasteItem.push(item);
        }
        else{
          this.currentCheckToPasteItem.splice(index,1)
        }
      });
      this.currentCheckToPasteItem.sort((a,b)=>a.checkNum-b.checkNum)
      console.log(this.currentCheckToPasteItem,'this.currentCheckToPasteItem')

      this.forceUpdateNum++;
    },
    setAllWorkCheckedToPasteFalse() {
      let index = 0,
        subIndex = 0;
      this.$store.state.collateCheckTable.forEach((item, i) => {
        if (item.collatejobNum == this.currentWorkNum()) {
          index = i;
          item.workData.forEach((subitem, j) => {
            subitem.isCheckedToPaste = false;
            subitem.checkNum=0
          });
        }
      });

      this.forceUpdateNum++;
    },
    setworkChecked(targetItem) {
      let index = 0,
        subIndex = 0;
      this.$store.state.collateCheckTable.forEach((item, i) => {
        if (item.collatejobNum == this.currentWorkNum()) {
          index = i;
          item.workData.forEach((subitem, j) => {
            if (subitem.id == targetItem.id) {
              subIndex = j;
            }
          });
        }
      });
      this.$store.state.collateCheckTable[index].workData[subIndex].isChecked = !this.$store.state.collateCheckTable[index].workData[subIndex].isChecked;
      this.forceUpdateNum++;
    },
    sortWorkData() {
      this.$store.state.isSortWork = !this.$store.state.isSortWork;
    },
    currentCollateWorkData() {
      let targetItems = [];
      let sortItems = [];
      this.$store.state.collateCheckTable.forEach((item) => {
        if (item.collatejobNum == this.currentWorkNum()) {
          // console.log(item.mode)
          if (this.$store.state.isFilterCheckwork == true) {
            item.workData.forEach((subitem) => {
              if (subitem.isChecked == true) {
                targetItems.push(subitem);
              }
            });
            this.worksData = item.workData;
          } else {
            targetItems = item.workData;
            this.worksData = item.workData;
          }
        }
      });
      if (this.$store.state.isSortWork == true) {
        sortItems = [...targetItems];
        sortItems.sort((a, b) => {
          return a.seat - b.seat;
        });
      }
      return this.$store.state.isSortWork == true ? sortItems : targetItems;
    },
    currentCollateItem() {
      let targetItem = "";
      this.$store.state.collateCheckTable.forEach((item) => {
        if (item.collatejobNum == this.currentWorkNum()) {
          // console.log(item.mode)
          targetItem = item;
        }
      });
      return targetItem;
    },
    currentWorkNum() {
      return this.$store.state.currentWorkNum;
    },
    //验证基础数据或重整页面
    init() {
      console.log(JSON.parse(sessionStorage.getItem("user")), "llll");
      console.log(this, "this的内容");
      if (sessionStorage.getItem("idToken") === null || sessionStorage.getItem("loginBack") === null) {
        this.$q.loading.show({
          message: this.$t("board['请登陆']"),
          sanitize: true,
        });
        this.timer = setTimeout(() => {
          this.$router.push("/");
        }, 4000);
      }
      let usertoken = sessionStorage.getItem("TWtoken");
      let userdeviceIds = sessionStorage.getItem("deviceId");
      let usermqtts = sessionStorage.getItem("mqtts");
      let userloginBacks = sessionStorage.getItem("loginBack");
      let userclassnums = sessionStorage.getItem("classNum");
      if (userdeviceIds !== "" && usertoken !== "" && usermqtts !== "" && userloginBacks !== "" && userclassnums !== "") {
        this.$store.state.userLogin.userdeviceid = userdeviceIds;
        this.$store.state.userLogin.token = usertoken;
        this.$store.state.userLogin.usermqtt = usermqtts;
        this.$store.state.userLogin.userloginBack = userloginBacks;
        this.$store.state.userLogin.userClassNum = userclassnums;
      }
      let Fullscreen = sessionStorage.getItem("Fullscreen");
      if (Fullscreen === 1) {
        this.isFullscreen = false;
      } else if (Fullscreen === 0) {
        this.isFullscreen = true;
      }
    },
    studentList() {
      this.$store.state.students.forEach((item) => {
        console.log(item.studentName);
      });
    },
    handleUploadSuc(response, file, i) {
      //this.beginPdfshowLoading()
      this.$store.state.uploadUrl = response.result.data.model.blobUrl;
      sessionStorage.setItem("slide", 1);
      this.pdfloadingpage = response.result.data.page;
      this.saveArr = [];
      this.slidenum = 1;
      this.$store.state.carouselSlide = 1;
      this.$store.state.oldSlide = 0;
      this.modalUpload = false;
      this.modalUploadType = false;
      this.Hishow = false;
      let pdfname = response.result.data.model.fileName;
      sessionStorage.setItem("classname", pdfname);
      this.$Message.success(this.$t("board['上传成功']") + "！");
      // this.isUploadSuc =true
      this.$store.state.mode = "pencil-alt";
      console.log(this.Hishow, "查看目前的状态值");
    },
    //上傳JSON與拉回，也許會用到
    // async fetchSavedImgJsonData(url, ImgUrlType) {
    //   let that = this
    //   let blob = await fetch(url).then(r => r.blob())
    //   console.log(blob)
    //   let filereader = new FileReader()
    //   filereader.onload = async function() {
    //     if (ImgUrlType == 'pdfOringinalImgsUrl') {
    //       if (JSON.parse(this.result).idArr.length == 1) {
    //         that.$store.state.pdfOringinalImgs = []
    //         await that.$store.state.pdfOringinalImgs.push(JSON.parse(this.result).imgData)
    //       } else {
    //         that.$store.state.pdfOringinalImgs = await JSON.parse(this.result).imgData
    //       }

    //       console.log(that.$store.state.pdfOringinalImgs)
    //       that.$store.state.pdfOringinalImgsIdArr = JSON.parse(this.result).idArr
    //     } else if (ImgUrlType == 'ImgArrUrl') {
    //       that.$store.state.pdfOringinalImgs = JSON.parse(this.result)
    //     }
    //   }
    //   filereader.readAsText(blob)
    // },
    // async uploadJsonData(JsonData, JsonfileName, localLinkName) {
    //   let blobinfo = JSON.parse(enc.decrypt(sessionStorage.getItem('blobConnect'), de.text))
    //   // 調整BlobURL設定 Osbert
    //   this.blobinit = new Upblob(blobinfo.url, '', blobinfo.sas_write)
    //   await this.blobinit.uploadJsonData(JsonData, 'temp/HiTeachCC/' + this.activityRecordNumberS, JsonfileName).then(res => {
    //     console.log(res, '上传JSON成功后的返回')
    //     sessionStorage.setItem(localLinkName, res.url)
    //   })
    // },

     // 上傳到Temp工作目錄之外的地方，給定路徑，如上傳名單
    async uploadResourceByPath(file, path) {
      return new Promise(async (r, j) => {
        let blobinfo = this.classInfo.blob;
        console.log(this.classInfo);
        // 調整BlobURL設定 Osbert
        this.blobinit = new Upblob(blobinfo.url, "", blobinfo.sas_write);
        let fileBolb = {
          url: blobinfo.url,
          name: file.name,
          size: file.size,
          loadedBytes: 0,
          progress: 0,
          status: 0,
        };
        this.uploadedList.push(fileBolb);
        await this.blobinit.upload(file, path, { blobHTTPHeaders: { blobContentType: file.type } }).then(
          (res) => {
            r(res);
          },
          (err) => {
            j(err);
          }
        );
      });
    },

    // 上傳不需要修改檔名的圖片或其他資源檔案
    async uploadResourceFile(file, subFileName) {
      return new Promise(async (r, j) => {
        let blobinfo = this.classInfo.blob;
        console.log(this.classInfo);
        // 調整BlobURL設定 Osbert
        this.blobinit = new Upblob(blobinfo.url, "", blobinfo.sas_write);
        let fileBolb = {
          url: blobinfo.url,
          name: file.name,
          size: file.size,
          loadedBytes: 0,
          progress: 0,
          status: 0,
        };
        this.uploadedList.push(fileBolb);
        let subFilePath = subFileName ? "/" + subFileName : "";
        await this.blobinit.upload(file, this.classInfo.workingFolder + this.activityRecordNumberS + subFilePath, { blobHTTPHeaders: { blobContentType: file.type } }).then(
          (res) => {
            r(res);
          },
          (err) => {
            j(err);
          }
        );
      });
    },
    // 上傳且需要修改檔名的圖片或其他資源檔案
    async uploadChangeNameResourceFile(file, changefileName, subPathFileName) {
      return new Promise(async (r, j) => {
        let blobinfo = this.classInfo.blob;
        // 調整BlobURL設定 Osbert
        this.blobinit = new Upblob(blobinfo.url, "", blobinfo.sas_write);
        let fileBolb = {
          url: blobinfo.url,
          name: changefileName,
          size: file.size,
          loadedBytes: 0,
          progress: 0,
          status: 0,
        };
        this.uploadedList.push(fileBolb);
        await this.blobinit.uploadAndChangeFileName(file, this.classInfo.workingFolder + this.activityRecordNumberS + "/" + subPathFileName, changefileName).then(
          (res) => {
            r(res);
          },
          (err) => {
            j(err);
          }
        );
      });
    },
    uploadingDefaultPDF() {
      this.uploadPDF();
      this.uploading("defaultPDF");
    },
    uploading(file) {
      console.log(file);
      this.modalUpload = false;
      this.modalUploadType = false;
      this.showClassNum = true;
      this.$store.state.initIsWhiteboard = false;
      this.$store.state.showPDFInitPageSelect = true;

      this.currentPDFInitFile = file;

      let defaultPDF_URL = "";
      if (this.lang == "tw") {
        defaultPDF_URL = "./HiTeachCCreadme.pdf";
      } else if (this.lang == "cn") {
        defaultPDF_URL = "./HiTeachCCreadmeCN.pdf";
      } else {
        defaultPDF_URL = "./HiTeachCCreadmeEN.pdf";
      }

      if (file == "defaultPDF") {
        this.$store.state.uploadUrl = defaultPDF_URL;
      } else if (file == "IES5PDF") {
        this.$store.state.uploadUrl = this.classInfo.materialUrl;
      } else {
        this.$store.state.uploadUrl = URL.createObjectURL(file);
      }

      sessionStorage.setItem("slide", 1);
      this.saveArr = [];
      this.slidenum = 1;
      this.filetypeImage = "add_pdf";
      this.$store.state.carouselSlide = 1;
      this.$store.state.oldSlide = 1;
      this.$store.state.mode = "pencil-alt";
      sessionStorage.setItem("fileType", "pdf");
      setTimeout(() => {
        this.Hishow = false;
      }, 2000);
      this.showStudentList();
      //     } else if (file.type === 'application/ppt') {
      //       this.blobPath = res.blob
      //       this.$store.state.oldSlide = 1
      //       this.fileAnalysisinfo(res)
      //       sessionStorage.setItem('fileType', 'ppt')
      //       this.filetypeImage = 'add_ppt'
      //       this.beginPdfshowLoading(1)
      //       //this.modalUpload = true
      //       //this.$q.loading.hide()
      //       //this.$Message.info('PPT正在努力开发中，尽请期待')
      //     }
      //   })
      return false;
    },
    //获取解析
    fileAnalysisinfo(e) {
      var uploadFile = e;
      var userid = sessionStorage.getItem("user");
      this.$api
        .FileAnalysis({
          id: userid.sub,
          file: uploadFile.url,
        })
        .then((res) => {
          console.log(res, "解析后的返回");
          this.$store.state.fileAnalysis = res;
          this.$q.loading.hide();
          this.timer = void 0;
          var joint = res.index.indexOf("index.json");
          console.log(joint, "位置");
          this.$store.state.jointUrl = res.index.substring(0, joint);
          console.log(this.$store.state.jointUrl, "截取过后的内容");
          sessionStorage.setItem("slide", 1);
          this.initCanvas(0, "container", "init");
        });
    },
    handleUploadError() {
      this.beginPdfshowLoading();
      this.$Message.error(this.$t("board['上传失败！请查看PDF页数是否超过20页 是否符合标准']"));
      this.modalUpload = true;
      this.modalUploadType = true;
    },
    HihandleUploadError() {
      this.beginPdfshowLoading();
      this.$Message.error(this.$t("board['上传失败！请查看PDF页数是否超过20页 是否符合标准']"));
    },
    upload() {
      this.modalUpload = false;
      this.modalUploadType = true;
    },
    uploadPDF() {
      this.showPPT = false;
    },
    uploadPPT() {
      this.$Message.warning("载入PPT文件正在研发中，敬请期待...");
    },
    openNew() {
      this.showClassNum = true;
      this.modal_loading = true;
      this.openNewWord = this.$t("board['正在制作画板']") + "...";
      setTimeout(() => {
        this.modal_loading = false;
        this.modalUpload = false;
        this.openNewWord = "开启新画板";
        this.$store.state.uploadUrl = this.$store.state.newboardUrl;
        this.$store.state.mode = "pencil-alt";
        sessionStorage.setItem("classname", "HiTeach CC");
        sessionStorage.setItem("fileType", "pdf");
        this.$Message.success(this.$t("board['制作画板制作成功']"));

        //MqttIotData開課時間
        this.setClassOpTime();
        this.showStudentList();
      }, 1500);
    },
    handleFormatError(file) {
      this.$Notice.config({
        top: 500,
        duration: 3,
      });
      this.$Notice.warning({
        title: this.$t("board['上传格式有误']"),
        desc: this.$t("board['您上传的文件']") + file.name + this.$t("board['格式不正确, 请上传PDF格式']") + "！",
      });
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: this.$t("board['上传文件大小限制']"),
        desc: this.$t("board['您上传的文件']") + file.name + this.$t("board['大小超过5M, 请上传不超过5M的文件']") + "！",
      });
    },
    resize(newRect) {
      this.width = newRect.width;
      this.height = newRect.height;
      this.top = newRect.top;
      this.left = newRect.left;
    },
    /*渲染部分（重写）*/
    async rendererinof(slide) {
      let blobUrl = this.classInfo.blob;

      console.log(this.$store.state.pdfOringinalImgsIdArr, "pdf原圖ID");
      console.log(this.$store.state.pdfOringinalImgs, "pdf原圖渲染");
      console.log(slide, "调用了渲染方法");

      if (this.$store.state.imgArr.idArr == undefined) {
        this.$store.state.imgArr.idArr = JSON.parse(sessionStorage.getItem("idArr"));
      }

      if (slide != "undefined") {
        // let stageInfo =JSON.parse(sessionStorage.getItem('stageInfo'))
        let stageInfo = this.saveArr;
        let reShape = stageInfo[slide + ""];
        let that = this;
        console.log(reShape, "目前的序列化資料");

        if (reShape) {
          //重整刪掉白色底圖
          reShape.children.forEach((item) => {
            if (item.className == "Rect" && item.attrs.name == "whiteRect") {
              item.destroy();
            }
          });
          let targetPageIndex = await that.$store.state.pdfOringinalImgsIdArr.findIndex((x) => x.pageid == that.$store.state.imgArr.idArr[slide].pageid);
          //自動補背景圖

          if ((reShape.children.length == 0 && targetPageIndex != -1) || (targetPageIndex != -1 && reShape.children[0].className != "Image")) {
            let imageObj = new Image();

            imageObj.setAttribute("crossOrigin", "Anonymous");
            let pdfSrc = that.$store.state.pdfOringinalImgs[targetPageIndex];
            imageObj.src = pdfSrc; //不是背景圖不取連結
            that.$q.loading.show();
            imageObj.onload = async () => {
              that.$q.loading.hide();
              that.$q.loading.show();
              await Konva.Image.fromURL(pdfSrc, async function (image) {
                let ratio = 0;

                if (image.width() > that.layer.width() && image.height() < that.layer.height()) {
                  ratio = that.layer.width() / image.width();
                } else if (image.width() < that.layer.width() && image.height() > that.layer.height()) {
                  ratio = that.layer.height() / image.height();
                } else if (image.width() < that.layer.width() && image.height() < that.layer.height()) {
                  ratio = that.layer.width() / image.width() < that.layer.height() / image.height() ? that.layer.width() / image.width() : that.layer.height() / image.height();
                } else {
                  //1.判斷要縮哪一邊
                  let imgWidthMinusCanvasWidth = image.width() - that.layer.width();
                  let imgHeightMinusCanvasHeight = image.height() - that.layer.height();
                  //console.log(imgWidthMinusCanvasWidth,imgHeightMinusCanvasHeight)
                  if (imgWidthMinusCanvasWidth > imgHeightMinusCanvasHeight) ratio = that.layer.width() / image.width();
                  else {
                    ratio = that.layer.height() / image.height();
                  }
                }

                image.setAttrs({
                  x: (that.layer.width() - image.width() * ratio) / 2,
                  y: (that.layer.height() - image.height() * ratio) / 2,
                  width: image.width() * ratio,
                  height: image.height() * ratio,
                  width: image.width() * ratio,
                  height: image.height() * ratio,
                  // image: imageObj,
                  src: pdfSrc,
                  name: "boardPdfBg",
                  index: 0,
                });

                that.layer.add(image);
                image.moveToBottom();
                await that.layer.draw();
                that.$q.loading.hide();
              });
            };
          }

          for (let i = 0; i < reShape.children.length; i++) {
            let type = reShape.children[i].className;
            let attr = reShape.children[i].attrs;
            console.log(type);
            if (type == "Image") {
              // let boardPdfBg = new Konva.Image(attr)
              console.log(attr, "把圖片相關渲染出來");

              //  在画板上生成当前页背景图
              let imageObj = new Image();
              let that = this;
              imageObj.setAttribute("crossOrigin", "Anonymous");

              let finallink = targetPageIndex != -1 ? await that.$store.state.pdfOringinalImgs[targetPageIndex] : attr.src;
              imageObj.src = attr.name == "boardPdfBg" ? finallink : ""; //不是背景圖不取連結

              if (targetPageIndex != -1 || attr.name == "boardPdfBg") {
                //blob圖一定要先設跨域在load否則會報錯
                that.$q.loading.show();
                imageObj.onload = async () => {
                  that.$q.loading.hide();
                  await Konva.Image.fromURL(finallink, async function (image) {
                    let ratio = 0;

                    if (image.width() > that.layer.width() && image.height() < that.layer.height()) {
                      ratio = that.layer.width() / image.width();
                    } else if (image.width() < that.layer.width() && image.height() > that.layer.height()) {
                      ratio = that.layer.height() / image.height();
                    } else if (image.width() < that.layer.width() && image.height() < that.layer.height()) {
                      ratio = that.layer.width() / image.width() < that.layer.height() / image.height() ? that.layer.width() / image.width() : that.layer.height() / image.height();
                    } else {
                      //1.判斷要縮哪一邊
                      let imgWidthMinusCanvasWidth = image.width() - that.layer.width();
                      let imgHeightMinusCanvasHeight = image.height() - that.layer.height();
                      //console.log(imgWidthMinusCanvasWidth,imgHeightMinusCanvasHeight)
                      if (imgWidthMinusCanvasWidth > imgHeightMinusCanvasHeight) ratio = that.layer.width() / image.width();
                      else {
                        ratio = that.layer.height() / image.height();
                      }
                    }

                    image.setAttrs({
                      x: (that.layer.width() - image.width() * ratio) / 2,
                      y: (that.layer.height() - image.height() * ratio) / 2,
                      width: image.width() * ratio,
                      height: image.height() * ratio,
                      width: image.width() * ratio,
                      height: image.height() * ratio,
                      // image: imageObj,
                      src: finallink,
                      name: "boardPdfBg",
                      index: 0,
                    });

                    that.layer.add(image);
                    image.moveToBottom();
                    await that.layer.draw();
                  });
                };
              }

              //一般圖片
              if (attr.name != "boardPdfBg") {
                let imgs = new Konva.Image(attr);
                that.layer.add(imgs);
                imgs.moveToTop();
                // boardPdfBg.moveToBottom()
                that.layer.batchDraw();
              }

              // console.log(reShape.children)
              // console.log(this.stage)

              if (this.$store.state.isOnlyRefresh == true) {
                console.log("手動處理拉回圖片物件");
                this.stage.children[0].children.forEach((shapeNode) => {
                  if (shapeNode.className == "Image" && shapeNode.attrs.hasOwnProperty("src") && shapeNode.attrs.name != "boardPdfBg") {
                    const image = new Image();
                    image.setAttribute("crossOrigin", "Anonymous");
                    image.onload = () => {
                      shapeNode.image(image);
                      // shapeNode.moveToTop();
                      if (shapeNode.getLayer()) {
                        shapeNode.getLayer().batchDraw();
                      }
                    };
                    image.src = shapeNode.attrs.src.slice(0, 15) == "data:image/png;" || shapeNode.attrs.src.slice(0, 16) == "data:image/jpeg;" ? shapeNode.attrs.src : shapeNode.attrs.src + "?" + blobUrl.sas_read;
                  } else if (shapeNode.attrs.name == "boardPdfBg") {
                    shapeNode.destroy();
                    that.layer.batchDraw();
                  }
                });
              }
            } else if (type == "Line") {
              let lastLine = new Konva.Line(attr);
              this.layer.add(lastLine);
              this.layer.batchDraw();
            } else if ((type == undefined && reShape.children[i].children) || type == "Group") {
              //群組第一次存舞台Konva可能給無類別

              let group = new Konva.Group(attr);
              reShape.children[i].children.forEach((item) => {
                if (item.className == "Circle") {
                  let circle = new Konva.Circle(item.attrs);
                  group.add(circle);
                }
                if (item.className == "Rect") {
                  let rect = new Konva.Rect(item.attrs);
                  group.add(rect);
                }
                if (item.className == "Text") {
                  let text = new Konva.Text(item.attrs);
                  group.add(text);
                }
                if (item.className == "Image") {
                  //快速複製時可能不同步
                  Konva.Image.fromURL(item.attrs.src, async function (image) {
                    console.log(item.attrs);
                    await image.setAttrs(item.attrs);
                    await group.add(image);
                    await that.layer.batchDraw();
                    //  console.log(group,'目前的群組內容')
                    await that.layer.add(group);
                    await that.layer.batchDraw();
                  });
                }
              });
              await that.layer.add(group);
              await that.layer.batchDraw();
            } else if (type == "Label") {
              let label = new Konva.Label(attr);

              reShape.children[i].children.forEach((item) => {
                if (item.className == "Tag") {
                  let tag = new Konva.Tag(item.attrs);
                  label.add(tag);
                }
                if (item.className == "Text") {
                  let text = new Konva.Text(item.attrs);
                  label.add(text);
                }
              });
              console.log(label);
              this.layer.add(label);
              this.layer.batchDraw();
            } else if (type == "Text") {
              let text = new Konva.Text(attr);
              this.layer.add(text);
              this.layer.batchDraw();
            } else {
              let rect;
              if (type == "Circle") {
                rect = new Konva.Circle(attr);
                this.layer.add(rect);
              } else if (type == "Ellipse") {
                rect = new Konva.Ellipse(attr);
                this.layer.add(rect);
              } else if (type == "Star") {
                rect = new Konva.Star(attr);
                this.layer.add(rect);
              } else if (type == "Rect") {
                // console.log(attr)
                if (attr.name == "bgRect") {
                  attr.height = window.innerHeight;
                  attr.width = window.innerWidth;
                  rect = new Konva.Rect(attr);
                } else {
                  rect = new Konva.Rect(attr);
                }
                this.layer.add(rect);
              } else if (type == "RegularPolygon") {
                rect = new Konva.RegularPolygon(attr);
                this.layer.add(rect);
              }
              this.layer.batchDraw();
            }
          }
        } else {
          let targetUrl = blobUrl.url + "/" + this.classInfo.workingFolder + this.activityRecordNumberS + "/ObjSrcImage/" + this.$store.state.imgArr.idArr[slide].pageid + ".jpg" + "?" + blobUrl.sas_read;
          //  第一次在画板上生成当前页背景图
          let imageObj = new Image();
          let that = this;
          imageObj.setAttribute("crossOrigin", "Anonymous");
          imageObj.src = targetUrl;
          console.log(that.$store.state.imgArr, "在画板上生成当前页背景图");
          that.$q.loading.show();
          //blob圖一定要先設跨域在load否則會報錯
          imageObj.onload = () => {
            that.$q.loading.hide();
            that.$q.loading.show();
            Konva.Image.fromURL(targetUrl, function (image) {
              let ratio = 0;

              if (image.width() > that.layer.width() && image.height() < that.layer.height()) {
                ratio = that.layer.width() / image.width();
              } else if (image.width() < that.layer.width() && image.height() > that.layer.height()) {
                ratio = that.layer.height() / image.height();
              } else if (image.width() < that.layer.width() && image.height() < that.layer.height()) {
                ratio = that.layer.width() / image.width() < that.layer.height() / image.height() ? that.layer.width() / image.width() : that.layer.height() / image.height();
              } else {
                //1.判斷要縮哪一邊
                let imgWidthMinusCanvasWidth = image.width() - that.layer.width();
                let imgHeightMinusCanvasHeight = image.height() - that.layer.height();
                //console.log(imgWidthMinusCanvasWidth,imgHeightMinusCanvasHeight)
                if (imgWidthMinusCanvasWidth > imgHeightMinusCanvasHeight) ratio = that.layer.width() / image.width();
                else {
                  ratio = that.layer.height() / image.height();
                }
              }
              // ratio = ratio.Fixed(2)
              // console.log(that.layer.width(),ratio,image.height(), '倍率')

              image.setAttrs({
                x: (that.layer.width() - image.width() * ratio) / 2,
                y: (that.layer.height() - image.height() * ratio) / 2,
                width: image.width() * ratio,
                height: image.height() * ratio,
                // image: imageObj,
                src: targetUrl,
                name: "boardPdfBg",
                index: 0,
              });

              that.layer.add(image);
              that.layer.draw();
              that.$q.loading.hide();
            });
          };
          imageObj.onerror = function () {
            console.debug("圖片載入失敗");
            that.$q.loading.hide();
          };
        }
      }
    },
    //加载每页学生互动数据
    prestraindata() {
      let that = this;
      let pagepercentage = that.$store.state.msgBody;
      let studentnum = that.$store.state.students;
      let nowpage = that.$store.state.carouselSlide;
      console.log(this.nowpageRender.nowpageIds);
      console.log(this.$store.state.collateCheckTable);

      if (pagepercentage) {
        console.log(pagepercentage, "进入到当页有数据");
        for (var i = 0; i < pagepercentage.length; i++) {
          if (nowpage === pagepercentage[i].page) {
            that.$store.state.answerstatus = pagepercentage[i].answerstatus;
            that.$store.state.answervalue = pagepercentage[i].correctanswer;
            that.$store.state.answerpattern = that.$store.state.currentAnsOption == "En" ? "letter" : "number";
            that.$store.state.AnswerSelect = pagepercentage[i].letterselect;
            that.$store.state.AnswerNumselect = pagepercentage[i].numberselect;
            that.$store.state.worksArr = pagepercentage[i].workData;
            this.$store.state.currentWorkNum = pagepercentage[i].currentWorkNum;
            // this.worksData = pagepercentage[i].workData
            this.irsModel = pagepercentage[i].irsmodel;
            this.$store.state.buzzInStudents = pagepercentage[i].buzzInStudents;

            // //跨頁上傳
            // this.$store.state.collateCheckTable.forEach(item => {
            //   if (item.collatejobNum == this.$store.state.currentWorkNum) {
            //     this.worksData = item.workData
            //   }
            // })
            // 處理有當題作答時還沒有加入的學生
            if (pagepercentage[i].totalirs.length < studentnum.length) {
              for (var e = 0; e < studentnum.length; e++) {
                let arr1 = pagepercentage[i].totalirs;
                let arr3 = arr1.filter((item) => item.accountid === studentnum[e].accountid);
                if (arr3.length) studentnum[e] = arr3[0];
                else {
                  // clean state for each student answer
                  studentnum[e].option = "";
                  studentnum[e].score = 0;
                  studentnum[e].rank = "";
                  studentnum[e].class = "studyboxone";
                  studentnum[e].showcardclass = "brand";
                  studentnum[e].answerclass = "studyboxone";
                  studentnum[e].answerbrand = "brand";
                  studentnum[e].statement = "";
                }
              }
              this.$store.state.students = studentnum;
            } else if (pagepercentage[i].totalirs.length === studentnum.length) {
              that.$store.state.students = pagepercentage[i].totalirs;
            }
            for (var e = 0; e < that.$store.state.students.length; e++) {
              if (that.students[e]) {
                if (that.$store.state.students[e].status != that.students[e].status) {
                  that.$store.state.students[e].status = that.students[e].status;
                  that.$store.state.students[e].id = that.students[e].id;
                  that.$store.state.students[e].accountid = that.students[e].accountid;
                  that.$store.state.students[e].headImg = that.students[e].headImg;
                  that.$store.state.students[e].studentName = that.students[e].studentName;
                }
              }
            }
          }
        }
      } else {
        this.StudentPercentage = 0;
        this.irsModel = "normal";
      }
      this.$store.state.irsModel = this.irsModel;
      // this.reSendNormalState()
    },
    reSendNormalState() {
      var times = new Date().getTime();
      var msgInitiative = {
        action: "State.Get",
        clientType: "HiTeachCC",
        sender: sessionStorage.getItem("user").sub,
        timestamp: times,
        waitReturn: false,
        payload: "",
      };
      this.$store.state.IRSswitch = true;
      this.singnalRMessage(msgInitiative, "8888", "", "");
    },
    //舊版點按文字框編輯動作
    editText(stage, textNode, layer) {
      let that = this;
      // let tr = new Konva.Transformer({
      //         enabledAnchors: ['middle-left', 'middle-right'],
      //         anchorStroke: '#00a6ff',
      //         anchorFill: '#fff',
      //         anchorSize: 12,
      //         anchorCornerRadius: 5,
      //         anchorStrokeWidth: 2,
      //         borderStroke: '#6ac9fc',
      //         borderStrokeWidth: 2,
      //         borderDash: [3, 3],
      //         padding: 10,
      //         boundBoxFunc: function(oldBox, newBox) {
      //           newBox.width = Math.max(50, newBox.width)
      //           newBox.height = Math.max(50, newBox.height)
      //           return newBox
      //         }
      //       })
      textNode.on("transform", function () {
        // reset scale, so only with is changing by transformer
        textNode.setAttrs({
          width: Math.max(textNode.width() * textNode.scaleX(), 20),
          height: Math.max(textNode.height() * textNode.scaleY(), 20),
          scaleX: 1,
          scaleY: 1,
        });
      });

      // layer.add(tr)
      //  tr.nodes([textNode])
      // layer.draw()
      textNode.on("click touchstart", (e) => {
        // console.log(e)
        that.textPickerToolpos = {
          x: textNode.x - 550,
          y: textNode.y - 100,
        };
        textNode.hide();
        // tr.show()
        that.$store.state.isEditingTextNow = true;
        layer.draw();

        let textPosition = textNode.absolutePosition();
        let stageBox = stage.container().getBoundingClientRect();
        let areaPosition = {
          x: stageBox.left + textPosition.x,
          y: stageBox.top + textPosition.y,
        };
        let textarea = document.createElement("textarea");
        document.body.appendChild(textarea);

        textarea.value = textNode.text();
        textarea.placeholder = this.$t("board.輸入文字");
        textarea.style.position = "absolute";
        textarea.style.top = areaPosition.y + "px";
        textarea.style.left = areaPosition.x + "px";
        textarea.style.width = textNode.width() - textNode.padding() * 2 + "px";
        textarea.style.height = textNode.height() + "px";
        // textNode.height() - textNode.padding() * 2 + 5 + 'px'
        textarea.style.fontSize = textNode.fontSize() + "px";
        textarea.style.border = "none";
        textarea.style.padding = "0px";
        textarea.style.margin = "0px";
        textarea.style.overflow = "hidden";
        textarea.style.background = "none";
        textarea.style.outline = "none";
        textarea.style.resize = "none";
        textarea.style.lineHeight = textNode.lineHeight();
        textarea.style.fontFamily = textNode.fontFamily();
        textarea.style.transformOrigin = "left top";
        textarea.style.textAlign = textNode.align();
        textarea.style.color = textNode.fill();
        let rotation = textNode.rotation();
        let transform = "";
        if (rotation) {
          transform += "rotateZ(" + rotation + "deg)";
        }
        let px = 0;
        let isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
        if (isFirefox) {
          px += 2 + Math.round(textNode.fontSize() / 20);
        }
        transform += "translateY(-" + px + "px)";
        textarea.style.transform = transform;
        textarea.style.height = "auto";
        textarea.style.height = textarea.scrollHeight + 3 + "px";
        textarea.focus();
        function removeTextarea() {
          textarea.parentNode.removeChild(textarea);
          window.removeEventListener("click", handleOutsideClick);
          window.removeEventListener("touchstart", handleOutsideClick);
          textNode.show();
          // tr.nodes([])
          // tr.forceUpdate()
          layer.draw();
          textNode.off("click");
          textNode.off("touchstart");
          that.$store.state.isEditingTextNow = false;
        }
        function setTextareaWidth(newWidth) {
          if (!newWidth) {
            // set width for placeholder
            newWidth = textNode.placeholder.length * textNode.fontSize();
          }
          // some extra fixes on different browsers
          let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
          let isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
          if (isSafari || isFirefox) {
            newWidth = Math.ceil(newWidth);
          }
          let isEdge = document.documentMode || /Edge/.test(navigator.userAgent);
          if (isEdge) {
            newWidth += 1;
          }
          textarea.style.width = newWidth + "px";
        }
        textarea.addEventListener("keydown", function (e) {
          // hide on enter
          // but don't hide on shift + enter
          if (e.keyCode === 13 && !e.shiftKey) {
            textNode.text(textarea.value);
            removeTextarea();
          }
          // on esc do not set value back to node
          if (e.keyCode === 27) {
            removeTextarea();
          }
        });
        textarea.addEventListener("keydown", function (e) {
          // console.log(e)
          let scale = textNode.getAbsoluteScale().x;
          setTextareaWidth(textNode.width() * scale);
          textarea.style.height = "auto";
          textarea.style.height = textarea.scrollHeight + textNode.fontSize() * 2 + "px";
        });
        function handleOutsideClick(e) {
          // console.log(e)
          that.textPickerToolpos = {
            x: textNode.x - 250,
            y: textNode.y - 100,
          };
          if (that.$store.state.currentEditTextNode.index == textNode.index) {
            console.log("目前編輯的index:" + textNode.index);
            textNode.setAttrs({
              fill: that.$store.state.textColor,
              fontSize: that.$store.state.textSize,
            });
            textarea.style.color = textNode.fill();
            textarea.style.fontSize = textNode.fontSize() + "px";
          }
          if (e.target !== textarea && e.target.className != "slider_box" && e.target.className != "menu-title" && e.target.className != "color-btn" && e.target.className != "ivu-slider-bar" && e.target.className != "ivu-slider-button" && e.target.className != "q-gutter-sm" && e.target.className != "ivu-slider-wrap" && e.target.className != "color-btn select-color" && e.target.className != "ivu-tooltip") {
            // that.textPickerToolpos = {
            //   x: 0,
            //   y: 0
            // }
            textNode.setAttrs({
              text: textarea.value,
              width: textNode.width() * textNode.scaleX(),
              height: textarea.scrollHeight,
              // fill: that.$store.state.textColor,
              // fontSize: that.$store.state.textSize,
              // scaleX: 1,
              // scaleY: 1
            });
            removeTextarea();
          }
        }
        setTimeout(() => {
          window.addEventListener("touchstart", handleOutsideClick);
          window.addEventListener("click", handleOutsideClick);
        });
      });
    },

    pen(slide) {
      console.log("进入PEN");
      let that = this;
      return new Promise((r, j) => {
        // let that = this   //可能有錯
        this.prestraindata(); //翻页加载当页的互动数据
        this.answerSelect();
        this.studentPercentages();

        // 清理 konva
        that.layer.destroyChildren();
        /*
        that.stage = new Konva.Stage({
          container: 'container',
          width: window.innerWidth,
          height: window.innerHeight
          // draggable: true
        })
        */
        that.stage.width(window.innerWidth);
        that.stage.height(window.innerHeight);

        var canvas_dom = document.getElementById("container"); // make this your canvas DOM element
        canvas_dom.addEventListener("touchstart", function (event) {
          event.preventDefault();
        });
        canvas_dom.addEventListener("touchmove", function (event) {
          event.preventDefault();
        });
        canvas_dom.addEventListener("touchend", function (event) {
          event.preventDefault();
        });
        canvas_dom.addEventListener("touchcancel", function (event) {
          event.preventDefault();
        });

        // 初始化画板
        let stage = that.stage;
        that.$store.state.stage = that.stage;
        // that.layer = new Konva.Layer()
        let layer = that.layer;
        that.$store.state.layer = layer;
        // stage.add(layer)

        //    //空白背景底圖
        //  let background = new Konva.Rect({
        //   x: 0,
        //   y: 0,
        //   fill: 'white',
        //   width: window.innerWidth,
        //   height:  window.innerHeight,
        //   illLinearGradientStartPoint: { x: 0, y: 0 },
        //   listening: false,
        //   name:'whiteRect'
        // });
        // background.moveToBottom()
        // that.layer.add(background);

        // that.layer.draw();
        // // the stage is draggable
        // // that means absolute position of background may change
        // // so we need to reset it back to {0, 0}

        // that.stage.on('dragmove', () => {
        //   background.absolutePosition({ x: 0, y: 0 });
        // });
        this.rendererinof(slide);
        // 定义绘制所需的一些属性
        let isPaint = false; // 画笔是否生效
        let mode = that.$store.state.mode; // 绘制模式
        let opacity = 1; // 镭射笔透明度
        let lineId = ""; // 线条id
        let lastLine; // 线条
        let rect; // 各种图形的容器
        let currentArrow;
        let shapeType; // 绘制图形的类型 circle、rect...
        let start = [];
        let panning = false; //平移
        let lastClientX;
        let lastClientY;
        let beginClientX;
        let beginClientY;

        let end = []; // 记录绘制图形的起始点坐标、结束点坐标
        // 点击画布 获取到e.pos、mode等绘制信息
        stage.on("mouseup.1 touchend.1", function (e) {

          if(mode=='shape'|| mode=='pencil-alt'){
            let addElement= layer.children[layer.children.length - 1]
            that.$toolbox.saveUndoHistory('add', addElement )//儲存undo記錄
          }else if(mode == 'check'){
            that.$toolbox.saveUndoUpdate()
          } 

          that.$store.state.touchHideMenu = false;
          //  console.log(e)
          isPaint = false;
          panning = false;
          if (Math.pow(beginClientX - stage.getPointerPosition().x, 2) + Math.pow(beginClientY - stage.getPointerPosition().y, 2) < 4) {
            if (mode == "hand" || mode == "zooms") {
              that.add();
            }
          }

          if (e.type == "touchend") {
            lastClientX = stage.getPointerPosition().x;
            lastClientY = stage.getPointerPosition().y;
          } else {
            lastClientX = e.evt.offsetX;
            lastClientY = e.evt.offsetY;
          }

          (start = []), (end = []);
        });
        stage.on("mousedown.1 touchstart.1", function (e) {
          that.$store.state.touchHideMenu = false;
          // console.log(e)
          panning = true;
          if (e.type == "touchstart") {
            beginClientX = lastClientX = stage.getPointerPosition().x; //touch在舞台 x y 一般event 似乎回傳為空
            beginClientY = lastClientY = stage.getPointerPosition().y;
          } else {
            beginClientX = lastClientX = e.evt.offsetX;
            beginClientY = lastClientY = e.evt.offsetY;
          }

          // console.log('进入11111111111')
          // console.log(stage)
          let target = e.target;
          let anim = new Konva.Animation(function (frame) {
            // 镭射笔
            let scale = Math.sin((frame.time * 2 * Math.PI) / 3000) + 0.001;
            if (stage.findOne("#magic")) {
              stage.findOne("#magic").opacity(scale);
            }
          }, layer);
          let penType;
          if (mode == "pencil-alt") {
            penType = that.$store.state.penType ? that.$store.state.penType : "pencil-alt";
          }
          let strokeColor = that.$store.state.strokeColor;
          let strokeWidth = that.$store.state.strokeWidth; //形状边框粗度
          let magicColor = that.$store.state.magicColor;
          shapeType = that.$store.state.shapeType;
          let shapeColor = that.$store.state.shapeColor;
          let shapeBorderColor = that.$store.state.shapeBorderColor;
          let textSize = that.$store.state.textSize;
          let textColor = that.$store.state.textColor;
          let pos = stage.getPointerPosition();
         
          if (mode == "check") {
            // 选择工具
            document.body.classList.remove("cursor-fdj");
            var checkvalue = that.$store.state.checkvalue;
            console.log(mode);
            if (checkvalue === "checkbtn") {
              console.log("进入基础选择", target.className);
              if (target == stage || target.attrs.name == "boardPdfBg") {
                that.$toolbox.currentSelectElement = ''
                that.showObjMenu = false;
                stage.find("Transformer").forEach((item) => {
                  item.destroy();
                });
                layer.draw();
              } else if (target.attrs.name !== "objBtn") {
                console.log(target, "拖动的元素");
                that.$toolbox.currentSelectElement = target 
                that.$toolbox.saveUndoUpdate()

                if (target.className == "Rect" && target.parent.className == "Transformer") {
                  target = target.parent._nodes[0];
                }

                // console.log(target.parent.className == 'Transformer', '拖动的元素')
                if (target.className != "text" && target.attrs.name != "pastTextContent" && target.attrs.name != "pastImgContent"&&target.attrs.name != "pastTextPickName") {
                  target.draggable(true);
                }

                target.on("dblclick dbltap", function (e) {
                  if (target.attrs.name == "text") {
                    //新版彈出文字編輯視窗
                    that.showObjMenu=false
                    that.$store.state.showtextInuptCard = true;
                    that.$store.state.currentEditTextNode = target;
                    that.currentAddTextValue = target.attrs.text;
                    that.$store.state.textSize = target.attrs.fontSize;
                    that.$store.state.textColor = target.attrs.fill;
                  } else if (target.attrs.name == "pastTextContent") {
                    console.log(target, "pastTextContent");
                    that.$store.state.showtextInuptCard = true;
                    that.$store.state.currentEditTextNode = target.parent;
                    that.currentAddTextValue = target.attrs.text;
                    that.currentStickerColor = target.parent.children[0].attrs.fill;
                    that.$store.state.textSize = target.attrs.fontSize;
                    that.$store.state.textColor = target.attrs.fill;
                  }
                });
                target.on("transform", (e) => {
                  // with enabled anchors we can only change scaleX
                  // so we don't need to reset height
                  // just width
                  //  console.log(target,'變形的')
                  if (target.attrs.name == "text") {
                    target.setAttrs({
                      width: Math.max(target.width() * target.scaleX(), 20),
                      height: Math.max(target.height() * target.scaleY(), 20),
                      scaleX: 1,
                      scaleY: 1,
                    });
                  }
                  if (target.className == "Label") {
                    let tempText = e.target.children[1];
                    // console.log(e.target)
                    // console.log(tempText.attrs)

                    e.target.children[1].destroy();
                    e.target.add(
                      new Konva.Text({
                        text: tempText.attrs.text,
                        fontSize: tempText.attrs.fontSize,
                        fill: tempText.attrs.fill,
                        padding: 20,
                        lineHeight: 1,
                        width: Math.abs(tempText.attrs.width * e.target.attrs.scaleX),
                        height: tempText.attrs.height == "auto" ? Math.abs(200 * e.target.attrs.scaleY) : Math.abs(tempText.attrs.height * e.target.attrs.scaleY),
                        name: "pastTextContent",
                        draggable: false,
                        listening: true,
                      })
                    );
                    (e.target.attrs.scaleX = 1), (e.target.attrs.scaleY = 1), layer.batchDraw();
                  }
                });

                target.on("click tap", function (e) {
                  // stage.find('Transformer').destroy()
                  let tr = new Konva.Transformer({
                    anchorStroke: "#00a6ff",
                    anchorFill: "#fff",
                    anchorSize: 12,
                    anchorCornerRadius: 5,
                    anchorStrokeWidth: 2,
                    borderStroke: "#6ac9fc",
                    borderStrokeWidth: 2,
                    borderDash: [3, 3],
                    padding: 10,
                    name: "default",
                  });

                  stage.find("Transformer").forEach(function (ele, i) {
                    if (ele.attrs.name == "default") {
                      ele.destroy();
                    }
                  });
                  if (target.attrs.name !== "boardPdfBg" && target.attrs.name !== "pastTextContent" && target.attrs.name !== "pastImgContent" && target.attrs.name != "pastTextPickName" && target.className != "text") {
                    layer.add(tr);
                    tr.nodes([this]);
                    that.addMenuBtnToTr(tr, this);
                    layer.draw();
                  } else if (target.attrs.name == "pastImgContent" && target.className != "text"||target.attrs.name == "pastTextPickName"||target.attrs.name == "pastTextContent" && target.className != "text") {
                    target.draggable(false);
                    target.parent.draggable(true);
                    layer.add(tr);
                    tr.nodes([this.parent]);
                    that.addMenuBtnToTr(tr, this.parent);
                    layer.draw();
                  } else {
                    that.showObjMenu = false;
                    tr.nodes([]);
                    layer.draw();
                    target.draggable(false);
                  }

                  if (this.parent && target.attrs.name == "pastTextContent") {
                    this.parent.on("transform", function (e) {
                      let tempText = e.target.children[1];
                      // console.log(e.target)
                      // console.log(tempText.attrs)

                      e.target.children[1].destroy();
                      e.target.add(
                        new Konva.Text({
                          text: tempText.attrs.text,
                          fontSize: tempText.attrs.fontSize,
                          fill: tempText.attrs.fill,
                          padding: 20,
                          lineHeight: 1,
                          width: Math.abs(tempText.attrs.width * e.target.attrs.scaleX),
                          height: tempText.attrs.height == "auto" ? Math.abs(200 * e.target.attrs.scaleY) : Math.abs(tempText.attrs.height * e.target.attrs.scaleY),
                          name: "pastTextContent",
                          draggable: false,
                          listening: true,
                        })
                      );
                      (e.target.attrs.scaleX = 1), (e.target.attrs.scaleY = 1), layer.batchDraw();
                    });
                  }
                  //tr.on('mouseenter', function () {
                  //    stage.container().style.cursor = 'help';
                  //});
                  //tr.on('mouseleave', function () {
                  //    stage.container().style.cursor = 'default';
                  //});
                });
                //konva變形奇怪的地方，點到目標Transform的上下節點，初次加入會把非目標單行英文的node文字變形，變形完設定為1
                stage.find("Text").forEach(function (ele) {
                  ele.on("transformend", function () {
                    this.scale({ x: 1, y: 1 });
                    layer.batchDraw();
                  });
                });
              }
            } else if (checkvalue == "hand") {
              //拖动效果
              mode = "hand";
            }
          }
          if (mode == "hand" || mode == "zooms") {
            console.log("触发了！");
            stage.draggable(true);
            // that.add()
            console.log(stage, "查看内容");
            if (mode == "hand") {
              document.body.style.cursor = "grab";
            }
            if (mode == "zooms") {
              document.body.classList.add("cursor-fdj");
            }
          } else {
            stage.draggable(false);
            document.body.style.cursor = "default";
          }
          if (mode != "check" && (penType == "pencil-alt" || penType == "highlighter" || penType == "magic")) {
            if (penType == "pencil-alt") {
              strokeColor = strokeColor;
              strokeWidth = strokeWidth * 2;
              opacity = 1;
              lineId = "";
              // anim.stop();
            } else if (penType == "highlighter") {
              strokeColor = strokeColor;
              strokeWidth = strokeWidth * 5;
              opacity = 0.5;
              lineId = "";
              // anim.stop();
            } else if (penType == "magic") {
              if (stage.findOne("#magic")) {
                stage.findOne("#magic").destroy();
              }
              strokeColor = strokeColor;
              strokeWidth = strokeWidth * 5;
              opacity = 0.5;
              lineId = "magic";
              // anim.start()
            }
            isPaint = true;
            lastLine = new Konva.Line({
              stroke: strokeColor,
              strokeWidth: strokeWidth,
              lineCap: "round",
              lineJoin: "round",
              opacity: opacity,
              globalCompositeOperation: "source-over",
              points: [that.getRelativePointerPosition(stage).x, that.getRelativePointerPosition(stage).y],
              id: lineId,
              name: "brush",
              closed: false,
              shadowBlur: 1,
              shadowColor: strokeColor,
              tension: 0.5,
              uuid:that.$jsFn.getUUID(),
              index: layer?.children ? layer?.children.length : 1
            });
            layer.add(lastLine);
          } else if (mode == "shape") {
            // 画各种图形
            document.body.classList.remove("cursor-fdj");
            isPaint = true;
            if (shapeType == "line") {
              // 直线
              rect = new Konva.Line({
                fill: shapeBorderColor,
                stroke: shapeBorderColor,
                strokeWidth: 5,
                closed: true,
                globalCompositeOperation: "source-over",
                points: [that.getRelativePointerPosition(stage).x, that.getRelativePointerPosition(stage).y],
                name: "line",
                lineCap: "round",
                lineJoin: "round",
                uuid:that.$jsFn.getUUID(),
                index: layer?.children ? layer?.children.length : 1
              });
              layer.add(rect);
            } else if (shapeType == "long-arrow-alt-right") {
              currentArrow = new Konva.Line({
                x: that.getRelativePointerPosition(stage).x,
                y: that.getRelativePointerPosition(stage).y,
                rotation: 180,
                offsetX: 0,
                offsetY: 0,
                // points: [0, 0, 3.25695, -22.371525, 3.25695, -22.371525, 9.6, -19.2, 0, -38.4, -9.6, -19.2, -3.25695, -22.371525],
                points: [0, 0, 6.25695, -22.371525, 6.25695, -22.371525, 13.6, -19.2, 0, -38.4, -13.6, -19.2, -6.25695, -22.371525],
                // fill: 'red',
                fill: shapeBorderColor,
                stroke: shapeBorderColor,
                strokeWidth: 1,
                shadowBlur: 0,
                closed: true,
                lineCap: "round",
                lineJoin: "round",
                draggable: true,
                uuid:that.$jsFn.getUUID(),
                index: layer?.children ? layer?.children.length : 1
              });
            } else {
              if (shapeType == "square") {
                // 矩形
                rect = new Konva.Rect({
                  x: that.getRelativePointerPosition(stage).x - 10,
                  y: that.getRelativePointerPosition(stage).y - 10,
                  width: 10,
                  height: 10,
                  fill: shapeColor,
                  stroke: shapeBorderColor,
                  strokeWidth: 4,
                  name: "rect",
                  uuid:that.$jsFn.getUUID(),
                  index: layer?.children ? layer?.children.length : 1
                });
              } else if (shapeType == "circle") {
                // 正圆
                rect = new Konva.Circle({
                  x: that.getRelativePointerPosition(stage).x - 5,
                  y: that.getRelativePointerPosition(stage).y - 5,
                  // radius: 50,
                  radius: 5,
                  fill: shapeColor,
                  stroke: shapeBorderColor,
                  strokeWidth: 4,
                  name: "circle",
                  uuid:that.$jsFn.getUUID(),
                  index: layer?.children ? layer?.children.length : 1
                });
              } else if (shapeType == "ellipse") {
                // 椭圆
                rect = new Konva.Ellipse({
                  x: that.getRelativePointerPosition(stage).x - 5,
                  y: that.getRelativePointerPosition(stage).y - 5,
                  // radius: 50,
                  radiusX: 5,
                  radiusY: 5,
                  fill: shapeColor,
                  stroke: shapeBorderColor,
                  strokeWidth: 4,
                  name: "ellipse",
                  uuid:that.$jsFn.getUUID(),
                  index: layer?.children ? layer?.children.length : 1
                });
              } else if (shapeType == "campground") {
                // 三角形
                rect = new Konva.RegularPolygon({
                  x: that.getRelativePointerPosition(stage).x - 10,
                  y: that.getRelativePointerPosition(stage).y - 5,
                  sides: 3,
                  radius: 10,
                  fill: shapeColor,
                  stroke: shapeBorderColor,
                  strokeWidth: 4,
                  name: "campground",
                  uuid:that.$jsFn.getUUID(),
                  index: layer?.children ? layer?.children.length : 1
                });
              } else if (shapeType == "star") {
                // 五角星
                rect = new Konva.Star({
                  x: that.getRelativePointerPosition(stage).x - 7,
                  y: that.getRelativePointerPosition(stage).y - 7,
                  numPoints: 5,
                  innerRadius: 4,
                  outerRadius: 8,
                  fill: shapeColor,
                  stroke: shapeBorderColor,
                  strokeWidth: 4,
                  name: "star",
                  uuid:that.$jsFn.getUUID(),
                  index: layer?.children ? layer?.children.length : 1
                });
              }
              console.log(rect);
              console.log(shapeType);
              //layer.add(rect)//点击会生成一个印章图形
              console.log(layer);
              layer.draw();
            }
          } else if (mode == "eraser") {
            console.log("点击了删除元素");
            var trs = new Konva.Transformer({});
            layer.add(trs);
            console.log(trs);
            //trs.nodes();
            var selectionRectangle = new Konva.Rect({
              fill: "rgba(180,180,180,0.5)",
              name: "selectionRectangle",
            });
            console.log(selectionRectangle);
            layer.add(selectionRectangle);
            var x1, y1, x2, y2;
            console.log(x1, y1, x2, y2);
            stage.on("mousedown.2 touchstart.2", (e) => {
              // do nothing if we mousedown on eny shape
              if (e.currentTarget !== stage) {
                return;
              }
              x1 = that.getRelativePointerPosition(stage).x;
              y1 = that.getRelativePointerPosition(stage).y;
              x2 = that.getRelativePointerPosition(stage).x;
              y2 = that.getRelativePointerPosition(stage).y;

              console.log(x1, x2, y1, y2);
              selectionRectangle.visible(true);
              selectionRectangle.width(0);
              selectionRectangle.height(0);
              layer.draw();
            });
            stage.on("mousemove.2 touchmove.2", () => {
              // no nothing if we didn't start selection
              if (!selectionRectangle.visible()) {
                return;
              }
              x2 = that.getRelativePointerPosition(stage).x;
              y2 = that.getRelativePointerPosition(stage).y;
              selectionRectangle.setAttrs({
                x: Math.min(x1, x2),
                y: Math.min(y1, y2),
                width: Math.abs(x2 - x1),
                height: Math.abs(y2 - y1),
              });
              layer.batchDraw();
              //console.log(selectionRectangle)
            });
            stage.on("mouseup.2 touchend.2", () => {
              // no nothing if we didn't start selection
              if (!selectionRectangle.visible()) {
                return;
              }
              // update visibility in timeout, so we can check it in click event
              setTimeout(() => {
                selectionRectangle.visible(false);
                layer.batchDraw();
              });
              var shapes = stage.getChildren()[0].children.toArray();

              var box = selectionRectangle.getClientRect();

              var selected = shapes.filter((shape) => (!shape.attrs.name || (shape.attrs.name !== "boardPdfBg" && shape.attrs.name !== "bgRect")) && Konva.Util.haveIntersection(box, shape.getClientRect()));
              stage.eventListeners.mousedown = stage.eventListeners.mousedown.filter((i) => (i.name = "2")).splice(0, 1);
              stage.eventListeners.mousemove = stage.eventListeners.mousemove.filter((i) => (i.name = "2")).splice(0, 1);
              stage.eventListeners.mouseup = stage.eventListeners.mouseup.filter((i) => (i.name = "2")).splice(0, 1);
              stage.eventListeners.touchend = stage.eventListeners.touchend.filter((i) => (i.name = "2")).splice(0, 1);
              stage.eventListeners.touchmove = stage.eventListeners.touchmove.filter((i) => (i.name = "2")).splice(0, 1);
              stage.eventListeners.touchstart = stage.eventListeners.touchstart.filter((i) => (i.name = "2")).splice(0, 1);
              //console.log(selected, '内容')
              //console.log(stage.eventListeners,'点击过后的')
              selected.forEach((i) => {
                that.$toolbox.saveUndoHistory("delete", i);
                i.destroy();
              });
              //trs.nodes(selected);
              //layer.batchDraw();
            });
            let currentDeleteObj;
            document.body.classList.remove("cursor-fdj");
            if (e.target != stage && e.target.attrs.name != "boardPdfBg") {
             
              that.$toolbox.removeTransformer()
              //刪除便利貼，點到文字就殺整個
              if (e.target.className == "Text" && e.target.parent?.className == "Label"||e.target.attrs.name=='pastTextPickName'||e.target.attrs.name=='pastImgContent') {
                currentDeleteObj = e.target.parent;
                
              }else{
                currentDeleteObj= e.target
              }
              that.$toolbox.saveUndoHistory("delete",  currentDeleteObj); 
              currentDeleteObj.destroy();
              layer.draw();
            }
          }
        });
        // 离开画布 关闭线条生效，重置画笔开始结束数组

        // 介于点击和离开之间，在画布上面移动，获取移动的e.pos信息，将信息赋值给正在绘制的图形
        stage.on("mousemove.1 touchmove.1", function (e) {
          panning = true;
          // console.log(e)
          if (panning == true && e.type == "touchmove") {
            // Update the last X and Y values
            lastClientX = e.evt.x;
            lastClientY = e.evt.y;
          } else {
            if (panning == true && e.type == "mousemove") {
              // Update the last X and Y values
              lastClientX = e.evt.offsetX;
              lastClientY = e.evt.offsetY;
            }
          }

          if (that.$store.state.mode == "check") {
            mode = "check";
          }
          if (mode == "check") {
            document.body.classList.remove("cursor-fdj");
            if (e.target == stage || e.target.attrs.name == "boardPdfBg") {
              document.body.style.cursor = "default";
            } else {
              document.body.style.cursor = "move";
              // mode='check'
            }
          }
          if (mode == "zooms") {
            document.body.classList.remove("cursor-fdj");
            document.body.style.cursor = "grab";
          }
          // 直线的
          if (!isPaint) {
            return;
          }
      
          const pos = stage.getPointerPosition();
          let onex = that.getRelativePointerPosition(stage).x;
          let oney = that.getRelativePointerPosition(stage).y;
          let newPoints = [];
          if (mode == "pencil-alt" || mode == "highlighter" || mode == "magic") {
            //console.log(onex, oney, 'XY的坐标')
            if (this.oldonex == "" && this.oldoney == "") {
              this.oldonex = onex;
              this.oldoney = oney;
              this.newPoints = lastLine.points().concat([onex, oney]);
            } else {
              if (this.oldonex != onex || this.oldoney != oney) {
                this.oldonex = onex;
                this.oldoney = oney;
                this.newPoints = lastLine.points().concat([onex, oney]);
              }
            }
            //let newPoints = lastLine.points().concat([onex, oney])
            document.body.classList.remove("cursor-fdj");
            lastLine.points(this.newPoints);
            layer.batchDraw();
          } else if (shapeType == "long-arrow-alt-right") {
            const newPoints = currentArrow.points();
            const deltaX = that.getRelativePointerPosition(stage).x - currentArrow.x();
            const deltaY = that.getRelativePointerPosition(stage).y - currentArrow.y();
            let newArrowHeight = Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2));
            if (newArrowHeight < 38.4) newArrowHeight = 38.4;
            const newRotation = (Math.atan2(deltaX, -deltaY) * 180) / Math.PI;
            newPoints[1] = newArrowHeight - 38.4;
            currentArrow.offsetY(newArrowHeight - 38.4);
            currentArrow.points(newPoints);
            currentArrow.rotation(newRotation);
            layer.add(currentArrow);
            layer.batchDraw();
          } else {
            document.body.classList.remove("cursor-fdj");
            if (!rect) {
              return;
            }
            let name = rect.name();
            if (name == "line") {
              let newPoints;
              if (shapeType == "line") {
                if (rect.points().length > 4) {
                  newPoints = rect
                    .points()
                    .slice(0, 2)
                    .concat([that.getRelativePointerPosition(stage).x, that.getRelativePointerPosition(stage).y]);
                } else {
                  newPoints = rect.points().concat([that.getRelativePointerPosition(stage).x, that.getRelativePointerPosition(stage).y]);
                }
              }
              rect.points(newPoints);
            } else if (name == "rect") {
              // 矩形
              if (start.length < 1) {
                start = [pos.x + 10, pos.y + 10];
              }
              let newPoints = end.concat([pos.x + 10, pos.y + 10]).slice(-2);

              rect.width(newPoints[0] - start[0] + 10);
              rect.height(newPoints[1] - start[1] + 10);
            } else if (name === "circle") {
              // 正圆
              if (start.length < 1) {
                start = [pos.x + 5, pos.y + 5];
              }
              let newPoints = end.concat([pos.x + 5, pos.y + 5]).slice(-2);
              let radiusX = newPoints[0] - start[0] + 5;
              let radiusY = newPoints[1] - start[1] + 5;
              if (radiusX < 10 || radiusY < 10) {
                return;
              }
              rect.radius(newPoints[0] - start[0] + 5);
              // rect.radiusY(newPoints[1] - start[1] + 5);
            } else if (name === "ellipse") {
              // 椭圆
              if (start.length < 1) {
                start = [pos.x + 5, pos.y + 5];
              }
              let newPoints = end.concat([pos.x + 5, pos.y + 5]).slice(-2);
              let radiusX = newPoints[0] - start[0] + 5;
              let radiusY = newPoints[1] - start[1] + 5;
              if (radiusX < 10 || radiusY < 10) {
                return;
              }
              rect.radiusX(newPoints[0] - start[0] + 5);
              rect.radiusY(newPoints[1] - start[1] + 5);
            } else if (name == "campground") {
              // 三角形&多边形
              if (start.length < 1) {
                start = [pos.x - 10, pos.y - 5];
              }
              let newPoints = end.concat([pos.x, pos.y]).slice(-2);
              let radiusX = newPoints[0] - start[0] + 5;
              rect.radius(newPoints[0] - start[0]);
            } else if (name == "star") {
              // 五角星
              if (start.length < 1) {
                start = [pos.x - 4, pos.y - 4];
              }
              let newPoints = end.concat([pos.x, pos.y]).slice(-2);
              rect.innerRadius(newPoints[0] - start[0]);
              rect.outerRadius((newPoints[0] - start[0]) * 2);
            }
            layer.add(rect);
            layer.batchDraw();
            // var c =layer.clone();
            // sessionStorage.setItem('stageInfo',JSON.stringify(c));
          }
        });
        // 工具切换（点击侧边栏按钮）
        let tools1 = document.getElementById("tool1");
        tools1.addEventListener("click", async function (event) {
          event.stopPropagation();
          // console.log('click tools1')

          let val = that.$store.state.mode;

          // console.log(val)
          if (!val) {
            return;
          }
          if (val == "check" || val == "pickOne") {
            // console.log('进入了check')
            document.body.classList.remove("cursor-fdj");
            var valuename = that.$store.state.checkvalue;
            if (valuename === "checkbtn" && layer.children.length > 0) {
              for (let i = 0; i < layer.children.length; i++) {
                if (layer.children[i].attrs.name === "boardPdfBg") {
                  layer.children[i].draggable(false);
                } else {
                  layer.children[i].draggable(true);
                }
              }
            }
          } else {
            if (layer.children) {
              for (let i = 0; i < layer.children.length; i++) {
                layer.children[i].draggable(false);
              }
            }
          }

          // if(val == 'shape'){
          //   document.body.style.cursor = 'crosshair';
          // }
          if (val == "eraser") {
            console.log("点击了删除按钮");
            // console.log(stage)
            console.log(that.$store.state.eraser, "Vuex的值");
            if (that.$store.state.eraser == "clear") {
              that.showCanvasClearDangerHint=true
              
            } else {
              mode = "eraser";
            }

            stage.eventListeners.mousedown = stage.eventListeners.mousedown ? stage.eventListeners.mousedown.filter((i) => (i.name = "2")).splice(0, 1) : "";
            stage.eventListeners.mousemove = stage.eventListeners.mousemove ? stage.eventListeners.mousemove.filter((i) => (i.name = "2")).splice(0, 1) : "";
            stage.eventListeners.mouseup = stage.eventListeners.mouseup ? stage.eventListeners.mouseup.filter((i) => (i.name = "2")).splice(0, 1) : "";
            stage.eventListeners.touchend = stage.eventListeners.touchend ? stage.eventListeners.touchend.filter((i) => (i.name = "2")).splice(0, 1) : "";
            stage.eventListeners.touchmove = stage.eventListeners.touchmove ? stage.eventListeners.touchmove.filter((i) => (i.name = "2")).splice(0, 1) : "";
            stage.eventListeners.touchstart = stage.eventListeners.touchstart ? stage.eventListeners.touchstart.filter((i) => (i.name = "2")).splice(0, 1) : "";

            //console.log(stage.eventListeners)
            document.body.classList.remove("cursor-fdj");
          } else {
            mode = val;
            that.$store.state.eraser = "eraser";
            document.body.style.cursor = "default";
            layer.draw();
           
          }
          if (val == "zooms") {
            // console.log('点击了缩放工具！')
            document.body.classList.add("cursor-fdj");
            return;
            //console.log(that.$store.state.mode)
            //console.log(document.body.style)
            //let zoomType = that.$store.state.zoomType
            //if (zoomType == 'hand') {
            //    mode = that.$store.state.zoomType
            //}
          }
          if (val == "addImg" || val == "pickOne") {
            mode = "check";
            that.$store.state.mode = "check";
            isPaint = false;
          }
        });

        // 键盘删除
        window.onkeydown = function (e) {
          if (e.keyCode == "46") {
            if (stage.find("Transformer")[0]._node.attrs.name != "boardPdfBg") {
              // 键盘不删除背景图
              stage.find("Transformer")[0]._node.destroy();
              // stage.find('Transformer').destroy()
              stage.find("Transformer").forEach(function (ele, i) {
                if (ele.attrs.name == "default") {
                  ele.destroy();
                }
              });
              layer.draw();
            }
          }
          // if (e.keyCode == '38') {
          //   // 上 之后需要判断是否处于文字编辑状态
          //   that.carousel.previous()
          // }
          // if (e.keyCode == '40') {
          //   // 下
          //   that.carousel.next()
          // }
          if (isPaint == true) {
            if (mode == "shape") {
              if (e.keyCode == "187") {
                if (rect.name() == "star") {
                  rect.numPoints(rect.numPoints() + 1);
                } else {
                  rect.sides(rect.sides() + 1);
                }
                layer.batchDraw();
              } else if (e.keyCode == "189") {
                if (rect.name() == "star") {
                  if (rect.numPoints() > 3) {
                    rect.numPoints(rect.numPoints() - 1);
                  }
                } else {
                  if (rect.sides() > 3) {
                    rect.sides(rect.sides() - 1);
                  }
                }
                layer.batchDraw();
              }
            }
          }
        };
        console.log(this.irsModel, this.$store.state.students, "查看当前页的状态和学生");
        let nowIrsmodel = this.irsModel;
        let isAnswer = this.$store.state.students.filter((item) => {
          return item.option != "";
        });
        if (this.$store.state.isExportPDFNow == false) {
          var times = new Date().getTime();
          var msgInitiative = {
            action: "State.Get",
            clientType: "HiTeachCC",
            sender: sessionStorage.getItem("user").sub,
            timestamp: times,
            waitReturn: false,
            payload: "",
          };
          console.log(nowIrsmodel, "當前模式");
          if (nowIrsmodel != "normal" && nowIrsmodel != "irsText") {
            console.log("当前页有IRS活动");
            this.$store.state.IRSswitch = true; //開關打開訊息才會送出去
            if (nowIrsmodel == "irsImmediately") {
              this.singnalRMessage(msgInitiative, "8888", "", isAnswer.length == 0 ? "irsImmediately" : "irsImmediatelyEnd");
            } else if (nowIrsmodel == "irsGather" && this.currentCollateItem().mode == "Collate_Start") {
              this.singnalRMessage(msgInitiative, "8888", "", "irsGatherRestart");
            } else if (nowIrsmodel == "irsGather" && this.currentCollateItem().mode == "Collate_End") {
              this.singnalRMessage(msgInitiative, "8888", "", "irsGatherEnd");
            } else if (nowIrsmodel == "irsBuzz") {
              this.singnalRMessage(msgInitiative, "8888", "", "irsBuzzEnd");
            }
          } else if (nowIrsmodel == "irsText") {
            let isAnswerStatement = this.$store.state.students.filter((item) => {
              return item.statement != "";
            });
            console.log("当前页有IRS活动");
            this.singnalRMessage(msgInitiative, "8888", "", isAnswerStatement.length == 0 ? "irsText" : "irsTextEnd");
          } else if (nowIrsmodel == "normal") {
            this.$store.state.IRSswitch = true;
            this.singnalRMessage(msgInitiative, "8888", "", "");
          }
        }

        r(200);
      });
    },
    //每10秒广播一次state状态
    broadcastState() {
      if (this.stage != null && this.$store.state.imgArr && this.$store.state.imgArr.idArr && sessionStorage.getItem("user") && this.$store.state.isExportPDFNow == false) {
        //等所有資料載入才能發訊息
        console.log("调用到了广播方法");
        var times = new Date().getTime();
        var msgInitiative = {
          action: "State.Get",
          clientType: "HiTeachCC",
          sender: JSON.parse(sessionStorage.getItem("user")).sub,
          timestamp: times,
          waitReturn: false,
          payload: "",
        };
        let irsstatsName = "";
        if (this.irsModel == "normal") {
          irsstatsName = "";
        } else if (this.irsModel == "irsGather" && this.currentCollateItem().mode == "Collate_Start") {
          irsstatsName = "irsGatherRestart";
        } else if (this.irsModel == "irsGather" && this.currentCollateItem().mode == "Collate_End") {
          irsstatsName = "irsGatherEnd";
        } else {
          irsstatsName = this.$store.state.currentState;
        }
        // console.log(this.$store.state.currentState)
        if (irsstatsName != "Class.Close") {
          this.singnalRMessage(msgInitiative, "8888", "", irsstatsName);
        } else {
          this.sendCloseClass();
        }
      }
    },
    deleteSelectPage() {
      this.deletePage = true;

      //刪除頁面之情況
      let originalData = this.$store.state.msgBody;
      if (originalData) {
        for (var i = 0; i < originalData.length; i++) {
          if (this.$store.state.deletePageNum === originalData[i].page) {
            originalData.splice(i, 1);
          }
        }
        for (var i = 0; i < originalData.length; i++) {
          if (this.$store.state.deletePageNum < originalData[i].page && originalData.length > 1) {
            originalData[i].page = originalData[i].page - 1;
          }
          if (this.$store.state.deletePageNum == 1 && originalData.length == 1) {
            originalData[i].page = 1;
            console.log(originalData[i]);
          }
        }
      }
      console.log(originalData);

      console.log(this.$store.state.carouselSlide, this.$store.state.deletePageNum);
      console.log("刪除1page");
      if (this.$store.state.imgArr.length > 1) {
        this.$store.state.imgArr.splice(this.$store.state.deletePageNum - 1, 1);
        // this.$store.state.imgArrOld.splice(this.$store.state.deletePageNum - 1, 1)
        //console.log(this.$store.state.imgArrOld)
        this.$store.state.imgArr.idArr.splice(this.$store.state.deletePageNum - 1, 1);
        this.layer.destroyChildren();
        this.saveArr.splice(this.$store.state.deletePageNum - 1, 1);
        sessionStorage.setItem("stageInfo", JSON.stringify(this.saveArr));
        if (this.$store.state.deletePageNum != 1) this.$store.state.carouselSlide = this.$store.state.carouselSlide - 1 > 0 ? this.$store.state.carouselSlide - 1 : 1;
        else {
          this.$store.state.carouselSlide = this.$store.state.carouselSlide > 0 ? this.$store.state.carouselSlide : 1;
          this.saveStage();
        }
      } else if (this.$store.state.deletePageNum == 1 && this.$store.state.imgArr.length == 1) {
        console.log(this.stage);
        this.layer.destroyChildren();
        this.stage.clear();
        this.saveArr.splice(0, 1);
        sessionStorage.setItem("stageInfo", JSON.stringify(this.saveArr));
        this.$store.state.oldSlide = 1;
        this.$store.state.carouselSlide = 1;
        this.$store.state.imgArrOld.splice(0, 1, this.whiteBG);
        this.$store.state.imgArr.splice(0, 1, this.whiteBG);
        //this.$store.state.imgArrOld.idArr[0].pageid = this.genID()
        this.$store.state.imgArr.idArr[0].pageid = this.genID();
        // console.log(this.$store.state.imgArr)
        // console.log(this.$store.state.imgArrOld)
        // this.addNewPage()

        this.saveStage();
      }
      this.$store.state.totalpage = this.$store.state.imgArr.length;
      sessionStorage.setItem("imgArr", JSON.stringify(this.$store.state.imgArr));
    },
    addNewPage(e) {
      this.addBlank = true;
      this.workStatus = false;
      this.isOpenBuzzPop = false;
      this.isWaitingBuzz = false;
      console.log(typeof this.$store.state.oldSlide, "类型");
      // // //舊版需自行手動新增側邊欄頁數
      this.$store.state.imgArr.splice(this.$store.state.oldSlide, 0, this.whiteBG);
      // this.$store.state.imgArrOld.splice(this.$store.state.oldSlide, 0, this.whiteBG)
      this.$store.state.carouselSlide = typeof this.$store.state.oldSlide == "string" ? Number(this.$store.state.oldSlide) + 1 : this.$store.state.oldSlide + 1;
      this.$store.state.oldSlide = this.$store.state.oldSlide + 1;
      let newtotalpage = this.$store.state.totalpage;
      this.$store.state.totalpage = newtotalpage + 1;
      sessionStorage.setItem("slidelength", this.$store.state.totalpage);
      sessionStorage.setItem("imgArr", JSON.stringify(this.$store.state.imgArr));
      console.log(this.$store.state.totalpage, "查看总页数");
    },

    async saveStage(flag) {
      let that = this;
      // 切换轮播时保存舞台
      console.log("savestage 1", this.$store.state.oldSlide, this.$store.state.carouselSlide, this.slidenum);
      console.log(this.$store.state.carousel, "切换舞台时的内容");
      document.body.classList.remove("cursor-fdj");
      //翻页关闭作品收集开关
      that.$store.state.workswitch = false;
      that.workSwitch = false;
      that.workinfo.icon = "stop";
      that.workinfo.text = "停止收集";
      this.workStatus = false;
      //处理翻页默认选中画笔
      that.$store.state.eraser = "eraser";
      let slide = that.$store.state.oldSlide;
      let slideNext = Number(that.$store.state.carouselSlide);
      this.slidenum = slideNext;
      let fileType = sessionStorage.getItem("fileType");
      if (slideNext > this.$store.state.totalpage) {
        slideNext = 1;
        this.$store.state.carouselSlide = 1;
      }
      console.log("savestage 2", this.$store.state.oldSlide, this.$store.state.carouselSlide, this.slidenum, this.$store.state.students, this.$store.state.msgBody);

      if (this.addBlank == true) {
        this.slidenum = Number(this.slidenum);
        let addnewpages = this.$store.state.oldSlide - 1;
        let minusNum = addnewpages === 0 ? 1 : 2;
        let slide = this.$store.state.oldSlide;

        this.stageScreenShotToImgArr(slide, minusNum);

        let layerInfo = this.layer.clone();

        this.saveArr[slide - minusNum + ""] = layerInfo;

        this.switchoverpdf(addnewpages);
        // let originalData1 = JSON.parse(sessionStorage.getItem('msgBody'))
        // console.log(originalData1, '翻頁的值')

        // this.irsModel='normal'
        this.saveArr.splice(slide - minusNum, 0, layerInfo);

        sessionStorage.setItem("stageInfo", JSON.stringify(this.saveArr)); //更新缓存数据
        this.layer.children.length = 0;
        this.$store.state.totalpage = Number(this.totalpage) + 1;
        sessionStorage.setItem("slidelength", this.$store.state.totalpage);
        this.totalpage = Number(this.totalpage) + 1;
        //缩略图内容更改
        let litimgArr = this.$store.state.imgArr;
        let imgpage = this.slidenum - 1;
        let fileType = sessionStorage.getItem("fileType");

        if (fileType === "pdf") {
          for (let l = 0; l < litimgArr.length; l++) {
            if (imgpage < litimgArr[l]) {
              litimgArr = litimgArr[l + 1];
            } else if (imgpage === litimgArr[l]) {
              litimgArr = litimgArr[l + 1];
            }
          }
        }
        //关于新加一页 数据修改
        let originalData = this.$store.state.msgBody;
        if (originalData) {
          for (let i = 0; i < originalData.length; i++) {
            if (this.slidenum <= originalData[i].page && this.$store.state.imgArr.length != 1) {
              originalData[i].page = originalData[i].page + 1;
            }
          }
        }
        if (sessionStorage.getItem("fileType") == "pdf") {
          //新增一页处理pageNum
          this.$store.state.imgArr.idArr.forEach((item) => {
            if (imgpage < item.page || imgpage == item.page) {
              item.page = item.page + 1;
            }
          });
          // if (that.$store.state.beforeSaveStageGenID == false) {
          let parameter = {
            pageid: this.genID(),
            pagetype: "Quiz",
            page: imgpage,
          };
          //新增一页处理pageID
          this.$store.state.imgArr.idArr.splice(imgpage, 0, parameter);
          // } else {
          //   that.$store.state.beforeSaveStageGenID = false
          // }

          // console.log(originalData)

          let newIrsback = this.$store.state.irsBack;
          for (var c = 0; c < newIrsback.length; c++) {
            if (this.slidenum < newIrsback[c].page) {
              newIrsback[c].page = newIrsback[c].page + 1;
            } else if (this.slidenum === newIrsback[c].page) {
              newIrsback[c].page = newIrsback[c].page + 1;
            }
          }
          this.$store.state.irsBack = newIrsback;
        }
        if (this.$store.state.mode != "check") {
          this.$store.state.mode = "pencil-alt";
        }

        //新增後且複製上個舞台內容，即直接畫上個舞台
        if (this.$store.state.copyPage == true) {
          console.log(this.saveArr[slide - minusNum + ""]);
          await this.rendererinof(slide - minusNum);
          //從第一頁複製到第二頁縮圖會是白色，直接取代縮圖
          await this.$store.state.imgArr.splice(slide - 1, 1, this.$store.state.imgArr[slide - minusNum]);
          sessionStorage.setItem("imgArr", JSON.stringify(this.$store.state.imgArr));
          this.$store.state.copyPage = false;
        }

        this.addBlank = false;
      } else if (this.deletePage) {
      } else if (flag == "now") {
        this.switchoverpdf(this.slidenum);
      } else {
        this.switchoverpdf(slide);
      }

      this.irsModel = "normal";
      this.$toolbox.undoHistory=[] //換頁將之前的Undo清空

      if (this.deletePage == false) {
        let that = this;
        if (fileType === "pdf") {
          this.stageScreenShotToImgArr(slide, 1);

          let layerInfo = await that.layer.clone() // 画布内容提取

          console.log(layerInfo, "最新的背景圖次序");

          that.saveArr[slide - 1 + ''] = layerInfo
          console.log(that.saveArr, '页面的提取')
          await sessionStorage.setItem('stageInfo', JSON.stringify(that.saveArr))

          that.stage.clear();
          console.log(slideNext, "页数页数页数");
          let imanumpage = Number(slideNext) - 1;
          console.log(imanumpage, "传进pen的页数");
          await this.pen(imanumpage); // 重新准备画布并传入当前使用的背景图
          //this.rendererinof(imanumpage)   //新的渲染页面
        }
        var times = new Date().getTime();
        var msgInitiative = {
          action: "State.Get",
          clientType: "HiTeachCC",
          sender: sessionStorage.getItem("user").sub,
          timestamp: times,
          waitReturn: false,
          payload: "",
        };
        console.log(this.irsModel, "现在的值");
        this.singnalRMessage(msgInitiative, "8888", "", "");
      } else {
        if (fileType === "pdf") {
          let imanumpage = Number(this.$store.state.carouselSlide) - 1;
          console.log(imanumpage, "传进pen的页数");
          await this.pen(imanumpage); // 重新准备画布并传入当前使用的背景图
          //this.rendererinof(imanumpage)   //新的渲染页面
        }
        this.deletePage = false;
      }
    },
    stageScreenShotToImgArr(slide, minusNum) {
      this.$store.state.zoom=1
      this.zoomFuc();

      let background = new Konva.Rect({
        x: 0,
        y: 0,
        fill: "white",
        width: window.innerWidth,
        height: window.innerHeight,
        illLinearGradientStartPoint: { x: 0, y: 0 },
        listening: false,
        name: "whiteRect",
      });

      this.layer.add(background);
      background.moveToBottom();
      this.layer.batchDraw();
      // the stage is draggable
      // that means absolute position of background may change
      // so we need to reset it back to {0, 0}

      this.stage.on("dragmove", () => {
        background.absolutePosition({ x: 0, y: 0 });
      });

      let stageImg = this.stage.toDataURL({ mimeType: "image/jpeg", quality: 0.1, pixelRatio: 0.3 }); //降低側邊縮圖畫素 // 画布截图{mimeType:"image/jpeg",quality: 0.1,pixelRatio:0.5}
      this.$store.state.imgArr[slide - minusNum] = stageImg;
      sessionStorage.setItem("imgArr", JSON.stringify(this.$store.state.imgArr));

      //拍完照就刪掉白色背景
      this.layer.children.forEach((item) => {
        if (item.className == "Rect" && item.attrs.name == "whiteRect") {
          item.destroy();
        }
      });
    },
    zoomFuc() {
      // 缩放 回到1:1
      let stage = this.stage;
      let layer = this.layer;
      for (let i = 0; i < layer.children.length; i++) {
        layer.children[i].draggable(false);
      }
      stage.draggable(true);
      let zoom = this.$store.state.zoom;
      // console.log(zoom, '缩放工具')
      let oldScale = stage.scaleX();
      let pointer = stage.getPointerPosition();
      if (pointer && this.$store.state.zoomMode == "click") {
        let mousePointTo = {
          x: (pointer.x - stage.x()) / oldScale,
          y: (pointer.y - stage.y()) / oldScale,
        };
        let newPos = {
          x: pointer.x - mousePointTo.x * zoom,
          y: pointer.y - mousePointTo.y * zoom,
        };
        stage.position(newPos);
      } else {
        stage.absolutePosition({
          x: (-stage.width() / 2) * (zoom - 1),
          y: (-stage.height() / 2) * (zoom - 1),
        });
        stage.position({
          x: (-stage.width() / 2) * (zoom - 1),
          y: (-stage.height() / 2) * (zoom - 1),
        });
      }
      // console.log(this.$store.state.zoomMode )
      stage.scale({ x: zoom, y: zoom });
      // // let xandy = stage.absolutePosition()
      // console.log(xandy, '位置')
      //不去控制位置即可邊放大邊拖曳
      // stage.absolutePosition({
      //   x: (-stage.width() / 2) * (zoom - 1),
      //   y: (-stage.height() / 2) * (zoom - 1)
      // })
      // stage.position({
      //   x: (-stage.width() / 2) * (zoom - 1),
      //   y: (-stage.height() / 2) * (zoom - 1)
      // })
      if (zoom == 1) {
        stage.absolutePosition({
          x: (-stage.width() / 2) * (zoom - 1),
          y: (-stage.height() / 2) * (zoom - 1),
        });
        stage.position({
          x: (-stage.width() / 2) * (zoom - 1),
          y: (-stage.height() / 2) * (zoom - 1),
        });
      }
      if (sessionStorage.getItem("fileType") == "ppt" && zoom == 1) {
        //处理PDF相关缩放

        stage.x(this.primevalInit.x);
        console.log(this.stage.size());
        var scaleNumber = this.primevalInit.scale == sessionStorage.getItem("scalings") ? 1 : sessionStorage.getItem("scalings");
        this.stage.scaleX(scaleNumber);
        this.stage.scaleY(scaleNumber);
        this.stage.draw();
      }
      let s = stage.getTransform();
      stage.batchDraw();
    },
    question() {
      // if(this.$store.state.submit=='1'){
      let question = this.$store.state.question;
      let complexText = new Konva.Text({
        x: 20,
        y: 60,
        text: "问：" + question.text + "\nA：" + question.textselectA + "\nB：" + question.textselectB + "\nC：" + question.textselectC + "\nD：" + question.textselectD,
        fontSize: 24,
        fontFamily: "Calibri",
        lineHeight: "2",
        fill: "#3993f1",
        width: 300,
        padding: 20,
        align: "left",
        draggable: true,
      });
      this.layer.add(complexText);
      this.layer.draw();
      // }
    },
    showLoading() {
      const spinner = typeof QSpinnerGears !== "undefined" ? QSpinnerGears : Quasar.components.QSpinnerGears;
      Loading.show({
        spinner,
        spinnerColor: "orange",
        messageColor: "orange",
        backgroundColor: "black",
        message: this.$t("board['正在制作中']") + "...",
      });
      this.timer = setTimeout(() => {
        Loading.hide();
        this.timer = void 0;
      }, 2000);
    },

    /*IM相关连接*/
    async signalRConnect() {
      console.log("调用执行了IM连接资讯");
      let TMDid = jwt_decode(sessionStorage.getItem("idToken"));
      let userid = TMDid.sub;
      let chanpid = "HiTeachCC";
      let Pin = "8888";

      // 先暫時躲避因為 classNum 的判斷所以新增此判斷
      if (sessionStorage.getItem("classNumTemp")) {
        this.classNum = sessionStorage.getItem("classNumTemp");
        sessionStorage.setItem("classNum", this.classNum);
      } else {
        this.classNum = sessionStorage.getItem("classNum");
      }

      this.$api.coreApi
        .joinChannel({
          client_name: chanpid,
          user_id: userid,
          pin: Pin,
          channel: this.classNum,
        })
        .then((res) => {
          this.imConnect = res;
          console.log(res, "加入教室成功");
          this.$Message.success(this.$t("board['通讯连接已建立']"));
          this.imConnectinfo(this, res.access_token, res.channel_url);
        })
        .catch((res) => {
          console.log(res, "失败原因");
          this.$Message.error(this.$t("board['加入教室失败，请尝试重连!']"));
        });
    },
    /*IM连接*/
    imConnectinfo(that, acToken, urls) {
      /* signalRInfo(url, acToken);*/
      this.ConnectSignalR(that, urls, acToken);
      //this.$Message.success('通讯连接成功！')
    },
    /*开始连接IM*/
    ConnectSignalR(that, url, token) {
      console.log(url, "连接地址");
      console.log(token);
      console.log(that);
      const options = {
        accessTokenFactory: () => token,
      };

      //const connection = new signalR.HubConnectionBuilder()
      //  .withUrl(url, options)
      //  .configureLogging(signalR.LogLevel.Information)
      //  .build()

      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(url, options)
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: (retryContext) => {
            if (retryContext.elapsedMilliseconds < 10000) {
              // If we've been reconnecting for less than 60 seconds so far,
              // wait between 0 and 3 seconds before the next reconnect attempt.
              return Math.random() * 3000;
            } else {
              // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
              return 10000;
            }
          },
        })
        .configureLogging(signalR.LogLevel.Information)
        .build();

      this.connection.on("onMessage", onMessage);
      this.connection.on("onConnected", onConnected);
      this.connection.on("onDisconnected", onDisconnected);
      //connection.on('connectionSlow', onConnectionSlow);
      this.connection.onclose(() => {
        console.log("close connection");
        this.connection
          .start()
          .then(() => {
            console.log("re-connection!");
          })
          .catch(setTimeout(() => connection.start(), 2000));
      });

      console.log("connecting...");
      this.connection
        .start()
        .then(() => console.log("connected!"))
        .catch(console.log("Start connect fail!!"));

      function onConnected(message) {
        //data.myConnectionId = message.ConnectionId;
        message.id = this.IMcounter++; // vue transitions need an id
        //  data.messages.unshift(message);
      }
      function onMessage(message) {
        console.log(message, "收到消息!!!!");
        /* console.log(message.To[0],'111111111111')*/
        message.id = that.IMcounter++; // vue transitions need an id
        message.Timestamp = Math.floor(Date.now());
        var uncertainties = message.Text ? message.Text : message.text;
        var stateinfo = JSON.parse(uncertainties);
        console.log(stateinfo, "99999999999");
        // console.log(JSON.stringify(message), '外部')
        // console.log(JSON.stringify(stateinfo), '内部')
        var userSub = JSON.parse(sessionStorage.getItem("user")).sub;
        console.log(userSub, "use的ID");

        //避免无线循环发送接收消息
        if (stateinfo && stateinfo.sender != userSub) {
          //因为频道10秒广播一次state，所以这里要屏蔽掉自己的发的广播
            console.log('收到消息后调用发送消息方法了！！')
            that.singnalRMessage(stateinfo, '8888', message.ConnectionId)
        }
      }
      function onDisconnected(message) {
        console.log("SignalR斷線");
        message.id = this.IMcounter++; // vue transitions need an id
      }
    },
    sendAcktoTA(messages){
      let user = JSON.parse(sessionStorage.getItem("user"));
      let channelapi = this.imConnect.channel_api + "/messages";
      let sender = user.sub;
      let receive=[this.currentTA]
      let messageText = {
          action: "Ack",
          clientType: "HiTeachCC",
          sender: "HiTeachCC-" + sender,
          timestamp: new Date().getTime(),
          waitReturn: false,
          payload: {
            ack_Action: messages.action,
            ack_Timestamp: messages.timestamp,
            error: "",
          },
        };
       this.$api
          .tomessage(channelapi, {
            connectionId: "",
            sender: sender,
            isPrivate: true,
            groupname: null,
            to: receive,
            Text: JSON.stringify(messageText),
          })
          .then((res) => {
            console.log(res, "消息发送成功");
          })
          .catch((res) => {
            console.log(res, "消息发送失败");
       });  
    },
    sendCloseClass() {
      let user = JSON.parse(sessionStorage.getItem("user"));
      let channelapi = this.imConnect.channel_api + "/messages";
      let sender = user.sub;

      //学生加入教室一系列操作
      let messages = {
        action: "Class.Close",
        clientType: "HiTeachCC",
        sender: sender,
        timestamp: new Date().getTime(),
        waitReturn: false,
        payload: "",
      };
      let receive = [];
      if (messages.sender !== user.sub) {
        receive.push(messages.sender);
      }
      console.log(receive, "查看接收者");

      this.$api
        .tomessage(channelapi, {
          connectionId: "",
          sender: sender,
          isPrivate: true,
          groupname: null,
          to: receive,
          Text: JSON.stringify(messages),
        })
        .then((res) => {
          console.log(res, "消息发送成功");
        })
        .catch((res) => {
          console.log(res, "消息发送失败");
        });
      this.$store.state.currentState = "Class.Close";
    },
    //防止當頁狀態跑掉獨立送normal 給webIRS
    sendNormalInCurrentPage() {
      let randomId = this.genID();
      let messages = {
        action: "State.Get",
        clientType: "HiTeachCC",
        sender: sessionStorage.getItem("user").sub,
        timestamp: new Date().getTime(),
        waitReturn: false,
        payload: "",
      };
      let channelapi = this.imConnect.channel_api + "/messages";
      let user = JSON.parse(sessionStorage.getItem("user"));
      let sender = user.sub;
      let receive = [];
      if (messages.sender !== user.sub) {
        receive.push(messages.sender);
      }
      console.log(receive, "查看接收者");

      if (messages.action === "State.Get") {
        let payloadContent = {
          activityRecordNumber: this.activityRecordNumberS,
          collateJobHistory: this.workInsidedata ? this.workInsidedata : [],
          collateType: "None",
          examFileURL: "",
          mode: "Normal",
          content: {
              id: randomId,
              exercise: {
                answer: [],
                blankCount: 0,
                children: [],
                createTime: 0,
                creator: "",
                explain: "",
                type: "Single",
                knowledges: [],
                objective: false,
                order: 0,
                opts: 4,
                field: 1,
                level: 3,
                periodId: "",
                gradeIds: [],
                repair: [],
                subjectId: "",
                scope: null,
                score: 10,
              },
              render: 0,
              item: [
                {
                  question: "_popquiz_",
                  option: [
                    { code: "A", timeStamp: 0 },
                    { code: "B", timeStamp: 0 },
                    { code: "C", timeStamp: 0 },
                    { code: "D", timeStamp: 0 },
                    { code: "E", timeStamp: 0 },
                    { code: "F", timeStamp: 0 },
                    { code: "G", timeStamp: 0 },
                    { code: "H", timeStamp: 0 },
                    { code: "I", timeStamp: 0 },
                  ],
                },
              ],
              blob: null,
              lite: false,
              repair: [],
              contentQuestionCategory: "Knowledge",
              concept: "",
              keyword: "",
              questionAnsweredState: "Unanswered",
              questionDurationTime: "00:00:00",
              questionIndex: 0,
              questionLocationType: "Local",
              questionRenderType: "Htex",
              questionSpendTime: "00:00:00",
              shaCode: "",
              totalAnsweredMemberCount: 0,
          },
          pageID: this.nowpageRender.nowpageIds,
          pagePushHistory: this.pagePushHistory,
          resourcePushHistory: [],
          messagePushHistory: this.messagePushHistory,
          currentTA:this.currentTA,
          
        };
        //学生加入教室一系列操作
        let messageText = {
          action: "State",
          clientType: "HiTeachCC",
          sender: sender,
          timestamp: new Date().getTime(),
          waitReturn: false,
          payload: payloadContent,
        };
        this.$api
          .tomessage(channelapi, {
            connectionId: "",
            sender: sender,
            isPrivate: true,
            groupname: null,
            to: receive,
            Text: JSON.stringify(messageText),
          })
          .then((res) => {
            console.log(res, "消息发送成功");
          })
          .catch((res) => {
            console.log(res, "消息发送失败");
          });
      }
      this.$store.state.currentState = this.irsModel == "irsImmediately" ? "irsImmediatelyEnd" : "irsTextEnd";
    },
    /*signalR发送消息*/
    async singnalRMessage(message, pincode, connectionId, statusname) {
      let that=this
      //發給之後廣播使用
      if (statusname != undefined) {
        this.$store.state.currentState = statusname;
      }
      // console.log(statusname)
      console.log(message, pincode, statusname, "调用到发送消息方法");
      console.log(this.$store.state.IRSswitch, "当前开关的状态");
      // if (this.$store.state.IRSswitch == false && statusname != 'irsImmediatelyEnd' && statusname != 'irsTextEnd') {
      //   return
      // } //IRS开关关闭状态直接return 出去不执行下面操作
      var messages = message;
      let user = JSON.parse(sessionStorage.getItem("user"));
      let sender = user.sub;
      let receive = [];
      // var activityRecordNumberS = '198384384636751872'

      if (messages.sender !== user.sub) {
        receive.push(messages.sender);
      }
      console.log(receive, "查看接收者");
      let channelapi = this.imConnect.channel_api + "/messages";
      let classnum = sessionStorage.getItem("classNum");
      let time = Math.floor(Date.now());
      //学生加入教室一系列操作
      var messageText = {}
      if (messages.clientType == 'TA') {
        that.currentTA=messages.sender
        //針對TA畫板控制獨立動作
        switch (messages.action) {
          case 'Board.Ctrl': {
            this.$refs.hd.boardCtrlbyTA(messages.payload.BoardAction)
            break
          }
          case 'IRS.Ctrl': {
            this.$refs.hd.irsCtrlbyTA(messages.payload)
            break
          }
          case 'Timer.Ctrl': {
            let waitingPopupTimer
            clearTimeout(waitingPopupTimer)
            if (messages.payload.Operation.toLowerCase() == 'start') {
              this.toolIndexList.push(2)

              let timer = Number(messages.payload.Time) / 1000
              let hours = Math.floor(timer / 3600)
              let minutes = Math.floor((timer % 3600) / 60)
              let seconds = Math.floor(timer % 60)

              hours = minutes < 10 ? '0' + hours : hours
              minutes = minutes < 10 ? '0' + minutes : minutes
              seconds = seconds < 10 ? '0' + seconds : seconds

              waitingPopupTimer = setTimeout(() => {
                if (this.$refs?.ButtonTimer) {
                  this.$refs.ButtonTimer.handleReset()
                  setTimeout(() => {
                    if (this.$refs?.ButtonTimer) {
                      this.$refs.ButtonTimer.minuteVal = minutes.toString()
                      this.$refs.ButtonTimer.secondVal = seconds.toString()
                      this.$refs.ButtonTimer.countDown()
                    }
                  }, 1000)
                }
              }, 1000)
            }
            if (messages.payload.Operation.toLowerCase() == 'stop') {
              if (this.$refs?.ButtonTimer) this.$refs.ButtonTimer.onhandlePause()
            } else if (messages.payload.Operation.toLowerCase() == 'close') {
              if (this.$refs?.ButtonTimer) this.$refs.ButtonTimer.handleReset()
              this.toolIndexList.splice(this.toolIndexList.indexOf(2, 1))
              this.$store.state.timerIsStart = false
            }
            break
          }
          case 'Pickup.Ctrl': {
            if (!this.toolIndexList.includes(1)) {
              this.toolIndexList.push(1)
              let waitingPopupTimer
              clearTimeout(waitingPopupTimer)

              waitingPopupTimer = setTimeout(() => {
                 that.$refs.TurnTable.pickOne()
              }, 1000)
             
            }
            else{
              this.handleCloseTool(1) 
            }
             break
          }
           case 'Media.Push': {
            // console.log(messages.payload,'Media.Push')
            const works= messages.payload.MediaList.map(item=>item.source)
            this.$store.state.timeLineEvent.push({
              Time: (Math.floor(Date.now()) - this.$store.state.startTime) / 1000,
              Pgid: this.nowpageRender.nowpageIds,
              EventId: 917529,
              Event: "WrkCmp",
              WrkCmpSrcTypeId: 655440,
              WrkCmpSrcType: "HitaClientCmp",
              WrkCmpCount: messages.payload.MediaList.length,
              WrkType: 0,
              Works:works,
            });

            for (let i in messages.payload.MediaList) {
              let link=messages.payload.MediaList[i]
              if(link.fileType!='Video'){
                that.$refs.addImgBox.addTAImgObj(that.classInfo.blob.url + '/records/' + that.activityRecordNumberS + link.source, i,messages.payload.MediaList.length )
              }
            } 
            break 
          }
        }
      }

      if (messages.action === 'Announce.Ask') {
        //加入教室返回相应课程相关信息
        if(messages.clientType=='TA') this.sendAcktoTA(messages)
        messageText = {
          payload: {
            announceURL: "",
            announce: {
              classState: "InProcess",
              hostConnID: "",
              className: this.className,
              memberCount: this.memberList.length,
              verifyMode: this.verifyMode,
              pincode: pincode,
              channel: classnum,
              memberList: this.memberList,
              groupList: [],
              apI_Version: 0,
              allowVisitor: this.allowVisitor,
              allowSitin: this.allowSitin,
              schoolId: this.schoolId,
              schoolName: this.schoolName,
              totalOptions: this.$store.state.currentAnsOption == "En" ? ["A", "B", "C", "D", "E", "F", "G", "H", "I"] : ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
              workingFolder: this.classInfo.workingFolder,
            },
            resourcePushHistory: [],
            messagePushHistory: this.messagePushHistory,
          },
          action: "Announce.Push",
          clientType: "HiTeachCC",
          sender: "HiTeachCC-" + sender,
          timestamp: time,
          waitReturn: false
        }
      } else if (messages.action === 'MemberBinding') {
        console.log('MemberBinding')
        if (messages.clientType == 'TA') {
          this.sendAcktoTA(messages)
          console.log(messages, 'MemberBinding')

          let blobUrl = this.classInfo.blob

          messageText = {
            payload: {
              activityRecordNumber: this.activityRecordNumberS,
              id: messages.payload.hostID,
              seatID: 0,
              name: messages.payload.hostName,
              className: this.className,
              schoolId: this.schoolId,
              schoolName: this.schoolName,
              hostBlobUrl: blobUrl.url,
              clientBlobUrl: blobUrl.url,
              blobSAS: blobUrl.sas,
              blobSAS_Write: blobUrl.sas_write,
              blobSAS_Read: blobUrl.sas_read,
              errorCode: 0
            },
            action: 'BindingResult',
            clientType: 'HiTeachCC',
            sender: 'HiTeachCC-' + sender,
            timestamp: time,
            waitReturn: false,
            isNewTA: false
          }
        } else {
          // 不是TA，其餘視為IRS學生
          // 過濾是否為先前登入
          this.students = this.$store.state.students
          let filterStudy = this.students.filter(item => {
            return item.accountid == messages.payload.memberID
          })

          let onlineClients = this.students.filter(item => {
            return item.status == 'online'
          })

          if (onlineClients.length >= this.limitClient && filterStudy.length == 0) {
            // 額滿
            if (this.limitClientHint === false) {
              this.$Message.info({
                content: this.$t("board['偵測到有請求加入，但加入教室人數已達您的權限人數上限']") + ':' + this.limitClient,
                duration: 5
              })
              this.limitClientHint = true
            }

            let blobUrl = this.classInfo.blob
            // this.seatNum = Number(this.seatNum) + 1
            messageText = {
              payload: {
                activityRecordNumber: this.activityRecordNumberS,
                id: null,
                seatID: 0,
                name: messages.payload.memberName,
                className: this.className,
                schoolId: this.schoolId,
                schoolName: this.schoolName,
                hostBlobUrl: blobUrl.url,
                clientBlobUrl: blobUrl.url,
                blobSAS: blobUrl.sas,
                blobSAS_Write: blobUrl.sas_write,
                blobSAS_Read: blobUrl.sas_read,
                errorCode: 307,
                resourcePushHistory: [],
                messagePushHistory: this.messagePushHistory
              },
              action: 'BindingResult',
              clientType: 'HiTeachCC',
              sender: 'HiTeachCC-' + sender,
              timestamp: time,
              waitReturn: false
            }
          } else if (onlineClients.length <= this.limitClient) {
            // 加入
            console.log(onlineClients.length, this.limitClient)
            //绑定request(座位号及相关学生个人信息)
            this.seatNum = Number(this.seatNum) + 1
            let blobUrl = this.classInfo.blob
            console.log(blobUrl, 'BLOB相关信息')
            messageText = {
              payload: {
                activityRecordNumber: this.activityRecordNumberS,
                id: messages.payload.memberID,
                seatID: this.seatNum,
                name: messages.payload.memberName,
                className: this.className,
                schoolId: this.schoolId,
                schoolName: this.schoolName,
                hostBlobUrl: blobUrl.url,
                clientBlobUrl: blobUrl.url,
                blobSAS: blobUrl.sas,
                blobSAS_Write: blobUrl.sas_write,
                blobSAS_Read: blobUrl.sas_read,
                errorCode: 0,
                resourcePushHistory: [],
                messagePushHistory: this.messagePushHistory
              },
              action: 'BindingResult',
              clientType: 'HiTeachCC',
              sender: 'HiTeachCC-' + sender,
              timestamp: time,
              waitReturn: false
            }
            //确认学生加入和把学生加入到 学生列表
            this.studentAlone = {
              id: messages.sender,
              accountid: messages.payload.memberID,
              studentName: messages.payload.memberName,
              sendTime: time,
              headImg: ''
            }

            let oneStudent = {
              sort: this.seatNum,
              id: this.studentAlone.id,
              accountid: this.studentAlone.accountid,
              studentName: this.studentAlone.studentName,
              sendTime: this.studentAlone.sendTime,
              headImg: this.studentAlone.headImg,
              option: '',
              done: true,
              score: 0,
              rank: '',
              class: 'studyboxone',
              showcardclass: 'brand',
              answerclass: 'studyboxone',
              answerbrand: 'brand',
              statement: '', //发言,
              status: 'online',
              type: 0
            }

            console.log(filterStudy, 'filter的返回值, 若有找到表示重新加入原本就在名單裡')
            if (filterStudy.length == 0) {
              // 全新加入
              this.students.push(oneStudent)
              this.memberList.push({
                memberID: oneStudent.accountid,
                memberName: oneStudent.studentName,
                seatID: oneStudent.sort,
                groupName: null,
                groupID: null,
                isHiGroup: false
              })
            } else {
              this.seatNum = Number(this.seatNum) - 1 //Reclaim exist Seat Number
              // 如果 accountid 已經存在, 但是 deviceid 不同踢掉先前加入的
              if (filterStudy[0].id != this.studentAlone.id && filterStudy[0].id != '') {
                messageText = {
                  action: 'FuckOff',
                  clientType: 'HiTeachCC',
                  sender: 'HiTeachCC-' + sender,
                  timestamp: time,
                  waitReturn: false,
                  isNewTA: false
                }
                console.log(messageText, filterStudy[0].id, '發送踢人消息')
                this.$api
                  .tomessage(channelapi, {
                    connectionId: connectionId,
                    sender: sender,
                    isPrivate: true,
                    groupname: null,
                    to: [filterStudy[0].id],
                    Text: JSON.stringify(messageText)
                  })
                  .then(res => {
                    console.log(res, '消息发送成功')
                  })
                  .catch(res => {
                    console.log(res, '消息发送失败')
                  })
              }
              //處理 device id 可能有重複的狀況
              this.students.forEach(item => {
                if (item.id == this.studentAlone.id) {
                  item.id = ''
                  item.status = 'offline'
                }
              })

              // 回應加入
              messageText = {
                payload: {
                  activityRecordNumber: this.activityRecordNumberS,
                  id: filterStudy[0].accountid,
                  seatID: filterStudy[0].sort,
                  name: filterStudy[0].studentName,
                  className: this.className,
                  schoolId: this.schoolId,
                  schoolName: this.schoolName,
                  hostBlobUrl: blobUrl.url,
                  clientBlobUrl: blobUrl.url,
                  blobSAS: blobUrl.sas,
                  blobSAS_Write: blobUrl.sas_write,
                  blobSAS_Read: blobUrl.sas_read,
                  errorCode: 0,
                  resourcePushHistory: [],
                  messagePushHistory: this.messagePushHistory
                },
                action: 'BindingResult',
                clientType: 'HiTeachCC',
                sender: 'HiTeachCC-' + sender,
                timestamp: time,
                waitReturn: false,
                isNewTA: false
              }
            }

            // replace device id/status for reclaim
            for (let ii = 0; ii < this.students.length; ++ii) {
              if (this.students[ii].accountid == this.studentAlone.accountid) {
                this.students[ii].id = this.studentAlone.id
                if (this.studentAlone.studentName) (this.students[ii].studentName = this.studentAlone.studentName), (this.students[ii].status = 'online')
                break
              }
            }
            let Ss = 0
            this.students.forEach(item => {
              if (item.status == 'online') Ss++
              if (item.headImg === undefined || item.headImg === '') item.headImg = require('../assets/img/profile-user.svg')
            })
            this.onlineSs = Ss
            console.log(this.students, '学生情况')
            this.$store.state.students = this.students
          }
        }
      } else if (messages.action === 'Leave') {
        // 登出
        console.log("Leave ask", messages.sender);
        this.students = this.$store.state.students;
        let Ss = 0;
        for (let ii = 0; ii < this.students.length; ++ii) {
          if (this.students[ii].id == messages.sender) {
            this.students[ii].id = "";
            this.students[ii].status = "offline";
            console.log("Leave:", this.students[ii].accountid);
          }
          if (this.students[ii].status == "online") Ss++;
        }
        this.onlineSs = Ss;
        this.$store.state.students = this.students;

        if(messages.clientType=='TA'){
          this.currentTA=''
        }
      } else if (messages.action === "State.Get") {
        if (sessionStorage.getItem("fileType") == "pdf") {
          let pagenums = sessionStorage.getItem("slide") ? sessionStorage.getItem("slide") - 1 : 0;
          if (this.$store.state.imgArr) {
            if (typeof this.$store.state.imgArr.idArr != "undefined") {
              this.nowpageRender.nowpageIds = await this.$store.state.imgArr.idArr[pagenums].pageid;
              this.nowpageRender.nowpageType = await this.$store.state.imgArr.idArr[pagenums].pagetype;
              console.log(this.nowpageRender.nowpageIds, this.$store.state.imgArr.idArr, "ID对照");
            } else {
              return;
            }
          }
        }
        //准备学生端向教师getstatus数据内容
        var times = new Date().getTime();
        var randomId = this.genID();
        var payloadContent = {};
        if (this.irsModel == "normal" && connectionId != "") {
          statusname = "";
        } else if (this.irsModel == "irsImmediately" && connectionId != "" && statusname != "irsImmediatelyEnd") {
          statusname = "irsImmediately";
        } else if (this.irsModel == "irsText" && connectionId != "" && statusname != "irsTextEnd") {
          statusname = "irsText";
        } else if (this.irsModel == "irsGather" && connectionId != "") {
          // && statusname!=undefined) {
          let targetIndex = this.workInsidedata ? this.workInsidedata.findIndex((x) => x.jobName == this.$t("board['作品收集']") + this.$store.state.workNum) : -1;
          if (targetIndex == -1 && isNaN(this.$store.state.workNum) == false) {
            statusname = "irsGather";
          } else {
            statusname = "irsGatherRestart";
          }
        }
        if (statusname == "" || statusname == undefined) {
          if (this.irsModel != "normal") {
            return;
          }
          payloadContent = {
            activityRecordNumber: this.activityRecordNumberS,
            collateJobHistory: this.workInsidedata ? this.workInsidedata : [],
            collateType: "None",
            examFileURL: "",
            mode: "Normal",
            pageID: this.nowpageRender.nowpageIds,
            pagePushHistory: this.pagePushHistory,
            resourcePushHistory: [],
            messagePushHistory: this.messagePushHistory,
          };
          this.irsModel = "normal";
        } else if (statusname === "irsImmediately" || statusname === "irsImmediatelyEnd") {
          if (statusname === "irsImmediatelyEnd") {
            if (this.sendEndMsgID != this.nowpageRender.nowpageIds) {
              this.$Message.info(this.$t("board['單選題已結束']"));
              this.sendEndMsgID = this.nowpageRender.nowpageIds;
            }
          }
          //IRS即问即答（主动发起）
          payloadContent = {
            activityRecordNumber: this.activityRecordNumberS,
            collateJobHistory: this.workInsidedata ? this.workInsidedata : [],
            collateType: "None",
            mode: statusname === "irsImmediately" ? "Quiz" : "Normal",
            pageID: this.nowpageRender.nowpageIds,
            content: {
              id: randomId,
              exercise: {
                answer: [],
                blankCount: 0,
                children: [],
                createTime: 0,
                creator: "",
                explain: "",
                type: "Single",
                knowledges: [],
                objective: false,
                order: 0,
                opts: 4,
                field: 1,
                level: 3,
                periodId: "",
                gradeIds: [],
                repair: [],
                subjectId: "",
                scope: null,
                score: 10,
              },
              render: 0,
              item: [
                {
                  question: "_popquiz_",
                  option: [
                    { code: "A", timeStamp: 0 },
                    { code: "B", timeStamp: 0 },
                    { code: "C", timeStamp: 0 },
                    { code: "D", timeStamp: 0 },
                    { code: "E", timeStamp: 0 },
                    { code: "F", timeStamp: 0 },
                    { code: "G", timeStamp: 0 },
                    { code: "H", timeStamp: 0 },
                    { code: "I", timeStamp: 0 },
                  ],
                },
              ],
              blob: null,
              lite: false,
              repair: [],
              contentQuestionCategory: "Knowledge",
              concept: "",
              keyword: "",
              questionAnsweredState: "Unanswered",
              questionDurationTime: "00:00:00",
              questionIndex: 0,
              questionLocationType: "Local",
              questionRenderType: "Htex",
              questionSpendTime: "00:00:00",
              shaCode: "",
              totalAnsweredMemberCount: 0,
            },
            pagePushHistory: this.pagePushHistory,
            examFileURL: "",
            resourcePushHistory: [],
            messagePushHistory: this.messagePushHistory,
          };
          this.irsModel = "irsImmediately";
        } else if (statusname === "irsText" || statusname === "irsTextEnd") {
          //IRS文字（主动发起）
          if (statusname === "irsTextEnd") {
            if (this.sendEndMsgID != this.nowpageRender.nowpageIds) {
              this.$Message.info(this.$t("board['文字題已結束']"));
              this.sendEndMsgID = this.nowpageRender.nowpageIds;
            }
          }
          payloadContent = {
            activityRecordNumber: this.activityRecordNumberS,
            collateJobHistory: this.workInsidedata ? this.workInsidedata : [],
            collateType: "None",
            mode: statusname === "irsText" ? "Quiz" : "Normal",
            content: {
              concept: "",
              contentQuestionCategory: "Knowledge",
              exercise: {
                answer: [],
                blankCount: 1,
                children: [],
                createTime: 0,
                creator: "",
                explain: "",
                field: 1,
                gradeIds: [],
                knowledges: [],
                level: 3,
                objective: false,
                opts: 4,
                order: 0,
                periodId: "",
                repair: [],
                score: 10,
                subjectId: "",
                type: "Complete",
              },
              id: randomId,
              item: [{ option: [], question: "" }],
              keyword: "",
              questionAnsweredState: "Unanswered",
              questionDurationTime: "00:00:00",
              questionIndex: 0,
              questionLocationType: "Local",
              questionRenderType: "Htex",
              questionSpendTime: "00:00:00",
              shaCode: "",
              totalAnsweredMemberCount: 0,
            },
            examFileURL: "",
            pageID: this.nowpageRender.nowpageIds,
            pagePushHistory: this.pagePushHistory,
            resourcePushHistory: [],
            messagePushHistory: this.messagePushHistory,
          };
          this.irsModel = "irsText";
        } else if (statusname === "irsBuzz" || statusname === "irsBuzzEnd") {
          payloadContent = {
            activityRecordNumber: this.activityRecordNumberS,
            collateJobHistory: this.workInsidedata ? this.workInsidedata : [],
            collateType: "None",
            mode: statusname === "irsBuzz" ? "Quiz_Buzz" : "Normal",
            pageID: this.nowpageRender.nowpageIds,
            content: {
              id: randomId,
              exercise: {
                answer: [],
                blankCount: 0,
                children: [],
                createTime: 0,
                creator: "",
                explain: "",
                type: "Single",
                knowledges: [],
                objective: false,
                order: 0,
                opts: 4,
                field: 1,
                level: 3,
                periodId: "",
                gradeIds: [],
                repair: [],
                subjectId: "",
                scope: null,
                score: 10,
              },
              render: 0,
              item: [
                {
                  question: "_popquiz_",
                  option: [
                    { code: "A", timeStamp: 0 },
                    { code: "B", timeStamp: 0 },
                    { code: "C", timeStamp: 0 },
                    { code: "D", timeStamp: 0 },
                    { code: "E", timeStamp: 0 },
                    { code: "F", timeStamp: 0 },
                    { code: "G", timeStamp: 0 },
                    { code: "H", timeStamp: 0 },
                    { code: "I", timeStamp: 0 },
                  ],
                },
              ],
              blob: null,
              lite: false,
              repair: [],
              contentQuestionCategory: "Knowledge",
              concept: "",
              keyword: "",
              questionAnsweredState: "Unanswered",
              questionDurationTime: "00:00:00",
              questionIndex: 0,
              questionLocationType: "Local",
              questionRenderType: "Htex",
              questionSpendTime: "00:00:00",
              shaCode: "",
              totalAnsweredMemberCount: 0,
            },
            pagePushHistory: this.pagePushHistory,
            examFileURL: "",
            resourcePushHistory: [],
            messagePushHistory: this.messagePushHistory,
          };
          this.irsModel = "irsBuzz";
        } else if (statusname === "irsGather") {
          this.workSwitch = true;
          this.$store.state.workswitch = true;
          console.log("进入到作品收集");
          console.log(this.workInsidedata == "");

          // if (this.$store.state.beforeSaveStageGenID == true) {
          //   let imgpage = this.slidenum - 1
          //   let parameter = {
          //     pageid: this.genID(),
          //     pagetype: 'Quiz',
          //     page: imgpage
          //   }
          //   // this.$store.state.imgArr.idArr.push(parameter)
          //   // this.$store.state.collateCheckTable.push({
          //   //   pageid: parameter.pageid,
          //   //   mode: 'Collate_Start',
          //   //   collatejobNum: this.$store.state.workNum,
          //   //   workData: []
          //   // })
          //   this.nowpageRender.nowpageIds = parameter.pageid
          // }
          this.$store.state.collateCheckTable.push({
            pageid: this.nowpageRender.nowpageIds,
            mode: "Collate_Start",
            collatejobNum: this.$store.state.workNum,
            workData: [],
          });

          let collateData = {
            pageID: this.nowpageRender.nowpageIds,
            jobName: this.$t("board['作品收集']") + this.$store.state.workNum,
            timestamp: times,
            jobIndex: 0,
            collateType: "Image",
            mode: "Collate_Start",
            pageMeta: "",
          };
          this.workInsidedata = this.workInsidedata ? this.workInsidedata : [];
          this.workInsidedata.push(collateData);

          //作品收集(主动)
          payloadContent = {
            activityRecordNumber: this.activityRecordNumberS,
            examFileURL: "",
            mode: "Collate_Start",
            pageID: this.nowpageRender.nowpageIds,
            pagePushHistory: this.pagePushHistory,
            resourcePushHistory: [],
            messagePushHistory: this.messagePushHistory,
            collateType: "Image",
            collateJobHistory: this.workInsidedata ? this.workInsidedata : [],
          };
          console.log(payloadContent, "作品收集");
          this.irsModel = "irsGather";

          console.log(payloadContent.pageID);
        } else if (statusname === "irsGatherEnd") {
          console.log("irsGatherEnd");

          let targetId = "";
          this.$store.state.collateCheckTable.forEach((item) => {
            if (item.collatejobNum == this.$store.state.currentWorkNum) {
              targetId = item.pageid;
              item.mode = "Collate_End";
            }
          });

          this.workInsidedata.forEach((item) => {
            if (item.pageID == targetId) {
              item.mode = "Collate_End";
            }
          });

          //作品收集停止(主动)
          payloadContent = {
            activityRecordNumber: this.activityRecordNumberS,
            examFileURL: "",
            mode: "Collate_End",
            pageID: targetId,
            pagePushHistory: this.pagePushHistory,
            resourcePushHistory: [],
            messagePushHistory: this.messagePushHistory,
            collateType: "Image",
            collateJobHistory: this.workInsidedata ? this.workInsidedata : [],
          };
          console.log(payloadContent, "停止作品收集");
        } else if (statusname === "irsGatherRestart") {
          let targetId = "";
          this.$store.state.collateCheckTable.forEach((item) => {
            if (item.collatejobNum == this.$store.state.currentWorkNum) {
              targetId = item.pageid;
              item.mode = "Collate_Start";
            }
          });

          this.workInsidedata.forEach((item) => {
            if (item.pageID == targetId) {
              item.mode = "Collate_Start";
            }
          });

          //作品收集停止(主动)
          payloadContent = {
            activityRecordNumber: this.activityRecordNumberS,
            examFileURL: "",
            mode: "Collate_Start",
            pageID: targetId,
            pagePushHistory: this.pagePushHistory,
            resourcePushHistory: [],
            messagePushHistory: this.messagePushHistory,
            collateType: "Image",
            collateJobHistory: this.workInsidedata ? this.workInsidedata : [],
          };
          console.log(payloadContent, "重啟作品收集");
          this.irsModel = "irsGather";
        }
        //加入當前TA發送到State
        payloadContent.currentTA=that.currentTA

        messageText = {
          action: "State",
          clientType: "HiTeachCC",
          sender: sender,
          timestamp: times,
          waitReturn: false,
          payload: payloadContent,
        };

        console.log(messageText, "即将发送的内容");
      } else if (messages.action === "IRS.Answer" && this.$store.state.IRSswitch != false) {
        console.log(this.$store.state.students, "当前的学生信息",this.isWaitingBuzz,this.irsModel);
        //声明Ack相关信息
        var messageText = "";
        var studenDatas = this.$store.state.students;
        for (let i = 0; i < studenDatas.length; i++) {
          if (studenDatas[i].id === messages.sender) {
            if (this.irsModel == "irsBuzz" && this.isWaitingBuzz == true) {
              //每次顯示沒有搶過的人
              if (this.$store.state.buzzInStudents.indexOf(studenDatas[i]) == -1&&this.$store.state.buzzInStudents.findIndex(item=>item.sort==studenDatas[i].sort)==-1) {
                this.$store.state.answervalue = studenDatas[i];
                this.$store.state.buzzInStudents.push(studenDatas[i]);

                // console.log(this.$store.state.buzzInStudents)
                this.playAudio("audio3");
                let slideNext = this.$store.state.carouselSlide;
                this.switchoverpdf(slideNext);
                this.studentPercentages();
                this.isWaitingBuzz = false;
                this.StudentPercentage = 100;

                var times = new Date().getTime();
                var msgInitiative = {
                  action: "State.Get",
                  clientType: "HiTeachCC",
                  sender: JSON.parse(sessionStorage.getItem("user")).sub,
                  timestamp: times,
                  waitReturn: false,
                  payload: "",
                };
                this.singnalRMessage(msgInitiative, "8888", "", "irsBuzzEnd");
                // this.singnalRMessage(msgInitiative, '8888', '', 'Buzz.End')
                break;
                // this.$store.state.answervalue=studenDatas[i].name;
                //如果當頁為搶權就把第一個搶到的學生存到該頁答案
                // console.log(studenDatas[i])
              }
            }
            if (messages.payload.type === "Single") {
              studenDatas[i].option = messages.payload.answer[0];
              studenDatas[i].class = "studybox";
              studenDatas[i].showcardclass = "brand-answer";
            } else if (messages.payload.type === "Complete") {
              studenDatas[i].statement = messages.payload.answer[0];
              studenDatas[i].class = "studybox";
              studenDatas[i].showcardclass = "brand-answer";
              if (this.irsModel != "irsBuzz") {
                messageText = {
                  payload: {
                    ack_Action: "IRS.Answer",
                    ack_Timestamp: time,
                    error: "",
                    // resourcePushHistory: [],
                    // messagePushHistory: []
                  },
                  action: "Ack",
                  clientType: "HL",
                  sender: "HiTeachCC-" + sender,
                  timestamp: time,
                  waitReturn: false,
                };
              }
            }
          }
        }
        this.$store.state.students = studenDatas;

        if (this.irsModel != "irsBuzz") {
          this.answer();
          this.answerSelect();
          this.studentPercentages();
        }
      } else if (messages.action === "DirectIRS.Answer" && this.$store.state.IRSswitch != false) {
        var messageText = "";
        var studenDatas = this.$store.state.students;
        for (let i = 0; i < studenDatas.length; i++) {
          if (studenDatas[i].accountid === messages.sender) {
            console.log(messages, studenDatas[i], "DirectIRS");
            if (studenDatas[i].status == "offline") {
              if (this.onlineSs >= this.limitclient) {
                console.log("DirectIRS over limit!");
                break;
              }
              studenDatas[i].status = "online";
              this.onlineSs++;
            }
            if (this.irsModel == "irsBuzz" && this.isWaitingBuzz == true) {
              //每次顯示沒有搶過的人
              if (this.$store.state.buzzInStudents.indexOf(studenDatas[i]) == -1&&this.$store.state.buzzInStudents.findIndex(item=>item.sort==studenDatas[i].sort)==-1) {
                //this.$store.state.answervalue = studenDatas[i].studentName
                this.$store.state.answervalue = studenDatas[i];
                this.$store.state.buzzInStudents.push(studenDatas[i]);
                this.playAudio("audio3");
                let slideNext = this.$store.state.carouselSlide;
                this.switchoverpdf(slideNext);
                this.studentPercentages();
                this.isWaitingBuzz = false;
                this.StudentPercentage = 100;

                var times = new Date().getTime();
                var msgInitiative = {
                  action: "State.Get",
                  clientType: "HiTeachCC",
                  sender: JSON.parse(sessionStorage.getItem("user")).sub,
                  timestamp: times,
                  waitReturn: false,
                  payload: "",
                };
                this.singnalRMessage(msgInitiative, "8888", "", "irsBuzzEnd");
              }
            } else {
              studenDatas[i].option = messages.payload.answer[0];
              studenDatas[i].class = "studybox";
              studenDatas[i].showcardclass = "brand-answer";
            }
            break;
          }
        }
        this.$store.state.students = studenDatas;
        if (this.irsModel != "irsBuzz") {
          this.answer();
          this.answerSelect();
          this.studentPercentages();
        }
      } else if (messages.action === "Page.Push" && this.$store.state.IRSswitch != false) {
        //console.log(messages)
        messageText = {
          action: "Ack",
          clientType: "HL",
          sender: "HiTeachCC-" + sender,
          timestamp: time,
          waitReturn: false,
          payload: {
            ack_Action: "Page.Push",
            ack_Timestamp: messages.timestamp,
            error: "",
          },
        };
        console.log(this.$store.state.AfterParsing);
        let sendId = messages.sender;
        let pageNum = sessionStorage.getItem("slide") ? sessionStorage.getItem("slide") - 1 : 0;
        let pageKey = sessionStorage.getItem("fileType") == "pdf" ? this.$store.state.imgArr.idArr[pageNum].pageid : this.$store.state.AfterParsing.slides[pageNum].pageid;
        let blobUrl = this.classInfo.blob;
        let worksImgurl = blobUrl.url + "/" + this.classInfo.workingFolder + this.activityRecordNumberS + messages.payload.snapshotList[0].source + "?" + blobUrl.sas_read;
        let name = this.$store.state.students.filter((item) => {
          return item.id == sendId;
        });
        console.log(name, this.$store.state.students, this.students, "pagepushfilter");
        // let inquireData = this.worksData.findIndex(index => index.id == sendId && index.url == worksImgurl)
        // console.log(inquireData,messages)
        // console.log(this.nowpageRender.nowpageIds)
        // console.log(this.workSwitch == true)
        // if (inquireData == -1 && this.nowpageRender.nowpageIds == messages.payload.pageID && this.workSwitch == true) {
        //   this.worksData.push({
        //     id: name[0].id,
        //     seat: name[0].sort,
        //     name: name[0].studentName,
        //     url: worksImgurl
        //   })
        // }

        console.log(messages.payload);

        if (this.$store.state.collateCheckTable) {
          this.$store.state.collateCheckTable.forEach((item) => {
            let inquireData = -1;
            // currentworkData = -1

            if (item.workData) {
              inquireData = item.workData.findIndex((index) => index.id == sendId);
            }

            if (inquireData != -1 && item.mode == "Collate_Start" && item.pageid == messages.payload.pageID) {
              this.forceUpdateNum++;
              item.workData[inquireData] = {
                id: name[0].id,
                seat: name[0].sort,
                name: name[0].studentName,
                url: worksImgurl + `&time=${this.forceUpdateNum}`,
                isChecked: item.workData[inquireData].isChecked,
                isCheckedToPaste: item.workData[inquireData].isCheckedToPaste,
                checkNum:0,
              };
            } else if (inquireData == -1 && item.mode == "Collate_Start" && item.pageid == messages.payload.pageID) {
              item.workData.push({
                id: name[0].id,
                seat: name[0].sort,
                name: name[0].studentName,
                url: worksImgurl,
                isChecked: false,
                isCheckedToPaste: false,
                checkNum:0,
              });
            }

            //換頁後再切回當前的作品收集, 最終以當前作品為準
            // if (this.worksData != '') {
            //   currentworkData = this.worksData.findIndex(index => index.id == sendId && index.url == worksImgurl)
            // }

            // if (currentworkData == -1 &&item.mode=='Collate_Start'&& item.collatejobNum == this.$store.state.currentWorkNum && item.pageid == messages.payload.pageID && this.nowpageRender.nowpageIds != messages.payload.pageID) {
            //   this.worksData.push({
            //     id: name[0].id,
            //     seat: name[0].sort,
            //     name: name[0].studentName,
            //     url: worksImgurl
            //   })
            // }
          });
        }

        this.$store.state.worksArr = this.worksData;
        console.log(this.worksData, name, "最后的数据内容");
      } else if (messages.action === "Stage.Push") {
        //推送舞台畫面
        messageText = {
          action: "Page.Push", //注意:調整對內運作的命名，避免跟收作品收集的 Page.Push 衝到上面action才會叫stage.push，但送的時候給WebIRS5 也是跟HT一樣要叫Page.Push
          clientType: "HiTeachCC",
          sender: sender,
          timestamp: messages.timestamp,
          waitReturn: false,
          payload: {
            pageId: this.nowpageRender.nowpageIds,
            pageMeta: "/Host/Task/" + "stageImg" + this.nowpageRender.nowpageIds + messages.timestamp + ".jpg",
          },
        };
        let hasPushedIndex = this.pagePushHistory.findIndex((x) => x.payload.pageId == this.nowpageRender.nowpageIds);
        if (hasPushedIndex == -1) {
          this.pagePushHistory.push(messageText);
        } else [(this.pagePushHistory[hasPushedIndex] = messageText)];

        this.$store.state.timeLineEvent.push({
          Time: (Math.floor(Date.now()) - this.$store.state.startTime) / 1000,
          Pgid: this.nowpageRender.nowpageIds,
          EventId: 917530,
          Event: "FastPgPush",
          PushPgNo: this.slidenum,
        });
      }
    

      if (JSON.stringify(messageText) != "{}") {
        console.log(messageText, "发送的消息");
        // console.log(
        //   JSON.stringify({
        //     connectionId: connectionId,
        //     sender: sender,
        //     isPrivate: true,
        //     groupname: null,
        //     to: receive,
        //     Text: JSON.stringify(messageText)
        //   })
        // )
        this.$api
          .tomessage(channelapi, {
            connectionId: connectionId,
            sender: sender,
            isPrivate: true,
            groupname: null,
            to: receive,
            Text: JSON.stringify(messageText),
          })
          .then((res) => {
            console.log(res, "消息发送成功");
          })
          .catch((res) => {
            console.log(res, "消息发送失败");
          });
      }
    },
    /*停止作品收集*/
    currentIcon() {
      if (this.currentCollateItem().mode == "Collate_Start") {
        return "stop";
      } else {
        return "play";
      }
    },
    clearBuzzInStudents() {
      this.$store.state.buzzInStudents = [];
    },
    clearWorkData() {
      this.$store.state.isFilterCheckwork = false;
      this.$store.state.collateCheckTable.forEach((item) => {
        if (item.collatejobNum == this.currentWorkNum()) {
          item.workData = [];
          this.worksData = item.workData;
        }
      });
      this.showWorksClearDangerHint=false
    },
    stopwork() {
      // let works = this.workSwitch
      this.$store.state.isFilterCheckwork = false;
      if (this.currentCollateItem().mode == "Collate_Start") {
        this.workSwitch = false;
        this.$store.state.workswitch = false;
        var times = new Date().getTime();
        var msgInitiative = {
          action: "State.Get",
          clientType: "HiTeachCC",
          sender: sessionStorage.getItem("user").sub,
          timestamp: times,
          waitReturn: false,
          payload: "",
        };
        this.singnalRMessage(msgInitiative, "8888", "", "irsGatherEnd");
      } else {
        this.workSwitch = true;
        this.$store.state.workswitch = true;
        var times = new Date().getTime();
        var msgInitiative = {
          action: "State.Get",
          clientType: "HiTeachCC",
          sender: sessionStorage.getItem("user").sub,
          timestamp: times,
          waitReturn: false,
          payload: "",
        };
        //this.worksData = []
        this.singnalRMessage(msgInitiative, "8888", "", "irsGatherRestart");
      }
    },
    /*白板缩放后 重新获取新的坐标未知*/
    getRelativePointerPosition(node) {
      // 函数将返回相对于传递的节点的指针位置
      var transform = node.getAbsoluteTransform().copy();
      // 为了检测相对位置，需要进行逆变换
      transform.invert();
      // 获取指针(如鼠标或触摸)位置
      var pos = node.getStage().getPointerPosition();
      // now we find relative point
      return transform.point(pos);
    },
    /*设定答案后 判定答案模式*/
    answerpatterns() {
      let pattern = this.$store.state.currentAnsOption == "En" ? "letter" : "number";
      let studentDatas = this.$store.state.students;
      if (pattern === "letter") {
        for (var i = 0; i < studentDatas.length; i++) {
          if (studentDatas[i].option === "1" || studentDatas[i].option === "A") {
            studentDatas[i].option = "A";
          } else if (studentDatas[i].option === "2" || studentDatas[i].option === "B") {
            studentDatas[i].option = "B";
          } else if (studentDatas[i].option === "3" || studentDatas[i].option === "C") {
            studentDatas[i].option = "C";
          } else if (studentDatas[i].option === "4" || studentDatas[i].option === "D") {
            studentDatas[i].option = "D";
          } else if (studentDatas[i].option === "5" || studentDatas[i].option === "E") {
            studentDatas[i].option = "E";
          } else if (studentDatas[i].option === "6" || studentDatas[i].option === "F") {
            studentDatas[i].option = "F";
          } else if (studentDatas[i].option === "7" || studentDatas[i].option === "G") {
            studentDatas[i].option = "G";
          } else if (studentDatas[i].option === "8" || studentDatas[i].option === "H") {
            studentDatas[i].option = "H";
          } else if (studentDatas[i].option === "9" || studentDatas[i].option === "I") {
            studentDatas[i].option = "I";
          } else if (studentDatas[i].option === "0" || studentDatas[i].option === "J") {
            studentDatas[i].option = "J";
          }
        }
      } else if (pattern === "number") {
        for (var i = 0; i < studentDatas.length; i++) {
          if (studentDatas[i].option === "1" || studentDatas[i].option === "A") {
            studentDatas[i].option = "1";
          } else if (studentDatas[i].option === "2" || studentDatas[i].option === "B") {
            studentDatas[i].option = "2";
          } else if (studentDatas[i].option === "3" || studentDatas[i].option === "C") {
            studentDatas[i].option = "3";
          } else if (studentDatas[i].option === "4" || studentDatas[i].option === "D") {
            studentDatas[i].option = "4";
          } else if (studentDatas[i].option === "5" || studentDatas[i].option === "E") {
            studentDatas[i].option = "5";
          } else if (studentDatas[i].option === "6" || studentDatas[i].option === "F") {
            studentDatas[i].option = "6";
          } else if (studentDatas[i].option === "7" || studentDatas[i].option === "G") {
            studentDatas[i].option = "7";
          } else if (studentDatas[i].option === "8" || studentDatas[i].option === "H") {
            studentDatas[i].option = "8";
          } else if (studentDatas[i].option === "9" || studentDatas[i].option === "I") {
            studentDatas[i].option = "9";
          } else if (studentDatas[i].option === "0" || studentDatas[i].option === "J") {
            studentDatas[i].option = "0";
          }
        }
      }
      this.$store.state.students = studentDatas;
    },
    /*修改答题、未答题人数*/
    answer() {
      this.answerpatterns();
      let studentnum = this.$store.state.students;
      let answerstatus = this.$store.state.answerstatus;
      var Answerdata = [];
      var Noanswerdata = [];
      let Rightanswerdata = [];
      let Erroranswerdata = [];
      console.log(answerstatus,'answerstatus',studentnum);
      // this.forceUpdateNum++，過度刷新可能造成result設定答案一直偵聽一直送訊息
      if (answerstatus === 1) {
        let rightanswers = this.$store.state.answervalue;
        let i = "";
        if(this.irsModel=='irsImmediately'){
          for (i = 0; i < studentnum.length; i++) {
            if (studentnum[i].option === "") {
              studentnum[i].answerclass = "studyboxone";
              studentnum[i].answerbrand = "brand";
              studentnum[i].score = 0;
              Noanswerdata.push(studentnum[i]);
            } 
            else if (studentnum[i].option == rightanswers[0]) {
              studentnum[i].answerclass = "answercorrect studyboxone";
              studentnum[i].answerbrand = "banrd-success";
              studentnum[i].score = 10;
              //studentnum[i].score +=10
              Rightanswerdata.push(studentnum[i]);
            } else if (studentnum[i].option !== rightanswers[0]) {
              studentnum[i].answerclass = "answer-error studyboxone";
              studentnum[i].answerbrand = "banrd-error";
              studentnum[i].score = 0;
              Erroranswerdata.push(studentnum[i]);
            }
        }
        }
        else if(this.irsModel=='irsText'){
            for (i = 0; i < studentnum.length; i++) {
            if (studentnum[i].statement=== "") {
              studentnum[i].answerclass = "studyboxone";
              studentnum[i].answerbrand = "brand";
              studentnum[i].score = 0;
              Noanswerdata.push(studentnum[i]);
            } 
            else if (studentnum[i].statement== rightanswers) {
              studentnum[i].answerclass = "answercorrect studyboxone";
              studentnum[i].answerbrand = "banrd-success";
              studentnum[i].score = 10;
              //studentnum[i].score +=10
              Rightanswerdata.push(studentnum[i]);
            } else if (studentnum[i].statement !== rightanswers) {
              studentnum[i].answerclass = "answer-error studyboxone";
              studentnum[i].answerbrand = "banrd-error";
              studentnum[i].score = 0;
              Erroranswerdata.push(studentnum[i]);
            }
        }
        }

        this.$store.state.students = studentnum;
        let slideNext = this.$store.state.carouselSlide;
        this.switchoverpdf(slideNext);
        this.$store.state.rightanswernum = Rightanswerdata.length;
        this.$store.state.erroranswernum = Erroranswerdata.length;
        this.$store.state.noanswernum = Noanswerdata.length;
      } else if (answerstatus === 0) {
        let l = "";
        for (l = 0; l < studentnum.length; l++) {
          if (studentnum[l].option !== "" || studentnum[l].statement !== "") {
            Answerdata.push(studentnum[l]);
          } else if (studentnum[l].option === "" && studentnum[l].statement === "") {
            Noanswerdata.push(studentnum[l]);
          }
        }
        this.$store.state.answernum = Answerdata.length;
        this.$store.state.noanswernum = Noanswerdata.length;
        console.log(this.$store.state.answernum, this.$store.state.noanswernum, "已答未答人数");
      }
      // console.log(this.$store.state.msgBody,'msgBody')
    },
    /*实时修改echart数据*/
    answerSelect() {
      let studentselect = this.$store.state.students;
      let i = "";
      let selectA = [];
      let selectB = [];
      let selectC = [];
      let selectD = [];
      let selectE = [];
      let selectF = [];
      let selectG = [];
      let selectH = [];
      let selectI = [];
      let selectJ = [];
      let AnswerselectDataFull = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
      let NumSelectDataFull = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
      let AnswerselectData = [];
      let NumSelectData = [];
      for (i = 0; i < studentselect.length; i++) {
        if (studentselect[i].option === "1" || studentselect[i].option === "A") {
          selectA.push(studentselect[i]);
        } else if (studentselect[i].option === "2" || studentselect[i].option === "B") {
          selectB.push(studentselect[i]);
        } else if (studentselect[i].option === "3" || studentselect[i].option === "C") {
          selectC.push(studentselect[i]);
        } else if (studentselect[i].option === "4" || studentselect[i].option === "D") {
          selectD.push(studentselect[i]);
        } else if (studentselect[i].option === "5" || studentselect[i].option === "E") {
          selectE.push(studentselect[i]);
        } else if (studentselect[i].option === "6" || studentselect[i].option === "F") {
          selectF.push(studentselect[i]);
        } else if (studentselect[i].option === "7" || studentselect[i].option === "G") {
          selectG.push(studentselect[i]);
        } else if (studentselect[i].option === "8" || studentselect[i].option === "H") {
          selectH.push(studentselect[i]);
        } else if (studentselect[i].option === "9" || studentselect[i].option === "I") {
          selectI.push(studentselect[i]);
        } else if (studentselect[i].option === "0" || studentselect[i].option === "J") {
          selectJ.push(studentselect[i]);
        }
      }
      let a = Math.round((selectA.length / studentselect.length) * 100);
      let b = Math.round((selectB.length / studentselect.length) * 100);
      let c = Math.round((selectC.length / studentselect.length) * 100);
      let d = Math.round((selectD.length / studentselect.length) * 100);
      let e = Math.round((selectE.length / studentselect.length) * 100);
      let f = Math.round((selectF.length / studentselect.length) * 100);
      let g = Math.round((selectG.length / studentselect.length) * 100);
      let h = Math.round((selectH.length / studentselect.length) * 100);
      let is = Math.round((selectI.length / studentselect.length) * 100);
      let j = Math.round((selectJ.length / studentselect.length) * 100);
      let selectData = [a, b, c, d, e, f, g, h, is, j];

      selectData.forEach((num, i) => {
        if (num !== 0) {
          AnswerselectData = [];
          NumSelectData = [];
          for (let j = 0; j <= i; j++) {
            AnswerselectData.push(AnswerselectDataFull[j]);
            NumSelectData.push(NumSelectDataFull[j]);
          }
        }
      });

      //字母选项
      this.$store.state.AnswerSelect = AnswerselectData;
      //数字选项
      this.$store.state.AnswerNumselect = NumSelectData;

      this.$store.state.echartbaranswer = selectData;
      console.log(this.$store.state.AnswerSelect, "currentAnsSelect");
      console.log(this.$store.state.AnswerNumselect);
    },
    sendMsg(e) {
      let msg = this.mqmsg;
      let payload = "";
      let directMethod = this.responderId;
      let mqttInfo = JSON.parse(sessionStorage.getItem("mqtts"));
      let userdeviceId = mqttInfo.mqtt.connectInfo.clientID;
      let ClassrommNum = sessionStorage.getItem("classNum");
      let sendMsg = "devices/" + userdeviceId + "/messages/events/endpoint=coretopiciot&groupNum=" + ClassrommNum + "&deviceId=" + userdeviceId;
      const timestamp = Math.floor(Date.now() / 1000);
      if (e === "BackSeatnum") {
        var msgconfig = {
          Action: e,
          Sender: "Teach",
          Timestamp: timestamp,
          Payload: { seatnum: msg },
        };
        payload = JSON.stringify(msgconfig);
      }
      let receiveId = {
        directMethod: directMethod,
      };
      let pubTopic = sendMsg + "&$.to=" + JSON.stringify(receiveId);
      console.log("发送内容：" + payload);
      console.log("发送主题：" + pubTopic);
      let client = this.client;
      client.publish(pubTopic, payload, { qos: 0 }, (error) => {
        if (!error) {
          console.log("发布成功");
        } else {
          console.log("发布失败");
        }
      });
    },
    handleToolPick(index) {
      if (!this.toolIndexList.includes(index)) {
        this.toolIndexList.push(index);
      } else {
        this.toolIndexList.splice(this.toolIndexList.indexOf(index), 1);
      }
      //時鐘打開關起來先校正
      if (index == 2) {
        this.$store.state.timerIsStart = false;
      }
    },
    handleCloseTool(index) {
      this.$store.state.turnTableＭode = "Normal";
      this.toolIndexList.splice(this.toolIndexList.indexOf(index), 1);
      //時鐘打開關起來先校正
      if (index == 2) {
        this.$store.state.timerIsStart = false;
      }
    },
    stickDown(e) {
      console.log(e);
    },
    takeScreenShot() {
      this.$Message.loading("畫板拍照轉檔中，請稍候");
      html2canvas(document.getElementsByTagName("canvas")[0]).then((canvas) => {
        const link = document.createElement("a");
        link.download = "myCanvasShot.jpeg";
        link.href = canvas.toDataURL("image/jpeg");
        link.click();
      });
    },
    /*全屏方法*/
    screenfull() {
      this.inception = false;
      if (!screenfull.isEnabled) {
        this.$Message.error(this.$t("board['您的瀏覽器無法進入全屏模式']"));
        return false;
      }
      screenfull.toggle();
      this.isfullnow = !this.isfullnow;
      this.isFullscreen = true;
      console.log(screenfull);
      sessionStorage.setItem("Fullscreen", 1);
    },
    checkFull() {
      var isFull = document.fullscreenEnabled || window.fullScreen || document.webkitIsFullScreen || document.msFullscreenEnabled;
      // to fix : false || undefined == undefined
      if (isFull === undefined) {
        isFull = false;
      }
      return isFull;
    },
    /*全屏点击图片切换*/
    screenbtn(e) {
      if (e === "success") {
        this.ScreenSuccess = require("../assets/img/screenfull-success.png");
        this.ScreenError = require("../assets/img/screenfull-Derror.png");
        setTimeout(() => {
          this.screenfull();
        }, 300);
      } else if (e === "error") {
        this.ScreenError = require("../assets/img/screenfull-error.png");
        this.ScreenSuccess = require("../assets/img/screenfull-Dsuccess.png");
        setTimeout(() => {
          this.inception = false;
          sessionStorage.setItem("Fullscreen", 0);
        }, 300);
      }
    },
    // 微信 QR Code 弹出
    // clickQRcode() {
    //   this.styleObj.isShow = true
    //   this.styleObj.width = '800px'
    //   this.styleObj.height = 'auto'
    //   this.styleObj.maxWidth = '800px'
    //   this.styleObj.marginLeft = '0px'
    //   this.styleObj.marginBottom = '20%'
    //   this.styleObj.backgroundColor = '#fff'
    //   this.componentIndex = 8
    //   this.isShowCard = true
    // },
    /*实时监听人数和学生答题完成率*/
    studentPercentages() {
      let studentsData = this.$store.state.students.length;
      //let answernumDatas = this.answernum
      let newstudent = this.$store.state.students;
      let responseanswer = [];
      for (var i = 0; i < newstudent.length; i++) {
        if (newstudent[i].option !== "" || newstudent[i].statement !== "") {
          console.log("进入到监听人数");
          responseanswer.push(newstudent[i]);
        }
      }
      let answernumDatas = responseanswer.length;
      this.answernum = responseanswer.length;
      let percentagesdata = this.irsModel != "irsGather" ? Number(answernumDatas / studentsData) * 100 : Number(this.currentCollateWorkData().length / studentsData) * 100;
      let percentagesnum = percentagesdata.toFixed(0);
      this.StudentPercentage = Number(percentagesnum);
      if (answernumDatas === 0 && this.irsModel != "irsGather") {
        this.StudentPercentage = 0;
      }
      if (this.irsModel == "irsBuzz" && this.$store.state.answervalue != "") {
        this.StudentPercentage = 100;
      }
      // console.log(this.StudentPercentage)
    },
    /*页数动态数据*/
    pageinit() {
      let tatalpagenum = this.$store.state.totalpage;
      this.totalpage = tatalpagenum;
    },
    /*
    studenttext() {
      let that = this
      let positionw = this.$store.state.position.x + 5
      let positionh = this.$store.state.position.y + 20
      let positionname = this.$store.state.position.name
      let positionvalue = this.$store.state.position.value
      let stage = that.stage
      var layer = new Konva.Layer()
      stage.add(layer)
      var studentname = new Konva.Text({
        x: positionw,
        y: positionh,
        text: positionname,
        fontSize: 16, // 默认值为12
        fontweight: 'bold',
        fontFamily: '"微软雅黑"', // 默认是Arial
        fill: '#4A4D54'
      })
      var studenttext = new Konva.Text({
        x: positionw,
        y: positionh + 15,
        text: positionvalue,
        width: 130,
        fontSize: 14, // 默认值为12
        fontFamily: '"微软雅黑"', // 默认是Arial
        fill: '#333'
      })
      layer.add(studentname)
      layer.add(studenttext)
      layer.batchDraw()
    },
    */
    closeScreenFull() {
      sessionStorage.setItem("Fullscreen", 0);
    },
    //IRS信息与文字模式内容 关联当前PDF页数
    switchoverpdf(leavepage) {
      console.log(leavepage, "switchoverpdf");
      let leavepagenum = leavepage;
      let patternstustas = this.$store.state.currentAnsOption == "En" ? "letter" : "number";
      let thispagestatus = JSON.parse(JSON.stringify(this.StudentPercentage));
      let irscontent = JSON.parse(JSON.stringify(this.$store.state.students));
      console.log(irscontent, "获取到的学生内容");
      this.$store.state.zoom = 1;

      let textcontent = JSON.parse(JSON.stringify(this.$store.state.irsBack));

      let answerstatusdata = this.$store.state.answerstatus;
      let answercorrects = this.$store.state.answervalue;
      let worksData = this.$store.state.worksArr;
      let currentWorkNum = this.currentWorkNum();
      //柱状图动态选项渲染
      let NowpageSelect = this.$store.state.AnswerSelect;
      let NowpageNumselect = this.$store.state.AnswerNumselect;

      let buzzInStudents = this.$store.state.buzzInStudents;

      if (typeof this.$store.state.imgArr.idArr == "undefined") {
        this.$store.state.imgArr.idArr = JSON.parse(sessionStorage.getItem("idArr"));
      }

      let templist = this.$store.state.msgBody;

      let totalcontents = {
        pageid: this.$store.state.imgArr.idArr[leavepage - 1].pageid, //關聯pageImgArr中存放的pageId
        page: leavepagenum,
        totalirs: irscontent,
        totaltext: textcontent,
        StudentPercentage: thispagestatus,
        answerstatus: answerstatusdata,
        workData: worksData,
        currentWorkNum: currentWorkNum,
        buzzInStudents: buzzInStudents,
        correctanswer: answercorrects,
        pattern: patternstustas,
        letterselect: NowpageSelect,
        numberselect: NowpageNumselect,
        irsmodel: this.irsModel,
      };
      if (templist) {
        for (var i = 0; i < templist.length; i++) {
          //if (templist[i].page === '') {
          // let DelData=templist.splice(i,1)
          //}
          if (leavepagenum === templist[i].page) {
            for (var l = 0; l < templist[i].totalirs.length; l++) {
              /*Q!*/
              //if (templist[i].totalirs[l].option !== '') {
              //    totalcontents.totalirs = templist[i].totalirs
              //}
            }
            templist.splice(i, 1);
          }
        }
        templist.push(totalcontents);
      }
      // Zip msgBody
      var zip = new JSZip();
      zip.file("msgBody", JSON.stringify(templist));
      zip
        .generateAsync({
          type: "base64",
          compression: "DEFLATE",
        })
        .then(function (data) {
          sessionStorage.setItem("msgBody", data);
        });
      //sessionStorage.setItem('msgBody', JSON.stringify(templist)) //每次saveStage都備份
    },
    opennewpage() {
      let studentsinfo = this.$store.state.students;
      for (var i = 0; i < studentsinfo.length; i++) {
        studentsinfo[i].class = "studyboxone";
        studentsinfo[i].option = "";
        studentsinfo[i].statement = "";
        studentsinfo[i].showcardclass = "brand";
        studentsinfo[i].score = 0;
      }
      this.answernum = 0;
      this.irshint = true;
      this.StudentPercentage = 0;
      this.$store.state.zoom = 1;
      this.$store.state.IRSswitch = true;
      this.$store.state.answerpattern = "letter";
      this.$store.state.mode = "pencil-alt";
      this.$store.state.answerstatus = 0;
      this.$store.state.answervalue = "";
      this.$store.state.echartbaranswer = "";
      this.$store.state.students = studentsinfo;
      // this.worksData = []
      this.$store.state.worksArr = [];
    },
    /*Hi 按钮方法*/
    Hibtn(e) {
      if (!this.HiShow) {
      }
      this.Hishow = !this.Hishow;
      document.body.classList.remove("cursor-fdj");
    },
    /*清除页面重启互动*/
    async eliminatepage() {
      await this.uploadLessonRecord(); // 上傳IES5開課的課堂紀錄
      await this.updProfilePREF();
      await this.sendCloseClass();
      this.sendIoTReportToMQTT();

      // 初始化
      this.$store.dispatch("classInfo/init", { blob: this.blobConnect });

      let TMDid = jwt_decode(sessionStorage.getItem("idToken"));
      let userid = TMDid.sub;

      // 重新建立教室
      await this.$api.coreApi
        .crtChannel({
          client_name: "HiTeachCC",
          user_id: userid,
          pin: "8888",
          local_ip: "",
          channel: "",
          school_code: this.classInfo.schoolId,
        })
        .then((res) => {
          sessionStorage.setItem("classNumTemp", res.channel_num);
          sessionStorage.setItem("external_ip", res.ip);
        })
        .catch((err) => {
          console.log(err);
        });

      // 如果是IES5開課
      if (this.classInfo.isIES5) {
        // 取得IES5開課資訊
        let ies5CrtLesson = JSON.parse(sessionStorage.getItem("ies5CrtLesson")); // 提取IES5的開課資訊
        ies5CrtLesson.channel = sessionStorage.getItem("classNumTemp");
        await this.$api.ies5Api.getClassInfo(ies5CrtLesson).then((res) => {
          this.$store.dispatch("classInfo/setupByIES5", res);
          // 權益
          if (!!res.funcs) {
            this.$store.dispatch("funcs/setup", res.funcs);
          }
        });
      }

      this.isRequestReBuild = true;
      sessionStorage.setItem("activityRecordNumberS", "");
      sessionStorage.setItem("classNum", "");
      sessionStorage.setItem("isRequestReBuild", true);
      if (sessionStorage.getItem("msgBody")) {
        window.sessionStorage.removeItem("msgBody");
      }
      this.StudentPercentage = 0;
      //删除不必要的数据储存
      sessionStorage.removeItem("fileType");
      sessionStorage.removeItem("slide");
      sessionStorage.removeItem("stageInfo"); // 新增刪除側邊的資訊

      // 重新賦予開始時間
      this.$store.state.startTime = Math.floor(Date.now());
      sessionStorage.setItem("startTime", this.$store.state.startTime);
      this.$store.state.timeLineEvent.push({
        Time: 0,
        EventId: 589825,
        Event: "ActStart",
        ActStartTime: "0",
      });
      sessionStorage.setItem("timeLineEvent", JSON.stringify(this.$store.state.timeLineEvent));

      //如為主動點選重建教室，則不秀提示
      window.location.reload();
      // if (this.stage) {
      //   console.log(this.stage)
      // }
      // let layers = this.$store.state.layer
      // sessionStorage.removeItem('msgBody')
      // sessionStorage.removeItem('stageInfo')
      // this.saveArr = []
      // this.$Message.success('页面数据已清除！')
      // this.Hishow = false
      // console.log(this.$store.state.imgArr)
      // if (this.whiteboard === '1') {
      //   layers.children.length = 0
      // } else {
      //   layers.children.length = 1
      // }
      // this.$store.state.mode = 'pencil-alt'
      // this.$store.state.penType = 'pencil-alt'
      // this.stage.destroyChildren()
      // this.stage.clear()
      // this.saveArr.splice(0, 1)
      // sessionStorage.setItem('stageInfo', JSON.stringify(this.saveArr))
      // this.$store.state.oldSlide = 1
      // this.$store.state.carouselSlide = 1
      // this.$store.state.imgArrOld.splice(1, this.$store.state.imgArr.length - 1)
      // this.$store.state.imgArr.splice(1, this.$store.state.imgArr.length - 1)
      // this.$store.state.imgArr.idArr.splice(1, this.$store.state.imgArr.length - 1)
      // this.$store.state.imgArr.splice(0, 1, this.whiteBG)
      // this.$store.state.imgArrOld.splice(0, 1, this.whiteBG)
      // console.log(this.$store.state.imgArr)
      // // this.addNewPage()
      // this.saveStage()
      // this.pen(0)
      // this.$store.state.totalpage = this.$store.state.imgArr.length
      // layers.draw()
    },
    /*退出教室按钮*/
    async quitbtns() {
      this.sendIoTReportToMQTT();
      this.$store.dispatch("mqtt/logout"); // mqtt 先斷線
      // 呼叫登出API (如果是被mqtt 踢的則不呼叫)
      if (!this.callLogout) await this.$api.coreApi.logout(sessionStorage.getItem("idToken"), [this.mqttDeviceId]);

      await this.uploadLessonRecord(); // 上傳IES5開課的課堂紀錄
      await this.updProfilePREF();
      await this.sendCloseClass();
      sessionStorage.clear();
      this.saveArr = [];
      this.Hishow = false;
      sessionStorage.setItem("classNum", "");
      sessionStorage.setItem("activityRecordNumberS", "");

      this.$Message.success(this.$t("board['账号正在退出!']"));

      let that = this;
      setTimeout(async function () {
        await that.$router.push({ path: "/" });
        window.location.reload();
      }, 2000);
    },
    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    cancelPdfExport() {
      this.isCancelExportPDF = true;
      this.$store.state.isExportPDFNow = false;
      this.$store.state.carouselSlide = 1;
      this.rendererinof(0);
    },
    async exportPDF(mode) {
      this.exportPDFMode = mode;
      this.renderEachPage = false;
      this.isCancelExportPDF = false;
      this.$store.state.isExportPDFNow = true;
      return new Promise(async (r, j) => {
        this.Hishow = false;
        this.$store.state.IRSswitch = false;
        const doc = new jsPDF("l", "px", [window.innerWidth, window.innerHeight]);
        this.$store.state.carouselSlide = 1;
        let that = this;
        let blobinfo = that.classInfo.blob;
        const myInterval = await setInterval(async () => {
          if (this.isCancelExportPDF) {
            clearTime();
            r(200);
            return;
          }
          if (this.$store.state.carouselSlide < this.$store.state.imgArr.length) {
            that.$store.state.carouselSlide++;

            let background = new Konva.Rect({
              x: 0,
              y: 0,
              fill: "white",
              width: window.innerWidth,
              height: window.innerHeight,
              illLinearGradientStartPoint: { x: 0, y: 0 },
              listening: false,
              name: "whiteRect",
            });
            that.layer.add(background);
            background.moveToBottom();
            that.layer.draw();

            that.stage.on("dragmove", () => {
              background.absolutePosition({ x: 0, y: 0 });
            });

            let stageImg = await that.$store.state.stage.toDataURL({ mimeType: "image/jpeg", quality: 0.9 }); // 画布截图

            if (that.classInfo.isIES5 && mode == "upload") {
              if (that.$store.state.carouselSlide - 2 == 0) {
                let coverImgFile = await that.dataURLtoFile(stageImg, `CoverImage.jpg`);
                await that.uploadResourceFile(coverImgFile, "Record").then(async (res) => {
                  console.log(res.url + "?" + blobinfo.sas_read, `上傳 CoverImage.jpg 成功`);
                });
              }
              let imgFile = await that.dataURLtoFile(stageImg, `${that.$store.state.imgArr.idArr[that.$store.state.carouselSlide - 2].pageid}.jpg`);
              await that.uploadResourceFile(imgFile, "Memo").then(async (res) => {
                console.log(res.url + "?" + blobinfo.sas_read, `上傳 Memo -${that.$store.state.carouselSlide - 1}成功`);
              });
            }

            let img = new Image();

            img.onload = function () {
              doc.addImage(img, "JPEG", 0, 0, img.width, img.height);
              doc.addPage();
            };
            img.src = stageImg;
          } else {
            let background = new Konva.Rect({
              x: 0,
              y: 0,
              fill: "white",
              width: window.innerWidth,
              height: window.innerHeight,
              illLinearGradientStartPoint: { x: 0, y: 0 },
              listening: false,
              name: "whiteRect",
            });
            that.layer.add(background);
            background.moveToBottom();
            that.layer.draw();

            that.stage.on("dragmove", () => {
              background.absolutePosition({ x: 0, y: 0 });
            });
            that.renderEachPage = true;
            that.$store.state.carouselSlide = 1;
            let stageImg = await that.$store.state.stage.toDataURL({ mimeType: "image/jpeg", quality: 0.9 }); // 画布截图
            let img = new Image();
            if (that.classInfo.isIES5 && mode == "upload") {
              let imgFile = await that.dataURLtoFile(stageImg, `${that.$store.state.imgArr.idArr[that.$store.state.imgArr.length - 1].pageid}.jpg`);
              await that.uploadResourceFile(imgFile, "Memo").then(async (res) => {
                console.log(res.url + "?" + blobinfo.sas_read, `上傳 Memo -${that.$store.state.imgArr.length}成功`);
              });
            }

            img.onload = async function () {
              doc.addImage(img, "JPEG", 0, 0, img.width, img.height);

              if (that.classInfo.isIES5 && mode == "upload") {
                let PDFfile = await that.dataURLtoFile(doc.output("datauristring"), "Note.pdf");
                await that.uploadResourceFile(PDFfile).then(async (res) => {
                  r(console.log(res.url + "?" + blobinfo.sas_read, "上傳 PDFfile 成功"));
                });
              }
              if (mode !== "addZip") {
                doc.save("Note.pdf");
              } else {
                r(doc.output("datauristring"));
              }
            };
            img.src = stageImg;
            clearTime();
          }
        }, 1700);

        function clearTime() {
          clearInterval(myInterval);
          that.$store.state.isExportPDFNow = false;
          that.$store.state.IRSswitch = true;
        }
      });
    },
    add() {
      let zoominfo = this.$store.state.zoom;
      if (this.$store.state.mode == "zooms") {
        //alert('111111')
        if (zoominfo < 4) {
          zoominfo += 0.3;
          this.$store.state.zoom = zoominfo.toFixed(1);
          this.$store.state.zoomMode = "click";
          console.log(this.$store.state.zoom);
        }
      }
    },
    /*调用子页面互动*/
    popuphd() {
      // console.log(this.irsModel)
      if (this.irsModel != "irsGather" && this.irsModel != "normal" && this.irsModel != "irsBuzz") {
        this.$refs.hd.handleFan();
      } else if (this.irsModel == "irsGather") {
        this.workStatus = !this.workStatus;
      } else if (this.irsModel == "irsBuzz") {
        this.isOpenBuzzPop = !this.isOpenBuzzPop;
        //this.$Message.warning('Qizz_Buzz活動'+this.$store.state.answervalue.studentName +'搶答')
      } else {
        this.$Message.warning(this.$t("board['本頁無Quiz活動']"));
      }
    },
    /*关闭Hi按钮*/
    closeHi(e) {
      if (e === "topic") {
        this.topicedit = false;
        this.NowQuestion = "";
      } else {
        this.Hishow = false;
      }
    },
    /*PDF加载提示动画*/
    PdfshowLoading(e, i) {
      if (e === 1) {
        if (this.$store.state.isOnlyRefresh == false) {
          this.$q.loading.show({
            message: '<p style="font-size:20px;color:#FFA07A;">' + this.$t("board['正在努力加载，请稍等...']") + '</p><br/><p style="font-size:18px;color:#fff">' + i + "/" + this.$store.state.totalpage + "</p>",
            backgroundColor: "#F5F5F5",
          });
        }
      } else {
        // hiding in 3s
        this.timer = setTimeout(() => {
          this.$q.loading.hide();
          this.timer = void 0;
        }, 2000);
      }
    },
    /*PDF加载提示动画*/
    beginPdfshowLoading(e, i) {
      if (e === 1) {
        this.$q.loading.show({
          message: '<p style="font-size:20px;color:#FFA07A;">' + this.$t("board['正在努力加载，请稍等...']") + '</p><br/><p style="font-size:18px;color:#fff">' + i + "/" + this.totalpage + "</p>",
          backgroundColor: "#F5F5F5",
        });
      } else if (e === 0) {
        this.$q.loading.show({
          message: '<p style="font-size:20px;color:#FFA07A;">' + this.$t("board['正在上传文件,请稍等...']") + "</p>",
          backgroundColor: "#F5F5F5",
        });
      } else {
        this.timer = setTimeout(() => {
          this.$q.loading.hide();
          this.timer = void 0;
        }, 1000);
      }
    },
    downloadURI(uri, name) {
      var link = document.createElement("a");
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    /*修改加载动画页面*/
    LoadingPage(e) {
      this.loadingpage = e;
    },
    /*knova渲染json HTEX*/
    async initCanvas(page, spacename, initStatus) {
      var pagenow = "";
      console.log(page, "InitCanvas");
      console.log(page, "传输渲染的值");
      console.log(spacename, "命名值");
      page === 0 ? (pagenow = page) : (pagenow = page - 1);
      console.log(window.innerWidth, "屏幕的宽");
      console.log(window.innerHeight, "屏幕的高");
      this.prestraindata();
      return new Promise(async (r, j) => {
        var signature = "?sv=2019-02-02&st=2020-08-04T07%3A30%3A37Z&se=2024-08-05T07%3A30%3A00Z&sr=c&sp=rl&sig=x4PbJZ5qh8yjFdZwOy1EUca98MaLQyXKth2XGIZ%2Bt%2B4%3D";
        var NowptxData = "";
        var callUrl = "";
        let res = "";
        if (initStatus === "init") {
          console.log("进入init内容");
          let jsoncallback = await this.getHistoryData(this.$store.state.fileAnalysis.index, 1);
          let laborderData = JSON.parse(jsoncallback);
          //给值到vuex的PPT整体带pageID的数据
          for (var y = 0; y < laborderData.slides.length; y++) {
            var pageId = this.genID();
            laborderData.slides[y].pageid = pageId;
          }
          this.$store.state.AfterParsing = laborderData;
          console.log(laborderData, this.$store.state.AfterParsing, "解析的结果");
          var pptUrl = this.$store.state.AfterParsing.slides;
          this.$store.state.imgArr.length = pptUrl.length;
          this.beginPage = pptUrl.length;
          this.$store.state.totalpage = pptUrl.length;
          sessionStorage.setItem("slidelength", this.$store.state.totalpage);
          var callUrls = this.$store.state.jointUrl;
          callUrl = callUrls;
          NowptxData = callUrls + pptUrl[pagenow].url;
          this.nowpageRender.nowpageIds = laborderData.slides[0].pageid;
        } else {
          var newReformdata = this.$store.state.AfterParsing.slides;
          console.log(pagenow, "页数");
          console.log(newReformdata, "进入渲染方法里面的数组内容");
          var callUrlinfo = this.$store.state.jointUrl;
          callUrl = callUrlinfo;
          if (newReformdata[pagenow].url !== "") {
            NowptxData = callUrlinfo + newReformdata[pagenow].url;
          }
          console.log(newReformdata[pagenow], "新数组的渲染当前内容");
          this.nowpageRender.nowpageIds = newReformdata[pagenow].pageid;
        }
        console.log(NowptxData, "访问的地址");
        console.log(callUrl, "访问的地址前半段");
        if (NowptxData !== "") {
          res = await this.getHistoryData(NowptxData);
        }
        console.log(res, "单页JSON数据");
        sessionStorage.setItem("pageStatus", spacename);
        //console.log(ulrdz.width, '宽度赋值测试')
        //console.log(ulrdz.height, '高度赋值测试')
        var scaling = (window.innerHeight / res.height).toFixed(2) && res.height !== "" ? (window.innerHeight / res.height).toFixed(2) : 0;
        var stageX = (window.innerWidth - res.width * scaling) / 2 && res.width !== "" ? (window.innerWidth - res.width * scaling) / 2 : 0;
        console.log(res.height, "渲染的高");
        this.primevalInit.height = window.innerHeight;
        this.primevalInit.width = res.width;
        this.primevalInit.scale = scaling;
        this.primevalInit.x = stageX;
        sessionStorage.setItem("scalings", scaling);
        console.log(stageX, "定位画布的起始X");
        console.log(scaling, "高度比例");
        var stage = new Konva.Stage({
          container: spacename, // id of container <div>
          width: window.innerWidth,
          height: window.innerHeight,
          x: stageX,
          y: 0,
        });
        this.$store.state.stage = stage;
        console.log(stage, "打印的stage");
        var layer = new Konva.Layer();
        //var lastlayer = new Konva.FastLayer();
        this.layersinfo = layer;
        this.layer = layer;
        //this.FastLayer = lastlayer
        this.stage = stage;
        layer.listening(false);
        //this.$store.state.totalpage = moreoage.pptx.page
        var trx = new Konva.Transformer();
        trx.nodes([]);
        var that = this;
        let promiseArr = [];
        if (initStatus === "additional" || this.$store.state.AfterParsing.slides[pagenow].url === "") {
          return;
        }
        //console.log(res, '返回的json串')
        if (res.fill && initStatus !== "additional") {
          console.log("进入有背景图");
          var imageObj = new Image();
          var backgroundGroup = {
            width: res.width * scaling,
            height: res.height * scaling,
            draggable: false,
            rotation: 0,
          };
          var backgroup = new Konva.Group(backgroundGroup);
          if (res.fill.type === 3) {
            var imgSrcinfo = callUrl + res.fill.blipFill.url;
            console.log(imgSrcinfo, "背景图片3333");
            imageObj.onload = async function () {
              //处理超过尺寸背景图
              var offsetxValue = 0;
              var offsetyValue = 0;
              if (res.fill.blipFill && res.fill.blipFill.fillRect) {
                if (res.fill.blipFill.fillRect.b !== 0 || res.fill.blipFill.fillRect.t !== 0) {
                  offsetyValue = (res.height * scaling * (Math.abs(res.fill.blipFill.fillRect.b) / 100)).toFixed(2);
                }
                if (res.fill.blipFill.fillRect.l !== 0 || res.fill.blipFill.fillRect.r !== 0) {
                  offsetxValue = (res.width * scaling * (Math.abs(res.fill.blipFill.fillRect.l) / 100)).toFixed(2);
                }
              }
              let screenshotW = res.width * scaling + offsetxValue;
              let screenshotH = res.height * scaling + offsetyValue;
              if (imageObj.width > screenshotW || imageObj.height > screenshotH) {
                var img = new Image();
                img.setAttribute("crossOrigin", "anonymous");
                img.src = imgSrcinfo;
                img.onload = () => {
                  let renderWidth = img.width > screenshotW ? screenshotW : img.width;
                  let renderHeight = img.height > screenshotH ? screenshotH : img.height;
                  var canvas = document.createElement("canvas");
                  canvas.width = renderWidth;
                  canvas.height = renderHeight;
                  var context = canvas.getContext("2d");
                  context.drawImage(img, 0, 0, renderWidth, renderHeight);
                  var newImage = canvas.toDataURL();
                  that.newBackgroundima = newImage;
                  var img2 = new Image();
                  img2.setAttribute("crossOrigin", "anonymous");
                  img2.src = newImage;
                  img2.onload = () => {
                    var bgImage = new Konva.Image({
                      fillPatternImage: img2,
                      width: img2.width,
                      height: img2.height,
                      offsetX: offsetxValue,
                      offsetY: offsetyValue,
                    });

                    backgroup.add(bgImage);
                    layer.add(backgroup);
                    backgroup.zIndex(0);
                    layer.batchDraw();
                  };
                  promiseArr.push(that.doRender(stage, layer, trx, res.item, callUrl, spacename, initStatus, page, scaling));
                  //处理超过尺寸背景图END
                };
              } else if (initStatus !== "additional") {
                imageObj.setAttribute("crossOrigin", "anonymous");
                var bgImage = new Konva.Image({
                  fillPatternImage: imageObj,
                  height: res.height,
                  width: res.width,
                  scaleY: scaling,
                  scaleX: scaling,
                });
                //backgroup.add(bgImage);
                //layer.add(backgroup);
                //backgroup.zIndex(0)
                //layer.batchDraw();
                backgroup.add(bgImage);
                layer.add(backgroup);
                backgroup.zIndex(0);
                layer.batchDraw();
                promiseArr.push(that.doRender(stage, layer, trx, res.item, callUrl, spacename, initStatus, page, scaling));
              }
            };
            imageObj.setAttribute("crossOrigin", "anonymous");
            imageObj.src = imgSrcinfo;
          } else if (res.fill.type === 4) {
            imageObj.onload = async function () {
              var bgImage = new Konva.Image({
                fillPatternImage: imageObj,
                height: res.height * scaling,
                width: res.width * scaling,
                //scaleY: scaling,
                //scaleX: scaling
              });
              //backgroup.add(bgImage);
              //layer.add(backgroup);
              //backgroup.zIndex(0)
              //layer.batchDraw();
              backgroup.add(bgImage);
              layer.add(backgroup);
              backgroup.zIndex(0);
              layer.batchDraw();
              console.log(trx, "图片打印tr");
              promiseArr.push(that.doRender(stage, layer, trx, res.item, callUrl, spacename, initStatus, page, scaling));
            };
            imageObj.crossOrigin = "Anonymous";
            imageObj.src = res.fill.pattFill.base64;
          } else if (res.fill.type === 1) {
            console.log("进入到纯色背景");
            var backgrounds = new Konva.Rect({
              width: res.width * scaling,
              height: res.height * scaling,
              fill: res.fill.solidFill,
              //scaleY: scaling,
              //scaleX: scaling
            });
            //backgroup.add(backgrounds);
            //layer.add(backgroup);
            //backgroup.zIndex(0)
            //layer.batchDraw();
            backgroup.add(backgrounds);
            layer.add(backgroup);
            backgroup.zIndex(0);
            layer.batchDraw();
            promiseArr.push(that.doRender(stage, layer, trx, res.item, callUrl, spacename, initStatus, page, scaling));
          } else if (res.fill.type === 2) {
            var color0 = "";
            var color5 = "";
            var color1 = "";
            if (res.fill.gradientFill.colors) {
              color0 = res.fill.gradientFill.colors[0].color;
              color5 = res.fill.gradientFill.colors[1].color;
              color1 = res.fill.gradientFill.colors[2].color;
            }
            var backgrounds = new Konva.Rect({
              width: res.width * scaling,
              height: res.height * scaling,
              fill: res.fill.solidFill,
              //scaleY: scaling,
              //scaleX: scaling,
              fillLinearGradientStartPoint: res.fill !== null && res.fill.gradientFill == null ? { x: 0, y: 0 } : { x: 0, y: 0 },
              fillLinearGradientEndPoint: res.fill !== null && res.fill.gradientFill == null ? { x: 0, y: 0 } : { x: 500, y: 500 },
              fillLinearGradientColorStops: res.fill !== null && res.fill.gradientFill == null ? null : [0, color0, 0.5, color5, 1, color1],
            });
            backgroup.add(backgrounds);
            layer.add(backgroup);
            backgroup.zIndex(0);
            layer.batchDraw();
            console.log(backgroup.zIndex(), "背景图片的层级");
            promiseArr.push(that.doRender(stage, layer, trx, res.item, callUrl, spacename, initStatus, page, scaling));
          } else {
            //type为-1时，进入的无背景渲染调用
            promiseArr.push(that.doRender(stage, layer, trx, res.item, callUrl, spacename, initStatus, page, scaling));
          }
        }
        //else if (initStatus !== 'additional') {
        //    console.log('进入无背景图')
        //    promiseArr.push(that.doRender(stage, layer, trx, res.item, callUrl, spacename, initStatus, page));
        //}
        if (res.exercise) {
          console.log("有题目。进入题目渲染");
          promiseArr.push(that.doRenderExercise(res.exercise, stage, layer, callUrl, spacename, initStatus, page, scaling));
        }
        //console.log(imageObj,'图片内容')
        stage.on("click tap", function (e) {
          // if click on empty area - remove all selections
          if (e.target === stage) {
            console.log(trx, "操作打印tr");
            trx.nodes([]);
            layer.draw();
            return;
          }
          const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
          const isSelected = trx.nodes().indexOf(e.target) >= 0;
          if (!metaPressed && !isSelected) {
            // 点击哪个就选中哪个
            trx.nodes([e.target]);
          } else if (metaPressed) {
            // 取消选择操作
            const nodes = trx.nodes().slice();
            nodes.splice(nodes.indexOf(e.target), 1);
            trx.nodes(nodes);
          } else if (metaPressed && !isSelected) {
            // 多选操作
            const nodes = trx.nodes().concat([e.target]);
            trx.nodes(nodes);
          }
          //layer.batchDraw();
        });
        stage.add(layer);
        console.log(this.blobPath, "删除的路径");
        Promise.all(promiseArr).then((res) => {
          var slidePage = sessionStorage.getItem("slide");
          console.log(slidePage, "页数");
          var filelength = this.$store.state.imgArr.length;
          //if (slidePage == 1 && initStatus !=='preparative') {
          //    if (spacename == 'container') { this.prestrain() }
          //} else if (slidePage === filelength && initStatus !=='preparative'){
          //    if (spacename == 'container') { this.prestrain() }
          //}else if(initStatus !=='preparative'){
          //    if (spacename == 'container' || spacename == 'nextPagebox') { this.prestrain() }
          //}
          console.log(this.$store.state.imgArr);
          console.log(res);
          //that.$refs.Pdf.$refs.pdfPreview.simgs('1')
          r(page);
        });
        //}
        //this.blobinit.deleteBlob(this.blobPath).then(res => {
        // window.setInterval(() => {
        //   setTimeout(() => {
        //     console.log(this, '执行调用广播方法！')
        //     this.broadcastState()
        //   }, 0)
        // }, 10000)

        //})
        //r(layer.children.length)
      });
      //setTimeout(() => {
      //    //console.log(stage.children[0].children.length, '截图之前的stage打印')
      //    var dataURL = stage.toDataURL();
      //    that.$store.state.imgArr[0] = dataURL
      //    //this.downloadURI(dataURL, 'stage.png');
      //    let layerInfo = layer.clone({ listening: false })// 画布内容提取
      //    //that.saveArr[i] = layerInfo
      //    sessionStorage.setItem('stageInfo', JSON.stringify(that.saveArr))
      //}, 4000);
      //}//layer.add(trx);
      //this.$refs.Pdf.thumbnailimage()
      //console.log(that.$store.state.imgArr, 'Vuex里面的缩略图')
      //return Promise.resolve("RenderTheComplete")
    },

    //加载
    async doRender(stage, layer, tr, items, callUrl, spacename, status, imageArpage, scaling) {
      console.log(items, imageArpage, "传进来渲染的值");
      let that = this;
      return new Promise((r, j) => {
        let f = async function () {
          let promiseArr = [];
          for (let k = 0; k < items.length; k++) {
            let promise = new Promise(async (r, j) => {
              let i = items[k];
              if (i.shapeType === "Media" || i.mediaType === "image") {
                switch (i.mediaType) {
                  case "image":
                    console.log(k, "调用到图片渲染方法");
                    let result = await drawImage(stage, layer, i, tr, callUrl, k + 1, scaling);
                    r(200);
                    break;
                  default:
                    break;
                }
              } else if (i.type === "Group") {
                //drawRect(stage, layer, i, tr);
                if (i.shapes) {
                  that.doRender(stage, layer, tr, i.shapesm);
                  console.log("进入到group  shapes");
                } else {
                  if (i.svg.d) {
                    let renderPathGroup = await drawPath(stage, layer, i, tr, callUrl, k + 1, scaling);
                    console.log(i.uid, "进入到group path的ID");
                  } else {
                    let renderRect = await drawRect(stage, layer, i, tr, callUrl, k + 1, scaling);
                    console.log(i.uid, "进入到group rect的ID");
                  }
                }
                console.log(i, "传输的group");
              } else if (i.type === "HtexText") {
                console.log("调用到了htextext");
                let renderRect = await drawRect(stage, layer, i, tr, callUrl, k + 1, scaling);
              } else if (i.type === "CxnSp" && i.svg) {
                if (i.svg.type === "polyline") {
                  console.log(i.svg.type, "type的值");
                  let renderPath = await drawPath(stage, layer, i, tr, callUrl, k + 1, scaling);
                } else if (i.svg.type === "polygon" && i.svg.points) {
                  console.log(i.svg.type, "type的值");
                  let renderPathinfo = await drawPath(stage, layer, i, tr, callUrl, k + 1, scaling);
                } else {
                  let renderPaths = await drawPath(stage, layer, i, tr, callUrl, k + 1, scaling);
                }
              } else if (i.type === "Sp" && i.svg) {
                if (i.svg.type === "polyline") {
                  console.log(i.svg.type, "type的值");
                  let renderPathz = await drawPath(stage, layer, i, tr, callUrl, k + 1, scaling);
                } else if (i.svg.type === "polygon" && i.svg.points) {
                  console.log(i.svg.type, "type的值");
                  let renderPathinfos = await drawPath(stage, layer, i, tr, callUrl, k + 1, scaling);
                } else if (i.svg.type === "path") {
                  let renderPatharray = await drawPath(stage, layer, i, tr, callUrl, k + 1, scaling);
                } else if (i.svg.d) {
                  let renderPathdata = await drawPath(stage, layer, i, tr, callUrl, k + 1, scaling);
                  console.log(i, "传递的path内容");
                } else if (i.shapeType === "ellipse") {
                  let renderEllipse = await drawEllipse(stage, layer, i);
                } else if (i.shapeType === "rect" || i.shapeType === "roundRect") {
                  let renderRect = await (stage, layer, i, tr, callUrl, k + 1, scaling);
                } else {
                  let renderDraw = await drawPath(stage, layer, i, tr, callUrl, k + 1, scaling);
                }
                r(200);
              } else if (i.type === "Math" && i.formula) {
                let renderMath = await drawMath(stage, layer, tr, i, callUrl, k + 1, scaling);
              } else if (i.charts && i.charts !== null) {
                let renderEchart = await drawEchart(stage, layer, tr, i, k + 1, scaling);
                console.log(renderEchart, "echarts图表的返回");
                that.iframeValue.push(renderEchart);
              } else if (i.type === "Table") {
                let renderTable = await drawTable(stage, layer, tr, i, k + 1, scaling);
              } else if (i.shapeType === "Diagram") {
                let renderRectinfo = await drawRect(stage, layer, i, tr, callUrl, k + 1, scaling);
                this.doRender(stage, layer, tr, i.shapes);
              } else if (i.shapeType !== "audio") {
                if (i.shapeType) {
                  switch (i.shapeType) {
                    //case "path":
                    //    drawPath(stage, layer, i, tr);
                    //    break;
                    case "arc":
                      let renderArc = await drawArc(stage, layer, i, k + 1, scaling);
                      break;
                    case "rect":
                      let renderRectinfo = await drawRect(stage, layer, i, tr, callUrl, k + 1, scaling);
                      console.log(tr, "传输的i进去");
                      break;
                    case "ellipse":
                      let renderEllipseinfo = await drawEllipse(stage, layer, i, k + 1, scaling);
                      break;
                    case "roundRect":
                      let renderRound = await drawRect(stage, layer, i, tr, callUrl, k + 1, scaling);
                      break;
                    default:
                      //drawLine(stage, layer, i);
                      //console.log(i, '调用的line方法')
                      break;
                  }
                }
              }
              r(200);
            });
            promiseArr.push(promise);
          }
          Promise.all(promiseArr).then((res) => {
            console.log("22222222222222");
            console.log(res);
            r(200);
            console.log("可以验证截图");
            setTimeout(() => {
              var snippingPage = JSON.parse(sessionStorage.getItem("slide"));
              var dataURL = stage.toDataURL();
              //console.log(dataURL, '截图')
              console.log(snippingPage, "页数！！！！");
              //console.log(that.$store.state.imgArr)
              if (spacename === "container") {
                //that.$store.state.imgArr[snippingPage - 1] = dataURL
                that.$store.state.imgArr.splice(snippingPage - 1, 1, dataURL);
              } else if (spacename === "nextPagebox") {
                //that.$store.state.imgArr[snippingPage] = dataURL
                that.$store.state.imgArr.splice(snippingPage, 1, dataURL);
              } else if (spacename === "lastPagebox") {
                //that.$store.state.imgArr[snippingPage - 2] = dataURL
                that.$store.state.imgArr(snippingPage - 2, 1, dataURL);
              }
              if (status === "preparative") {
                console.log(imageArpage, "截图的页数");
                //that.$store.state.imgArr[imageArpage-1] = dataURL
                that.$store.state.imgArr.splice(imageArpage - 1, 1, dataURL);
                console.log(that.$store.state.imgArr, "缩略图组");
              } else {
              }
              sessionStorage.setItem("imgArr", JSON.stringify(that.$store.state.imgArr));
            }, 1500);
            //that.$refs.Pdf.$refs.pdfPreview.simgs('1')
          });
        };
        f();
      });
    },

    //题目渲染加载
    //doRenderExercise(exercise, stage, layer, tr) {
    //    this.topics = exercise
    //    let that = this
    //    this.$nextTick(() => {
    //        html2canvas(document.getElementById("itemQuestion"), {
    //            backgroundColor: null,
    //        }).then((canvas) => {
    //            document.getElementById("itemQuestion").innerHTML = "";
    //            const img = canvas.toDataURL();
    //            var imageObj = new Image();
    //            imageObj.src = img;
    //            imageObj.setAttribute('crossOrigin', 'anonymous');
    //            //console.log(imageObj.src,'图片地址1')
    //            imageObj.onload = function () {
    //                var questioninfo = new Konva.Image({
    //                    x: 380,
    //                    y: 120,
    //                    image: imageObj,
    //                    width: imageObj.width,
    //                    height: imageObj.height,
    //                    draggable: true,
    //                });
    //                questioninfo.on('dblclick', (e) => {
    //                    console.log('触发了dblclick!')
    //                    console.log(that, 'this指针内容')
    //                    that.topicedit = true
    //                    that.TypeVerdict = 'question'
    //                    that.EditContent = exercise.question
    //                    //that.NowOption = exercise.option
    //                    //this.editorinfo = new Editors(this.$refs.editorElem)
    //                })
    //                // add the shape to the layer
    //                layer.add(questioninfo);
    //                layer.batchDraw();
    //            };
    //        });
    //        document.getElementsByClassName("item-option").forEach((i, index) => {
    //            html2canvas(i, { backgroundColor: null }).then((canvas) => {
    //                console.log(i, '打印的i')
    //                i.innerHTML = "";
    //                // layer.add(canvas)
    //                const img = canvas.toDataURL();
    //                var imageObj = new Image();
    //                imageObj.src = img;
    //                imageObj.setAttribute('crossOrigin', 'anonymous');
    //                //console.log(imageObj.src, '图片地址2')
    //                imageObj.onload = function () {
    //                    var choice = new Konva.Image({
    //                        x: 380,
    //                        y: 200 + index * 80,
    //                        image: imageObj,
    //                        width: imageObj.width,
    //                        height: imageObj.height,
    //                        draggable: true,
    //                    });
    //                    choice.on('dblclick', () => {
    //                        let SetAnswer = that.topics.option
    //                        console.log(SetAnswer[index].value, '答案内容')
    //                        that.topicedit = true
    //                        that.TypeVerdict = 'option'
    //                        that.OptionNum = index
    //                        that.EditContent = SetAnswer[index].value
    //                    })
    //                    // add the shape to the layer
    //                    layer.add(choice);
    //                    layer.batchDraw();
    //                };
    //            });
    //        });
    //        //console.log(document.getElementById("itemQuestion").getClientRects());
    //    });
    //},

    //渲染加载完成测试
    //async testpptx() {
    //    console.log('1111V1')
    //    const v1arr = await this.initCanvas();
    //    //const v2arr = await this.doRenderExercise();
    //    console.log(v1arr, '打印的V1')
    //    //console.log(v2arr,'打印的V2')
    //    if (v1arr === 'RenderTheComplete') {
    //        console.log('执行截图')
    //        let stageinof = this.stage
    //        var dataURL = stageinof.toDataURL();
    //        this.$store.state.imgArr[0] = dataURL
    //        //this.downloadURI(dataURL, 'stage.png');
    //    }
    //    this.$refs.Pdf.thumbnailimage()
    //    console.log('操作完成')
    //},

    //访问详细页的地址
    getHistoryData(url, status) {
      //return new Promise((resolve, reject) => {
      //    this.$api.fileJson(url, { params: {} }).then(res => {
      //        resolve(res)
      //    })
      //})
      if (status === 1) {
        return new Promise((resolve, reject) => {
          var xhr = new XMLHttpRequest();
          var formData = new FormData();
          xhr.open("get", url); //url填写后台的接口地址，如果是post，在formData append参数（参考原文地址）
          // xhr.responseType = 'arraybuffer';
          xhr.onload = function (e) {
            console.log(e);
            resolve(e.currentTarget.response);
          };
          xhr.send(formData);
        });
      } else {
        return new Promise((resolve, reject) => {
          var xhr = new XMLHttpRequest();
          var formData = new FormData();
          xhr.open("get", url); //url填写后台的接口地址，如果是post，在formData append参数（参考原文地址）
          // xhr.responseType = 'arraybuffer';
          xhr.onload = function (e) {
            console.log(e);
            resolve(JSON.parse(e.currentTarget.response));
          };
          xhr.send(formData);
        });
      }
    },

    //预加载
    prestrain() {
      console.log("调用到了预加载方法");
      var nPage = JSON.parse(sessionStorage.getItem("slide"));
      var pageStatusinfo = sessionStorage.getItem("pageStatus");
      var fileLength = this.$store.state.imgArr.length;
      console.log(pageStatusinfo);
      console.log(fileLength);
      var stagename = name;
      if (nPage === 1) {
        console.log("进去到预加载渲染方法");
        this.initCanvas(nPage + 1, "nextPagebox");
      } else if (nPage === fileLength) {
        console.log("进入到最后一页的预加载");
        if (pageStatusinfo === "container") {
          this.initCanvas(nPage - 1, "lastPagebox");
        }
      } else {
        console.log("进入到其他预加载渲染方法");
        if (pageStatusinfo === "nextPagebox") {
          this.initCanvas(nPage - 1, "lastPagebox");
        } else if (pageStatusinfo === "lastPagebox") {
          console.log("进入到上一页的渲染");
        } else if (pageStatusinfo === "container") {
          this.initCanvas(nPage + 1, "nextPagebox");
        }
      }
    },

    //生成唯一pageID
    genID(length) {
      var s = [];
      var hexDigits = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4";
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
      s[8] = s[13] = s[18] = s[23] = "-";
      let uuid = s.join("");
      return uuid;
    },
    // 開分頁顯示個人詳細資訊
    showUserInfo() {
      let idToken = sessionStorage.getItem("idToken");
      let serverLocation = this.$jsFn.isCN() ? "cn" : "global";
      let accountUrl = profile.accountUrl[serverLocation];
      this.$api.coreApi.getCode(idToken, false).then((res) => {
        window.open(accountUrl + "?code=" + res);
        console.log(res, "res");
      });
    },
    //确认题目修改
    //topicalter() {
    //    this.$q.loading.show()
    //    //alert(this.Editorinfo.txt.getJSON())
    //    let VerdictStatus = this.TypeVerdict
    //    let OptionStatus = this.OptionNum
    //    let topicstage = this.stage
    //    let topiclayer = this.layersinfo
    //    let contentinfo = this.Editorinfo.txt.text()
    //    console.log(contentinfo, '内容获取的文字')
    //    if (VerdictStatus === 'question') {
    //        RenderKnova.exercise.question = contentinfo
    //        this.topics.question = contentinfo
    //    } else if (VerdictStatus === 'option') {
    //        RenderKnova.exercise.option[OptionStatus].value = contentinfo
    //        this.topics.option[OptionStatus].value = contentinfo
    //    }
    //    console.log(this.topics.option, '页面的选项')
    //    console.log(RenderKnova.exercise.option, 'json串里的选项')
    //    this.doRenderExercise(RenderKnova.exercise, topicstage, topiclayer)
    //    this.timer = setTimeout(() => {
    //        this.$q.loading.hide()
    //        this.timer = void 0
    //        this.topicedit = false
    //    }, 2000)
    //    this.EditContent = ''
    //}
    //檢測當前偵聽器
    listAllEventListeners() {
      const allElements = Array.prototype.slice.call(document.querySelectorAll("*"));
      allElements.push(document);
      allElements.push(window);

      const types = [];

      for (let ev in window) {
        if (/^on/.test(ev)) types[types.length] = ev;
      }

      let elements = [];
      for (let i = 0; i < allElements.length; i++) {
        const currentElement = allElements[i];
        for (let j = 0; j < types.length; j++) {
          if (typeof currentElement[types[j]] === "function") {
            elements.push({
              node: currentElement,
              type: types[j],
              func: currentElement[types[j]].toString(),
            });
          }
        }
      }

      return elements.sort(function (a, b) {
        return a.type.localeCompare(b.type);
      });
    },
    // 修改個人偏好設定
    async updProfilePREF() {
      let data = {
        MCQType: this.$store.state.currentAnsOption, // 選擇題選項
        IRSBlockSize: this.$store.state.tempIRSBlockSize, // IRS Block 大小
        pens: this.$store.state.tempPenstate,
        gptActionList:this.$store.state.tempGptActionList,
        memberListIDs:this.customMemberlist.map(item=>item.listID)
      };
      this.$Spin.show(); // 畫面loading表現你可以自己調整
      await this.$store.dispatch("preferences/updProfilePREF", data).then(() => {
        this.$Spin.hide();
      });
    },

    loadMsgBody() {
      let that = this;
      var unzip = new JSZip();
      if (sessionStorage.hasOwnProperty("msgBody"))
        return new Promise((r, j) => {
          unzip.loadAsync(sessionStorage.getItem("msgBody"), { base64: true }).then(function (data) {
            data
              .file("msgBody")
              .async("string")
              .then(function (content) {
                that.$store.state.msgBody = JSON.parse(content);
                console.log("Unzip msgBody complete", that.$store.state.msgBody);
                r();
              });
          });
        });
    },
    //匯出課堂紀錄:Pdf+ Excel 包成Zip
    async exportRecordZip() {
      this.$refs.hd.closeFan();
      // 開始處理課堂紀錄
      sessionStorage.setItem("pagePushHistory", JSON.stringify(this.pagePushHistory));
      sessionStorage.setItem("messagePushHistory", JSON.stringify(this.messagePushHistory));
      this.saveStage("now");

      setTimeout(async () => {
        const classRecord = await this.$saveIES.print();

        console.log(classRecord, "classRecord");

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(classRecord["xlsx"]);
        XLSX.utils.book_append_sheet(wb, ws, "summary");

        this.$store.state.carouselSlide = 1;
        this.rendererinof(0);

        let zip = new JSZip();

        const classPDF = await this.exportPDF("addZip");
        if (classPDF != "") {
          try {
            zip.file("summary.xlsx", XLSX.write(wb, { type: "base64" }), { base64: true });
            zip.file("Note.pdf", classPDF.replace("data:application/pdf;filename=generated.pdf;base64,", ""), { base64: true });
            zip.generateAsync({ type: "blob" }).then(function (content) {
              saveAs(content, "ClassRecords.zip");
            });
          } catch (err) {
            console.log(err);
          }
        }
      }, 2000);
    },
    // 上傳課堂紀錄
    async uploadLessonRecord() {
      if (this.classInfo.isIES5) {
        let studentCount = 0;
        let classDuration = 0;

        // 開始處理課堂紀錄
        this.beginPdfshowLoading(0); // show loading
        sessionStorage.setItem("pagePushHistory", JSON.stringify(this.pagePushHistory));
        sessionStorage.setItem("messagePushHistory", JSON.stringify(this.messagePushHistory));
        this.saveStage("now");
        this.$store.state.timeLineEvent.push({
          Time: (Math.floor(Date.now()) - this.$store.state.startTime) / 1000,
          EventId: 589826,
          Event: "ActEnd",
          ActPeriod: (Math.floor(Date.now()) - this.$store.state.startTime) / 1000,
        });
        let classRecord = await this.$saveIES.print();
        let promises = [];

        Object.keys(classRecord).forEach((i) => {
          // 根據性質組成相對應的檔案
          if (i == "xlsx") {
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.aoa_to_sheet(classRecord[i]);
            XLSX.utils.book_append_sheet(wb, ws, "summary");
            var wbout = XLSX.write(wb, {
              bookType: "xlsx",
              bookSST: true,
              type: "array",
            });

            let blob = new Blob([wbout], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            blob.lastModifiedDate = new Date();
            blob.name = "summary.xlsx";
            promises.push(this.uploadResourceFile(blob, "IES"));

            // 下載Excel 留著可以測試
            // XLSX.writeFile(wb, 'summary.xlsx');
          } else if (i != "msgbody") {
            let fileName = i + ".json";
            let blob = new Blob([JSON.stringify(classRecord[i])], { type: "application/json" });
            blob.lastModifiedDate = new Date();
            blob.name = fileName;
            promises.push(this.uploadResourceFile(blob, "IES"));
          }

          // 計算上課經過時間
          if (i == "base") {
            studentCount = classRecord[i].student.length;
            let startTimeArr = classRecord[i].report.startTime.split(":");
            let endTimeArr = classRecord[i].report.endTime.split(":");
            let startTime = new Date(0, 0, 0, startTimeArr[0], startTimeArr[1], startTimeArr[2]).getTime();
            let endTime = new Date(0, 0, 0, endTimeArr[0], endTimeArr[1], endTimeArr[2]).getTime();
            classDuration = Math.floor(-(startTime - endTime) / 1000);
          }
        });

        // 上傳課堂紀錄
        await Promise.all(promises).then(() => {
          console.log("classRecord - 上傳成功");
        });

        // 呼叫IES5課堂紀錄
        await this.$api.ies5Api.updClassRecord({
          lesson_id: this.classInfo.activityRecordNumber,
          tmdid: jwt_decode(sessionStorage.getItem("idToken")).sub,
          name: this.classInfo.className,
          school: this.classInfo.schoolId,
          scope: this.classInfo.scope,
          grant_types: [
            {
              grant_type: "up-duration", //课程时长
              data: classDuration,
            },
            // {
            //     "grant_type": "up-tScore",//t分
            //     "data": 78
            // },
            // {
            //     "grant_type": "up-pScore",//p分
            //     "data": 68
            // },
            {
              grant_type: "up-mCount", //上课学生数
              data: studentCount,
            },
            // {
            //     "grant_type": "up-techCount",//议课次数,
            //     "data":1
            // }
          ],
        });

        this.$q.loading.hide();
        // 上傳PDF
        await this.exportPDF("upload");
      }
    },
    isAuthWordCloud() {
      return this.getFunc('clients').get > 50
    },
    //check是否為純掃碼登入類型
    isAuthOnlyPureScan(){
      return(sessionStorage.getItem('loginByScan') == 'true' || sessionStorage.getItem('loginByScan') == true) && this.getFunc("works").para === 6 && this.getFunc("clients").para === 50;
    },
    isAuthAccount() {
      //17.授權:(0: 無, 1: ID, 2: 機器, 3: ID+機器) 0不是IES5也沒權益的純登入 1:ID有包含權益, 2.IES5過來的CC就是機器授權  3.：1和2都有
      let isPureAccount = this.getFunc("works").get === null && this.getFunc("clients").get === null && this.getFunc("pageqty").get === null;
      let type = 0;
      if (isPureAccount && !this.classInfo.isIES5) type = 0;
      else if (!isPureAccount && !this.classInfo.isIES5) type = 1;
      else if (isPureAccount && this.classInfo.isIES5) type = 2;
      else if (!isPureAccount && this.classInfo.isIES5) type = 3;
      console.log(type,'AuthType',this.$store.state.loginType)
      return type;
    },
    isAuthOpenAIAccount(){
      return this.getFunc("aigpt")?.active
    },
    setMQTTIoTAuthType() {
      this.$store.state.mqtt.mqttIoTData.authType = this.isAuthAccount();
      // console.log(this.getFunc('works').get===null,this.getFunc('clients').get===null,this.getFunc('pageqty').get===null)
    },
    setClassOpTime() {
      //MqttIotData開課時間
      this.classOpTime = new Date().getTime();
      sessionStorage.setItem("classOpTime", this.classOpTime);
    },
    setMQTTIoTClassTime() {
      let timeNow = new Date().getTime();
      let duration = Math.floor((timeNow - Number(this.classOpTime)) / 60000);
      this.$store.state.mqtt.mqttIoTData.classTime = duration;
    },
    // 於課堂結束或關閉Tab 課堂回報MQTT IoT資料
    sendIoTReportToMQTT() {
      this.$store.state.mqtt.mqttIoTData.channelNo = this.classNum;
      this.$store.state.mqtt.mqttIoTData.isWebIRS = this.$store.state.students.length > 0 ? 1 : 0;
      this.$store.state.mqtt.mqttIoTData.studentNum = this.$store.state.students.length;
      this.$store.state.mqtt.mqttIoTData.external_ip = sessionStorage.getItem("external_ip");
      let versionArray = this.$store.state.version.split(".");
      this.$store.state.mqtt.mqttIoTData.version = versionArray[0].replace("v", "") + "0" + versionArray[1] + versionArray[2];
      this.setMQTTIoTClassTime();

      //計算18-21 參照SaveIES的Report
      let collateCount = 0;
      let interactionCount = 0;
      let clientInteractionCount = 0;
      let irsmodel = "";
      for (var i = 0; i < this.$store.state.msgBody.length; i++) {
        collateCount += this.$store.state.msgBody[i].workData.length;
        irsmodel = this.$store.state.msgBody[i].irsmodel;
        let interactionflag = true;
        switch (irsmodel) {
          case "irsBuzz":
            clientInteractionCount += this.$store.state.msgBody[i].buzzInStudents.length;
            interactionflag = false;
            break;
          case "irsImmediately":
            for (var j = 0; j < this.$store.state.msgBody[i].totalirs.length; j++) {
              if (this.$store.state.msgBody[i].totalirs[j].option) clientInteractionCount++;
            }
            break;
          case "irsText":
            for (var j = 0; j < this.$store.state.msgBody[i].totalirs.length; j++) {
              if (this.$store.state.msgBody[i].totalirs[j].statement) clientInteractionCount++;
            }
            break;
          default:
            interactionflag = false;
        }
        if (interactionflag) {
          interactionCount++;
        }
      }
      let workInsidedata = JSON.parse(sessionStorage.getItem("workInsidedata"));
      if (workInsidedata) this.$store.state.mqtt.mqttIoTData.collateTaskCount = workInsidedata.length;
      this.$store.state.mqtt.mqttIoTData.collateCount = collateCount;
      this.$store.state.mqtt.mqttIoTData.interactionCount = interactionCount;
      this.$store.state.mqtt.mqttIoTData.clientInteractionCount = clientInteractionCount;

      this.$store.dispatch("mqtt/saveMqttIoTsessionStorage");
      this.$store.dispatch("mqtt/sendIoTReport");
      console.log(this.$store.state.mqtt.mqttIoTData,"mqttIoTData");
      //儲存部分課堂初始化就知道的 mqttIoTData
    },
    backToUse() {
      this.showIdlelogout = false;
      //偵測SignalR是否活著
      if (this.connection.connectionState != "Connected") {
        this.$Message.warning(this.$t("board['SignalR協議未正常連線']"));
      }
    },
    //選取物件時,在變形外框加入子選單按鈕
    addMenuBtnToTr(tr, trNode) {
      this.currentMenuObj = trNode;
      this.showObjMenuTextCopyOption = false;
      let that = this;
      const stage = this.stage;
      const objbtnbg = new Konva.Circle({
        name: "objBtn",
        fill: "#d8d8d8",
        radius: 12,
        x: tr.findOne(".top-right").position().x + 30,
        y: tr.findOne(".top-right").position().y + 12,
      });
      tr.add(objbtnbg);
      const objbtn = new Konva.Path({
        x: tr.findOne(".top-right").position().x + 18,
        y: tr.findOne(".top-right").position().y,
        fill: "#141414",
        data: `<path d="M17.9188 8.17969H11.6888H6.07877C5.11877 8.17969 4.63877 9.33969 5.31877 10.0197L10.4988 15.1997C11.3288 16.0297 12.6788 16.0297 13.5088 15.1997L15.4788 13.2297L18.6888 10.0197C19.3588 9.33969 18.8788 8.17969 17.9188 8.17969Z" fill="#292D32"/>`,
        name: "objBtn",
        listening: false,
      });
      tr.add(objbtn);
      function updatePos() {
        if (tr?.findOne(".top-right")?.position()?.x) {
          objbtnbg.position({ x: tr.findOne(".top-right").position().x + 30, y: tr.findOne(".top-right").position().y + 12 });
          objbtn.position({ x: tr.findOne(".top-right").position().x + 18, y: tr.findOne(".top-right").position().y });
        }
      }
      trNode.on("transform", updatePos);
      objbtnbg.on("mouseenter", (e) => {
        stage.container().style.cursor = "pointer";
      });

      objbtnbg.on("mouseleave", (e) => {
        stage.container().style.cursor = "default";
      });

      objbtnbg.on("click touchstart", (e) => {
        // console.log('click bg', e.evt.clientX)
        console.log(trNode);
        if (trNode.className == "Label" || trNode.className == "Text") {
          that.showObjMenuTextCopyOption = true;
          that.currentObjMenuCopyText = trNode.className == "Label" ? trNode.children[1].attrs.text : trNode.attrs.text;
        }
        that.showObjMenu = true;

        let touch, menuX, menuY;

        if (e.type == "touchstart") {
          touch = e.evt.touches[0] || e.evt.changedTouches[0];
          menuX = touch.pageX - 60;
          menuY = touch.pageY + 20;
        } else {
          menuX = e.evt.clientX - 60;
          menuY = e.evt.clientY + 20;
        }
        that.objMenuPos = { x: menuX, y: menuY };
      });
    },
    deleteObjByObjMenu() {
      this.$toolbox.saveUndoHistory("delete",  this.currentMenuObj); 
      this.currentMenuObj.destroy();
      this.stage.find("Transformer").forEach((item) => {
        item.destroy();
      });
      this.layer.batchDraw();
      this.showObjMenu = false;
    },

    copyPasteByObjMenu() {
      let currentIndex=this.layer?.children ? JSON.parse(JSON.stringify(this.layer?.children)).length : 1
      const copyObj = this.currentMenuObj.clone({
        x: isNaN(this.currentMenuObj.attrs?.x) ? -Math.floor(Math.random() * 40) : this.currentMenuObj.attrs.x - Math.floor(Math.random() * 40),
        y: isNaN(this.currentMenuObj.attrs?.y) ? -Math.floor(Math.random() * 40) : this.currentMenuObj.attrs.y - Math.floor(Math.random() * 40),
        uuid:this.$jsFn.getUUID(),
        index:currentIndex
      });
      this.$toolbox.saveUndoHistory("add",  copyObj); 
      this.layer.add(copyObj);
      this.layer.batchDraw();

      this.showObjMenu = false;
      this.stage.find("Transformer").forEach(function (ele, i) {
        if (ele.attrs.name == "default") {
          ele.destroy();
        }
      });
    },
    moveToTopByObjMenu() {
      let currentIndex=this.layer?.children ? JSON.parse(JSON.stringify(this.layer?.children)).length : 1
      this.currentMenuObj.moveToTop();
      this.currentMenuObj.setAttrs({ index:currentIndex})
      this.$toolbox.saveUndoHistory("update",  this.currentMenuObj); 
      this.layer.batchDraw();
    },
    moveToBottomByObjMenu() {
      this.currentMenuObj.moveToBottom();
      this.currentMenuObj.moveUp();
      this.currentMenuObj.setAttrs({ index:1})
      this.$toolbox.saveUndoHistory("update", this.currentMenuObj); 
      this.layer.batchDraw();
    },
    toImageByObjMenu() {
      let that = this;
      const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
        const byteCharacters = atob(b64Data.replace(/^data:image\/(png|jpeg|jpg);base64,/, ""));
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
      };
      if (navigator.clipboard && Object.getPrototypeOf(navigator.clipboard)?.write) {
        this.currentMenuObj.toImage({
          async callback(img) {
            // console.log(img)
            const b64Data = img.src;
            const item = new clipboard.ClipboardItem({
              "image/png": b64toBlob(b64Data, "image/png", 512),
            });
            await clipboard.write([item]);
            that.showObjMenu = false;
            that.$Message.info(that.$t("board.objMenu.已複製到剪貼簿"));
          },
        });
      } else {
        this.$Message.warning(this.$t("board.objMenu['瀏覽器版本不支援']"));
        this.showObjMenu = false;
      }
    },
    async toTextByObjMenu() {
      if (navigator.clipboard && Object.getPrototypeOf(navigator.clipboard)?.write) {
        let that = this;
        const item = new clipboard.ClipboardItem({
          "text/html": new Blob([`<p>${that.currentObjMenuCopyText}</p>`], { type: "text/html" }),
          "text/plain": new Blob([`${that.currentObjMenuCopyText}`], { type: "text/plain" }),
        });
        await clipboard.write([item]);
        this.showObjMenu = false;
        this.$Message.info(this.$t("board.objMenu.已複製到剪貼簿"));
      } else {
        this.$Message.warning(this.$t("board.objMenu['瀏覽器版本不支援']"));
        this.showObjMenu = false;
      }
    },
    sendTextToIRSByObjMenu(){
        let that = this
        let messages = {
          action: 'Message',
          clientType: 'HiTeachCC',
          sender: sessionStorage.getItem('user').sub,
          timestamp: new Date().getTime(),
          waitReturn: false,
          payload: ''
        }
        let channelapi = this.imConnect.channel_api + '/messages'
        let user = JSON.parse(sessionStorage.getItem('user'))
        let sender = user.sub
        let receive = []
        if (messages.sender !== user.sub) {
          receive.push(messages.sender)
        }
        console.log(receive, '查看接收者')

        if (messages.action === 'Message') {
          let payloadContent = {
            text: this.currentMenuObj.attrs.text,
          }
          //学生加入教室一系列操作
          let messageText = {
            action: 'Message',
            clientType: 'HiTeachCC',
            sender: sender,
            timestamp: new Date().getTime(),
            waitReturn: false,
            payload: payloadContent
          }
          this.$api
            .tomessage(channelapi, {
              connectionId: '',
              sender: sender,
              isPrivate: true,
              groupname: null,
              to: receive,
              Text: JSON.stringify(messageText)
            })
            .then(res => {
              that.$Message.info(that.$t("board.addImgBox['文字發送成功']"))
              console.log(res, '消息发送成功')
            })
            .catch(res => {
              console.log(res, '消息发送失败')
            })
          this.messagePushHistory.push(messageText)
    
      }
    },
    openGptBox(){
      
      this.showGptBox =true
      this.$store.state.mqtt.mqttIoTData.isGpt=1
      this.$store.dispatch('mqtt/saveMqttIoTsessionStorage')
      this.$refs.gptBox.currentText = this.currentObjMenuCopyText
      this.$refs.gptBox.currentGPTAns=''
      this.$refs.gptBox.cancelWaitingGPTRes = false
      this.showObjMenu = false

      // const alreadyHaveContaner = document.querySelector('.chat-gpt-container')
      // if (alreadyHaveContaner) alreadyHaveContaner.remove()
    },
    openEditMemberList() {
      this.$refs.editMemberList.openEditMemberList();
    },
    openEditMemberListAtInit() {
      this.isUseIES5studentList=false 
      this.isEditMemberListAtInit=true
      this.$refs.editMemberList.openEditMemberListAtInit();
      this.modalUpload=false
      
    },
    setCurrentMemberList(list) {
      this.isUseIES5studentList=false
      this.$refs.editMemberList.setCurrentMemberList(list);
      this.allowSitin=true
    },
    backChooseMemberList(){
     this.showMemberlist = true
     if(this.showIES5studentListBtn){
      this.isUseIES5studentList=true
     }
    },
    setStudentsFromList(){
      this.showMemberlist=false
      if(!this.isUseIES5studentList){
      this.students=[]
      if(Object.keys(this.currentMemberList).length > 0){

      this.memberList = Object.keys(this.currentMemberList).length == 0 ? [] : JSON.parse(JSON.stringify(this.currentMemberList?.list))
      this.verifyMode = Object.keys(this.currentMemberList).length == 0 ? "Dynamic" : "Fixed";
      this.allowSitin = true

      let time = Math.floor(Date.now())
      if(this.currentMemberList?.list){
      this.currentMemberList?.list?.forEach((item)=>{
          let student = {
              sort: item.seatID,
              id: '',
              accountid: item.memberID,
              studentName: item.memberName,
              sendTime: time,
              headImg: '',
              option: '',
              done: true,
              score: 0,
              rank: '',
              class: 'studyboxone',
              showcardclass: 'brand',
              answerclass: 'studyboxone',
              answerbrand: 'brand',
              statement: '', //发言,
              status: 'offline',
              type: 0
          }
          this.students.push(student)
      })
      // 調整排序
      this.students.sort(function(a, b){
          return a.sort - b.sort
      })
      }
    }
    this.$store.state.students = this.students;
    this.seatNum=this.$store.state.students.length //已有學生接續編號
    console.log(this.students,"this.students")
  }
    },
    freeAttend(){
      this.isUseIES5studentList=false
      setTimeout(()=>{
        this.setCurrentMemberList({})
        this.setStudentsFromList()
      },500)
    },
    confirmClear() {
      console.log("清空舞台");
      if (this.layer.children[0].attrs.name === "boardPdfBg" || this.layer.children[0].attrs.name === "bgRect") {
        this.layer.children.length = 1;
      } else {
        this.layer.children.length = 0;
      }
      this.layer.draw();
      this.showCanvasClearDangerHint=false
      this.$store.state.eraser = "eraser";
    },
    cancelClear(){
      this.showCanvasClearDangerHint=false
      this.$store.state.eraser = "eraser";
    },
    openPluginPage(){
      // this.$Message.info('待上架!')
      const url = 'https://chrome.google.com/webstore/detail/chatgpt-for-hiteachcc/efbagkfbecggdadlmneljlefmlpjgbdf';
      window.open(url, '_blank');
    },
    pasteFromClipboard(){
      this.$refs.addImgBox.pasteFromClipboard()
      this.showRightClickMenu=false
    },
  },

  created() {
    // 初始植
    this.activityRecordNumberS = this.classInfo.activityRecordNumber;

    // 權益
    console.log(this.getFunc("aigpt"), "aigpt"); // AIGPT
    console.log(this.getFunc("works"), "works"); // 作品收集任務數
    console.log(this.getFunc("clients"), "clients"); // 連線人數
    this.limitTime = this.getFunc("clients").isDeadline == true ? moment(this.getFunc("clients").exp * 1000).format("YYYY.MM.DD") : 0;
    this.aigptTime = this.getFunc("aigpt").isDeadline == true ? moment(this.getFunc("aigpt").exp * 1000).format("YYYY.MM.DD") : 0;
    console.log(this.limitTime, "exptime");
    console.log(this.aigptTime, "aigptTime");
    this.limitCollate = this.getFunc("works").para;
    this.limitClient = this.getFunc("clients").para;

    this.showMemberlist=this.isAuthAccount()==0||this.isAuthOnlyPureScan()?false:true

    // this.loginCallback();
    this.init();
    this.isRequestReBuild = sessionStorage.getItem("isRequestReBuild");
    this.signalRConnect();

    // else{
    //   this.imConnectinfo(this, sessionStorage.getItem('imConnect').access_token, sessionStorage.getItem('imConnect').channel_url)
    // }
    sessionStorage.setItem("isRequestReBuild", false);
    //this.getTMDToken()
    //this.MQTTconnect()
    // this.TMDInfo();
    // this.question();
    // this.showLoading();
    this.$store.state.imgArr = [];
    this.$store.state.timeLineEvents = [];
    console.log(this.getPREFMCQType, "getPREFMCQType"); // 選擇題選項
    console.log(this.getPREFIRSBlockSize, "getPREFIRSBlockSize"); // IRS Block 大小
    console.log(this.getPREFPenState("pencilAlt"), "pencilAlt");
    console.log(this.getPREFPenState("highlighter"), "highlighter");
    console.log(this.getPREFPenState("magic"), "magic");
    console.log(this.getGptActionList,'getGptActionList')
    console.log(this.getPREFMemberList,"getPREFMemberList")
    console.log(this.classInfo, "classInfo");
    console.log(this.blobConnect);

    if(!!this.getGptActionList){
      this.$store.state.tempGptActionList=this.getGptActionList
    }
   
  },
  async mounted() {
    setTimeout(() => {
      this.canShowGptDragBall = true
    }, 3000);
    // 連線MQTT
    this.$store.dispatch("mqtt/connect");

    //帶入後端偏好設定值
    let that = this;
    if (sessionStorage.getItem("currentAnsOption")) this.setAnsOption(sessionStorage.getItem("currentAnsOption"));
    else this.setAnsOption(this.getPREFMCQType);
    
     //帶入自訂名單偏好設定
    this.customMemberlist=this.getPREFMemberList?this.getPREFMemberList.length!=0?await this.$refs.editMemberList.fetchCCroster():[]:[]


    this.getStorageSize("session");

    var editor = new E("#editor");
    //editor.customConfig.onchange = (html) => {
    //    this.editorContent = html
    //}
    editor.create();
    this.Editorinfo = editor;
    let self = this;
    this.$nextTick(function () {
      document.addEventListener("keyup", function (e) {
        if (e.keyCode == 27) {
          self.closeScreenFull();
        }
      });

      // //Ctrl+v 與一般本機操作取值行為有點不同，後續待研究，先註解功能
      // document.addEventListener(
      //   'keydown',
      //   function(ev) {
      //     // function to check the detection
      //     ev = ev || window.event // Event object 'ev'
      //     let key = ev.which || ev.keyCode // Detecting keyCode

      //     // Detecting Ctrl
      //     let ctrl = ev.ctrlKey ? ev.ctrlKey : key === 17 ? true : false

      //     // If key pressed is V and if ctrl is true.
      //     if (key == 86 && ctrl) {
      //       // print in console.
      //       // console.log('Ctrl+V is pressed.')
      //       self.$refs.addImgBox.pastContent()
      //       self.layer.draw()
      //     }
      //     // } else if (key == 67 && ctrl) {
      //     //   // If key pressed is C and if ctrl is true.
      //     //   // print in console.
      //     //   console.log('Ctrl+C is pressed.')

      //     // }
      //   },
      //   false
      // )
    });
    if (this.students.length != 0) {
      this.eliminatepage();
    }
    sessionStorage.setItem("activityRecordNumberS", this.activityRecordNumberS);

    // Init Konva Stage and Layer
    console.log("Init Konva on mounted");
    this.stage = new Konva.Stage({
      container: "container",
      width: window.innerWidth,
      height: window.innerHeight,
      // draggable: true
    });
    this.$store.state.stage = this.stage;
    this.layer = new Konva.Layer();
    this.stage.add(this.layer);
    this.$store.state.layer = this.layer;

    console.log("TEST preload students");
    if (localStorage.getItem("students") && this.$store.state.students.length == 0)
      // TEST ONLY
      this.$store.state.students = JSON.parse(localStorage.getItem("students"));

    if (this.classInfo.students) {
      if (!!this.classInfo.students.length) {
        this.$store.state.students = this.classInfo.students;
        console.log("Load IES5 classInfo", this.classInfo);
      }
    }

    if (this.classInfo.className) this.className = this.classInfo.className;
    if (this.classInfo.schoolId) this.schoolId = this.classInfo.schoolId;
    if (this.classInfo.schoolName) this.schoolName = this.classInfo.schoolName;

    if (this.$store.state.students.length > 0) {
      // got 已經存在的名單例如 ies5 帶過來的
      this.verifyMode = "Fixed";

      // Check possible sort missing or duplicate problem
      let err = false;
      this.$store.state.students.forEach((item, index) => {
        if (item.sort == 0 || item.sort == "") {
          err = true;
        } else {
          let samesort = this.$store.state.students.filter((item1) => {
            return item1.sort === item.sort;
          });
          if (samesort.length > 1) err = true;
        }
      });
      if (err)
        this.$store.state.students.forEach((item, index) => {
          item.sort = index + 1;
        });

      this.students = this.$store.state.students;
      this.seatNum = this.$store.state.students.length; //已有學生接續編號
      this.students.forEach((item, index) => {
        if (item.sort > that.seatNum) that.seatNum = item.sort;
        that.memberList.push({
          memberID: item.accountid,
          memberName: item.studentName,
          seatID: item.sort,
          groupName: null,
          groupID: null,
          isHiGroup: false,
        });
      });
      console.log("preloaded: ", err, this.$store.state.students);
      sessionStorage.setItem("students", JSON.stringify(this.$store.state.students));
      this.showMemberlist=false //IES5過來不需要在重設名單
      this.showIES5studentListBtn = true
    }

    this.$store.state.startTime = Math.floor(Date.now());
    if (sessionStorage.getItem("startTime")) {
      // fetch original
      this.$store.state.startTime = sessionStorage.getItem("startTime");
      this.$store.state.timeLineEvent = JSON.parse(sessionStorage.getItem("timeLineEvent"));
    } else {
      //init
      sessionStorage.setItem("startTime", this.$store.state.startTime);
      this.$store.state.timeLineEvent = [];
      this.$store.state.timeLineEvent.push({
        Time: 0,
        EventId: 589825,
        Event: "ActStart",
        ActStartTime: "0",
      });
      sessionStorage.setItem("timeLineEvent", JSON.stringify(this.$store.state.timeLineEvent));
    }

    if (sessionStorage.getItem("classNum") != "" && sessionStorage.getItem("stageInfo") && sessionStorage.getItem("idArr")) {
      this.$store.state.isRenderingPDFNow = false;
      if (this.$store.state.imgArr.idArr == undefined) {
        this.$store.state.imgArr.idArr = JSON.parse(sessionStorage.getItem("idArr"));
      }
      this.showClassNum = true;
      this.modalUpload = false;
      this.$store.state.isOnlyRefresh = true;
      // this.openNew()
      this.$store.state.uploadUrl = this.$store.state.newboardUrl;
      let arr = [];
      JSON.parse(sessionStorage.getItem("stageInfo")).forEach((item) => {
        if (typeof item == "string") {
          arr.push(JSON.parse(item));
        } else {
          arr.push(item);
        }
      });
      this.saveArr = arr;

      let blobinfo = this.classInfo.blob;
      // this.fetchSavedImgJsonData(sessionStorage.getItem('pdfOringinalImgsUrl') + '?' + blobinfo.sas_read, 'pdfOringinalImgsUrl')
      if (sessionStorage.getItem("imgArr")) this.$store.state.imgArr = JSON.parse(sessionStorage.getItem("imgArr"));
      else this.$store.state.imgArr = [];
      if (isNaN(parseInt(sessionStorage.getItem("workNum"))) == true) {
        this.$store.state.workNum = 0;
        sessionStorage.setItem("workNum", this.$store.state.workNum);
      } else {
        this.$store.state.workNum = parseInt(sessionStorage.getItem("workNum"));
      }

      if (sessionStorage.getItem("collateCheckTable") != []) {
        this.$store.state.collateCheckTable = JSON.parse(sessionStorage.getItem("collateCheckTable"));
        this.workInsidedata = JSON.parse(sessionStorage.getItem("workInsidedata"));
      } else sessionStorage.setItem("workInsidedata", []);

      if (sessionStorage.getItem("pagePushHistory") != []) {
        this.pagePushHistory = JSON.parse(sessionStorage.getItem("pagePushHistory"));
      }

      if (sessionStorage.getItem("messagePushHistory") != []) {
        this.pagePushHistory = JSON.parse(sessionStorage.getItem("messagePushHistory"));
      }

      if (sessionStorage.getItem("students")) {
        this.$store.state.students = JSON.parse(sessionStorage.getItem("students"));
        this.students = this.$store.state.students;
        this.seatNum = this.$store.state.students.length; //已有學生接續編號
        this.students.forEach((item, index) => {
          if (item.sort > that.seatNum) that.seatNum = item.sort;
          that.memberList.push({
            memberID: item.accountid,
            memberName: item.studentName,
            seatID: item.sort,
            groupName: null,
            groupID: null,
            isHiGroup: false,
          });
        });
      }

      if (sessionStorage.getItem("allowVisitor")) {
        this.allowVisitor = JSON.parse(sessionStorage.getItem("allowVisitor"));
      }

      if (sessionStorage.getItem("allowSitin")) {
        this.allowSitin = JSON.parse(sessionStorage.getItem("allowSitin"));
      }

      if (sessionStorage.getItem("slidelength") != null) {
        this.$store.state.totalpage = parseInt(sessionStorage.getItem("slidelength"));
      } else {
        this.$store.state.totalpage = 0;
        sessionStorage.setItem("slidelength", this.$store.state.totalpage);
      }
      // console.log(this.saveArr)
      // this.pen(0)

      //讀取pdf原圖連結備份在本地
      this.$store.state.pdfOringinalImgsIdArr = JSON.parse(sessionStorage.getItem("pdfOringinalImgsIdArr"));
      this.$store.state.pdfOringinalImgs = JSON.parse(sessionStorage.getItem("pdfOringinalImgs"));

      //讀取msgBody備份在本地
      //this.$store.state.msgBody =  JSON.parse(sessionStorage.getItem('msgBody'))

      //MqttIotData開課時間與資料寫回
      if (sessionStorage.getItem("classOpTime")) this.classOpTime = sessionStorage.getItem("classOpTime");
      if (sessionStorage.getItem("mqttIoTData")) this.$store.state.mqtt.mqttIoTData = JSON.parse(sessionStorage.getItem("mqttIoTData"));

      console.log("Loading msgBody");
      if (sessionStorage.hasOwnProperty("msgBody")) {
        await this.loadMsgBody();
      }
      console.log("New stage on reload mounted");
      /*
      this.stage = new Konva.Stage({
        container: 'container',
        width: window.innerWidth,
        height: window.innerHeight
        // draggable: true
      })
      this.$store.state.stage = this.stage
      this.layer = new Konva.Layer()
      this.stage.add(this.layer)
      */

      this.slidenum = 1;
      this.$store.state.carouselSlide = 1;
      this.$store.state.oldSlide = 1;
      this.$store.state.mode = "pencil-alt";
      this.pen(0);
    }
    // this.CreateGroup();

    window.onresize = () => {
      // 全屏下监控是否按键了ESC
      if (!this.checkFull()) {
        // 全屏下按键esc后要执行的动作
        this.isFullscreen = false;
      }
    };
    //用户刷新
    window.onbeforeunload = async function (e) {
      console.log('Unload#0')
      //Safari may report error
      await that.updProfilePREF()
    }
    window.onunload = async function (e) {
      console.log("Unload#1");
      // await that.updProfilePREF()
      console.log("Unload#1.1");
      await that.saveStage("now");
      console.log("Unload#1.2");
      await sessionStorage.setItem("slidelength", that.totalpage);
      console.log("Unload#1.3");
      // //舞台變動上傳縮圖
      // const time = new Date().getTime()
      // that.uploadJsonData(that.$store.state.imgArr, `${time}ImgArrData.json`, 'ImgArrUrl')
      await sessionStorage.setItem("pagePushHistory", JSON.stringify(that.pagePushHistory));
      console.log("Unload#1.4");
      await sessionStorage.setItem("messagePushHistory", JSON.stringify(that.messagePushHistory));
      console.log("Unload#1.5");
      await sessionStorage.setItem("slide", 1);
      console.log("Unload#1.6");
      //重整存的圖是掉圖的序列化渲染
      await sessionStorage.setItem("imgArr", JSON.stringify(that.$store.state.imgArr));
      console.log("Unload#1.7");
      await sessionStorage.setItem("collateCheckTable", JSON.stringify(that.$store.state.collateCheckTable));
      console.log("Unload#1.8");
      if (that.$store.state.students) {
        sessionStorage.setItem("students", JSON.stringify(that.$store.state.students));
      }
      await sessionStorage.setItem("allowVisitor", JSON.stringify(that.allowVisitor));
      await sessionStorage.setItem("allowSitin", JSON.stringify(that.allowSitin));
      await sessionStorage.setItem("timeLineEvent", JSON.stringify(that.$store.state.timeLineEvent));
      if (that.isRequestReBuild == true) {
        e.preventDefault();
        that.isRequestReBuild = false;
      } else if (that.isRequestReBuild == false || that.isRequestReBuild == "false") {
        e = e || window.event;
        // 兼容IE8和Firefox 4之前的版本
        if (e) {
          e.returnValue = "关闭提示";
        }
        // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
        return "关闭提示";
      }
    };
    this.idleLogoutTimer = setTimeout(() => {
      this.showIdlelogout = true;
    }, 1000 * 60 * 60);
    window.onclick = () => {
      let timeNow = new Date().getTime();
      let duration = Math.floor((timeNow - Number(this.classOpTime)) / 60000);
      console.log("使用者點了畫面，目前距離開始課堂" + duration + "分鐘");
      clearTimeout(this.idleLogoutTimer);
      this.idleLogoutTimer = setTimeout(() => {
        this.showIdlelogout = true;
      }, 1000 * 60 * 60);
    };

    //浏览器变化
    window.onresize = () => {
      return (() => {
        /* this.screenWidth = document.body.clientWidth;*/
        if (this.stage) {
          // console.log(this.stage, '获取现在的宽高')
          // var size = this.stage ? this.stage.size() : 0
          // var scale = parseFloat((window.innerHeight / size.height).toFixed(2))
          // var scaleData = scale <= 1 ? scale : 1
          // var starting = (window.innerWidth - this.primevalInit.width * this.primevalInit.scale * scaleData) / 2
          // console.log(window.innerHeight, window.innerWidth, starting, this.primevalInit.width, this.primevalInit.scale, scale, '起点的位置')
          // /* this.stage.absolutePosition({ x: starting, y: 0 })*/
          // // this.stage.x(starting);

          // this.stage.x(0)
          // this.stage.y(0)
          // this.primevalInit.x = starting
          // console.log(scale, starting, this.stage)
          // var widths = size.width * scale
          // var heights = size.height * scale
          // this.stage.size({ width: widths, height: heights })
          // if (window.innerHeight == this.primevalInit.height) {
          //   scale = 1
          // }
          // this.stage.scaleX(scale)
          // this.stage.scaleY(scale)
          // sessionStorage.setItem('scalings', scale)
          // this.stage.draw()

          //Louise更新寫法
          //https://stackoverflow.com/questions/65433710/how-to-set-stage-width-and-height-to-100-react-konva
          let container = document.querySelector(".boardArea");
          this.stage.width(container.offsetWidth);
          this.stage.height(container.offsetHeight);
          this.layer.batchDraw();
        }
      })();
    };
    window.setInterval(() => {
      setTimeout(() => {
        console.log(this, this.$store.state, "执行调用广播方法！");
        this.broadcastState();
      }, 0);
    }, 10000);

    // 測試上傳用
    // this.uploading("https://teammodeltest.blob.core.windows.net/1639466071/2021%E7%AC%AC%E4%BA%8C%E5%B1%86YONEX%E4%B8%80%E7%B7%9A%E5%85%A5%E9%AD%82%E5%85%A8%E5%9C%8B%E7%BE%BD%E7%90%83%E9%8C%A6%E6%A8%99%E8%B3%BD%E5%A0%B1%E5%90%8D%E5%90%8D%E5%96%AE.pdf?sv=2020-10-02&st=2022-04-06T10%3A12%3A00Z&se=2022-04-07T10%3A12%3A00Z&sr=b&sp=r&sig=UxEvklDf1yU3Mv0aDghGE68JJSY62%2FTvxfoRArnAz6M%3D")

    //偵測網路斷開
    window.onoffline = () => {
      if (!navigator.onLine) this.$Message.error(this.$t("board['網路斷線']"));
    };

    if (this.classInfo.materialUrl != "" && this.$store.state.isOnlyRefresh == false) {
      this.uploading("IES5PDF");
    }
    console.log(this.classInfo.isIES5,(this.isAuthAccount() != 0&&!this.isAuthOnlyPureScan()))
    if (this.classInfo.isIES5||(this.isAuthAccount() != 0&&!this.isAuthOnlyPureScan())) {
      this.showInsertPDFBtn = true; //IES5, 專家版可以課中匯入PDF
    }
    //儲存部分課堂初始化就知道的 mqttIoTData
    this.$store.state.mqtt.mqttIoTData.userId = jwt_decode(sessionStorage.getItem("idToken")).sub;
    this.$store.state.mqtt.mqttIoTData.schoolId = this.schoolId;
    this.$store.state.mqtt.mqttIoTData.isIES5 = this.classInfo.isIES5 ? 1 : 0;
    this.$store.state.mqtt.mqttIoTData.isIES5Material = this.classInfo.materialUrl != "" ? 1 : 0;
    this.setMQTTIoTAuthType();
  },
  computed: {
    ...mapGetters({
      getFunc: "funcs/getFunc",
      getPREFMCQType: "preferences/getMCQType", // 取得選擇題預設類型
      getPREFIRSBlockSize: "preferences/getIRSBlockSize", // 取得IRS Block 大小
      getPREFPenState: "preferences/getPenState", // 取得預設畫筆設定檔
      getGptActionList:'preferences/getGptActionList',
      getPREFMemberList:"preferences/getPREFMemberList", // 取得自訂名單
      classInfo: "classInfo/getInfo", // 取得課堂設定
      callLogout: "mqtt/getCallLogout", // 取得mqtt是否呼叫登出
      mqttDeviceId: "mqtt/getDeviceId", // 取得mqtt 裝置ID
    }),
    lang: function () {
      switch (this.$i18n.locale) {
        case "zh-TW":
          return "tw";
        case "zh-CN":
          return "cn";
        case "en-US":
          return "en";
      }
    },
    watchUrl() {
      return this.$store.state.question.submit;
    },
    watchSlide() {
      return this.$store.state.carouselSlide;
    },
    watchZoom() {
      return this.$store.state.zoom;
    },
    watchAdd() {
      return this.$store.state.addNew;
    },
    watchDelete() {
      return this.$store.state.deletePage;
    },
    watchCarousel() {
      return this.$store.state.carousel;
    },
    watchAnswerStatus() {
      return this.$store.state.answerstatus;
    },
    watchirstexts() {
      return this.$store.state.irsBack;
    },
    watchanswernum() {
      return this.$store.state.answernum;
    },
    watchstickup() {
      return this.$store.state.position.status;
    },
    watchtotalpage() {
      return this.$store.state.totalpage;
    },
    watchanswervalue() {
      return this.$store.state.answervalue;
    },
    watchanswerpattern() {
      return this.$store.state.currentAnsOption == "En" ? "letter" : "number";
    },
    watchIdArr() {
      console.log(this.$store.state.imgArr.idArr, "this.$store.state.imgArr.idArr");
      return this.$store.state.imgArr.idArr;
    },
    isEditingTextNow() {
      return this.$store.state.isEditingTextNow;
    },
  },
  watch: {
    // classInfo(classInfo){
    //   console.log(classInfo.materialUrl, 'classInfo.materialUrl')
    //   if(classInfo.materialUrl!=''){
    //     this.uploading('IES5PDF')
    //   }
    // },
    watchUrl(e) {
      if (e) {
        this.question();
      }
    },
    watchSlide(e) {
      if (e) {
        //在切換頁時先移除偵聽，pen的時候又會再加入
        const tools1 = document.getElementById("tool1");
        tools1.removeEventListener("click", () => {});

        const canvas_dom = document.getElementById("container"); // make this your canvas DOM element
        canvas_dom.removeEventListener("touchstart", () => {});
        canvas_dom.removeEventListener("touchmove", () => {});
        canvas_dom.removeEventListener("touchend", () => {});
        canvas_dom.removeEventListener("touchcancel", () => {});

        this.stage.off("mouseup.1 touchend.1");
        this.stage.off("mouseup.2 touchend.2");
        this.stage.off("mousedown.1 touchstart.1");
        this.stage.off("mousedown.2 touchstart.2");
        this.stage.off("mousemove.1 touchmove.1");
        this.stage.off("mousemove.2 touchmove.2");

        console.log("11111111" + e);
        this.$store.state.isFilterCheckwork = false;
        this.showObjMenu =false;
        this.isViewWorkImg = false;
        this.saveStage();
      }
    },
    watchZoom(e) {
      if (e) {
        this.zoomFuc();
      }
    },
    watchAdd(e) {
      if (e) {
        this.addNewPage();
        this.$store.state.addNew = false;
      }
    },
    watchDelete(e) {
      if (e) {
        this.deleteSelectPage();

        this.$store.state.deletePage = false;
      }
    },
    watchCarousel(e) {
      if (e) {
        this.carousel = this.$store.state.carousel;
      }
    },

    watchanswernum(e) {
      if (e) {
        this.answernum = e;
      }
      this.studentPercentages();
    },
    students: function () {
      this.studentPercentages();
    },
    worksData: function () {
      //目前worksData僅作觀測變化用, 其他取值從vuex collateCheckTable取
      this.studentPercentages();
    },
    watchtotalpage(e) {
      if (e) {
        this.totalpage = e;
        this.pageinit();
      }
    },
    watchanswervalue(e) {
      // console.table(this.listAllEventListeners(), '查看目前偵聽器')
      this.answer();
    },
    /*监听答案模式  字母或数字*/
    watchanswerpattern(e) {
      if (e) {
        this.answerpatterns();
      }
    },
    workInsidedata: function (value) {
      sessionStorage.setItem("workInsidedata", JSON.stringify(value));
    },
    watchIdArr: function (value) {
      sessionStorage.setItem("idArr", JSON.stringify(this.$store.state.imgArr.idArr));
      if (value) {
        if (value.length == this.totalpage) {
          console.log("初始化預存頁數資料");
          for (let i = 1; i <= value.length; i++) {
            // this.switchoverpdf(i)
            let locationY = "";
            let imageObj = new Image();
            imageObj.src = this.$store.state.imgArrOld[i - 1];
            imageObj.height = imageObj.height;
            let ratio = (window.innerHeight / imageObj.height).toFixed(2);
            imageObj.height = imageObj.height * ratio;
            imageObj.width = imageObj.width * ratio;

            let stageX = (window.innerWidth - imageObj.width) / 2 && imageObj.width !== "" ? (window.innerWidth - imageObj.width) / 2 : 0;

            //  如果mounted有備份就不覆蓋
            if (this.saveArr.length < value.length) {
              let tempStageInfo = {
                attrs: {},
                children: [
                  {
                    attrs: {
                      name: "boardPdfBg",
                      x: stageX,
                      y: locationY ? parseInt(locationY) : 0,
                      image: imageObj,
                      index: 0,
                    },
                    className: "Image",
                  },
                ],
                className: "Layer",
              };
              this.saveArr.push(tempStageInfo);
            }
          }
          sessionStorage.setItem("stageInfo", JSON.stringify(this.saveArr));
        }
      }
    },
    callLogout: function (value) {
      if (value) {
        this.$q
          .dialog({
            title: "HiTeachCC", //標題
            message: this.$t("board['ssoError']"), //內文
            ok: this.$t("board['確定']"),
          })
          .onOk(() => {
            this.quitbtns();
          }, 1000 * 60 * 60);
      }
    },
    showIdlelogout(value) {
      if (value) {
        clearTimeout(this.idleLogoutTimer);
        this.idleLogoutTimer = setTimeout(() => {
          this.quitbtns();
        }, 1000 * 60 * 1);
      } else {
        clearTimeout(this.idleLogoutTimer);
        this.idleLogoutTimer = setTimeout(() => {
          this.showIdlelogout = true;
        }, 1000 * 60 * 60);
      }
    },
    currentEditMemberListName(v) {
      if (v == "") this.setAddMemberErrMsg("名單名稱不可為空");
      else {
        const index = this.addMemberListErrMsg.indexOf("名單名稱不可為空");
        if (index != -1) {
          this.addMemberListErrMsg.splice(index, 1);
        }
      }
    },

  },
  beforeDestroy() {
    if (this.timer !== void 0) {
      clearTimeout(this.timer);
      this.$q.loading.hide();
    }
  },
};
</script>
<style lang="less" scoped>
@import "../assets/css/Board.less";
</style>
<style lang="less">
@import "../assets/css/color.less";
.q-circular-progress__text {
  font-weight: bolder;
  color: orangered !important;
  &:hover {
    color: darken(orangered, 10%) !important;
  }
}
.hicard-icon {
  font-size: 50px !important;
}
.download-icon {
  position: relative;
  margin-bottom: 6px;
  font-size: 44px !important;
}
body {
  background: #fff !important;
}

li {
  float: left;
}

.boardArea {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0;
  overflow-y: hidden;
}

.konvajs-content {
  //height: 100% !important;
  width: 100% !important;
  background: rgb(255, 255, 255);
  /*background: red;*/
  position: absolute !important;
  top: 0;
  left: 0;
}

.vdr {
  z-index: 999999 !important;
  background: #ddd;
}

.vertical-center-modal {
  min-width: 100% !important;
  top: unset !important;
  bottom: 0px;
  position: absolute !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ivu-modal-body {
  display: none;
}

.ivu-modal-footer {
  border-top: 0 !important;
  padding: 0px !important;
  /*.ivu-btn{margin-bottom: 20px;}*/
}

.ivu-modal-header {
  border: 0;
}

.ivu-modal-close {
  display: none;
  top: 15px !important;
  right: 15px !important;
  background: #d8d8d8;
  border-radius: 50%;
  .ivu-icon-ios-close {
    color: #000 !important;
    font-size: 39px !important;
  }

  .ivu-icon-ios-close:hover {
    font-size: 39px;
    font-weight: bold;
  }
}

.ivu-modal-close:hover {
  font-weight: bold;
  font-size: 18px;
}

.ivu-modal-content {
  background-color: #fcfcfc !important;
  box-shadow: 0 2px 5px rgba(128, 128, 128, 0.5) !important;
}

.ivu-upload {
  display: inline-block;
  padding: 0;
  margin: 0;
}

.ivu-modal-mask {
  background: rgba(255, 255, 255, 0.5) !important;
}

.ivu-upload-list li {
  float: none;
}

.upload-box {
  overflow: hidden;
  padding: 10px 80px 0px 80px;
  display: flex;
  justify-content: center;
  background-color: #ececec;
  button {
    transition: none;
    // width: 120px;
    height: 120px;
    margin: 0 22px !important;
    border: 1px solid #ccc !important;
    background-color: #fcfcfc;
    font-size: 14px;
    color: rgb(20, 20, 20);
    font-weight: bold;
    border-width: 2px;
    border-radius: 10px;
    &:hover {
      // background-color: darken(#fcfcfc, 10%);
      color: blue;
      border: 1px solid blue !important;
    }
  }
}
.picker-drag {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  .tool-icon-close {
    right: 120px !important;
    top: 120px !important;
  }
}
.drag-wrap-button-timer {
  cursor: move;
  position: absolute;
  border: 1px solid #ccc;
  background: #eee;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 50px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  &:hover {
    cursor: move;
  }
  .tool-icon-close {
    position: absolute;
    right: -16px;
    top: -16px;
    font-size: 20px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    z-index: 999;
    background: #d8d8d8;
    cursor: pointer;
  }
}
.drag-wrap-button-timer.timer-drag {
  border-radius: 5px;
  height: 460px;
  width: 400px;
  padding: 50px 50px;
  .close-btn {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 30px;
    height: 30px;
    line-height: 32px;
    background-color: #d8d8d8;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    .close-icon {
      font-size: 20px;
    }
  }
}
.timer-small {
  height: auto !important;
  padding: 5px 5px !important;
  width: auto !important;
}

.drag-wrap {
  cursor: move;
  position: absolute;
  border: 1px solid #ccc;
  background: #eee;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 50px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  &:hover {
    cursor: move;
  }
  .tool-icon-close {
    position: absolute;
    right: -16px;
    top: -16px;
    font-size: 20px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    z-index: 999;
    background: #d8d8d8;
    cursor: pointer;
  }
}

.sueecess_btn {
  width: 80px;
  height: 80px;
  display: block;
  float: left;
  margin-right: 15px;
  margin-top: 20px;
}

.error_btn {
  width: 75px;
  height: 75px;
  display: block;
  float: right;
  margin-left: 25px;
  margin-top: 20px;
}
.q-ma-md {
  margin: 3px 12px !important;
}

.ivu-upload-list {
  margin-top: 0px !important;
  width: 110px;
  margin-left: 10px;
}

.ivu-upload-list-file {
  padding: 0px !important;
}

.ivu-upload-list-file span {
  font-size: 10px !important;
}

.cursor-fdj {
  cursor: zoom-in !important;
}

.visitor-switch {
  text-align: right;
  cursor: pointer;
  height: 30px;
  right: 8px;
  margin-top: -16px;
  margin-bottom: 4px;
}
</style>
