<template>
  <div class="card_box">
    <div v-show="styleObject.isShow" :width="styleObject.width" class="vertical-center-modal irs-view" @on-cancel="handleHide" @on-visible-change="handleShow" :style="`top:calc(100%${-optionViewHeight}px) !important;`" :class="{ 'small-modelwrap': optionViewHeight() == 240, 'mid-modelwrap': optionViewHeight() != 240 }">
      <div class="tl" :style="styleObject">
        <!--<q-dialog v-model="styleObject.isShow" @hide="handleHide" @show="handleShow">-->
        <!--<q-card :style="styleObject">-->
        <OptionView v-if="conIndex == 1" @styleObjScreen="getMsgFormSon" @close="getclose" ref='optionView'></OptionView>
        <Bar v-else-if="conIndex == 4"></Bar>
        <Pie v-else-if="conIndex == 5"></Pie>
        <Question v-else-if="conIndex == 6"></Question>
        <!--<Rang v-else-if="conIndex ==7"></Rang>-->
        <Qrcode v-else-if="conIndex == 8"></Qrcode>
        <Upload v-else-if="conIndex == 9"></Upload>
        <Menu v-else-if="conIndex == 10" style="position:relative !important"></Menu>
        <IrsNews v-else-if="conIndex == 11"></IrsNews>
        <!--</q-card>-->
        <!--</q-dialog>-->
      </div>
    </div>
  </div>
</template>

<script>
import OptionView from './Chart/OptionView.vue'
import BasePick from './Tools/BasePick.vue'
import BaseTimer from './Tools/BaseTimer.vue'
import Menu from './Tools/Menu.vue'
import IrsNews from './Tools/irsNews.vue'
import Bar from './Chart/Bar.vue'
import Pie from './Chart/Pie.vue'
import Question from './Tools/Question.vue'
import Qrcode from './Common/QRcode.vue'
import Upload from './Common/Upload.vue'
export default {
  name: 'card',
  components: {
    OptionView,
    BasePick,
    BaseTimer,
    Menu,
    IrsNews,
    Bar,
    Pie,
    Question,
    Qrcode,
    Upload
  },
  props: ['styleObj', 'componentIndex'],
  data() {
    return {
      isShow: false,
      conIndex: 0,
      fpWidths: '',
      fpHeights: '',
      fpml: '',
      fpmt: '',
      backgroundColor: 'unset',
      boxShadow: 'unset',
      styleObject: {},
      show: 'true'
    }
  },
  computed: {},
  created() {
    this.styleObject = this.styleObj
    this.conIndex = this.componentIndex
    // console.log(this.componentIndex)
  },
  methods: {
    optionViewHeight() {
      return this.$store.state.optionViewHeight
    },
    handleHide() {
      this.styleObject.isShow = false
      this.conIndex = 0
    },
    handleShow(e) {
      if (e === true) {
        this.conIndex = this.componentIndex
        this.styleObject.isShow = true
        this.$parent.isShowCard = false
      } else if (e === false) {
        this.conIndex = this.componentIndex
        this.styleObject.isShow = false
        this.$parent.isShowCard = false
        this.$store.state.cssvalue = '0'
      }
    },
    getMsgFormSon(data) {
      console.log(this.styleObject, '传输来的样式')
      this.styleObject = data
    },
    getclose() {
      this.styleObject.isShow = false
      this.$parent.isShowCard = false
    }
  }
}
</script>
<style lang="less">
.card_box {
  position: fixed;
  display: block;
  width: calc(~'100% - 100px') !important;
  height: 240px;
  bottom: 0px;
  z-index: 999;
}
</style>
<style scoped>
.q-card {
  /*padding: 30px 30px 0;*/
  border-radius: 5px;
  box-shadow: none;
}

.q-btn {
  background: #fff;
  border-radius: 50%;
  width: 90px;
  height: 30px;
  min-height: 30px;
  position: absolute;
  top: 0;
  right: 0;
}
.tl {
  box-shadow: 0px -1px 5px rgba(128, 128, 128, 0.3) !important;
  transition: 0.5s;
  overflow: hidden;
}
</style>
