<template>
  <div class="login-box bgf pad20" style="min-height: 0px;">
    <Upload action="/api/file/UploadDocument"
            :on-success="handleUploadSuc">
      <Button icon="ios-cloud-upload-outline">Select the file to upload</Button>
    </Upload>
  </div>
</template>
<script>
//  import Header from '@/components/Common/CommonHeader.vue'
//  import Footer from '@/components/Common/CommonFooter.vue'
  //import {Quasar,Loading,QSpinnerGears,QUploader} from 'quasar'
  //import axios from 'axios';
  export default {
    components: {
 //     Header,
 //     Footer
    },
    data () {
      return {
        file: null,
        loadingStatus: false
      }
    },
    methods: {
      handleUploadSuc(response){
        this.$store.state.uploadUrl=response.result.data.model.blobUrl;
      },
    },
    mounted () {

    }
  }
</script>
<style lang="less" scoped>

  .ul-box {
    height: 280px;
    overflow: hidden;
  }
  .ul-enter-active,
  .ul-leave-active {
    transition: height 0.3s;
  }
  .ul-enter,
  .ul-leave-to {
    height: 0;
  }

  .add-new{margin:30px 0 15px;}
  .new-input{border: 1px solid #ddd;padding:10px;margin:0px 0 40px;}
  .create-box{padding:0 10px;}
  /*.ivu-checkbox-group{float:left;margin-right: 20px;}*/
  .ivu-checkbox{ margin-right: 5px !important;margin-top: -2px;}

  .ivu-btn-primary{
    width: 88%;
    height:42px;border-radius: 2px;
    position: absolute;
    bottom:40px;
  }
  .demo-upload-list{
    display: inline-block;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    /*border: 1px solid transparent;*/
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    position: relative;
    box-shadow: 0 1px 1px rgba(0,0,0,.2);
    margin-right: 4px;
  }
  .demo-upload-list img{
    width: 100%;
    height: 100%;
  }
  .demo-upload-list-cover{
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.6);
  }
  .demo-upload-list:hover .demo-upload-list-cover{
    display: block;
  }
  .demo-upload-list-cover i{
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    margin: 0 2px;
  }
</style>

