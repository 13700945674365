<template>
  <div class="gptBox">
    <!--確認刪除名單-->
    <div class="deleteSet-card " v-show="showConfirmDeleteSet">
      <p class="deleteSet-card-title">{{ $t("gptBox['刪除']") }}</p>
      <p class="warndeletemsg">{{ $t("gptBox['確定刪除後這個分類將無法復原']") }}</p>
      <div class="deleteSet-group">
        <div class="deleteSet-btn" @click="deleteActionSetItem()">{{ $t("gptBox['確定']") }}</div>
        <div class="deleteSet-btn deleteSet-cancelbtn" @click="showConfirmDeleteSet = false">{{ $t("gptBox['取消']") }}
        </div>
      </div>
    </div>

    <div class="gptBox-card" v-show="!showConfirmDeleteSet">
      <div class="close-btn" v-show="!showEditActionsView" @click="smallGptBox()">
        <svg-icon class="hideIRSArrow-icon" icon-class="hideIRSArrow" />
      </div>

      <div class="gptBox-title" v-show="!showEditActionsView">{{ $t('gptBox["Gpt工具"]') }}</div>
      <div class="gptBox-title" v-show="showEditActionsView">
        <div class="back-btn" @click="closeEditActions()"><svg-icon icon-class="previous" class="back-icon" /></div>
        {{ $t('gptBox["Gpt工具"]') }} &nbsp;{{ `-${$t('gptBox["管理指令"]')}` }}


      </div>
      <!--一般畫面-->
      <div class="gptBox-wrap" v-show="!showEditActionsView">
        <div class="gptBox-left">
          <p class="text-wrap">{{ currentText }}</p>
          <div class="gptBox-subtitle">
            <div class="select-wrap">
              <Select v-model="currentUseSetId" :placement="'top'" :transfer="true" @on-change="setCurrentUseSet()">
                <Option v-for="(opt, index) in actionSet" :value="opt.setId" :key="index + opt.setName">{{ opt.setName }}
                </Option>
              </Select>
            </div>

            <div class="edit-btn" @click="openEditActions()">
              <font-awesome-icon icon="pencil-alt" />
            </div>
          </div>
          <div class="default-actbtn-wrap">
            <div class="default-actbtn" v-for="(actItem, index) in currentActionListItem.actionList"
              :key="actItem + index" @click="triggerGPT(actItem.value)" v-show="actItem.value !== ''"
              :class="{ 'self-actbtn': index >= 2 }">
              {{ actItem.value }}
            </div>
            <div class="noData" v-show="isCurrentUseSetEmpty()">
              <p><svg-icon icon-class="empty-data" class="empty-icon" /><br />{{ $t('gptBox["暫無數據"]') }}</p>
            </div>
          </div>
        </div>


        <div class="gptBox-right" id="GptToolBox">
          <div v-show="$parent.isWaitingGptFeedbackDone&&!cancelWaitingGPTRes" style="text-align:center" >
              <img src='/loading2.gif' />
              <br>
              <div v-show="showCancelBtn" class="cancel-btn" @click="cancelTriggerOpenAI()">{{ $t("gptBox['取消']") }}</div>
          </div>
          <div v-show="!$parent.isWaitingGptFeedbackDone" style="text-align: left; ">
            <pre v-html="currentGPTAns" style="white-space: pre-wrap"></pre>
          </div>  
        </div>
        <div class="gptbtn-group">
          <div v-show="cancelWaitingGPTRes" class="result-btn" @click="queryFinalResult()">{{ $t('gptBox["queryResult"]') }}</div>
          <div class="copy-btn-placeholder" v-show="$parent.isWaitingGptFeedbackDone" >&nbsp;&nbsp;&nbsp;</div>
          <div class="copy-btn" v-show="!$parent.isWaitingGptFeedbackDone"  @click="copyGPTAnsToClipboard()"><svg-icon icon-class="copy" /></div>
        </div>
      </div>
      <!-- <span @click="continueGpt()">繼續生成</span>&nbsp; -->

      <!-- 編輯動作畫面-->
      <div class="action-wrap" v-show="showEditActionsView">
        <div class="action-left">
          <div class="setname-btn" :class="{ 'setname-btn-light': currentActionListItem.setId == item.setId }"
            v-for="(item, index) in actionSet" :key="item.setName + index" @click="setCurrentActionSetItem(item, index)">

            <p class='setname-btn-text'
              v-show="!item.isEditNow && currentActionListItem.setId == item.setId || currentActionListItem.setId !== item.setId">
              {{ item.setName }}</p>
            <input v-show="currentActionListItem.setId == item.setId && item.isEditNow" type="text"  @input="currentSetName=$event.target.value"
              :id="'setName' + index" />
            <div class="edit-group" v-show="currentActionListItem.setId == item.setId && !item.isEditNow">
              <div class="edit-btn" @click="openEditSetName(index)"> <font-awesome-icon icon="pencil-alt"
                  class="edit-icon" /></div>
              <div class='edit-btn' v-show="item.setId != 0" @click="showConfirmDeleteSet = true"><svg-icon
                  icon-class="trash-alt-solid" /></div>
            </div>
            <div class="edit-group edit-group-confirm"
              v-show="currentActionListItem.setId == item.setId && item.isEditNow">
              <div class="edit-btn" v-show="currentSetName.trim() != ''"  @click="closeEditSetName(index)"> <font-awesome-icon icon="check"  /></div>
              <div class='edit-btn' @click="cancelEditSetName(index)"><svg-icon class="close-bold-icon"
                  icon-class="close-bold" /></div>
            </div>
          </div>
          <div class="add-btn" @click="addNewActionSetItem()" v-show="actionSet.length < 10">
            <svg-icon icon-class="new-page" />
          </div>

        </div>
        <div class="action-right">

          <div v-for="(item, index) in currentActionListItem.actionList" :key="'actionList' + index + refreshkey"
            class="input-gpt-prompt-wrap">
            <span class="prompt-num">{{ index + 1 }}</span>
            <input type="text" @click="setCurrentEditPromptIndex(index)" v-model="item.value" maxlength="100"
              :placeholder="$t('gptBox.可輸入一段100字以內的指令')" :disabled="currentActionListItem.setId == 0 && index < 2"
              :class="{ 'disabled-input': currentActionListItem.setId == 0 && index < 2, 'self-input': currentActionListItem.setId == 0 && index >= 2 || currentActionListItem.setId !== 0 }" />
            <div class="ctrl-btn-group"
              v-show="currentEditPromptIndex == index && currentActionListItem.setId == 0 && index > 1 || currentEditPromptIndex == index && currentActionListItem.setId !== 0">
              <div class="up-btn" @click="upPrompt()"
                v-show="currentActionListItem.setId == 0 && index > 2 || currentActionListItem.setId !== 0 && index > 0">
                <svg-icon iconClass="arrow-top" class="arrow-top-icon" />
              </div>
              <div class="up-btn" @click="downPrompt()" v-show="index < currentActionListItem.actionList.length - 1">
                <svg-icon iconClass="arrow-down" class="arrow-top-icon" />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SvgIcon from '../views/SvgIcon.vue';
import { jsFn } from "@/utils/jsFn.js";
export default {
  components: { SvgIcon },
  name: "GptBox",
  data() {
    return {
      currentText: "",
      currentActionListItem: "",
      currentTempListName: "",
      actionSet: [{
        setName: this.$t('gptBox["常用指令"]'),
        setId: 0,
        isEditNow: false,
        actionList: [{ value: this.$t('gptBox["總結大意"]') }, { value: this.$t('gptBox["整理同義字"]') }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }]
      }
      ],
      showEditActionsView: false,
      showConfirmDeleteSet: false,
      currentAddcount: 0,
      currentEditPromptIndex: 0,
      refreshkey: 0,
      currentUseSetId: "",
      currentSetName:'',
      currentGPTAns:'',
      cancelWaitingGPTRes:false,
      showCancelBtn:false,
      showCancelBtnTimeOut:"",
      finalTaskId:"",
    };
  },
  computed: {
    ...mapGetters({
      getGptActionList: "preferences/getGptActionList",
    }),
  },
  async mounted() {
    if (this.getGptActionList) {
      this.actionSet[0].actionList = [...this.getGptActionList];
    }
    console.log(this.actionSet[0].actionList.length < 10);

    for (let i = 0; this.actionSet[0].actionList.length < 10; i++) {
      this.actionSet[0].actionList.push({ value: "" });
    }
    this.currentActionListItem = this.actionSet[0]
    this.currentUseSetId = 0
    //抓取存放在blob的指令集
    let blobActionList = await this.fetchCCgptActionSet()

    this.actionSet = blobActionList ? blobActionList : this.actionSet
    this.currentAddcount = this.actionSet.length - 1

  },
  watch: {
    currentText() {
      document.querySelectorAll("#gpt-answer").forEach((item) => {
        item.remove()
      })
    },
    actionSet(val) {
      // let that=this
      if (val.length == 10&&this.showEditActionsView) {
        console.log(this)
        this.$Message.warning(this.$t('gptBox["已達最大上限10個分類"]'))
      }
    }
  },
  methods: {
    smallGptBox() {
      this.$parent.showGptBox = false;
    },
    closeGptBox() {
      this.$parent.showGptBox = false;
    },
    clearShowCancelBtnTimeOut(){
      clearTimeout(this.showCancelBtnTimeOut)
      this.showCancelBtnTimeOut=""
    },
    cancelTriggerOpenAI(){
      this.cancelWaitingGPTRes = true
      this.currentGPTAns = this.$t('gptBox["emptyReply"]');
      this.$parent.isWaitingGptFeedbackDone = false;
      this.clearShowCancelBtnTimeOut()
    },
    async queryFinalResult(){
      const idToken = sessionStorage.getItem("idToken");
      this.$parent.isWaitingGptFeedbackDone = true;
      if(this.finalTaskId!=""){
        const result = await this.$api.coreApi.getAITaskResult(idToken, this.finalTaskId);
        this.$parent.isWaitingGptFeedbackDone = false;
        if (result.hasOwnProperty('completionText')){
          this.currentGPTAns = result.completionText;
        }
      }
    },
    async triggerOpenAI(question){
      let that=this
      that.currentGPTAns = ''
      that.showCancelBtn = false
      that.cancelWaitingGPTRes = false
      that.finalTaskId = ""
      try {
      const idToken = sessionStorage.getItem("idToken");
      const task = await this.$api.coreApi.createAITask(idToken, question);
      // console.log(task,"235")
      const taskId = task?.taskId;

      that.showCancelBtnTimeOut=setTimeout(() => {
        that.showCancelBtn = true
      }, 10000);

      let tryCount = 0;  // 初始化重試次數
      if(taskId) {
        that.finalTaskId = taskId
        const checkCompletionText = async () => {
          const result = await this.$api.coreApi.getAITaskResult(idToken, taskId);
          // console.log(result,"238")
          if (result.hasOwnProperty('completionText')&&!that.cancelWaitingGPTRes) {
            that.currentGPTAns = result.completionText;
            that.$parent.isWaitingGptFeedbackDone = false;
            that.clearShowCancelBtnTimeOut()
          } else if (tryCount < 9&&!that.cancelWaitingGPTRes) {
            tryCount++;
            setTimeout(checkCompletionText, 3000);  // 等待 3 秒後再次檢查
          } else {
            that.currentGPTAns = this.$t('gptBox["emptyReply"]');
            that.$parent.isWaitingGptFeedbackDone = false;
            that.clearShowCancelBtnTimeOut()
            that.cancelWaitingGPTRes = true
          }
        };

        setTimeout(checkCompletionText, 3000);  // 初始等待 3 秒後檢查
      }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },
      triggerGPT(actItem) {
        if (!this.$parent.isWaitingGptFeedbackDone) {
          let question= this.$t('gptBox["當前語系指令"]') + `${actItem}\n` + this.currentText
          this.triggerOpenAI(question)
          this.$parent.isWaitingGptFeedbackDone = true;
        } else {
          this.$Message.info(this.$t('gptBox["等候提示字"]'));
        }
      },
    copyGPTAnsToClipboard() {
      // Create an auxiliary hidden input
      var aux = document.createElement("textarea");

      aux.value = this.currentGPTAns;

      // Append the aux input to the body
      document.body.appendChild(aux);

      // Highlight the content
      aux.select();

      // Execute the copy command
      document.execCommand("copy");

      // Remove the input from the body
      document.body.removeChild(aux);

      this.$Message.info(this.$t("board.optionView['已全部複製到剪貼簿!']"));
    },

    openEditActions() {
      this.showEditActionsView = true;
      this.actionSet[0].actionList.shift();
      this.actionSet[0].actionList.shift();
      this.actionSet[0].actionList.unshift({ value: `${this.$t('gptBox["整理同義字"]')}(${this.$t('gptBox["預設"]')})` });
      this.actionSet[0].actionList.unshift({ value: `${this.$t('gptBox["總結大意"]')}(${this.$t('gptBox["預設"]')})` });
      console.log(this.actionList, "actListOpen");
    },
    async closeEditActions() {
      this.showEditActionsView = false;
      this.currentUseSetId = this.currentActionListItem.setId
      this.actionSet[0].actionList.shift();
      this.actionSet[0].actionList.shift();
      this.actionSet[0].actionList.unshift({ value: this.$t('gptBox["整理同義字"]') });
      this.actionSet[0].actionList.unshift({ value: this.$t('gptBox["總結大意"]') });
      this.$store.state.tempGptActionList = this.actionSet[0].actionList;

      let blob = new Blob([JSON.stringify(this.actionSet)], { type: "application/json" });
      blob.lastModifiedDate = new Date();
      blob.name = "CCgptActionSet.json";
      await this.$parent.uploadResourceByPath(blob, "local/CCgptActionSet");

    },
    fetchCCgptActionSet() {
      return new Promise(async (r, j) => {
        const blobinfo = this.$parent.classInfo.blob;
        const CCgptActionSetURL = blobinfo.url + "/local/CCgptActionSet/CCgptActionSet.json" + "?" + blobinfo.sas_read;
        try {
          await fetch(CCgptActionSetURL)
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              r(data);
            });
        } catch (err) {
          j(err, "fetchCCgptActionSet err");
          console.log(err, "fetchCCgptActionSet err");
        }
      });
    },
    addNewActionSetItem() {
      this.currentActionListItem.isEditNow = false
      let emptyActionList = []
      for (let i = 0; i < 10; i++) {
        emptyActionList.push({ value: "" });
      }

      this.currentAddcount++

      this.actionSet.push({
        setName: this.$t('gptBox["新增分類"]') + this.currentAddcount,
        setId: jsFn.getUUID(),
        actionList: emptyActionList,
        isEditNow: false
      })
    },
    deleteActionSetItem() {
      const index = this.actionSet.findIndex(item => item.setId == this.currentActionListItem.setId)
      if (index != -1) {
        this.actionSet.splice(index, 1)
      }
      this.showConfirmDeleteSet = false
      this.currentActionListItem = this.actionSet[0]
      this.currentUseSetId = 0
    },
    setCurrentActionSetItem(item, index) {
      this.currentActionListItem = item
      this.currentEditPromptIndex = 0
      this.currentTempListName = item.setName
      this.currentSetName=''
      this.actionSet.forEach((sub, subindex) => {
        if (subindex != index) sub.isEditNow = false
      })
    },
    openEditSetName(index) {
      this.actionSet[index].isEditNow = true
    },
    closeEditSetName(index) {
      this.actionSet[index].isEditNow = false
      const editName = document.getElementById('setName' + index).value
      this.actionSet[index].setName = editName.length > 30 ? editName.slice(0, 30) + '...' : editName
      this.currentTempListName = ""
    },
    cancelEditSetName(index) {
      this.actionSet[index].setName = this.currentTempListName
      this.actionSet[index].isEditNow = false
    },
    setCurrentEditPromptIndex(index) {
      if (this.currentActionListItem.setId == 0 && index > 1) {
        this.currentEditPromptIndex = index
      }
      else this.currentEditPromptIndex = index

    },
    swapElements(array, index1, index2) {
      const temp = array[index1];
      array[index1] = array[index2];
      array[index2] = temp;
    },
    upPrompt() {
      const index = this.actionSet.findIndex(item => item.setId == this.currentActionListItem.setId)
      console.log(index)

      if (index != -1) {
        const arr = this.actionSet[index].actionList
        this.swapElements(arr, this.currentEditPromptIndex - 1, this.currentEditPromptIndex)
        this.refreshkey++
      }

    },
    downPrompt() {
      const index = this.actionSet.findIndex(item => item.setId == this.currentActionListItem.setId)
      console.log(index)

      if (index != -1) {
        const arr = this.actionSet[index].actionList
        this.swapElements(arr, this.currentEditPromptIndex, this.currentEditPromptIndex + 1)
        this.refreshkey++
      }
    },
    setCurrentUseSet() {
      const index = this.actionSet.findIndex(item => item.setId == this.currentUseSetId)
      console.log(index)
      if (index != -1)
        this.setCurrentActionSetItem(this.actionSet[index], index)
    },
    isCurrentUseSetEmpty() {
      let counthaveContent = 0
      if (this.currentActionListItem?.actionList)
        this.currentActionListItem.actionList.forEach(item => {
          if (item.value != '') {
            counthaveContent++
          }

        })
      // console.log(counthaveContent)
      return counthaveContent == 0
    }
  },
};
</script>

<style lang="less">
@import "../assets/css/color.less";

.gptBox {
  position: absolute;
  display: block;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  // border-radius: 5px;
  transition: 0.5s;
  color: black;
  height: 100%;
  z-index: 1003;

  .gptBox-card {
    top: 8%;
    width: 70%;
    min-height: 60vh;
    transition: 0.5s;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ededed;
    font-weight: bolder;
    padding: 20px 0px 30px 0px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    z-index: 102;
    text-align: center;
    cursor: default;

    .back-btn {
      color: #3d3d3d;
      position: relative;
      display: inline-block;
      cursor: pointer;
      margin-left: -10px;
      margin-right: 5px;

      &:hover {
        color: @main-color;
      }
    }

    .close-btn {
      position: absolute;
      top: -10px;
      right: -10px;
      width: 30px;
      height: 30px;
      line-height: 32px;
      background-color: #d8d8d8;
      text-align: center;
      border-radius: 50%;
      cursor: pointer;
      z-index: 100;

      .close-icon {
        font-size: 20px;
      }
    }

    .gptBox-title {
      font-size: 16px;
      color: #3d3d3d;
      text-align: left;
      margin-left: 35px;
      margin-bottom: 10px;
    }

    .gptBox-wrap {
      display: flex;
      margin-top: 10px;

      .gptBox-left {
        flex: 4;
        margin: 10px;
        margin-left: 35px;
        max-width: 40%;
        overflow-x: hidden;

        .self-actbtn {
          color: @main-color;
          border-color: @main-color !important;
          overflow-wrap: break-word;
        }

        .text-wrap {
          color: #3d3d3d;
          background-color: #ffffff;
          border-radius: 5px;
          border: 1px solid rgb(192, 192, 192);
          text-align: left;
          padding: 10px;
          min-height: 100px;
          max-height: 100px;
          overflow: auto;
          margin-bottom: 20px;
        }

        .gptBox-subtitle {
          font-size: 16px;
          color: #3d3d3d;
          text-align: left;
          position: relative;
          display: flex;

          .select-wrap {
            flex: 9;
            margin-right: 10px;
          }

          .edit-btn {
            flex: 1;
            text-align: right;
            margin-right: 10px;
            position: relative;
            top: 2px;
            cursor: pointer;
            height: 20px;
            width: 20px;
            z-index: 999;

            &:hover {
              color: @main-color;
            }
          }
        }

        .default-actbtn-wrap {
          margin-top: 10px;
          min-height: 10vh;
          max-height: 36vh;
          overflow: auto;
          padding-right: 10px;
        }

        .default-actbtn {
          text-align: left;

          border: 1px solid black;
          border-radius: 5px;
          margin: 10px 0px;
          padding: 8px 20px;
          cursor: pointer;

          &:hover {
            background-color: darken(#ededed, 8%);
          }
        }
      }

      .gptBox-right {
        flex: 6;
        background-color: #ffffff;
        border-radius: 5px;
        border: 1px solid rgb(192, 192, 192);
        margin: 10px;
        margin-right: 30px;
        text-align: left;
        padding: 10px;
        max-height: 54vh;
        overflow: auto;
        min-height: 54vh;
        position: relative;
        .cancel-btn{
          color: black;
          background-color: #d8d8d8;
          border-radius: 5px;
          display: inline-block;
          position: relative;
          padding:5px 10px;
          top: -40px;
          cursor: pointer;
          &:hover{
            background-color: darken(#d8d8d8, 8%);
          }

        }
      }

      .gptbtn-group {
        position: absolute;
        right: 40px;
        top: 15px;
        .result-btn{
          display: inline-block;
          cursor: pointer;
          font-size: 14px;
          background-color: @btn-color;
          color: white;
          padding:2px 8px;
          border-radius: 4px;
          margin-right: 10px;
          position: relative;
          top:-1px;
          &:hover{
            background-color: darken(@btn-color, 8%);
          }
        }
        

        .copy-btn {
          position: relative;
          display: inline-block;
          cursor: pointer;
          font-size: 20px;
          padding: 4px 0px;

          &:hover {
            color: blue;
          }
        }
        .copy-btn-placeholder {
          position: relative;
          display: inline-block;
          cursor: default;
          font-size: 20px;
          padding: 4px 0px;

        }

        .continue-btn,
        .stop-btn {
          display: inline-block;
          position: relative;
          text-align: center;
          margin-right: 15px;
          cursor: pointer;

          &:hover {
            color: blue;
          }
        }

        .stop-btn {
          font-size: 18px;
          padding-bottom: 7px;
        }

        .continue-btn {
          font-size: 24px;
          top: 1px;
          margin-right: 10px;
        }
      }
    }
  }

  .deleteSet-card {
    top: 8%;
    max-width: 300px !important;
    transition: 0.5s;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ededed;
    font-weight: bolder;
    padding: 20px 20px 10px 20px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    z-index: 1050;
    text-align: center;

    .deleteSet-card-title {
      font-size: 20px;
      margin-bottom: 15px;
    }

    .warndeletemsg {
      color: red;
      margin-bottom: 10px;
    }

    .deleteSet-group {
      display: flex;
      align-items: center;

      .deleteSet-cancelbtn {
        color: #3d3d3d !important;
        background-color: #d8d8d8 !important;
      }

      .deleteSet-btn {
        cursor: pointer;
        flex: 1;
        white-space: nowrap;
        padding: 5px 10px;
        margin: 10px 5px;
        border-radius: 5px;
        text-align: center;
        background-color: #d8d8d8;

        &:first-child,
        &:nth-child(2) {
          color: white;
          background-color: @btn-color;
        }
      }
    }
  }

  .noData {
    width: 100%;
    height: auto;
    text-align: center;
    margin-top: 5vh;

    // line-height: 500px;
    font-size: 22px;
    color: rgb(61, 61, 61);

    .empty-icon {
      font-size: 72px;
      padding-bottom: 5px;
    }
  }

  .action-wrap {
    display: flex;

    .action-left {
      flex: 1;
      padding: 20px;
      padding-left: 35px;

      .edit-group-confirm {
        position: absolute;
        right:-2px;
        top: 14px !important;
      }

      .add-btn {
        cursor: pointer;
        font-size: 20px;
        stroke: black;
        fill: black !important;
        padding: 4px 8px;

        &:hover {
          * {
            fill: blue !important;
            stroke: blue !important;
          }

          background: darken(#ededed, 8%);
        }

      }

      .edit-group {

        position: absolute;
        right: -10px;
        top: 10px;

        .edit-btn {
          display: inline-block;
          margin-left: 8px;
          color: black;

          .edit-icon {
            position: relative;
            top: 1px;
          }

          &:hover {
            color: @main-color;
          }

          .close-bold-icon {
            font-size: 18px;
          }
        }
      }


      .setname-btn-light {
        color: @main-color;
        background: linear-gradient(to right, darken(#ededed, 8%) 70%, darken(#ededed, 0%));
      }

      .setname-btn {
        text-align: left;
        border-bottom: 1px solid gray;
        padding: 10px 0px;
        position: relative;
        cursor: pointer;
        font-size: 16px;
        max-width: 100%;

        .setname-btn-text {
          position: relative;
          margin-left: 10px;
          padding-right: 40px;
        }

        &:last-child,
        &:nth-child(10) {
          border-bottom: unset !important;
        }

        &:hover {
          background: linear-gradient(to right, darken(#ededed, 8%) 70%, darken(#ededed, 0%));
        }

        input[type="text"] {
          width: 80%;
          padding: 2px 10px;
          margin: 0px;
          margin-left: 5px;
          box-sizing: border-box;
          border: 2px solid gray;
          border-radius: 5px;
          font-size: 16px;


          &:focus {
            border: 2px solid @main-color;
          }
        }
      }
    }

    .action-right {
      flex: 2;
      max-height: 60vh;
      overflow: auto;
      padding: 20px;

    }

    .input-gpt-prompt-wrap {
      position: relative;
      display: flex;
      align-items: center;

      .prompt-num {
        flex: 1;
        color: gray;
      }

      .ctrl-btn-group {
        position: absolute;
        right: 10px;
        top: 24px;
      }

      .up-btn {
        display: inline-block;
        cursor: pointer;
        margin: 0px 1px;

        .arrow-top-icon {
          stroke: gray;
          font-size: 20px;
        }

        &:hover {
          .arrow-top-icon {
            stroke: blue;
          }
        }
      }

      input[type="text"] {
        flex: 16;
        width: 100%;
        padding: 12px 20px;
        margin: 8px 0;
        box-sizing: border-box;
        border: 2px solid gray;
        border-radius: 5px;
        font-size: 16px;

        &:focus {
          border: 2px solid @main-color;
          background-color: #FFF9C4;
        }
      }

      .disabled-input {
        color: gray !important;
        background-color: #fff;
        opacity: 1 !important;
      }

      .self-input {
        color: @main-color !important;
      }
    }


  }

}
</style>
