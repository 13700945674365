<template>
  <div class="board-head-box">
      <p class="class-num" @click="clickQRcode()" v-if="classNum!=''">
          {{classNum}} <font-awesome-icon icon="share-square" />
      </p>
    <!--<ul :class="['board-head',isOpen?'drawer' : '']">-->
      <!--<li>-->
        <!--<a href="#/">-->
        <!--<i>-->
           <!--<font-awesome-icon icon="arrow-alt-circle-left" size="lg"/>-->
        <!--</i>-->
        <!--</a>-->
        <!--&lt;!&ndash;2018年下学期 707英文期中考&ndash;&gt;-->
      <!--</li>-->
      <!--<li class="tc class-num">-->
          <!--<b @click="clickQRcode()">教室编号：<span>18248 </span>-->
            <!--<font-awesome-icon icon="link"/>-->
          <!--</b>-->
          <!--<Card :styleObj="styleObj" :componentIndex="componentIndex" v-if="isShowCard"></Card>-->
      <!--</li>-->
      <!--<li class="tr">-->
        <!--&lt;!&ndash;<font-awesome-icon icon="cog"/>&ndash;&gt;-->
      <!--</li>-->
    <!--</ul>-->
    <q-fab color="amber" glossy icon="keyboard_arrow_down" direction="down">
      <q-fab-action color="amber" text-color="black" @click="clickUpload" icon="email" />
      <q-fab-action color="amber" text-color="black" @click="isOpen = !isOpen" icon="menu"/>
    </q-fab>
    <Card :styleObj="styleObj" :componentIndex="componentIndex" v-if="isShowCard"></Card>
    <!--<q-dialog v-model="alert">-->
      <!--<q-card>-->
        <!--<q-card-section>-->
          <!--<img src="../assets/img/QRcode.jpg" alt="">-->
        <!--</q-card-section>-->
      <!--</q-card>-->
    <!--</q-dialog>-->

  </div>

</template>

<script>
import Card from '../Card.vue'
export default {
  name: 'board-head',
  components: {
    Card
  },
  data () {
    return {
      classNum:this.$store.state.classNum,
      alert: false,
      value2: false,
      isOpen: false,
      componentIndex: 0,
      list: [],
      isShowCard: false,
      styleObj: {
        isShow: false,
        width: '0px',
        height: '0px',
        maxWidth: '0px',
        marginLeft: '0px',
        marginTop: '0px',
        backgroundColor: 'unset',
        boxShadow: 'unset'
      }
    }
  },
  methods: {
    // 微信 QR Code
    clickQRcode () {
      this.styleObj.isShow = true
      this.styleObj.width = 'auto'
      this.styleObj.height = 'auto'
      this.styleObj.maxWidth = 'auto'
      this.styleObj.marginLeft = '0px'
      this.styleObj.marginTop = '0px'
      this.styleObj.backgroundColor = '#fff'
      this.componentIndex = 8
      this.isShowCard = true
    },
    clickUpload () {
      this.styleObj.isShow = true
      this.styleObj.width = '800px'
      this.styleObj.height = '600px'
      this.styleObj.maxWidth = '800px'
      this.styleObj.marginLeft = '0px'
      this.styleObj.marginTop = '0px'
      this.styleObj.backgroundColor = 'transparent'
      this.componentIndex = 9
      this.isShowCard = true
    }
  }
}
</script>

<style>
  .q-fab{
    position: fixed !important;
    top:5px;
    right: 200px;
    z-index: 9999 !important;
  }
.q-btn--fab{
 width:20px !important;
  height:20px !important;
}
.q-btn--round{
  min-width:2.5em !important;
  min-height:2.5em !important;
}
.q-icon{
  font-size:24px !important;
}
 .board-head-box .material-icons{
    line-height:1.8 !important;
  }
</style>
