<template>
  <div class="flex timer">
    <Row class="timer-row timer-radio">
      <RadioGroup v-model="countType">
        <Radio label="1">{{$t("board.toolbox['倒數']")}}</Radio>
        <Radio label="0">{{$t("board.toolbox['計時']")}}</Radio>
      </RadioGroup>
    </Row>
   
    <Row class="timer-row" v-if="countType == '1'">
      <div class="timer-select" style="width:100%">
        <div class="flex flex-center row no-wrap count-time" style="display:none">{{ countTime.minStr }}:{{ countTime.secStr }}:{{ countTime.msStr }}</div>
        <div class="flex flex-center row no-wrap count-time" id="selectTime">
          <Select v-model="minuteVal" size="large" style="width:100px">
            <Option v-for="(item, index) in timeList" :value="item" :key="index">{{ item }}</Option>
          </Select>
          <span style="margin-bottom:8px">:</span>
          <Select v-model="secondVal" size="large" style="width:100px">
            <Option v-for="(item, index) in timeList" :value="item" :key="index">{{ item }}</Option>
          </Select>
        </div>
        <div class="flex timer-tools">
          <Icon :type="isStart ? 'md-pause' : 'md-play'" :title="$t('board.toolbox.開始計時')" @click="countDown" :disable="second == 0 || isStart" v-if="isStart === false" />
          <Icon type="md-pause" :title="$t('board.toolbox.暫停計時')" @click="onhandlePause" v-if="isStart === true" />
          <Icon type="md-refresh" :title="$t('board.toolbox.重置')" @click="handleReset" />
          <!-- <Icon type="md-stopwatch" title="记次"  @click="handleCountItem"  :disable="!countTime.isTiming"/> -->
        </div>
      </div>
    </Row>
    <Row class="timer-row" v-if="countType == '0'">
      <div class="flex column no-wrap" style="width:100%">
        <div class="flex flex-center row no-wrap count-time" id="countTime">{{ countTime.minStr }}:{{ countTime.secStr }}:{{ countTime.msStr }}</div>
        <div class="flex timer-tools">
          <Icon type="md-play" :title="$t('board.toolbox.開始計時')" @click="handleCountTime" :disable="countTime.isTiming" v-show="!countTime.isTiming" />
          <Icon type="md-pause" :title="$t('board.toolbox.暫停計時')" @click="handlePause" v-show="countTime.isTiming" />
          <Icon type="md-refresh" :title="$t('board.toolbox.復位')" @click="handleCountReset" />
          <Icon type="md-stopwatch" :title="$t('board.toolbox.記次')" @click="handleCountItem" :disable="!countTime.isTiming" />
        </div>
        <div class="flex column count-box no-wrap">
          <p v-for="(item, index) in countTime.countList" :key="index">{{$t("board.toolbox['第']")}}{{ index + 1 }}{{$t("board.toolbox['條記錄']")}} : {{ item }}</p>
        </div>
      </div>
    </Row>
  </div>
</template>

<script>
import audio1 from '../../assets/audio/de001.wav'
import audio2 from '../../assets/audio/buko001.wav'
export default {
  name: 'BaseTimer',
  data() {
    return {
      content: '开始计时', // 按钮里显示的内容
      total: 0, // 记录具体倒计时时间
      isShowInput: true,
      min: 0,
      max: 60,
      minute: 0,
      second: 0,
      timeleft: 0,
      timer: null,
      isStart: false,
      tab: '倒计时',
      countType: '1',
      countTime: {
        min: 0,
        sec: 0,
        ms: 0,
        timer: null,
        count: 0,
        minStr: '00',
        secStr: '00',
        msStr: '00',
        isTiming: false,
        minuteList: [],
        secondList: [],
        countList: []
      },
      minuteVal: '00',
      secondVal: '00',
      timeStartCount:0,
      timeList: []
    }
  },
  created() {
    this.timeList = this.handleTransferNumber([...Array(60).keys()])
    console.log(this.timeList)
  },
  methods: {
    countDown() {
      let that = this
      // 获取剩余时间
      this.getTimeLeft()
      this.isStart = true
      // 开启是一个定时任务, 每秒执行一次
      this.timer = setInterval(function() {
        that.timeleft--
        that.timeStartCount++
        

        // 如果剩余时间, 小于0, 执行暂停按钮的逻辑, 停止定时任务
        if (that.timeleft >= 1&&that.timeleft<6||that.timeStartCount<6) {
         that.playAudio(audio1)
        }
        if(that.timeleft==0){
          that.stopAudio(audio1)
          that.playAudio(audio2)
          
        }
        if (that.timeleft < 0) {
          that.stopAudio(audio2)
          clearInterval(that.timer)
          that.timer = null
          that.isStart = false
          console.log('倒计时结束!') // 输出弹框
          that.timeStartCount=0
          return // 终止函数
        }
        // 每一秒, 总的剩余时间变化的时候, 都会执行
        that.showTime()
      }, 1000)
    },
    onhandlePause() {
      let that = this
      this.getTimeLeft()
      that.isStart = false
      clearInterval(that.timer)
    },
    handleReset() {
      let timer = this.timer
      // 清除当前定时任务
      clearInterval(timer)
      // 清除原来的值, 还原计时器的初始值. 恢复默认的null
      this.timer = null
      this.minuteVal = '00'
      this.secondVal = '00'
      this.isStart = false
      this.timeleft = 0
    },

    // 计算剩余时间
    getTimeLeft() {
      // 计算剩余时间并返回
      this.timeleft = parseInt(this.minuteVal) * 60 + parseInt(this.secondVal)
    },

    // 显示时间, 把剩余时间拆分成, 分钟数和秒数, 分别写入到span里面
    showTime() {
      let m = parseInt(this.timeleft / 60)
      let s = parseInt(this.timeleft % 60)
      this.minuteVal = m < 10 ? '0' + m : m + ''
      this.secondVal = s < 10 ? '0' + s : s + ''
    },
    handleCountTime() {
      let that = this
      that.countTime.isTiming = true
      clearInterval(that.countTime.timer)
      that.countTime.timer = setInterval(function() {
        that.countTime.ms++
        if (that.countTime.sec == 60) {
          that.countTime.min++
          that.countTime.sec = 0
        }
        if (that.countTime.ms == 100) {
          that.countTime.sec++
          that.countTime.ms = 0
        }
        var msStr = that.countTime.ms
        if (that.countTime.ms < 10) {
          msStr = '0' + that.countTime.ms
        }
        var secStr = that.countTime.sec
        if (that.countTime.sec < 10) {
          secStr = '0' + that.countTime.sec
        }
        var minStr = that.countTime.min
        if (that.countTime.min < 10) {
          minStr = '0' + that.countTime.min
        }
        that.countTime.minStr = minStr
        that.countTime.secStr = secStr
        that.countTime.msStr = msStr
      }, 10)
    },
    handlePause() {
      let that = this
      that.countTime.isTiming = false
      clearInterval(that.countTime.timer)
    },
    handleCountReset() {
      let that = this
      clearInterval(that.countTime.timer)
      that.countTime.isTiming = false
      that.countTime.ms = 0
      that.countTime.sec = 0
      that.countTime.min = 0
      that.countTime.count = 0
      that.countTime.minStr = '00'
      that.countTime.secStr = '00'
      that.countTime.msStr = '00'
      that.countTime.countList = []
    },
    handleCountItem() {
      this.countTime.countList.push(document.getElementById('countTime').innerText)
    },
    handleTransferNumber(arr) {
      let newArr = []
      arr.forEach(item => {
        item = item > 9 ? item + '' : '0' + item
        newArr.push(item)
      })
      return newArr
    },
    playAudio(sound) {
     let audio = new Audio(sound)
      var isPlaying = audio.currentTime > 0 && !audio.paused && !audio.ended && audio.readyState > video.HAVE_CURRENT_DATA

      if (!isPlaying) {
        audio.play()
      }
    },
    stopAudio(sound) {
      let audio = new Audio(sound)
     
       var isPlaying = audio.currentTime > 0 && !audio.paused && !audio.ended && audio.readyState > video.HAVE_CURRENT_DATA

      if (isPlaying) {
        audio.pause()
        audio.currentTime = 0
      }
      
    }
  }
}
</script>

<style scoped>
.timer {
  justify-content: center;
  align-items: center;
}

.timer .timer-row {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.timer-radio .ivu-radio-wrapper {
  font-size: 22px;
  margin-left: 15px;
}
</style>

<style>
.timer-radio .ivu-radio-inner {
  width: 20px;
  height: 20px;
  border-width: 2px;
  border-color: #333 !important;
}

.timer-radio .ivu-radio-inner:after {
  width: 10px;
  height: 10px;
  top: 3px;
  left: 3px;
  background-color: #000;
}

.count-time {
  font-size: 50px;
  margin-top: 20px;
  border-radius: 20px;
  background: rgb(255, 255, 255);
}

.timer-tools {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.timer-tools .ivu-icon {
  font-size: 34px;
  margin: 0 10px;
  cursor: pointer;
}

.timer-select .ivu-select {
  height: 60px;
  color: #000;
}

.timer-select .ivu-select-selection {
  height: 60px !important;
  line-height: 60px;
  border: none;
  background: transparent;
  box-shadow: none !important;
}

.timer-select .ivu-select-selection .ivu-select-selected-value {
  font-size: 50px !important;
  line-height: 50px !important;
  height: 50px !important;
  padding-left: 17px !important;
  padding-top: 4px;
}

.timer-select .ivu-select-arrow {
  display: none !important;
}

.timer-select .ivu-select-visible .ivu-select-selection {
  border: none !important;
}

.timer-select .ivu-select-item {
  padding: 7px 40px 8px !important;
}

.timer .count-box {
  height: 53px;
  width: 172px;
  margin: 0 auto;
  align-items: center;
  overflow: auto;
}
</style>
