<template>
  <div class="editmemberlist-view" v-show="showEditMemberList">
    <!--編輯單個學生-->
    <div class="editmemberlist-card editstudent-card" v-if="showEditStudent">
      <p class="editstudent-title">{{ editStudentMode == "edit" ? $t("memberlist['編輯學生']") : $t("memberlist['新增學生']") }}</p>
      <div class="input-wrap">
        <label> {{ $t("memberlist['姓名']") }}</label
        ><input type="text" v-model="tempEditName" maxlength="50" />
      </div>
      <div class="input-wrap">
        <label> {{ $t("memberlist['座號']") }}</label
        ><input type="number" v-model="tempEditSeatID" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" @keydown="checkUserKeyboardInputSeatID($event)" min="1" max="999" maxlength="3" />
      </div>
      <div class="errmsg" v-show="showStudentErrMsgNum != 0">
        <p v-show="showStudentErrMsgNum == 1">{{ $t("memberlist['座號重複']") }}</p>
        <p v-show="showStudentErrMsgNum == 2">{{ $t("memberlist['座號不可為空或輸入無法辨認的值']") }}</p>
        <p v-show="showStudentErrMsgNum == 3">{{ $t("memberlist['姓名不可為空']") }}</p>
      </div>
      <div class="editmemberlistbtn-group">
        <div class="editmemberlist-btn" @click="updateStudent()">{{ $t("memberlist['確定']") }}</div>
        <div class="editmemberlist-btn editmemberlist-cancelbtn" @click="cancelEditStudent()">{{ $t("memberlist['取消']") }}</div>
      </div>
    </div>
    <!--修改名單名稱-->
    <div class="editmemberlist-card editstudent-card" v-show="showEditListName">
      <p class="editstudent-title">{{ $t("memberlist['修改名單名稱']") }}</p>
      <div class="input-wrap edit-listname"><input type="text" v-model="tempEditListName" maxlength="50" /></div>
      <div class="errmsg" v-show="showEditListNameMsg">{{ $t("memberlist['表單名稱不可為空']") }}</div>
      <div class="editmemberlistbtn-group">
        <div class="editmemberlist-btn" @click="updateListName()">{{ $t("memberlist['確定']") }}</div>
        <div class="editmemberlist-btn editmemberlist-cancelbtn" @click="cancelEditListName()">{{ $t("memberlist['取消']") }}</div>
      </div>
    </div>

    <!--確認刪除名單-->
    <div class="editmemberlist-card editstudent-card" v-show="showCorfirmDelete">
      <p class="editstudent-title">{{ $t("memberlist['刪除']") }}{{ $parent.currentMemberList.listName }}</p>
      <p class="warndeletemsg">{{ $t("memberlist['確定刪除後這個名單將無法復原']") }}</p>
      <div class="editmemberlistbtn-group">
        <div class="editmemberlist-btn" @click="deleteMemberList()">{{ $t("memberlist['確定']") }}</div>
        <div class="editmemberlist-btn editmemberlist-cancelbtn" @click="showCorfirmDelete = false">{{ $t("memberlist['取消']") }}</div>
      </div>
    </div>

    <!--自訂學生名單總覽-->
    <div class="editmemberlist-card" v-show="!showAddMemberList && !showEditStudent && !showCorfirmDelete && !showEditListName">
      <div class="closebtn" @click="closeEditMemberList()"><img src="@/statics/iconsvg/interaction/Close.svg" class="q-icon" /></div>
      <p class="editmemberlist-title">{{ $t("memberlist['自訂學生名單']") }}</p>
      <!--選單控制按鈕外部-->
      <div class="editmemberlist-rightbtns" v-show="!isCustomMemberlistEmpty()">
        <div class="edit-btn" @click="openAddMemberList()"><svg-icon icon-class="new-page" /></div>
        <div class="edit-btn" @click="openEditListName()" v-show="Object.keys($parent.currentMemberList).length > 0"><font-awesome-icon icon="pencil-alt" /></div>
        <div class="delete-btn" @click="showCorfirmDelete = true"><svg-icon icon-class="trash-alt-solid" /></div>
      </div>
      <div class="editmemberlist-empty" v-show="isCustomMemberlistEmpty()">
        <div @click="openAddMemberList()"><svg-icon icon-class="new-page" class="add-icon" /><br />{{ $t("memberlist['創建名單']") }}</div>
      </div>
      <div class="memberlist-wrap" v-if="!isCustomMemberlistEmpty()">
        <div class="listitems">
          <div class="listitem" v-for="(list, index) in $parent.customMemberlist" :key="list.listName + index" @click="setCurrentMemberList(list)" :class="{ 'listitem-light': $parent.currentMemberList.listID == list.listID }">
            {{ list.listName }}
          </div>
        </div>
        <div class="listDetail" :style="{ 'min-height': tableHeight + 'px' }">
          <Table :fixed-header="true" @on-row-click="setEditStudent" :row-class-name="hintSelectedRow" v-show="Object.keys($parent.currentMemberList).length >= 0" :height="tableHeight - 41" :no-data-text="`<p>${$t('memberlist.暫無數據')}</p>`" :columns="columnsTitle" :data="$parent.currentMemberList.list"></Table>
          <!--選單控制按鈕內部-->
          <div class="editmemberlist-bottombtns" v-show="!isCustomMemberlistEmpty()">
            <div class="edit-btn" @click="addStudent()"><svg-icon icon-class="new-page" /></div>
            <div class="edit-btn" v-show="Object.keys(currentEditStudent).length !== 0" @click="editStudent()"><font-awesome-icon icon="pencil-alt" /></div>
            <div class="delete-btn" v-show="Object.keys(currentEditStudent).length !== 0" @click="deleteStudent()"><svg-icon icon-class="trash-alt-solid" /></div>
          </div>
        </div>
      </div>
      <div class="errmsg" v-if="showAddListReachMax">{{ $t("memberlist['自訂名單已達上限提示字']") }}</div>
      <div class="errmsg" v-if="showAddStudentReachMax">{{ $t("memberlist['新增單筆名單人數達上限提示字']") }}</div>
    </div>
    <!--新增學生名單-->
    <div class="editmemberlist-card addmember-card" v-show="showAddMemberList && !showEditStudent">
      <div v-show="showEditHint">
        <div class="edit-tooltip-triangle"></div>
        <div class="edit-tooltip">
          <div class="close-btn" @click="showEditHint = false"><svg-icon icon-class="Close" /></div>
          <p v-html="hintInfo"></p>
          <div class="tooltip-btn" @click="useSampleList()">{{ $t("memberlist.複製範例") }}</div>
        </div>
      </div>

      <input type="text" class="add-title" v-model="currentEditMemberListName" :placeholder="$t('memberlist.新建的學生名單')" maxlength="50" />
      <div class="example-btn" @click="showEditHint = !showEditHint"><svg-icon icon-class="question" /></div>
      <div class="addmember-area">
        <textarea class="left-part" v-model="addmemberlisttext" name="addmember" id="" cols="30" rows="10" :placeholder="$t('memberlist.輸入名單資料')"></textarea>
        <div class="right-part">
          <Table :fixed-header="true" :height="tableHeight" :no-data-text="`<p>${$t('memberlist.暫無數據')}</p>`" :columns="columnsTitle" :data="tempAddMemberList" :row-class-name="hintBugRow"></Table>
        </div>
      </div>
      <div v-show="addMemberListErrMsg.length > 0" class="errmsg">
        <div v-for="text in addMemberListErrMsg" :key="text">
          {{ text }}
        </div>
      </div>
      <div></div>
      <div v-show="showAddMemberAutoSeatIDMsg" class="hintmsg">
        {{ $t("memberlist.自動編號提示字") }}
      </div>
      <div class="editmemberlistbtn-group">
        <div class="editmemberlist-btn" @click="checkAddMemberList()">{{ $t("memberlist.預覽") }}</div>
        <div class="editmemberlist-btn" @click="savecustomMemberlist()" :class="{ 'editmemberlist-disabledBtn': addMemberListErrMsg.length > 0 || currentEditMemberListName == '' || addmemberlisttext == '' || tempAddMemberList.length == 0 }">{{ $t("memberlist.儲存預覽表格") }}</div>
        <div class="editmemberlist-btn" @click="showAddMemberList = false">{{ $t("memberlist['取消']") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditMemberList",
  data() {
    return {
      hintInfo: this.$t("memberlist['名單範例模板']"),
      showEditMemberList: false,
      showAddMemberList: false,
      showEditStudent: false,
      currentEditMemberListName: "",
      addmemberlisttext: "",
      columnsTitle: [
        {
          title: this.$t("memberlist['姓名']"),
          key: "memberName",
          sortable: true,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: this.$t("memberlist['座號']"),
          key: "seatID",
          sortable: true,
        },
      ],
      tempAddMemberList: [],
      addMemberListErrMsg: [],
      showAddMemberAutoSeatIDMsg: false,
      addMemberListErrRow: [], // 存放預覽有問題的行數
      editMemberListMode: "Init", //Init or Menu
      showEditHint: false,
      currentEditStudent: {},
      tempEditName: "",
      tempEditSeatID: -1,
      tempEditListName: "",
      editStudentMode: "edit",
      showStudentErrMsgNum: 0,
      showCorfirmDelete: false,
      showEditListName: false,
      showEditListNameMsg: false,
      showAddListReachMax: false,
      showAddStudentReachMax: false,
    };
  },
  computed: {
    tableHeight() {
      return window.innerHeight * 0.5 < 250 ? 250 : window.innerHeight * 0.5;
    },
  },
  methods: {
    isCustomMemberlistEmpty() {
      if (this.$parent.customMemberlist) {
        return this.$parent.customMemberlist?.length == 0;
      } else return true;
    },
    checkUserKeyboardInputSeatID(e) {
      if (e.keyCode == 69 || (this.hasNonNumeric(e.key) && e.keyCode !== 8)) e.preventDefault();
    },
    setCurrentMemberList(list) {
      this.$parent.currentMemberList = list;
      this.$parent.memberList = Object.keys(this.$parent.currentMemberList).length == 0 ? [] : this.$parent.currentMemberList.list;
      this.$parent.verifyMode = Object.keys(this.$parent.currentMemberList).length == 0 ? "Dynamic" : "Fixed";
      this.currentEditStudent = {};
    },
    openEditMemberListAtInit() {
      this.editMemberListMode = "Init";
      this.showEditMemberList = true;
    },
    openEditMemberList() {
      this.editMemberListMode = "Menu";
      this.showEditMemberList = true;
      this.Hishow = false;
    },
    openAddMemberList() {
      this.showAddListReachMax = false;
      if (this.$parent.customMemberlist.length < 3) {
        let autolength = this.$parent.customMemberlist ? this.$parent.customMemberlist.length : 0;
        this.showAddMemberList = true;
        this.tempAddMemberList = [];
        this.addMemberListErrRow = [];
        this.addMemberListErrMsg = [];
        this.currentEditMemberListName = this.$t("memberlist['自訂名單']") + (autolength + 1);
        this.currentEditMemberList = {};
        this.addmemberlisttext = "";
        this.showAddMemberAutoSeatIDMsg = false;
      } else {
        this.showAddListReachMax = true;
      }
    },
    async closeEditMemberList() {
      this.showEditMemberList = false;
      this.showAddMemberList = false;
      if(this.$parent.isEditMemberListAtInit){
        this.$parent.modalUpload=true
      }
      let blob = new Blob([JSON.stringify(this.$parent.customMemberlist)], { type: "application/json" });
      blob.lastModifiedDate = new Date();
      blob.name = "CCroster.json";
      await this.$parent.uploadResourceByPath(blob, "local/CCroster");
      this.$parent.isEditMemberListAtInit=false
    },
    fetchCCroster() {
      return new Promise(async (r, j) => {
        const blobinfo = this.$parent.classInfo.blob;
        const CCrosterURL = blobinfo.url + "/local/CCroster/CCroster.json" + "?" + blobinfo.sas_read;
        try {
          await fetch(CCrosterURL)
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              r(data);
            });
        } catch (err) {
          j(err, "fetchCCroster err");
          console.log(err, "fetchCCroster err");
        }
      });
    },
    hasDuplicates(arr) {
      return arr.some((x) => arr.indexOf(x) !== arr.lastIndexOf(x));
    },
    setAddMemberErrMsg(Msg) {
      if (this.addMemberListErrMsg.indexOf(Msg) == -1) {
        this.addMemberListErrMsg.push(Msg);
      }
    },
    hasNonNumeric(str) {
      return /[^0-9]/.test(str); //匹配除了 0 到 9 的数字以外的任何字符
    },
    checkAddMemberList() {
      this.tempAddMemberList = [];
      this.addMemberListErrRow = [];
      this.addMemberListErrMsg = [];
      let data = this.addmemberlisttext
        .replaceAll("\n", ",")
        .split(",")
        .filter((item) => item != "");
      data = data.map((item) => item.trim());
      let isAutoSeatID = false;
      this.showAddMemberAutoSeatIDMsg = false;

      //檢測座號是否皆為數字，部分有部分沒有就直接自動編號
      if (this.currentEditMemberListName == "") this.setAddMemberErrMsg(this.$t("memberlist['表單名稱不可為空']"));
      if (data.length % 2 == 1) {
        isAutoSeatID = true;
      } else {
        data.forEach((item, index) => {
          const pointIndex = item.indexOf(".");
          const zeroIndex = item.indexOf("0");
          if (index % 2 == 1 && (zeroIndex == 0 || pointIndex != -1 || this.hasNonNumeric(item))) {
            //座號第一位數字非0、不可帶有小數點、須為數字
            isAutoSeatID = true;
          }
        });
      }
      // console.log(isAutoSeatID,'isAutoSeatID')
      if (!isAutoSeatID) {
        //檢測座號是否重複
        const seatIDs = data.filter((item, index) => index % 2 == 1);
        // console.log(seatIDs)
        if (this.hasDuplicates(seatIDs)) {
          this.setAddMemberErrMsg(this.$t("memberlist['帶有重複座號']"));

          let firstAppearID = [];
          seatIDs.forEach((id, index) => {
            const itemIndex = firstAppearID.findIndex((item) => item.id == id);
            if (itemIndex == -1) firstAppearID.push({ id: id, count: 1, index: index });
            else {
              seatIDs.forEach((subId) => {
                if (id == subId) {
                  this.addMemberListErrRow.push(index);
                }
              });
            }
          });
          // console.log(firstAppearID)
        }
        data.forEach((item, index) => {
          //檢測座號是否<=999
          // console.log(index%2)
          if (index % 2 == 1 && parseInt(item) > 999) {
            this.setAddMemberErrMsg(this.$t("memberlist['座號範圍提示字']"));
            this.addMemberListErrRow.push(parseInt(index / 2));
          }
          if (index % 2 == 1 && !isNaN(parseInt(item))) {
            this.tempAddMemberList.push({
              seatID: item,
              memberID: "member" + new Date().getTime() + index,
              memberName: data[index - 1],
            });
          }
        });
      } else {
        this.showAddMemberAutoSeatIDMsg = true;
        data.forEach((item, index) => {
          if (item) {
            this.tempAddMemberList.push({
              seatID: index + 1,
              memberID: "member" + new Date().getTime() + index,
              memberName: data[index],
            });
          }
        });
      }

      if (this.tempAddMemberList.length > 100) {
        this.setAddMemberErrMsg(this.$t("memberlist['名單人數上限提示字']"));
      }
    },
    savecustomMemberlist() {
      this.$parent.customMemberlist = this.$parent.customMemberlist ? this.$parent.customMemberlist : [];
      if (this.addMemberListErrMsg.length === 0 && this.addmemberlisttext != "" && this.tempAddMemberList.length != 0 && this.currentEditMemberListName != "") {
        this.$parent.customMemberlist.push({
          listName: this.currentEditMemberListName,
          list: this.tempAddMemberList,
          listID: "list" + new Date().getTime(),
        });
        this.editMemberListMode == "Init" ? this.closeEditMemberList() : (this.showAddMemberList = false);
        this.$parent.currentMemberList = this.$parent.customMemberlist[this.$parent.customMemberlist.length - 1];
      }
    },
    useSampleList() {
      this.addmemberlisttext = this.$t("memberlist['範例名單']");
    },
    deleteMemberList() {
      const deleteIndex = this.$parent.customMemberlist.findIndex((list) => list.listID == this.$parent.currentMemberList.listID);
      this.$parent.customMemberlist.splice(deleteIndex, 1);
      this.$parent.currentMemberList = {};
      this.showCorfirmDelete = false;
      this.showAddListReachMax = false;
    },
    setEditStudent(data) {
      this.currentEditStudent = data;
    },
    updateStudent() {
      const parentIndex = this.$parent.customMemberlist.findIndex((list) => list.listID == this.$parent.currentMemberList.listID);
      if (this.tempEditName.trim() == "") {
        this.showStudentErrMsgNum = 3;
        return;
      }
      if (this.tempEditSeatID == "") {
        this.showStudentErrMsgNum = 2;
        return;
      }
      if (this.editStudentMode == "edit") {
        if ((this.isSeatIDduplicate() && this.currentEditStudent.seatID == this.tempEditSeatID) || (!this.isSeatIDduplicate() && this.currentEditStudent.seatID != this.tempEditSeatID)) {
          //   console.log("純改");
          const targetId = this.$parent.customMemberlist[parentIndex].list.findIndex((stu) => stu.memberID == this.currentEditStudent.memberID);
          this.showStudentErrMsgNum = 0;
          this.$parent.customMemberlist[parentIndex].list[targetId].seatID = this.tempEditSeatID;
          this.$parent.customMemberlist[parentIndex].list[targetId].memberName = this.tempEditName;
          this.currentEditStudent = this.$parent.customMemberlist[parentIndex].list[targetId];
          this.showEditStudent = false;
        } else {
          this.showStudentErrMsgNum = 1;
        }
      } else if (this.editStudentMode == "add") {
        if (!this.isSeatIDduplicate()) {
          this.$parent.customMemberlist[parentIndex].list.push({
            seatID: this.tempEditSeatID,
            memberID: "member" + new Date().getTime(),
            memberName: this.tempEditName,
          });
          this.currentEditStudent = this.$parent.customMemberlist[parentIndex].list[this.$parent.customMemberlist[parentIndex].list.length - 1];
          this.showEditStudent = false;
        }
        this.showStudentErrMsgNum = this.isSeatIDduplicate() ? 1 : 0;
      }
    },
    isSeatIDduplicate() {
      const parentIndex = this.$parent.customMemberlist.findIndex((list) => list.listID == this.$parent.currentMemberList.listID);
      const targetId = this.$parent.customMemberlist[parentIndex].list.findIndex((stu) => stu.seatID == this.tempEditSeatID);
      //   console.log(this.$parent.customMemberlist[parentIndex].list, targetId);
      return targetId !== -1;
    },
    cancelEditStudent() {
      this.showEditStudent = false;
    },
    deleteStudent() {
      const parentIndex = this.$parent.customMemberlist.findIndex((list) => list.listID == this.$parent.currentMemberList.listID);
      const targetId = this.$parent.customMemberlist[parentIndex].list.findIndex((stu) => stu.memberID === this.currentEditStudent.memberID);
      this.$parent.customMemberlist[parentIndex].list.splice(targetId, 1);
      this.currentEditStudent = {};
      this.showAddStudentReachMax = false;
    },
    editStudent() {
      this.showStudentErrMsgNum = 0;
      this.showEditStudent = true;
      this.editStudentMode = "edit";
      this.tempEditSeatID = this.currentEditStudent.seatID;
      this.tempEditName = this.currentEditStudent.memberName;
    },
    addStudent() {
      if (this.$parent.currentMemberList.list.length < 100) {
        this.showStudentErrMsgNum = 0;
        this.showEditStudent = true;
        this.editStudentMode = "add";
        this.tempEditName = "";
        this.tempEditSeatID = "";
      } else {
        this.showAddStudentReachMax = true;
      }
    },
    hintBugRow(row, index) {
      return this.addMemberListErrRow.indexOf(index) != -1 ? "bug-row" : "";
    },
    hintSelectedRow(row) {
      return row.memberID === this.currentEditStudent.memberID ? "selected-row" : "";
    },
    openEditListName() {
      this.showEditListNameMsg = false;
      this.showEditListName = true;
      this.tempEditListName = this.$parent.currentMemberList.listName;
      this.showAddListReachMax = false;
    },
    updateListName() {
      if (this.tempEditListName != "") {
        const parentIndex = this.$parent.customMemberlist.findIndex((list) => list.listID == this.$parent.currentMemberList.listID);
        this.$parent.customMemberlist[parentIndex].listName = this.tempEditListName;
        this.showEditListName = false;
        this.showEditListNameMsg = false;
      } else {
        this.showEditListNameMsg = true;
      }
    },
    cancelEditListName() {
      this.showEditListNameMsg = false;
      this.tempEditListName = "";
      this.showEditListName = false;
    },
  },
};
</script>

<style lang="less">
@import "../assets/css/color.less";
.ivu-table .bug-row td {
  background-color: pink;
}
.ivu-table tr {
  cursor: pointer;
}
.ivu-table .selected-row td {
  background-color: @btn-color;
  color: white;
}

.editmemberlist-view {
  position: relative;
  position: fixed;
  display: block;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  // border-radius: 5px;
  color: black;
  height: 100%;
  z-index: 1016;
  .close-btn {
    cursor: pointer;
    font-size: 16px;
    color: #3d3d3d;
    position: absolute;
    right: 10px;
    top: 5px;
  }
  .example-btn {
    position: absolute;
    right: 20px;
    top: 15px;
    cursor: pointer;
    font-size: 30px;
    color: #3d3d3d;
  }
  .tooltip-btn {
    display: inline-block;
    padding: 10px;
    color: white;
    background-color: @btn-color;
    cursor: pointer;
    white-space: nowrap;
    padding: 5px 10px;
    margin: 10px 5px;
    border-radius: 5px;
    text-align: center;
  }
  .edit-tooltip {
    position: absolute;
    display: inline-block;
    background-color: rgb(254, 255, 183);
    color: black;
    width: 65%;
    top: 65px;
    right: -14%;
    text-align: left;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(112, 112, 112, 0.5);
    z-index: 999;
  }
  .edit-tooltip-triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 15px 15px;
    border-color: transparent transparent rgb(254, 255, 183) transparent;
    line-height: 0px;
    position: absolute;
    top: 50px;
    right: 5%;
    z-index: 1000;
  }

  .addmember-card {
    text-align: left !important;
  }
  .closebtn {
    cursor: pointer;
    position: absolute;
    top: -12px;
    right: -10px;
    width: 30px;
    height: 30px;
    line-height: 27px;
    background-color: #d8d8d8;
    text-align: center;
    border-radius: 50%;
    z-index: 1017;
  }

  .closebtn img {
    width: 80%;
  }
  .editstudent-card {
    max-width: 300px !important;
    .editstudent-title {
      font-size: 20px;
      margin-bottom: 15px;
    }
    .errmsg {
      text-align: left;
    }
    .warndeletemsg {
      color: red;
      margin-bottom: 10px;
    }
  }
  .editmemberlist-card {
    top: 8%;
    max-width: 500px;
    transition: 0.5s;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ededed;
    font-weight: bolder;
    padding: 20px 20px 10px 20px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    z-index: 1050;
    text-align: center;
    .edit-listname {
      text-align: center !important;
    }
    .input-wrap {
      text-align: left;
      padding: 5px 10px 10px 5px;
      line-height: 30px;
      label {
        margin: 10px 0px;
      }
      input {
        font-size: 16px;
        padding: 5px;
        border-radius: 4px;
        border: 2px solid gray;
        width: 100%;
        text-align: center;
        &:focus {
          border: 2px solid blue;
        }
      }
    }
    .errmsg {
      margin-left: 6px;
      text-align: left;
      color: red;
      padding-bottom: 4px;
    }
    .hintmsg {
      margin-left: 6px;
      color: blue;
    }
    .editmemberlist-rightbtns {
      position: absolute;
      top: 10px;
      right: 15px;
      text-align: right;

      * {
        display: inline-block;
        &:hover {
          fill: blue !important;
          stroke: blue !important;
        }
      }
      .delete-btn {
        padding: 10px;
        font-size: 20px;
        fill: black;
        cursor: pointer;
      }
      .edit-btn {
        padding: 10px;
        font-size: 20px;
        stroke: black;
        cursor: pointer;
      }
    }
    .memberlist-wrap {
      display: flex;
      background-color: #fcfcfc;
      border-width: 2px;
      border-radius: 4px;
      margin: 10px 5px;

      .editmemberlist-bottombtns {
        position: absolute;
        width: 100%;
        bottom: 0;
        text-align: center;
        border-top: 1px solid #ccc !important;
        * {
          display: inline-block;
          &:hover {
            fill: blue !important;
            stroke: blue !important;
          }
        }
        .delete-btn {
          padding: 10px;
          font-size: 14px;
          fill: black;
          cursor: pointer;
        }
        .edit-btn {
          padding: 10px;
          font-size: 14px;
          stroke: black;
          cursor: pointer;
        }
      }
      .listitems {
        flex: 2;
        border: 1px solid #ccc !important;
        position: relative;
        width: 40%;
        .listitem-light {
          color: blue;
          border: 1px solid blue !important;
          border-bottom: 1px solid blue !important;
        }
        .listitem {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          cursor: pointer;
          background-color: #fcfcfc;
          border-width: 2px;
          border: 1px solid transparent;
          border-bottom: 1px solid #ccc;
          padding: 15px;
          font-size: 14px;
          &:hover {
            // background-color: darken(#fcfcfc, 10%);
            color: blue;
            border: 1px solid blue !important;
          }
        }
      }
      .listDetail {
        .editmemberlist-bottombtns {
          border: 1px solid #ccc !important;
          border-top: 1px solid transparent !important;
        }
        background-color: #fcfcfc;
        border-width: 2px;
        border-radius: 4px;
        position: relative;
        flex: 3;
        text-align: left;
        background-color: #fcfcfc;
        overflow: auto;
        .list-stu {
          font-size: 14px;
        }
        .list-info {
          text-align: center;
          margin-top: 10%;
          color: gray;
          font-size: 14px;
        }
      }
    }
    .editmemberlist-title {
      font-size: 20px;
      text-align: left;
      margin-left: 5px;
    }
    .add-title {
      font-size: 16px;
      text-align: left;
      margin-left: 5px;
      padding: 5px;
      border-radius: 4px;
      border: 1px solid #ccc;
      &:focus {
        border: 2px solid blue;
      }
    }
    .addmember-area {
      background-color: #fff;
      margin: 10px 5px;
      display: flex;
      .left-part {
        flex: 2;
      }
      .right-part {
        flex: 3;
        width: 100%;
      }
      textarea {
        font-size: 14px;
        border: 1px solid #ccc;
        padding: 5px;
        &:focus {
          border: 2px solid blue;
        }
      }
    }
    .editmemberlist-empty {
      background-color: #fff;
      color: #3d3d3d;
      border-radius: 5px;
      padding: 20px;
      margin: 10px 5px;
      cursor: pointer;
      .add-icon {
        stroke: #3d3d3d;
        font-size: 40px;
        margin: 10px;
      }
    }
    .editmemberlistbtn-group {
      display: flex;
      align-items: center;
      .editmemberlist-disabledBtn {
        cursor: not-allowed !important;
        color: rgba(128, 128, 128, 0.3) !important;
        background-color: #d8d8d8 !important;
        &:hover {
          color: rgba(128, 128, 128, 0.3);
        }
      }
      .editmemberlist-cancelbtn {
        color: #3d3d3d !important;
        background-color: #d8d8d8 !important;
      }
      .editmemberlist-btn {
        cursor: pointer;
        flex: 1;
        white-space: nowrap;
        padding: 5px 10px;
        margin: 10px 5px;
        border-radius: 5px;
        text-align: center;
        background-color: #d8d8d8;
        &:first-child,
        &:nth-child(2) {
          color: white;
          background-color: @btn-color;
        }
      }
    }
  }
}
</style>
