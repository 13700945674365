<template>
  <div class="addImgBox" :class="{ 'no-mask': currentTab == 'tab4' }">
    <div class="addImgBox-card">
      <div class="close-btn" @click="cancelSetBg()"><svg-icon icon-class="Close" class="close-icon" /></div>
      <Tabs v-model="currentTab" @on-click="handleTabShortcut">
        <TabPane :label="$t('board.addImgBox.檔案圖片')" name="tab1">
          <label class="img_upload-btn" @click="addImgMode">
            <svg-icon class="img_upload-icon" iconClass="add_img" />
            <input @click="resetAddImageUploader()" ref="addImageUploader" type="file" id="addimgfile" accept="image/png, image/gif, image/x-png,image/jpeg" @change="addImgObj" />
          </label>
          <div class="img_upload-text">{{ $t("board.addImgBox['選取本機檔案圖片']") }}</div>
        </TabPane>
        <TabPane :label="$t('board.addImgBox.剪貼簿')" name="tab2">
          <div class="tab2-wrap">
            <div class="paste-btn-wrap">
              <div @click="getClipboardData()" class="paste-btn"><svg-icon icon-class="refresh" class="refresh-icon" />{{ $t("board.addImgBox['取得複製到剪貼簿的資料']") }}</div>
            </div>

            <div id="clipboard-data" v-show="clipboardImg == '' && clipboardData != ''" v-html="clipboardData" />
            <div id="clipboard-noData" v-show="clipboardData == '' && clipboardImg == ''">
              <p><svg-icon icon-class="empty-data" class="empty-icon" /><br />{{ $t("board.addImgBox['暫無數據']") }}</p>
            </div>
            <div id="clipboard-img" v-show="clipboardImg != '' && clipboardData == ''">
              <img :src="clipboardImg" />
            </div>

            <div @click="pastContent()" class="paste-btn"><svg-icon icon-class="HTPaste-white" class="htpaste-icon" />{{ $t("board.addImgBox['轉換物件貼回']") }}</div>
            <div @click="sendText()" class="paste-btn" v-show="clipboardImg == ''"><svg-icon icon-class="message" class="message-icon" />{{ $t("board.addImgBox['發送文字']") }}</div>
          </div>
        </TabPane>
        <TabPane :label="$t('board.addImgBox.素材庫')" name="tab3">
          <div class="tab3-wrap">
            <div class="imgitem-wrap" v-for="(image, index) in images" :key="index" @click="addOpenClipArt(image.pathLong)">
              <img v-lazy="image.pathLong" alt="" />
            </div>
          </div>
        </TabPane>
        <TabPane :label="$t('board.addImgBox.背景庫')" name="tab4">
          <div class="bgColors-wrap">
            <div @click="previewBgColor(item)" class="bgColors-item" v-for="(item, index) in bgColors" :style="{ 'background-color': item }" :key="index + 'c'"></div>
            <input type="color" v-model="freecolor" />
          </div>
          <div class="divide-wrap">
            <div class="divide-line" />
          </div>
          <div class="tab4-wrap">
            <div class="imgitem-wrap" v-for="(image, index) in bgImages" :key="index" @click="previewBgImg(image.pathLong)">
              <img v-lazy="image.pathLong" alt="" />
            </div>
          </div>
          <div @click="confirmSetBg()" class="paste-btn">{{ $t('board.addImgBox.確定') }}</div>
          <div @click="cancelSetBg()" class="cancel-btn">{{ $t('board.addImgBox.取消') }}</div>
        </TabPane>
      </Tabs>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AddImgBox',
  computed:{
     ...mapGetters({
      classInfo: 'classInfo/getInfo', // 取得課堂設定
    }),
  },
  created() {},
  mounted() {},
  data() {
    return {
      currentTab: 'tab1',
      clipboardImg: '',
      clipboardData: '',
      clipboardmixImgs: [], //跨域大部分圖片無法入canvas
      clipboardmixText: '',
      clipboardHtmlData: '',
      currentClipBoardDataType: '',
      showClipboardHint: '',
      images: [],
      bgImages: [],
      bgColors: ['#ffcdd2', '#BBDEFB', '#C8E6C9', '#FFF9C4', '#f9f9f9'],
      freecolor: '#C9C9C9',
      selectBgImgItemPath: '',
      selectBgImgColor: ''
    }
  },
  mounted() {
    this.importAll(require.context('../assets/HT_resource/Openclipart', true, /\.png$/), this.images)
    this.importAll(require.context('../assets/HT_resource/Backgrounds', true, /\.jpg$/), this.bgImages)
    let that=this
    document.addEventListener('click', function(event){
      that.$parent.showRightClickMenu=false
    })
    document.addEventListener('contextmenu', function(event) {
      // 阻止默认的右键菜单行为
      event.preventDefault();
      that.$parent.showRightClickMenu=true
      console.log(event)
      that.$parent.rightClickMenuPos= { x: event.clientX, y: event.clientY}
    });

    document.addEventListener('paste',()=> {
      const isShowTextAnsPop=this?.$optionView?.showTextAnspop
      if(!this.$store.state.showtextInuptCard&&!isShowTextAnsPop&&!this.$parent.$refs.gptBox.showEditActionsView&&!this.$parent.$refs.editMemberList.showEditMemberList){
      this.$store.state.mode = 'check'
      this.pasteFromClipboard()
    }
    })
  },
  watch: {
    freecolor(value) {
      this.previewBgColor(value)
    }
  },
  beforeDestroy() {
    ;(this.clipboardImg = ''), (this.clipboardData = ''), (this.clipboardmixImgs = []), (this.clipboardmixText = ''), (this.clipboardHtmlData = ''), (this.currentClipBoardDataType = ''), (this.showClipboardHint = '')
  },
  methods: {
    pasteFromClipboard(){
      this.getClipboardData()
      this.$nextTick(()=>{
        setTimeout(()=>{
          this.pastContent()
        },500)
      })
    },
    handleTabShortcut() {
      this.addImgMode()
      if (this.currentTab == 'tab2') {
        this.getClipboardData()
      }
      if (this.currentTab == 'tab1') {
        document.getElementById('addimgfile').click() //切到Tab1 自動彈出file input
      }
    },
    destroyPreviewBg(layer) {
      layer.find('Rect').forEach(function(ele, i) {
        if (ele.attrs.name == 'tempPreviewbgRect') {
          ele.destroy()
        }
      })

      layer.find('Image').forEach(function(ele, i) {
        if (ele.attrs.name == 'tempPreviewBg') {
          ele.destroy()
        }
      })
    },
    ratioCalc(image, layer) {
      let ratio = 0

      if (image.width() > layer.width() && image.height() < layer.height()) {
        ratio = layer.width() / image.width()
      } else if (image.width() < layer.width() && image.height() > layer.height()) {
        ratio = layer.height() / image.height()
      } else if (image.width() < layer.width() && image.height() < layer.height()) {
        ratio = layer.width() / image.width() < layer.height() / image.height() ? layer.width() / image.width() : layer.height() / image.height()
      } else {
        //1.判斷要縮哪一邊
        let imgWidthMinusCanvasWidth = image.width() - layer.width()
        let imgHeightMinusCanvasHeight = image.height() - layer.height()
        //console.log(imgWidthMinusCanvasWidth,imgHeightMinusCanvasHeight)
        if (imgWidthMinusCanvasWidth > imgHeightMinusCanvasHeight) ratio = layer.width() / image.width()
        else {
          ratio = layer.height() / image.height()
        }
      }
      return ratio
    },
    importAll(r, target) {
      r.keys().forEach(key => target.push({ pathLong: r(key), pathShort: key }))
    },
    addImgMode() {
      this.$store.state.mode = 'check'
    },
    resetAddImageUploader() {
      this.$refs.addImageUploader.value = ''
    },
    //上傳本地所選檔案圖片
    async addImgObj(e) {
      let that = this
      let blobUrl = this.classInfo.blob
      let stage = this.$store.state.stage
      let layer = this.$store.state.layer

      let times = new Date().getTime()
      let fileName = e.target.files[0].name
      let compressFile

      let fileReader = new FileReader()
      fileReader.readAsDataURL(e.target.files[0])
      fileReader.onload = e => {
        let imageObj = new Image()
        imageObj.setAttribute('crossOrigin', 'Anonymous')
        imageObj.src = e.target.result
        imageObj.onload = async function() {
          console.log(imageObj.width, imageObj.height)

          let ratio = 0

          if (imageObj.width > 1024 && imageObj.height < 1024) {
            ratio = 1024 / imageObj.width
          } else if (imageObj.width < 1024 && imageObj.height > 1024) {
            ratio = 1024 / imageObj.height
          } else if (imageObj.width < 1024 && imageObj.height < 1024) {
            ratio = 1024 / imageObj.width < 1024 / imageObj.height ? 1024 / imageObj.width : 1024 / imageObj.height
          } else {
            //1.判斷要縮哪一邊
            let imgWidthMinusCanvasWidth = imageObj.width - 1024
            let imgHeightMinusCanvasHeight = imageObj.height - 1024
            //console.log(imgWidthMinusCanvasWidth,imgHeightMinusCanvasHeight)
            if (imgWidthMinusCanvasWidth > imgHeightMinusCanvasHeight) ratio = 1024 / imageObj.width
            else {
              ratio = 1024 / imageObj.height
            }
          }

          const canvas = document.createElement('canvas')
          const context = canvas.getContext('2d')

          const originalWidth = imageObj.width
          const originalHeight = imageObj.height

          const canvasWidth = originalWidth * ratio
          const canvasHeight = originalHeight * ratio

          canvas.width = canvasWidth
          canvas.height = canvasHeight

          context.drawImage(imageObj, 0, 0, canvasWidth, canvasHeight)
          console.log(canvasWidth, canvasHeight, '壓縮後的圖片長寬')
          compressFile = await that.dataURLtoFile(canvas.toDataURL('image/jpeg', 1), fileName)

          let size = parseFloat(compressFile.size / 1000000).toFixed(4)
          console.log(size, 'MB,壓縮後的圖片大小')
          if (size <= 3) {
            that.$q.loading.show({
              message: that.$t('board.Uploading')
            })
            //iPad選照片拍照上傳永遠都會給相同檔名叫做照片，所以避免被cache讀到同個給時間戳
            that.$parent.uploadChangeNameResourceFile(compressFile, times + fileName, 'ObjSrcImage').then(res => {
              that.$q.loading.hide()
              console.log(res, '上传Resource且變更檔名成功后的返回')
              let fileUrl = res.url
              let currentIndex=layer?.children ? JSON.parse(JSON.stringify(layer?.children)).length : 1
              // console.log(fileUrl)
              // console.log(stage)
              Konva.Image.fromURL(fileUrl + '?' + blobUrl.sas_read, function(image) {
                let scaleRatio = image.width() > stage.width() ? stage.width() / image.width() / 2 : 0.8
                // image is Konva.Image instance
                image.setAttrs({
                  x:stage.getAbsoluteTransform().copy().invert().point({x:stage.width()/2,y:stage.height()/2}).x,
                  y:stage.getAbsoluteTransform().copy().invert().point({x:stage.width()/2,y:stage.height()/2}).y,
                  width: image.width() * scaleRatio,
                  height: image.height() * scaleRatio,
                  draggable: true,
                  src: fileUrl,
                  uuid:that.$jsFn.getUUID(),
                  index: currentIndex
                })

                // stage.find('Transformer').destroy()
                let tr = new Konva.Transformer({
                  anchorStroke: '#00a6ff',
                  anchorFill: '#fff',
                  anchorSize: 12,
                  anchorCornerRadius: 5,
                  anchorStrokeWidth: 2,
                  borderStroke: '#6ac9fc',
                  borderStrokeWidth: 2,
                  borderDash: [3, 3],
                  padding: 10,
                  name: 'default'
                })
                that.$toolbox.removeTransformer()
                

                layer.add(image)
                that.$toolbox.saveUndoHistory('add',image)//儲存undo記錄
                layer.add(tr)
                tr.nodes([image])
                that.$parent.addMenuBtnToTr(tr, image) 
                layer.draw()
              })

              // fabric.Image.fromURL(fileUrl, function(img) {
              //   let cw = _this.canvas.getWidth()
              //   let ch = _this.canvas.getHeight()
              //   img.scaleToWidth(cw * 0.4)
              //   img.scaleToHeight(ch * 0.4)
              //   _this.canvas.add(img)
              //   _this.canvas.setActiveObject(img)
              //   _this.canvas.centerObject(img)
              // })
            })
          } else {
            that.$Message.warning(this.$t("board.toolbox['所選圖片過大']"))
          }
          // this.canvas.renderAll()
          // this.select()
          that.$store.state.showAddImgBox = false
        }
      }
    },
    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    //上傳本地轉換的剪貼簿擷取圖片
    addImgObjFromClipBoard(dataurl) {
      let that=this
      let ImgFile = this.dataURLtoFile(dataurl, 'clipBoardImg.png')

      let blobUrl = this.classInfo.blob
      let stage = this.$store.state.stage
      let layer = this.$store.state.layer
      let size = parseFloat(ImgFile.size / 1000000).toFixed(1)
      let times = new Date().getTime()
      if (size <= 3) {
        this.$q.loading.show({
          message: this.$t('board.Uploading')
        })
        //iPad選照片拍照上傳永遠都會給相同檔名叫做照片，所以避免被cache讀到同個給時間戳
        this.$parent.uploadChangeNameResourceFile(ImgFile, times + 'clipBoardImg.png', 'ObjSrcImage').then(res => {
          this.$q.loading.hide()
          console.log(res, '上传Resource且變更檔名成功后的返回')
          let fileUrl = res.url
          let currentIndex=layer?.children ? JSON.parse(JSON.stringify(layer?.children)).length : 1
          // console.log(fileUrl)
          // console.log(stage)
          Konva.Image.fromURL(fileUrl + '?' + blobUrl.sas_read, function(image) {
            let scaleRatio = image.width() > stage.width() ? stage.width() / image.width() / 2 : 0.8
            // image is Konva.Image instance
            image.setAttrs({
              x: stage.getAbsoluteTransform().copy().invert().point({x:stage.width()/2,y:stage.height()/2}).x,
              y: stage.getAbsoluteTransform().copy().invert().point({x:stage.width()/2,y:stage.height()/2}).y,
              width: image.width() * scaleRatio,
              height: image.height() * scaleRatio,
              draggable: true,
              src: fileUrl,
              uuid:that.$jsFn.getUUID(),
              index:currentIndex
            })

            // stage.find('Transformer').destroy()
            let tr = new Konva.Transformer({
              anchorStroke: '#00a6ff',
              anchorFill: '#fff',
              anchorSize: 12,
              anchorCornerRadius: 5,
              anchorStrokeWidth: 2,
              borderStroke: '#6ac9fc',
              borderStrokeWidth: 2,
              borderDash: [3, 3],
              padding: 10,
              name: 'default'
            })
            that.$toolbox.removeTransformer()
            layer.add(image)
            that.$toolbox.saveUndoHistory('add',image)//儲存undo記錄
            layer.add(tr)
            tr.nodes([image])
            that.$parent.addMenuBtnToTr(tr, image) 
            layer.draw()
          })

          // fabric.Image.fromURL(fileUrl, function(img) {
          //   let cw = _this.canvas.getWidth()
          //   let ch = _this.canvas.getHeight()
          //   img.scaleToWidth(cw * 0.4)
          //   img.scaleToHeight(ch * 0.4)
          //   _this.canvas.add(img)
          //   _this.canvas.setActiveObject(img)
          //   _this.canvas.centerObject(img)
          // })
        })
      } else {
        this.$Message.warning(this.$t("board.toolbox['所選圖片過大']"))
      }
      // this.canvas.renderAll()
      // this.select()
      this.$store.state.showAddImgBox = false
    },
    async getBase64FromUrl(url) {
      const data = await fetch(url)
      const blob = await data.blob()
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = function() {
          const base64data = reader.result
          resolve(base64data)
        }
      })
    },
    //FromTA 九宮格
    async addTAImgObj(url, i, length) {
      console.log(length)

      let colume
      let row = Math.ceil(length / 3)

      if (length == 1) colume = 1 
      else if (length == 4 || length == 2) colume = 2
      else colume = 3

      let that = this
      let blobUrl = this.classInfo.blob
      let stage = this.$store.state.stage
      let layer = this.$store.state.layer
      let stageX = parseInt(stage.width() / colume)
      let stageY = parseInt(stage.height() / row)
        let currentIndex=layer?.children ? JSON.parse(JSON.stringify(layer?.children)).length : 1

      let fileUrl = url
      // console.log(fileUrl)
      // console.log(stage)
      that.$q.loading.show()

      let mockLayer = {
        width: () => { return stageX },
        height: () => { return stageY }
      }

      await Konva.Image.fromURL(fileUrl + '?' + blobUrl.sas_read, function (image) {
        let scaleRatio = that.ratioCalc(image, mockLayer)
        let scaleX = Number(20+ stageX/2-image.width() * scaleRatio/2 + (i % colume) * stageX)
        let scaleY = Number(10+ stageY/2-image.height() * scaleRatio/2 + parseInt(i /(length == 4?2:3)) * stageY)

        // image is Konva.Image instance
        image.setAttrs({
          x: stage.getAbsoluteTransform().copy().invert().point({ x: scaleX, y: scaleY }).x,
          y: stage.getAbsoluteTransform().copy().invert().point({ x: scaleX, y: scaleY }).y,
          width: image.width() * scaleRatio * 0.8,
          height: image.height() * scaleRatio * 0.8,
          draggable: true,
          src: fileUrl,
          uuid:that.$jsFn.getUUID(),
          index: currentIndex
        })

        // stage.find('Transformer').destroy()
        let tr = new Konva.Transformer({
          anchorStroke: '#00a6ff',
          anchorFill: '#fff',
          anchorSize: 12,
          anchorCornerRadius: 5,
          anchorStrokeWidth: 2,
          borderStroke: '#6ac9fc',
          borderStrokeWidth: 2,
          borderDash: [3, 3],
          padding: 10,
          name: 'default'
        })
        stage.find('Transformer').forEach(function (ele, i) {
          if (ele.attrs.name == 'default') {
            ele.destroy()
          }
        })
        layer.add(image)
        layer.add(tr)
        tr.nodes([image])
        that.$toolbox.saveUndoHistory('add',image)//儲存undo記錄
        that.$parent.addMenuBtnToTr(tr, image)
        layer.draw()
        that.$q.loading.hide()
      })
    },

    //加入HTML連結圖片，來源跨域檔的話無法貼
    addHTMLImgObjFromClipBoard(Imgurl) {
      return new Promise((r,j)=>{
      let that=this
      let layer = this.$store.state.layer
      let stage = this.$store.state.stage
      let imageObj = new Image()
      imageObj.setAttribute('crossOrigin', 'anonymous')
      let finallink = Imgurl
      imageObj.onload = function() {
        let image = new Konva.Image({
          x: stage.getAbsoluteTransform().copy().invert().point({x:stage.width()/2,y:stage.height()/2}).x - Math.floor(Math.random() * 200),
          y: stage.getAbsoluteTransform().copy().invert().point({x:stage.width()/2,y:stage.height()/2}).y - Math.floor(Math.random() * 200),
          image: imageObj,
          width: imageObj.width * 0.4,
          height: imageObj.height * 0.4,
          draggable: true,
          src: finallink
        })
        let tr = new Konva.Transformer({
          anchorStroke: '#00a6ff',
          anchorFill: '#fff',
          anchorSize: 12,
          anchorCornerRadius: 5,
          anchorStrokeWidth: 2,
          borderStroke: '#6ac9fc',
          borderStrokeWidth: 2,
          borderDash: [3, 3],
          padding: 10,
          name: 'default'
        })
        layer.find('Transformer').forEach(function(ele, i) {
          if (ele.attrs.name == 'default') {
            ele.destroy()
          }
        })
        layer.add(image)
        layer.add(tr)
        tr.nodes([image])
        that.$parent.addMenuBtnToTr(tr, image) 
        layer.batchDraw()
        r(200)
      }
      imageObj.onerror =function(){
        j('err')
      }
      imageObj.src = finallink
      this.$store.state.showAddImgBox = false
      })
      
    },
    //剪貼簿文字
    addTextBox(target) {
      let that=this
      let currentIndex=layer?.children ? JSON.parse(JSON.stringify(layer?.children)).length : 1
      this.$store.state.showtextInuptCard = false
      let layer = this.$store.state.layer
      document.body.classList.remove('cursor-fdj')

      let textNode = new Konva.Text({
        text: target,
        x: 20 + Math.floor(Math.random() * 200),
        y: window.innerHeight / 2 - 100 + Math.floor(Math.random() * 200),
        fontSize: this.$store.state.textSize,
        fill: 'black',
        draggable: true,
        width: window.innerWidth / 2,
        lineHeight: 1,
        height: 'auto',
        name: 'text',
        uuid:that.$jsFn.getUUID(),
        index:currentIndex
      })

      this.$store.state.mode = 'check'
      let tr = new Konva.Transformer({
        anchorStroke: '#00a6ff',
        anchorFill: '#fff',
        anchorSize: 12,
        anchorCornerRadius: 5,
        anchorStrokeWidth: 2,
        borderStroke: '#6ac9fc',
        borderStrokeWidth: 2,
        borderDash: [3, 3],
        padding: 10,
        name: 'default'
      })
      layer.add(textNode)
      that.$toolbox.saveUndoHistory('add',textNode)//儲存undo記錄
      layer.add(tr)
      tr.nodes([textNode])
      that.$parent.addMenuBtnToTr(tr, textNode) 
      layer.draw()
      this.$store.state.showAddImgBox = false
    },
    pastContent() {
      // console.log('貼回', this.currentClipBoardDataType)
      if (navigator.clipboard && Object.getPrototypeOf(navigator.clipboard).read) {
        if (this.currentClipBoardDataType == 'html') {
          this.pasteHTMLelement()
        }
        if (this.currentClipBoardDataType == 'Image') {
          this.addImgObjFromClipBoard(this.clipboardImg)
        }
        if (this.currentClipBoardDataType == 'text') {
          this.addTextBox(this.clipboardData)
        }
      } else {
        this.$Message.warning(this.$t("board.addImgBox['瀏覽器版本不支援']"))
      }
    },
    pasteHTMLelement() {
      let that = this
      // console.log(this.clipboardmixText)
      if (this.clipboardmixText) {
        this.addTextBox(this.clipboardmixText)
      }
      if (this.clipboardmixImgs.length > 0)
        this.clipboardmixImgs.forEach(async Imgurl => {
          await that.addHTMLImgObjFromClipBoard(Imgurl)
        })
    },

    async loadImage(item, type) {
      let that = this
      const reader = new FileReader()
      reader.onload = function(e) {
        that.clipboardData = ''
        that.clipboardImg = e.target.result
      }
      reader.readAsDataURL(await item.getType(type))
    },

    async getClipboardData() {
      //   console.log(this.currentTab, '讀剪貼簿')
      let that = this
      this.clipboardmixImgs = []
      this.clipboardmixText = ''
      try{
        if (navigator.clipboard) {
          if (Object.getPrototypeOf(navigator.clipboard).read) {
            let items = await navigator.clipboard.read()
            for (let item of items) {
              // console.log(item)
              //情境1:貼上html
              if (item.types.includes('text/html')) {
                let reader = new FileReader()
                reader.addEventListener('load', loadEvent => {
                  console.log(reader.result)
                  that.clipboardmixText = reader.result
                    .replace(/\u00a0/g, '')
                    .replace(/<[^>]*>/g, '')
                    .replace(/&nbsp;/g, '')

                  that.clipboardData = `<p>` + that.clipboardmixText + '</p>'
                  that.clipboardImg = ''

                  //取得圖片連結
                  let tmp = document.createElement('div')
                  tmp.innerHTML = reader.result
                  let ImgItems = tmp.querySelectorAll('img')

                  for (let ImgItem of ImgItems) {
                    // console.log(ImgItem.getAttribute('src').slice(0, 16) == 'data:image/jpeg;')
                    if (ImgItem.getAttribute('src').slice(0, 15) == 'data:image/png;' || ImgItem.getAttribute('src').slice(0, 16) == 'data:image/jpeg;') {
                      if (that.clipboardmixImgs.length == 1) {
                        that.clipboardmixImgs[0] = ImgItem.getAttribute('src')
                      } else {
                        that.clipboardmixImgs.push(ImgItem.getAttribute('src'))
                      }
                      that.clipboardData = that.clipboardData + ImgItem.outerHTML
                    } else {
                      that.clipboardData = that.clipboardmixText + '\t' + ImgItem.getAttribute('src') + '\n'
                      that.clipboardmixText = that.clipboardmixText + '\t' + ImgItem.getAttribute('src') + '\n'
                    }
                    console.log(ImgItem)
                  }
                  if (that.clipboardmixText.length > 500) {
                    that.$Message.warning(that.$t("board.addImgBox['字串長度提示']"))
                    that.clipboardData = that.clipboardmixText.slice(0, 499) + '...'
                    that.clipboardmixText = that.clipboardmixText.slice(0, 499) + '...'
                  }
                  //   let tmp = document.createElement('div')
                  //   tmp.innerHTML = reader.result
                  //   let ImgItems = tmp.querySelectorAll('img')
                  //   for (let ImgItem of ImgItems) {
                  //     that.clipboardmixImgs.push(ImgItem.getAttribute('src'))
                  //     that.clipboardData = that.clipboardData + ImgItem.outerHTML

                  //     console.log(ImgItem)
                  //   }
                })
                reader.readAsText(await item.getType('text/html'))
                that.currentClipBoardDataType = 'html'
              }
              //情境2:貼上純文字
              else if (item.types.includes('text/plain')) {
                let reader = new FileReader()
                reader.addEventListener('load', loadEvent => {
                  that.clipboardData = ''
                  that.clipboardImg = ''
                  that.clipboardData = reader.result

                  if (that.clipboardmixText.length > 500) {
                    that.$Message.warning(that.$t("board.addImgBox['字串長度提示']"))
                    that.clipboardData = that.clipboardmixText.slice(0, 499) + '...'
                  }
                })
                reader.readAsText(await item.getType('text/plain'))
                that.currentClipBoardDataType = 'text'
              }

              //情境3:貼上圖片
              else {
                const IMAGE_MIME_REGEX = /^image\/(p?jpeg|gif|png)$/i
                item.types.forEach(type => {
                  if (IMAGE_MIME_REGEX.test(type)) {
                    console.log(type)
                    that.loadImage(item, type)
                    that.currentClipBoardDataType = 'Image'
                    return
                  }
                })
              }
            }
          } else {
            this.$Message.warning(this.$t("board.addImgBox['瀏覽器版本不支援']"))
          }
        } else {
          this.$Message.warning(this.$t("board.addImgBox['瀏覽器版本不支援']"))
        }
      }
      catch(err){
       if(err.toString()=='NotAllowedError: Read permission denied.')
       this.$Message.warning(this.$t("board.addImgBox['瀏覽器不允許讀剪貼簿']"))
      }
    },
    //插入素材庫的圖片
    addOpenClipArt(Imgurl) {
      let that=this
      let stage = this.$store.state.stage
      let layer = this.$store.state.layer
      let imageObj = new Image()
      let finallink = Imgurl.slice(0, 14) != 'data:image/png' ? window.location.origin + Imgurl : Imgurl
      let currentIndex=layer?.children ? JSON.parse(JSON.stringify(layer?.children)).length : 1

      imageObj.onload = function() {
        // console.log(imageObj)
        let image = new Konva.Image({
          x: stage.getAbsoluteTransform().copy().invert().point({x:stage.width()/2,y:stage.height()/2}).x - Math.floor(Math.random() * 50),
          y: stage.getAbsoluteTransform().copy().invert().point({x:stage.width()/2,y:stage.height()/2}).y - Math.floor(Math.random() * 50),
          image: imageObj,
          width: imageObj.width * 0.4,
          height: imageObj.height * 0.4,
          draggable: true,
          src: finallink,
          uuid:that.$jsFn.getUUID(),
          index:currentIndex
        })
        // stage.find('Transformer').destroy()
        let tr = new Konva.Transformer({
          anchorStroke: '#00a6ff',
          anchorFill: '#fff',
          anchorSize: 12,
          anchorCornerRadius: 5,
          anchorStrokeWidth: 2,
          borderStroke: '#6ac9fc',
          borderStrokeWidth: 2,
          borderDash: [3, 3],
          padding: 10,
          name: 'default'
        })
        that.$toolbox.removeTransformer()
       
        layer.add(image)
        that.$toolbox.saveUndoHistory('add',image)//儲存undo記錄
        layer.add(tr)
        tr.nodes([image])
        that.$parent.addMenuBtnToTr(tr, image) 
        layer.batchDraw()
      }
      imageObj.src = finallink
      this.$store.state.showAddImgBox = false
    },
    previewBgColor(color) {
      this.selectBgImgItemPath = ''
      this.selectBgImgColor = color

      let layer = this.$store.state.layer
      this.destroyPreviewBg(layer)
      let background = new Konva.Rect({
        x: 0,
        y: 0,
        fill: color,
        width: window.innerWidth,
        height: window.innerHeight,
        illLinearGradientStartPoint: { x: 0, y: 0 },
        listening: false,
        name: 'tempPreviewbgRect'
      })
      layer.add(background)
      background.moveToTop()
      layer.draw()
    },

    previewBgImg(Imgurl) {
      this.selectBgImgColor = ''
      this.selectBgImgItemPath = Imgurl

      let that = this
      let layer = this.$store.state.layer
      this.destroyPreviewBg(layer)
      //預覽先墊白底
      let background = new Konva.Rect({
        x: 0,
        y: 0,
        fill: 'white',
        width: window.innerWidth,
        height: window.innerHeight,
        illLinearGradientStartPoint: { x: 0, y: 0 },
        listening: false,
        name: 'tempPreviewbgRect'
      })
      layer.add(background)
      background.moveToTop()
      layer.draw()

      let imageObj = new Image()
      let finallink = window.location.origin + Imgurl

      Konva.Image.fromURL(finallink, async function(image) {
        let ratio = that.ratioCalc(image, layer)

        image.setAttrs({
          x: (layer.width() - image.width() * ratio) / 2,
          y: (layer.height() - image.height() * ratio) / 2,
          width: image.width() * ratio,
          height: image.height() * ratio,
          width: image.width() * ratio,
          height: image.height() * ratio,
          // image: imageObj,
          src: finallink,
          name: 'tempPreviewBg'
        })

        layer.add(image)
        image.moveToTop()
        await layer.draw()
      })
      imageObj.src = finallink
      // this.$store.state.showAddImgBox = false
    },

    setBgImg() {
      let that = this
      let Imgurl = this.selectBgImgItemPath
      let layer = this.$store.state.layer
      let slide = this.$store.state.carouselSlide - 1
      //如果當頁有PDF或純色底圖須先清掉
      let targetPageIndex = this.$store.state.pdfOringinalImgsIdArr.findIndex(x => x.pageid == this.$store.state.imgArr.idArr[slide].pageid)

      this.$store.state.pdfOringinalImgsIdArr.splice(targetPageIndex, 1)
      this.$store.state.pdfOringinalImgs.splice(targetPageIndex, 1)
      sessionStorage.setItem('pdfOringinalImgsIdArr', JSON.stringify(this.$store.state.pdfOringinalImgsIdArr))
      sessionStorage.setItem('pdfOringinalImgs', JSON.stringify(this.$store.state.pdfOringinalImgs))

      this.destroyPreviewBg(layer)

      layer.find('Image').forEach(function(ele, i) {
        if (ele.attrs.name == 'boardPdfBg') {
          ele.destroy()
        }
      })
      layer.find('Rect').forEach(function(ele, i) {
        if (ele.attrs.name == 'bgRect') {
          ele.destroy()
        }
      })

      let imageObj = new Image()
      let finallink = window.location.origin + Imgurl

      Konva.Image.fromURL(finallink, async function(image) {
        let ratio = that.ratioCalc(image, layer)

        image.setAttrs({
          x: (layer.width() - image.width() * ratio) / 2,
          y: (layer.height() - image.height() * ratio) / 2,
          width: image.width() * ratio,
          height: image.height() * ratio,
          width: image.width() * ratio,
          height: image.height() * ratio,
          // image: imageObj,
          src: finallink,
          name: 'boardPdfBg',
          index:0,
        })

        layer.add(image)
        image.moveToBottom()
        await layer.draw()
      })
      imageObj.src = finallink
    },
    setBgColor(color) {
      let layer = this.$store.state.layer
      let slide = this.$store.state.carouselSlide - 1
      //如果當頁有PDFF或純色底圖須先清掉
      let targetPageIndex = this.$store.state.pdfOringinalImgsIdArr.findIndex(x => x.pageid == this.$store.state.imgArr.idArr[slide].pageid)

      this.$store.state.pdfOringinalImgsIdArr.splice(targetPageIndex, 1)
      this.$store.state.pdfOringinalImgs.splice(targetPageIndex, 1)
      sessionStorage.setItem('pdfOringinalImgsIdArr', JSON.stringify(this.$store.state.pdfOringinalImgsIdArr))
      sessionStorage.setItem('pdfOringinalImgs', JSON.stringify(this.$store.state.pdfOringinalImgs))

      this.destroyPreviewBg(layer)

      layer.find('Image').forEach(function(ele, i) {
        if (ele.attrs.name == 'boardPdfBg') {
          ele.destroy()
        }
      })
      layer.find('Rect').forEach(function(ele, i) {
        if (ele.attrs.name == 'bgRect') {
          ele.destroy()
        }
      })
      let background = new Konva.Rect({
        x: 0,
        y: 0,
        fill: color,
        width: window.innerWidth,
        height: window.innerHeight,
        illLinearGradientStartPoint: { x: 0, y: 0 },
        listening: false,
        name: 'bgRect'
      })
      layer.add(background)
      background.moveToBottom()
      layer.draw()
    },

    confirmSetBg() {
      this.selectBgImgColor != '' ? this.setBgColor(this.selectBgImgColor) : this.setBgImg(this.selectBgImgItemPath)
      this.$store.state.showAddImgBox = false
    },
    cancelSetBg() {
      let layer = this.$store.state.layer
      this.destroyPreviewBg(layer)
      layer.draw()
      this.$store.state.showAddImgBox = false
    },

    //發送文字給學生
    sendText() {
      let that = this
      if (this.clipboardData != '') {
        let messages = {
          action: 'Message',
          clientType: 'HiTeachCC',
          sender: sessionStorage.getItem('user').sub,
          timestamp: new Date().getTime(),
          waitReturn: false,
          payload: ''
        }
        let channelapi = this.$parent.imConnect.channel_api + '/messages'
        let user = JSON.parse(sessionStorage.getItem('user'))
        let sender = user.sub
        let receive = []
        if (messages.sender !== user.sub) {
          receive.push(messages.sender)
        }
        console.log(receive, '查看接收者')

        if (messages.action === 'Message') {
          let payloadContent = {
            text: this.clipboardData.replace(/\t/g, '</br>'),
            fileUrl: '' //發送端上傳後的blob連結
          }
          //学生加入教室一系列操作
          let messageText = {
            action: 'Message',
            clientType: 'HiTeachCC',
            sender: sender,
            timestamp: new Date().getTime(),
            waitReturn: false,
            payload: payloadContent
          }
          this.$parent.$api
            .tomessage(channelapi, {
              connectionId: '',
              sender: sender,
              isPrivate: true,
              groupname: null,
              to: receive,
              Text: JSON.stringify(messageText)
            })
            .then(res => {
              that.$Message.info(that.$t("board.addImgBox['文字發送成功']"))
              console.log(res, '消息发送成功')
            })
            .catch(res => {
              console.log(res, '消息发送失败')
            })
          this.$parent.messagePushHistory.push(messageText)
        }
      }
    }
  }
}
</script>

<style lang="less">
@import '../assets/css/color.less';
.no-mask {
  background-color: rgba(0, 0, 0, 0) !important;
}
.addImgBox {
  position: absolute;
  display: block;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  // border-radius: 5px;
  transition: 0.5s;
  color: black;
  height: 100%;
  z-index: 1003;
  .close-btn {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 30px;
    height: 30px;
    line-height: 32px;
    background-color: #d8d8d8;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    z-index: 100;
    .close-icon {
      font-size: 20px;
    }
  }
  .addImgBox-card {
    top: 15%;
    width: 50%;
    min-height: 50vh;
    transition: 0.5s;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ededed;
    font-weight: bolder;
    padding: 10px 20px 20px 20px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    z-index: 102;
    text-align: center;
    .paste-btn {
      cursor: pointer;
      position: relative;
      display: inline-block;
      white-space: nowrap;
      padding: 5px 10px;
      border-radius: 5px;
      margin: 10px 10px;
      background-color: @btn-color;
      color: white;
      .refresh-icon {
        margin-right: 4px;
        font-size: 16px;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
      }
      &:first-child {
        position: absolute;
        top: -10px;
        right: -10px !important;
        z-index: 1;
        margin-bottom: 0px;
      }
      .htpaste-icon {
        margin-right: 4px;
        font-size: 20px;
      }
      .message-icon {
        margin-right: 4px;
        font-size: 16px;
      }
    }
    .cancel-btn {
      display: inline-block;
      cursor: pointer;
      flex: 1;
      white-space: nowrap;
      padding: 5px 10px;
      border-radius: 5px;
      margin: 10px 10px;
      text-align: center;
      background-color: #d8d8d8;
      &:first-child {
        color: white;
        background-color: @btn-color;
      }
    }
    .addImgBox-title {
      font-size: 16px;
      color: #3d3d3d;
    }
    .divide-wrap {
      background-color: #ededed;
      padding: 10px 0px;
      .divide-line {
        background-color: #ededed;
        border-bottom: 0.5px solid rgb(192, 192, 192);
      }
    }
    .img_upload-text {
      margin-top: 20px;
    }
    .img_upload-btn {
      display: inline-block;
      border-radius: 50%;
      background-color: #fff;
      height: 120px !important;
      width: 120px !important;
      cursor: pointer;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
      margin-top: 10%;
      .img_upload-icon {
        font-size: 80px;
        color: #3d3d3d;
        position: relative;
        top: 20px;
        left: 2px;
      }
      input[type='file'] {
        display: none;
      }
      &:hover {
        background-color: @main-color;
        .img_upload-icon {
          color: white;
        }
      }
    }
    .tab2-wrap {
      position: relative;
      .paste-note {
        position: absolute;
        top: 10px;
        left: 10px !important;
        z-index: 1;
        margin-bottom: 0px;
        font-weight: 400;
      }
      .paste-btn-wrap {
        position: relative;
        height: 40px;
      }

      #clipboard-img {
        display: block;
        text-align: center !important;
        background-color: #fff;
        position: relative;
        overflow: auto;
        border-radius: 5px;
        min-height: 42vh;
        img {
          max-height: 42vh;
        }
      }
      #clipboard-noData {
        display: block;
        text-align: center !important;
        background-color: #fff;
        position: relative;
        height: 42vh;
        overflow: auto;
        border-radius: 5px;
        padding-top: 12vh;
        .empty-icon {
          font-size: 72px;
          padding-bottom: 5px;
        }
      }
      #clipboard-data {
        padding: 20px;
        display: block;
        text-align: left !important;
        background-color: #fff;
        position: relative;
        height: 42vh;
        overflow: auto;
        border-radius: 5px;
        * {
          text-align: left !important;
        }
        img {
          height: auto !important;
        }
      }
    }
    .tab3-wrap {
      position: relative;
      width: 100%;
      height: 52vh;
      text-align: left;
      overflow: auto;
      .imgitem-wrap {
        display: inline-block;
        width: 10%;
        text-align: center;
        vertical-align: middle;
        padding: 10px 2px;
        margin: 0.5%;
        height: 15.5%;
        cursor: pointer;
        border-radius: 5%;
        background-color: #fff;
        &:hover {
          box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
        }
        img {
          cursor: pointer;
          height: auto;
          max-height: 100%;
          width: auto;
        }
      }
    }
    .tab4-wrap {
      position: relative;
      width: 100%;
      height: 40vh;
      text-align: left;
      overflow: auto;
      .imgitem-wrap {
        display: inline-block;
        width: 32%;
        text-align: center;
        vertical-align: middle;
        margin: 6px 0.5%;
        height: 30%;
        cursor: pointer;

        img {
          cursor: pointer;
          height: auto;
          max-height: 100%;
          width: auto;
          background-color: #fff;
          &:hover {
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
    .bgColors-wrap {
      position: relative;
      height: 45px;
      padding-left: 10px;
      input[type='color'] {
        cursor: pointer;
        float: left;
        -webkit-appearance: none;
        border: none;
        height: 40px;
        width: 40px;
        margin: 0px 10px;
      }
      input[type='color']::-webkit-color-swatch-wrapper {
        padding: 0;
      }
      input[type='color']::-webkit-color-swatch {
        border: none;
      }
      .bgColors-item {
        float: left;
        height: 40px;
        width: 40px;
        margin: 0px 10px;
        cursor: pointer;
        &:hover {
          box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}
</style>
