<template>
  <div class="pdfinit">
    <div class="pdfinit-card">
      <p class="pdfinit-title">{{ $t("board.PDFInit['開啟PDF檔案']") }}</p>

      <div class="divide-wrap">
        <div class="divide-line" />
      </div>

      <div class="pdfinit-flex">
        <div class="left-part">
          <p >{{ currentIES5FileName!=''?currentIES5FileName:currentPDFInsertFile.name }}</p>

          <div v-show="!this.$store.state.isRenderingPDFNow">
            <div v-show="localPdfPages==0">
              <img src='/loading2.gif' />
              <p>{{$t("board['正在努力加载，请稍等...']")}}</p>
            </div>
            <div v-show="localPdfPages!=0">
              <span class="left-part-title">{{ $t("board.PDFInit['從']") }} </span>
              <span class="left-part-select"
                ><Select v-model="fromIndex" size="small" style="width:100px">
                  <Option v-for="item in localPdfPages" :value="item" :key="'from' + item">{{ item }}</Option>
                </Select></span
              >

              <span class="left-part-title">{{ $t("board.PDFInit['到']") }}</span>
              <span class="left-part-select"
                ><Select v-model="endIndex" size="small" style="width:100px">
                  <Option v-for="item in localPdfPages" :value="item" :key="'End' + item" v-show="item >= fromIndex">{{ item }}</Option>
                </Select></span
              >
              <span>&nbsp;&nbsp;{{ $t("board.PDFInit['加到目前課堂文件']") }}</span>
            </div>
            <div class="limit-info">
              {{ $t("board.PDFInit['使用限制提示']") }}：
              <span :class="{ 'limit-hint': endIndex + 1 - fromIndex > limitInsertPageNum }">
                {{ $t("board.PDFInit['加入頁數']") }}：<strong>{{ endIndex + 1 - fromIndex }}</strong> ({{ $t("board.PDFInit['最多']") }}<span class="limit-num"> {{ limitInsertPageNum }} </span>{{ $t("board.PDFInit['頁']") }} )</span
              >
            </div>
            <div class="limit-hint" v-show="this.isOverlimit">{{ $t("board.PDFInit['使用限制提示警告']") }}</div>
          </div>
          <div class="pdfinitbtn-group" v-show="!this.$store.state.isRenderingPDFNow">
            <div class="pdfinit-btn" @click="setPDFInitRenderInfo()" v-if="this.isOverlimit == false&&localPdfPages!=0">{{ $t("board['確定']") }}</div>
            <div class="pdfinit-btn" @click="cancelPDFInit()">{{ $t("board['取消']") }}</div>
          </div>
          <div v-show="showPreviewArea" id="pdfInit-content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PDFObject from 'pdfobject' //PDF 呼叫內建預覽
import PDFJS from 'pdfjs-dist' //PDF 渲染圖片用
const de = require('@/utils/lib1.js')
import enc from '@/utils/enc.js'
import { mapGetters } from 'vuex'

export default {
  name: 'PDFInitPageSelect',
  props: ['currentPDFInsertFile'],
  mounted() {
    if (this.classInfo.materialUrl != '') {
      this.showPreviewArea = false
      this.limitInsertPageNum = 50
      this.currentIES5FileName = this.classInfo.materialUrl.split('?')[0].substring(this.$parent.classInfo.materialUrl.split('?')[0].lastIndexOf('/')+1)
    }
  },
  computed:{
     ...mapGetters({
      classInfo: 'classInfo/getInfo', // 取得課堂設定
    }),
  },
  created() {
    
    this.isOverlimit = false
  },
  data() {
    return {
      fromIndex: 1,
      endIndex: 1,
      currentRenderPage: 0,
      totalRenderPage: 0,
      localPdfPages: 0,
      localPdfURL: '',
      limitInsertPageNum: 20,
      limitTotalPageNum: 100,
      isOverlimit: false,
      renderCount: 1,
      showPreviewArea: true,
      currentIES5FileName:''
    }
  },
  watch: {
    fromIndex(value) {
      if (value > this.endIndex) {
        this.endIndex = value
      }
      this.checkIsOverLimit()
    },
    endIndex() {
      this.checkIsOverLimit()
    },
    currentPDFInsertFile(value) {
      if (value != '') {
        // console.log(window.URL.createObjectURL(value))
        let options = {
          height: window.innerHeight > 600 ? window.innerHeight * 0.5 + 'px' : window.innerHeight * 0.3 + 'px',
          width: window.innerWidth * 0.5 + 'px',
          pdfOpenParams: {
            navpanes: 1,
            statusbar: 1,
            toolbar: 1,
            view: 'FitH',
            pagemode: 'thumbs',
            page: 'anchor'
          }
        }
        // console.log(this.$store.state.uploadUrl)
        this.localPdfURL = value == 'defaultPDF' || value == 'IES5PDF' ? this.$store.state.uploadUrl : window.URL.createObjectURL(value)
        let that = this
        PDFObject.embed(this.localPdfURL, '#pdfInit-content', options)
        PDFJS.disableWorker = true // due to CORS
        PDFJS.getDocument({
          url: this.localPdfURL
        })
          .promise.then(
            function(pdf) {
              that.localPdfPages = pdf.numPages
              that.endIndex = pdf.numPages
            },
            function(reason) {
              that.$Message.warning(that.$t("board.PDFInit['PDF載入錯誤']") + ':' + reason)
            }
          )
          .catch(err => {
            that.$Message.warning(that.$t("board.PDFInit['PDF載入錯誤']") + ':' + err)
          })
      }
    }
  },

  methods: {
    checkIsOverLimit() {
      if (this.endIndex + 1 - this.fromIndex > this.limitInsertPageNum || parseInt(this.$store.state.totalpage) + parseInt(this.endIndex) + 1 - parseInt(this.fromIndex) > this.limitTotalPageNum) {
        this.isOverlimit = true
      } else {
        this.isOverlimit = false
      }
    },
    cancelPDFInit() {
      this.$store.state.showPDFInitPageSelect = false
      this.$parent.Hishow = false
      this.renderCount = 1
      //退回原本的狀態
      this.$parent.modalUpload = true
      this.$parent.modalUploadType = true
      this.$parent.modal_loading = false
      this.$store.state.initIsWhiteboard = true
    },
    closePDFInit() {
      this.$store.state.showPDFInitPageSelect = false
      this.fromIndex = 1
      this.$parent.Hishow = false
      this.renderCount = 1
    },
    //send Img需先將圖片轉換成file
    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },

    setPDFInitRenderInfo() {
      if (this.endIndex + 1 - this.fromIndex > this.limitInsertPageNum || parseInt(this.$store.state.totalpage) + parseInt(this.endIndex) + 1 - parseInt(this.fromIndex) > this.limitTotalPageNum) {
        this.isOverlimit = true
      } else {
        this.$store.state.PDFInitRenderInfo = {
          fromIndex: this.fromIndex,
          endIndex: this.endIndex,
          currentRenderPage: 0,
          totalRenderPage: this.endIndex + 1 - this.fromIndex,
          renderCount: 1
        }
        this.closePDFInit()
      }
    }
  }
}
</script>

<style lang="less">
@import '../assets/css/color.less';
.ivu-select-item {
  width: 100%;
}
.pdfinit {
  position: absolute;
  display: block;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  // border-radius: 5px;
  color: black;
  height: 100%;
  z-index: 1002;
  .pdfinit-card {
    top: 5%;
    transition: 0.5s;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ededed;
    font-weight: bolder;
    padding: 20px 20px 10px 20px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    z-index: 102;
    text-align: center;
    .pdfinit-title {
      font-size: 16px;
      color: #3d3d3d;
    }
    .textinput {
      border-radius: 5px;
      border: 2px solid @main-color;
      color: black;
      margin-top: 10px;
      width: 100%;
      min-height: 100px;
      font-size: 22px;
      padding: 10px;
    }
    .divide-wrap {
      background-color: #ededed;
      padding: 10px 0px;
      .divide-line {
        background-color: #ededed;
        border-bottom: 0.5px solid rgb(192, 192, 192);
      }
    }
    .pdfinit-flex {
      //   display: flex;
      .left-part {
        // flex: 1;
        min-width: 400px;
        // margin-right: 20px;
        // position: relative;
        line-height: 30px;
        .left-part-title,
        .left-part-select {
          display: inline-block;
          margin: 0px 5px;
        }
        #pdfInit-content {
          // flex: 1;
          width: 100%;
          height: auto;
          margin-top: 10px;
          position: relative;
          background: #999;
        }
      }
    }
    .limit-info {
      font-weight: normal;
      text-align: center;
      margin-top: 30px;
    }
    .limit-hint {
      text-align: center;
      color: red !important;
      .limit-num {
        color: red !important;
        font-weight: bold;
      }
    }
    .limit-num {
      color: @main-color;
      font-weight: bold;
    }

    .pdfinitbtn-group {
      display: flex;
      align-items: center;
      position: relative;
      margin-top: 8px;
      //   bottom: 10px;

      max-width: 50%;
      left: 50%;
      transform: translateX(-50%);
      .pdfinit-btn {
        cursor: pointer;
        flex: 1;
        white-space: nowrap;
        padding: 2px 10px;
        margin: 10px 5px;
        border-radius: 4px;
        text-align: center;
        background-color: #d8d8d8;
        &:first-child {
          color: white;
          background-color: @btn-color;
        }
      }
    }
  }
}
</style>
