<template>
  <div></div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
export default {
  name: 'saveIES',
  props: ['savePath'],
  data() {
    return {
        path: '',
        Base: {
            'summary': {
                'activityName': 'HiTeachCC',
                'hostName': 'Noname',
                'meterialName': 'Noname',
                'date': 'Nodate',
                'startTime': 'Nostart',
                'endTime': 'Noend',
                'attendCount': 0,
                'clientCount': 0,
                'attendRate': 0,
                'groupCount': 0,
                'totalPoint': 0,
                'totalInteractPoint': 0,
                'collateTaskCount': 0,
                'collateCount': 0,
                'pushCount': 0,
                'examCount': 0,
                'examQuizCount': 0,
                'examPointRate': 0,
                'interactionCount': 0,
                'clientInteractionCount': 0,
                'clientInteractionAverge': 0,
                'diffPushCount': 0,
                'learningCategory': {
                    'cooperation': 0,
                    'interaction': 0,
                    'task': 0,
                    'exam': 0,
                    'diffential': 0
                }
            },
            'report': {
                'activityName': 'HiTeachCC',
                'hostName': 'Noname',
                'meterialName': 'Noname',
                'date': 'Nodate',
                'startTime': 'Nostart',
                'endTime': 'Noend',
                'attendCount': 0,
                'clientCount': 0,
                'attendRate': 0,
                'groupCount': 0,
                'totalPoint': 0,
                'totalInteractPoint': 0,
                'collateTaskCount': 0,
                'collateCount': 0,
                'pushCount': 0,
                'examCount': 0,
                'examQuizCount': 0,
                'examPointRate': 0,
                'interactionCount': 0,
                'clientInteractionCount': 0,
                'clientInteractionAverge': 0,
                'diffPushCount': 0,
                'learningCategory': {
                    'cooperation': 0,
                    'interaction': 0,
                    'task': 0,
                    'exam': 0,
                    'diffential': 0
                },
                'quizSummaryList': [],
                'clientSummaryList': [],
            },
            'teacheract': [],
            'student': [
                //    {
                //        'id': '1605192585',
                //        'seatID': 1,
                //        'name': '林筱茹',
                //        'type': 1
                //    },
            ]
        },
        Task: [
            //{
            //    'pageID': '',
            //    'jobName': '',
            //    'reciveCount': 0,
            //    'duration': '',
            //    'collateType': 'Image',
            //    'clientWorks': [
                 //   {
                 //       'blobFiles': [
                 //           ''
                 //       ],
                 //       'seatID': 0,
                 //       'groupID': '0',
                 //       'isGroupItem': false,
                 //       'reciveTime': '',
                 //       'displayIndex': 0
                 //   },
                 //]
            //},
        ],
        Push: [
            //{
            //    'type': 'Image',
            //    'pageID': '220625084110e3fa85c28423f3165611767081640191122',
            //    'pageMeta': '/Host/Task/e3fa85c28423f3165611993757753303419.jpg',
            //    'timestamp': 1656119938203
            //},
        ],
        TimeLine: {
            'events': [
                //{
                //    'Time': 0.0002,
                //    'EventId': 589825,
                //    'Event': 'ActStart',
                //    'ActStartTime': '0'
                //},
            ],
            'PgIdList': [
                //'220625084102e3fa85c28423f3165611766200968266316',
            ]
        },
        IRS: [
        ],
        aoa: [],
        totalOptions: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'], 
    }
  },
  computed: {
    ...mapGetters({
      classInfo: 'classInfo/getInfo' // 取得課堂設定
    })},
  created() {
      Vue.prototype.$saveIES = this
  },
  methods: {
    resetInitData(){
        this.path= ''
        this.Base= {
            'summary': {
                'activityName': 'HiTeachCC',
                'hostName': 'Noname',
                'meterialName': 'Noname',
                'date': 'Nodate',
                'startTime': 'Nostart',
                'endTime': 'Noend',
                'attendCount': 0,
                'clientCount': 0,
                'attendRate': 0,
                'groupCount': 0,
                'totalPoint': 0,
                'totalInteractPoint': 0,
                'collateTaskCount': 0,
                'collateCount': 0,
                'pushCount': 0,
                'examCount': 0,
                'examQuizCount': 0,
                'examPointRate': 0,
                'interactionCount': 0,
                'clientInteractionCount': 0,
                'clientInteractionAverge': 0,
                'diffPushCount': 0,
                'learningCategory': {
                    'cooperation': 0,
                    'interaction': 0,
                    'task': 0,
                    'exam': 0,
                    'diffential': 0
                }
            },
            'report': {
                'activityName': 'HiTeachCC',
                'hostName': 'Noname',
                'meterialName': 'Noname',
                'date': 'Nodate',
                'startTime': 'Nostart',
                'endTime': 'Noend',
                'attendCount': 0,
                'clientCount': 0,
                'attendRate': 0,
                'groupCount': 0,
                'totalPoint': 0,
                'totalInteractPoint': 0,
                'collateTaskCount': 0,
                'collateCount': 0,
                'pushCount': 0,
                'examCount': 0,
                'examQuizCount': 0,
                'examPointRate': 0,
                'interactionCount': 0,
                'clientInteractionCount': 0,
                'clientInteractionAverge': 0,
                'diffPushCount': 0,
                'learningCategory': {
                    'cooperation': 0,
                    'interaction': 0,
                    'task': 0,
                    'exam': 0,
                    'diffential': 0
                },
                'quizSummaryList': [],
                'clientSummaryList': [],
            },
            'teacheract': [],
            'student': [
                //    {
                //        'id': '1605192585',
                //        'seatID': 1,
                //        'name': '林筱茹',
                //        'type': 1
                //    },
            ]
        }
        this.Task= [],
        this.Push= []
        this.TimeLine= {
            'events': [],
            'PgIdList': []
        },
        this.IRS= [],
        this.aoa= [],
        this.totalOptions= ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']
    },
    print() {
        console.log(this.path)
        this.path = this.savePath
        this.resetInitData() //每次生成應清空
        this.$store.state.msgBody.sort(function(x,y) { return(x.page-y.page) })
        this.generateBaseJSON()
        this.generateTaskJSON()
        this.generatePushJSON()
        this.generateIRS()
        this.generateTimeLine()
        this.generateXLSX()
        return {'base':this.Base, 'Task':this.Task, 'Push':this.Push, 'IRS':this.IRS, 'TimeLine':this.TimeLine, 'msgbody':this.$store.state.msgBody, 'xlsx': this.aoa}
    },

    generateBaseJSON() {
        let that = this
  		if(this.classInfo.className)  this.Base.report.activityName = this.Base.summary.activityName = this.classInfo.className
        this.Base.report.hostName  = this.Base.summary.hostName = JSON.parse(sessionStorage.getItem('user')).name
        this.Base.report.meterialName = this.Base.summary.meterialName = this.$store.state.uploadUrl ? (this.$store.state.uploadUrl.split('/').pop()).split('.pdf',1)[0] : 'Noname'
        let t = new Date(Number(this.$store.state.startTime))
        this.Base.report.date = this.Base.summary.date = t.getFullYear()+'.'+(t.getMonth()+1)+'.'+t.getDate()
        this.Base.report.startTime = this.Base.summary.startTime = t.toTimeString().split(' ')[0]
        let t1 = new Date()
        this.Base.report.endTime = this.Base.summary.endTime = t1.toTimeString().split(' ')[0]
        let Ss = 0
        this.$store.state.students.forEach(item => {
            if(item.status == 'online') Ss++
            let oneClientSummary =
            {
                'groupName': '',
                'groupScore': 0,
                'groupTaskCompleteCount': 0,
                'attendState': (item.status == 'online') ? 1 : 0,
                'score': 0,
                'taskCompleteCount': 0,
                'examScoreList': [],
                'interactScore': 0,
                'answerList': [],
                'seatID': Number(item.sort),
                'name': item.studentName
            }
            that.Base.report.clientSummaryList.push(oneClientSummary)
        })
        this.Base.report.attendCount = this.Base.summary.attendCount = Ss,
        this.Base.report.clientCount = this.Base.summary.clientCount = this.$store.state.students.length
        if(this.$store.state.students.length > 0) this.Base.report.attendRate = this.Base.summary.attendRate = Number(((Ss * 100) / this.$store.state.students.length).toFixed(1))
        let totalPoint = 0
        let collateCount = 0
        let interactionCount = 0
        let clientInteractionCount = 0
        let totalInteractPoint = 0
        let irsmodel = ''
        for (var i=0; i < this.$store.state.msgBody.length; i++) {
            let tempscore = 0
            for(var j=0; j < this.$store.state.msgBody[i].totalirs.length; j++) {
                if(this.$store.state.msgBody[i].irsmodel=='irsImmediately'||this.$store.state.msgBody[i].irsmodel=='irsText'){
                tempscore += this.$store.state.msgBody[i].totalirs[j].score
                totalInteractPoint += this.$store.state.msgBody[i].totalirs[j].score
                that.Base.report.clientSummaryList[j].interactScore += this.$store.state.msgBody[i].totalirs[j].score
               }
            }

            collateCount += this.$store.state.msgBody[i].workData.length
            irsmodel = this.$store.state.msgBody[i].irsmodel
            let interactionflag = true
            switch(irsmodel) {
                case 'irsBuzz':
                    clientInteractionCount+=this.$store.state.msgBody[i].buzzInStudents.length;
                    interactionflag = false;
                    break;
                case 'irsImmediately':
                    for(var j=0; j < this.$store.state.msgBody[i].totalirs.length; j++) {
                        if(this.$store.state.msgBody[i].totalirs[j].option) clientInteractionCount++;
                    }
                    break;
                case 'irsText':
                    for(var j=0; j < this.$store.state.msgBody[i].totalirs.length; j++) {
                        if(this.$store.state.msgBody[i].totalirs[j].statement) clientInteractionCount++;
                    }
                    break;
                default:
                    interactionflag = false;
            }
            if(interactionflag) {
                interactionCount++;
                // report.quizSummaryList
                let oneQuizSummary = 
                {
                    'quizID': i,
                    'pageOrderStr': 'P'+this.$store.state.msgBody[i].page,
                    'score': "10",
                    'correctAnswer': '--',
                    'correctRateStr': (tempscore * 100 / 10 / this.Base.report.clientCount).toFixed(1)+'%'
                }
                switch(that.$store.state.msgBody[i].irsmodel) {
                    case 'irsImmediately':
                        if(this.$store.state.msgBody[i].correctanswer != '' && this.$store.state.msgBody[i].correctanswer.length > 0)  {
                            oneQuizSummary.correctAnswer = ''
                            this.$store.state.msgBody[i].correctanswer.forEach(item => {
                                oneQuizSummary.correctAnswer+=(item+',')   
                            })
                            oneQuizSummary.correctAnswer =oneQuizSummary.correctAnswer.substring(0,oneQuizSummary.correctAnswer.length-1)
                        }
                        break;
                    case 'irsText':
                        if(this.$store.state.msgBody[i].correctanswer != '')  {
                         oneQuizSummary.correctAnswer = this.$store.state.msgBody[i].correctanswer
                        }
                        break;
                }
                this.Base.report.quizSummaryList.push(oneQuizSummary)
            }
            
        }
        // this.Base.report.quizSummaryList.sort(function(x,y) { return(x.quizID-y.quizID) })
        that.Base.report.clientSummaryList.forEach((item1, index1) => { 
            that.Base.report.quizSummaryList.forEach((item2, index2) => {
                if(index1 < that.$store.state.msgBody[item2.quizID].totalirs.length) {
                switch(that.$store.state.msgBody[item2.quizID].irsmodel) {
                    case 'irsImmediately':            
                        item1.answerList.push(that.$store.state.msgBody[item2.quizID].totalirs[index1].option)
                        break;
                    case 'irsText':
                        item1.answerList.push(that.$store.state.msgBody[item2.quizID].totalirs[index1].statement)
                        break;
                }}
                else item1.answerList.push('')
            })
        })

  		this.Base.report.quizSummaryList.forEach((item, index) => { item.quizID = (index+1).toString(10)})
        this.Base.report.totalInteractPoint = this.Base.summary.totalInteractPoint = totalInteractPoint
        this.Base.report.collateCount = this.Base.summary.collateCount = collateCount
        this.Base.report.interactionCount = this.Base.summary.interactionCount = interactionCount
        this.Base.report.clientInteractionCount = this.Base.summary.clientInteractionCount = clientInteractionCount
        if(Ss>0) this.Base.report.clientInteractionAverge = this.Base.summary.clientInteractionAverge = Number((clientInteractionCount / Ss).toFixed(1))
        let workInsidedata = JSON.parse(sessionStorage.getItem('workInsidedata'))
        if (workInsidedata) this.Base.report.collateTaskCount = this.Base.summary.collateTaskCount = workInsidedata.length
        if (sessionStorage.getItem('pagePushHistory') && sessionStorage.getItem('pagePushHistory') != 'null' ) this.Base.report.pushCount = this.Base.summary.pushCount = JSON.parse(sessionStorage.getItem('pagePushHistory')).length
        this.Base.report.learningCategory.interaction = this.Base.summary.learningCategory.interaction = (clientInteractionCount>0) ? 1 : 0
        this.Base.report.learningCategory.task = this.Base.summary.learningCategory.task = (collateCount > 0) ? 1 : 0
        this.Base.report.learningCategory.exam = this.Base.summary.learningCategory.exam = (totalInteractPoint > 0) ? 1 : 0

        if (this.$store.state.students.length > 0) {
            this.$store.state.students.forEach(item => {
                that.Base.student.push({
                    'id': item.accountid,
                    'seatID': Number(item.sort),
                    'name': item.studentName,
                    'type': item.type
                })
            })
        }
    },

    generateTaskJSON() {
        let that = this
        let workInsidedata = JSON.parse(sessionStorage.getItem('workInsidedata'))
        if (workInsidedata) workInsidedata.forEach(item => {
            let oneTask = {
                'pageID': item.pageID,
                'jobName': item.jobName,
                'reciveCount': 0,
                'duration': '00:00:10',
                'collateType': item.collateType,
                'clientWorks': [
                 ]
            }
            let oneMsgBody = that.$store.state.msgBody.filter(item1 => {
                return (item1.pageid == item.pageID)
            })
            if (oneMsgBody.length > 0) {
                if (oneMsgBody[0].workData.length > 0) {
                    oneTask.reciveCount = oneMsgBody[0].workData.length
                    oneMsgBody[0].workData.forEach((item2, index2) => {
                        let oneClientSummary = that.Base.report.clientSummaryList.filter(item3 => {
                            return (Number(item3.seatID) == Number(item2.seat))
                        })
                        if(oneClientSummary.length > 0) oneClientSummary[0].taskCompleteCount ++
                        oneTask.clientWorks.push({
                            'blobFiles': [
                                '/Clients/' + item2.url.split('?')[0].split('/Clients/')[1]
                            ],
                            'seatID': Number(item2.seat),
                            'groupID': '0',
                            'isGroupItem': false,
                            'reciveTime': '00:00:10',
                            'displayIndex': index2
                        })
                    })
                }
            }
            that.Task.push(oneTask)
        })
    },

    generatePushJSON() {
        let pagePushHistory = JSON.parse(sessionStorage.getItem('pagePushHistory'))
        if (pagePushHistory) pagePushHistory.forEach(item => {
            let onePush = {
                'type': 'Image',
                'pageID': item.payload.pageId,
                'pageMeta': item.payload.pageMeta,
                'timestamp': item.timestamp
            }
            this.Push.push(onePush)
        })
    },

    generateIRS() {
        let that = this
        for (var i = 0; i < this.$store.state.msgBody.length; i++) {
          let irsmodel = this.$store.state.msgBody[i].irsmodel
          if (irsmodel == 'irsBuzz' || irsmodel == 'irsImmediately' || irsmodel == 'irsText') {
            let oneIRS = {
            'pageID': this.$store.state.msgBody[i].pageid,
            'question': {
                'questionRenderType': 'Htex',
                'shaCode': '',
                'questionIndex': 0,
                'questionDurationTime': '00:00:00',
                'questionSpendTime': '00:00:00',
                'questionLocationType': 'Local',
                'totalAnsweredMemberCount': 0,
                'concept': '',
                'keyword': '',
                'contentQuestionCategory': 'Knowledge',
                'questionAnsweredState': 'Unanswered',
                'id': '',
                'exercise': {
                    'answer': [],
                    'objective': false,
                    'opts': 4,
                    'blankCount': 0,
                    'explain': '',
                    'type': 'single',
                    'knowledges': [],
                    'score': 1,
                    'field': 1,
                    'order': 0,
                    'subjectId': '',
                    'periodId': '',
                    'gradeIds': [],
                    'level': 3,
                    'createTime': 0,
                    'creator': '',
                    'children': [],
                    'repair': []
                },
                'item': [
                    {
                        'question': '_popquiz_',
                        'option': [
                            {
                                'code': 'A',
                                'timeStamp': 0
                            },
                            {
                                'code': 'B',
                                'timeStamp': 0
                            },
                            {
                                'code': 'C',
                                'timeStamp': 0
                            },
                            {
                                'code': 'D',
                                'timeStamp': 0
                            },
                            {
                                'code': 'E',
                                'timeStamp': 0
                            },
                            {
                                'code': 'F',
                                'timeStamp': 0
                            },
                            {
                                'code': 'G',
                                'timeStamp': 0
                            },
                            {
                                'code': 'H',
                                'timeStamp': 0
                            },
                            {
                                'code': 'I',
                                'timeStamp': 0
                            }
                        ]
                    }
                ]
            },
            'clientAnswers': {
                '0': []
            },
            'isBuzz': false,
            'buzzClients': []
            }
            switch(irsmodel) {
                case 'irsBuzz':
                    oneIRS.isBuzz = true
                    this.$store.state.msgBody[i].buzzInStudents.forEach(item => {
                        oneIRS.buzzClients.push(item.sort.toString(10))
                    })
                    oneIRS.question.exercise.type = 'single'
                break
                case 'irsImmediately':
                    this.$store.state.msgBody[i].totalirs.forEach(item => {
                        if (!item.option) oneIRS.clientAnswers["0"].push([])
                        else if (that.$store.state.currentAnsOption == 'En') oneIRS.clientAnswers["0"].push([item.option])
                        else {
                            oneIRS.clientAnswers["0"].push([that.totalOptions[parseInt(item.option,10)-1]])
                        }
                    })
                    oneIRS.question.exercise.type = 'single'
                    oneIRS.question.exercise.opts = this.$store.state.msgBody[i].letterselect.length
                    if (this.$store.state.msgBody[i].correctanswer) {
                        if (this.$store.state.currentAnsOption == 'En') oneIRS.question.exercise.answer = this.$store.state.msgBody[i].correctanswer
                        else this.$store.state.msgBody[i].correctanswer.forEach(item => {
                            oneIRS.question.exercise.answer.push(that.totalOptions[parseInt(item, 10) - 1])
                        })
                    }
                break
                case 'irsText':
                    this.$store.state.msgBody[i].totalirs.forEach(item => {
                        if(item.statement) oneIRS.clientAnswers["0"].push([item.statement])
                        else oneIRS.clientAnswers["0"].push([])
                    })
                    oneIRS.question.exercise.type = 'complete'
                    oneIRS.question.item[0].question = ''
                    oneIRS.question.item[0].option = []
                    oneIRS.question.exercise.blankCount = 1
                break
            }
            this.IRS.push(oneIRS)
          }
        }
    },

    generateTimeLine() {
        let idArr = JSON.parse(sessionStorage.getItem('idArr'))
        let totalpages = idArr.length
        for (var pageno = 0; pageno < totalpages; pageno++) {
            if(idArr[pageno] && idArr[pageno].pageid)
                this.TimeLine.PgIdList.push(idArr[pageno].pageid)
            else this.TimeLine.PgIdList.push("")
        }
        this.TimeLine.events = this.$store.state.timeLineEvent
    },

    generateXLSX() {
        let that = this
        console.log('quizSummary', this.Base.report.quizSummaryList)
        this.aoa.push([that.$t("report.title")])
        this.aoa.push([that.$t("report.activityName"), this.Base.report.activityName])
        this.aoa.push([that.$t("report.hostName"), this.Base.report.hostName, that.$t("report.meterialName"),this.Base.report.meterialName, that.$t("report.dateTime"), this.Base.report.date+' '+this.Base.report.startTime])
        this.aoa.push([that.$t("report.attendCount"), this.Base.report.attendCount+'/'+this.Base.report.clientCount, that.$t("report.attendRate"),this.Base.report.attendRate+'%', that.$t("report.collateTaskCount"), this.Base.report.collateTaskCount,that.$t("report.collateCount"),this.Base.report.collateCount,that.$t("report.pushCount"),this.Base.report.pushCount])
        this.aoa.push([that.$t("report.totalInteractPoint"), this.Base.report.totalInteractPoint, that.$t("report.interactionCount"),this.Base.report.interactionCount, that.$t("report.clientInteractionCount"), this.Base.report.clientInteractionCount,that.$t("report.clientInteractionAverge"),this.Base.report.clientInteractionAverge])
        let rows = [['','','','',that.$t("report.quizID")],
                    ['','','','',that.$t("report.pageOrderStr")],
                    ['','','','',that.$t("report.score")],
                    ['','','','',that.$t("report.correctAnswer")],
                    ['','','','',that.$t("report.correctRateStr")]]
        this.Base.report.quizSummaryList.forEach(item => {
            rows[0].push(item.quizID)
            rows[1].push(item.pageOrderStr)       
            rows[2].push(item.score)
            rows[3].push(item.correctAnswer)
            rows[4].push(item.correctRateStr)
        })
        rows.forEach(item=> {that.aoa.push(item)})
        this.aoa.push([that.$t("report.seatID"),that.$t("report.name"),that.$t("report.attendState"),that.$t("report.taskCompleteCount"),that.$t("report.interactScore"),that.$t("report.answerList")])
        this.Base.report.clientSummaryList.forEach(item => {
            let row = [item.seatID, item.name, (item.attendState==1) ? that.$t("report.attendState1"):that.$t("report.attendState0"), item.taskCompleteCount, item.interactScore]
            item.answerList.forEach(item2 => {
                row.push(item2)
            })
            that.aoa.push(row)
        })
    }
  }
}
</script>
