<template>
  <div class="pdfinsert">
    <div class="pdfinsert-card">
      <p class="pdfinsert-title">{{ $t("board['插入PDF']") }}</p>

      <div class="divide-wrap">
        <div class="divide-line" />
      </div>

      <div class="pdfinsert-flex">
        <div class="left-part">
          <p>{{ currentPDFInsertFile.name }}</p>
          <div v-show="this.$store.state.isRenderingPDFNow">{{ currentRenderPage }}&nbsp;&nbsp;{{ $t("board['正在努力加载，请稍等...']") }}</div>
          <div v-show="this.$store.state.isRenderingPDFNow" class="render-bar">
            <Progress :percent="isNaN(((renderCount / totalRenderPage) * 100).toFixed(0)) ? 0 : parseInt(((renderCount / totalRenderPage) * 100).toFixed(0))" />
          </div>
          <div v-show="!this.$store.state.isRenderingPDFNow">
            <span class="left-part-title">{{ $t("board.PDFInsert['從']") }} </span>
            <span class="left-part-select"
              ><Select v-model="fromIndex" size="small" style="width:100px">
                <Option v-for="item in localPdfPages" :value="item" :key="'from' + item">{{ item }}</Option>
              </Select></span
            >

            <span class="left-part-title">{{ $t("board.PDFInsert['到']") }}</span>
            <span class="left-part-select"
              ><Select v-model="endIndex" size="small" style="width:100px">
                <Option v-for="item in localPdfPages" :value="item" :key="'End' + item" v-show="item >= fromIndex">{{ item }}</Option>
              </Select></span
            >
            <span>&nbsp;&nbsp;{{ $t("board.PDFInsert['加到目前課堂文件']") }} {{ $t("board.PDFInsert['第']") }} {{ this.$store.state.carouselSlide }} {{ $t("board.PDFInsert['頁之後']") }}</span>
            <div class="limit-info">
              {{ $t("board.PDFInsert['使用限制提示']") }}：{{ $t("board.PDFInsert['目前課堂文件頁數']") }}：<strong>{{ this.$store.state.totalpage }}</strong
              >,
              <span :class="{ 'limit-hint': endIndex + 1 - fromIndex > limitInsertPageNum }">
                {{ $t("board.PDFInsert['加入頁數']") }}：<strong>{{ endIndex + 1 - fromIndex }}</strong> ({{ $t("board.PDFInsert['最多']") }}<span class="limit-num"> {{ limitInsertPageNum }} </span>{{ $t("board.PDFInsert['頁']") }} )</span
              >,
              <span :class="{ 'limit-hint': parseInt(this.$store.state.totalpage) + parseInt(endIndex) + 1 - parseInt(fromIndex) > limitTotalPageNum }">
                {{ $t("board.PDFInsert['總頁數']") }}：<strong>{{ parseInt(this.$store.state.totalpage) + parseInt(endIndex) + 1 - parseInt(fromIndex) }}</strong> ({{ $t("board.PDFInsert['最多']") }} <span class="limit-num">{{ limitTotalPageNum }}</span> {{ $t("board.PDFInsert['頁']") }} )</span
              >
            </div>
            <div class="limit-hint" v-show="this.isOverlimit">{{ $t("board.PDFInsert['使用限制提示警告']") }}</div>
          </div>
          <div id="pdf-content" />
          <div class="pdfinsertbtn-group" v-show="!this.$store.state.isRenderingPDFNow">
            <div class="pdfinsert-btn" @click="insertPDF()" v-if="this.isOverlimit == false">{{ $t("board['確定']") }}</div>
            <div class="pdfinsert-btn" @click="closePDFInsert()">{{ $t("board['取消']") }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PDFObject from 'pdfobject' //PDF 呼叫內建預覽
import PDFJS from 'pdfjs-dist' //PDF 渲染圖片用
const de = require('@/utils/lib1.js')
import enc from '@/utils/enc.js'
import { mapGetters } from 'vuex'

export default {
  name: 'PDFInsert',
  props: ['currentPDFInsertFile'],
  mounted() {
    if (this.classInfo.materialUrl != '') {
      this.showPreviewArea = false
      this.limitInsertPageNum = 50
    }
  },
  computed:{
     ...mapGetters({
      classInfo: 'classInfo/getInfo', // 取得課堂設定
    }),
  },
  created() {
    //this.pdf(1)
    this.isOverlimit = false
  },
  data() {
    return {
      fromIndex: 1,
      endIndex: 1,
      currentRenderPage: 0,
      totalRenderPage: 0,
      localPdfPages: 0,
      localPdfURL: '',
      limitInsertPageNum: 20,
      limitTotalPageNum: 100,
      isOverlimit: false,
      renderCount: 1
    }
  },
  watch: {
    fromIndex(value) {
      if (value > this.endIndex) {
        this.endIndex = value
      }
      this.checkIsOverLimit()
    },
    endIndex() {
      this.checkIsOverLimit()
    },
    currentPDFInsertFile(value) {
      if (value != '') {
        // console.log(window.URL.createObjectURL(value))
        let options = {
          height: window.innerHeight * 0.5 + 'px',
          width: window.innerWidth * 0.5 + 'px',
          pdfOpenParams: {
            navpanes: 1,
            statusbar: 1,
            toolbar: 1,
            view: 'FitH',
            pagemode: 'thumbs',
            page: 'anchor'
          }
        }
        this.localPdfURL = window.URL.createObjectURL(value)
        let that = this
        PDFObject.embed(this.localPdfURL, '#pdf-content', options)
        PDFJS.disableWorker = true // due to CORS
        PDFJS.getDocument(this.localPdfURL)
          .promise.then(
            function(pdf) {
              that.localPdfPages = pdf.numPages
              that.endIndex = pdf.numPages
            },
            function(reason) {
              that.$Message.warning(that.$t("board.PDFInsert['PDF載入錯誤']") + ':' + reason)
            }
          )
          .catch(err => {
            that.$Message.warning(that.$t("board.PDFInit['PDF載入錯誤']") + ':' + err)
          })
      }
    }
  },

  methods: {
    checkIsOverLimit() {
      if (this.endIndex + 1 - this.fromIndex > this.limitInsertPageNum || parseInt(this.$store.state.totalpage) + parseInt(this.endIndex) + 1 - parseInt(this.fromIndex) > this.limitTotalPageNum) {
        this.isOverlimit = true
      } else {
        this.isOverlimit = false
      }
    },
    closePDFInsert() {
      this.$store.state.showPDFInsert = false
      this.fromIndex = 1
      this.$parent.Hishow = false
      this.renderCount = 1
    },
    //send Img需先將圖片轉換成file
    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },

    insertPDF() {
      if (this.endIndex + 1 - this.fromIndex > this.limitInsertPageNum || parseInt(this.$store.state.totalpage) + parseInt(this.endIndex) + 1 - parseInt(this.fromIndex) > this.limitTotalPageNum) {
        this.isOverlimit = true
      } else {
        //先翻頁
        this.$parent.switchoverpdf(Number(this.$store.state.carouselSlide))
        this.totalRenderPage = this.endIndex + 1 - this.fromIndex
        let that = this
        PDFJS.disableWorker = true // due to CORS
        PDFJS.getDocument({
        url:this.localPdfURL,
        cMapUrl: "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.3.200/cmaps/",
        cMapPacked: true
      }).then(function(pdf) {
          let pages = []
          let heights = []
          let height = 0
          let currentPage = that.fromIndex
          let scale = 1.5
          getPage()

          function getPage() {
            pdf.getPage(currentPage).then(function(page) {
              that.currentRenderPage = currentPage
              let viewport = page.getViewport(scale)
              let canvas = document.createElement('canvas')
              let ctx = canvas.getContext('2d')
              let renderContext = { canvasContext: ctx, viewport: viewport }
              canvas.height = viewport.height
              canvas.width = viewport.width

              page.render(renderContext).then(async function() {
                that.$store.state.isRenderingPDFNow = true

                pages.push(ctx.getImageData(0, 0, canvas.width, canvas.height))
                heights.push(height)
                height += canvas.height

                let pageID = that.$parent.genID()
                let imgFile = await that.dataURLtoFile(canvas.toDataURL('image/jpeg', 0.9), `${pageID}.jpg`)

                let currentIndex = that.$store.state.carouselSlide + currentPage - that.fromIndex
                let idTypes = {
                  pageid: pageID,
                  pagetype: 'Quiz',
                  page: currentIndex
                }
                //序列化資料先補一個空的
                that.$parent.saveArr.splice(currentIndex, 0, '')

                //关于新加一页 数据修改
                let originalData = that.$store.state.msgBody
                if (originalData) {
                  for (let i = 0; i < originalData.length; i++) {
                    if (currentIndex + 1 <= originalData[i].page) {
                      originalData[i].page = originalData[i].page + 1
                    }
                  }
                }

                await that.$store.state.imgArr.idArr.splice(currentIndex, 0, idTypes)
                await that.$store.state.pdfOringinalImgsIdArr.splice(currentIndex, 0, idTypes) //存放原圖id
                await that.$parent.uploadResourceFile(imgFile, 'ObjSrcImage').then(async res => {
                  // console.log(res, '上传Resource成功后的返回')
                  await that.$store.state.imgArr.splice(currentIndex, 0, res.url + '?' + that.classInfo.blob.sas_read)
                  await that.$store.state.pdfOringinalImgs.splice(currentIndex, 0, res.url + '?' + that.classInfo.blob.sas_read) //存放原圖連結
                })
                //pdf原圖連結備份在本地
                await sessionStorage.setItem('pdfOringinalImgsIdArr', JSON.stringify(that.$store.state.pdfOringinalImgsIdArr))
                await sessionStorage.setItem('pdfOringinalImgs', JSON.stringify(that.$store.state.pdfOringinalImgs))

                if (currentPage <= that.endIndex - 1) {
                  currentPage++
                  that.renderCount++
                  getPage()
                } else {
                  that.$store.state.totalpage = that.$store.state.imgArr.length

                  if (currentPage > that.endIndex - 1) {
                    that.$store.state.isRenderingPDFNow = false
                    that.closePDFInsert()
                  }
                }
              })
            })
          }
        })
      }
    }
  }
}
</script>

<style lang="less">
@import '../assets/css/color.less';
.ivu-select-item {
  width: 100%;
}
.pdfinsert {
  position: absolute;
  display: block;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  // border-radius: 5px;
  color: black;
  height: 100%;
  z-index: 1002;
  .pdfinsert-card {
    top: 5%;
    transition: 0.5s;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ededed;
    font-weight: bolder;
    padding: 20px 20px 10px 20px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    z-index: 102;
    text-align: center;
    .pdfinsert-title {
      font-size: 16px;
      color: #3d3d3d;
    }
    .textinput {
      border-radius: 5px;
      border: 2px solid @main-color;
      color: black;
      margin-top: 10px;
      width: 100%;
      min-height: 100px;
      font-size: 22px;
      padding: 10px;
    }
    .divide-wrap {
      background-color: #ededed;
      padding: 10px 0px;
      .divide-line {
        background-color: #ededed;
        border-bottom: 0.5px solid rgb(192, 192, 192);
      }
    }
    .pdfinsert-flex {
      //   display: flex;
      .left-part {
        // flex: 1;
        min-width: 400px;
        // margin-right: 20px;
        // position: relative;
        line-height: 30px;
        .left-part-title,
        .left-part-select {
          display: inline-block;
          margin: 0px 5px;
        }
        #pdf-content {
          // flex: 1;
          width: 100%;
          height: auto;
          margin-top: 10px;
          position: relative;
          background: #999;
        }
      }
    }
    .limit-info {
      font-weight: normal;
      text-align: center;
      margin-top: 30px;
    }
    .limit-hint {
      text-align: center;
      color: red !important;
      .limit-num {
        color: red !important;
        font-weight: bold;
      }
    }
    .limit-num {
      color: @main-color;
      font-weight: bold;
    }

    .pdfinsertbtn-group {
      display: flex;
      align-items: center;
      position: relative;
      margin-top: 10px;
      //   bottom: 10px;
      width: 100%;
      .pdfinsert-btn {
        cursor: pointer;
        flex: 1;
        white-space: nowrap;
        padding: 5px 10px;
        margin: 10px 5px;
        border-radius: 5px;
        text-align: center;
        background-color: #d8d8d8;
        &:first-child {
          color: white;
          background-color: @btn-color;
        }
      }
    }
  }
}
</style>
