<template>
  <div class="board-menu irs-news">
    <Tabs :animated="false">
      <TabPane label="消息列表">
        <div class="text-box">
          <!--<h6 style="margin-bottom: 10px;">IRS返回消息</h6>-->
          <ul>
            <li v-for="item in irsBack">来自{{item.name}}：{{item.news}}
            <span class="fr">{{item.time}}</span>
            </li>
          </ul>
        </div>

      </TabPane>
      <!--<TabPane label="上传文件">-->
        <!--<Upload action="http://hiteachcc.chinacloudsites.cn/api/file/UploadDocument"-->
                <!--:on-success="handleUploadSuc">-->
          <!--<Button icon="ios-cloud-upload-outline">选择文件</Button>-->
        <!--</Upload>-->
      <!--</TabPane>-->
      <!--<TabPane label="退出教室">-->
        <!--<p><Button type="primary" @click="退出教室">退出教室</Button></p><br/>-->
      <!--</TabPane>-->
    </Tabs>
  </div>
</template>

<script>
  export default {
    name: "",
    data() {
      return {
        file: null,
        loadingStatus: false,
        userInfo:JSON.parse(sessionStorage.getItem('userInfo')),
        userName:'',
        headImg:'',
        classNum:sessionStorage.getItem('classNum'),
        irsBack:this.$store.state.irsBack,
      }
    },
    methods: {
      handleUploadSuc(response, file, i) {
        this.$store.state.uploadUrl = response.result.data.model.blobUrl;
      },
      userInfoSet(){
        if(this.userInfo){
          this.userName=this.userInfo.fullName;
          this.headImg=this.userInfo.headImgUrl;
        }
      }
    },
    mounted() {

    },
    created(){
      this.userInfoSet();
    },

    computed:{
      watchUrl(){
        return this.$store.state.irsBack;
      }
    },
    watch:{
      watchUrl(e){
        console.log(e)
        if(e){
          this.irsBack=this.$store.state.irsBack
        }
      }
    }
  }
</script>

<style scoped>
  .board-menu.irs-news .text-box li{border-bottom: 1px dashed #ddd;width: 100%;}
  .board-menu.irs-news .text-box li>span{font-size: 12px;margin-top:5px;}
</style>
