<template>
    <div class="pop_box" @click="setBarMode">
      <!--div class="shapes_box">
        <div v-for="(items,index) in shapeList" :key="items.id" class="shapes_icon" @click="zoomType(items.value,index)" :style="currentIndex==index?'color:'+color:''">
          <b :title="items.title" style="font-size: 17px;">{{items.icon}}</b>
          <i v-else :title="items.title"><font-awesome-icon :icon="items.icon"  style="font-size: 20px;"/></i>-->
        <!--/div-->
      <!--/div-->
      <!--br-->
      <div class="slider_box" >
        <p class="menu-title">{{$t('board.toolbox["比例"]')}}：<i style="color: blue;font-size: 12px">{{value}}</i></p>
        <Slider v-model="value" @on-input="size" @on-change="setBarMode"   :min='1' :step="0.2" :max='4' show-tip="never" ></Slider>
      </div>
      <!--<div class="shapes_box">-->
        <!--<div v-for="(items,index) in shapeList"  class="shapes_icon" @click="shapesSelect(items.value,index)" :style="currentIndex==index?'color:'+color:''">-->
          <!--&lt;!&ndash;<q-icon :name="items.icon"/>&ndash;&gt;-->
          <!--<b v-if="index==3" :title="items.title">{{items.icon}}</b>-->
          <!--<i v-else :title="items.title"> <font-awesome-icon :icon="items.icon"/></i>-->
        <!--</div>-->
      <!--</div>-->
    </div>
</template>
<script>
import {color} from 'vuex'
export default {
  name: 'pop',
  data() {
    return {
      color: '#0000ff',
      currentIndex: 0,
      colorArr: [
        { val: '#0000ff', color: 'teal' },
        { val: '#ff9800', color: 'orange' },
        { val: '#f44336', color: 'red' },
        { val: '#00bcd4', color: 'cyan' }
      ],
      shapeList: [
      //   {icon:'search-plus',value:'plus',title:'放大画布'},
      //   {icon:'search-minus',value:'mini',title:'缩小画布'},
      //   { icon: 'hand-rock', value: 'hand', title: '抓手工具' },
        { icon: '1:1', value: 'same', title: '回到原始比例' }
      ],
      button4: 'pencil-alt',
      hex: '#FF00FF',
      standard: 2,
      value: 1,
      strokeColor: '#0000ff;',
      brushType: 'hand'
    }
  },
  methods: {
      zoomType: function (e, index) {
      this.$store.state.zoomType = e
          this.currentIndex = index
          console.log(e,index,'1比1过来的值')
      if (e !== 'same') {
        this.$store.state.zoomType = e
      } else {
        this.value = 1
        //let stage = this.$store.state.stage
        //let primitiveX = stage.absolutePosition();
        //console.log(stage, primitiveX,'缩放的stage')
        //stage.scale({ x: 1, y: 1 })
        //stage.x(primitiveX.x)
        //stage.y(0)
        //stage.draw()
      }
    },
    size: function(e) {
      this.$store.state.zoom = e
    },
    setBarMode(){
      this.$store.state.zoomMode='bar'
    }
   

  },
  created() {

  },
  computed: {
    watchZoom() {
      return this.$store.state.zoom
    }
  },
  watch: {
      watchZoom(e) {
          // console.log(e,'缩放比例的值')
      if (e) {
        this.value = parseFloat(this.$store.state.zoom)
      }
    }
  }
}
</script>
<style>

  .pop_box .ivu-poptip-popper{
    left:-260px !important;
    min-height: 0;
  }

  .pop_box .ivu-radio-group-button .ivu-radio-wrapper-checked, .ivu-radio-group-button .ivu-radio-wrapper:hover{
    color:#0000ff;

  }
  .pop_box .ivu-radio-group-button .ivu-radio-wrapper{
    padding:0 20px 0 0 !important;
  }
  .pop_box .ivu-transfer-no-max-height{
    transform-origin: center top;
    position: absolute;
    will-change: top, left;
    top: 32px;
    left: 3px !important;
  }
  .pop_box .ivu-color-picker-saturation-wrapper{
    padding-bottom: 0% !important;
  }
  .pop_box .ivu-color-picker-picker-hue-slider{
    height:0px !important;;
  }

</style>
<style scoped>
  .pop_box{padding:0 8px;overflow: hidden;}
  .slider_box{
    width:190px;
    padding: 5px;
  }
  .ColorPicker_box{
    width:190px;
    padding: 5px;
  }
  .secondary_title{
    text-align: left;
  }

  .q-color-picker__cube{
    border-radius: 50%;
    box-sizing: border-box;
  }
  .pattern_box{
    width:200px;
    /*height:130px;*/
  }
  .shapes_box{
    overflow: hidden;
    /*width:96%;*/
    /*height:45px;*/
    /*padding: 2%;*/
    margin-top:10px;
  }
  .shapes_icon,.shapes_icon_bg{
    width:25%;
    height:30px;
    display: block;
    float: left;
  }
  .shapes_icon >.svg-inline--fa{
    font-size:18px;
  }
  .shapes_icon_bg>.svg-inline--fa{
    font-size:20px;
  }

  .ColorPicker_box{
    width:190px;
    padding: 5px;
  }
  .menu-title {
  font-weight: bolder;
  text-align: left;
  margin-left: -5px;
}

</style>
