<template>
  <div class="code_box ">
      <div class="QRcode tc">
          <!--<img src="../../assets/img/QRcode.jpg" style="opacity: 0.8"/>-->
          <div id="qrcode" ref="qrcode" align="center"></div>
          <h4 style="font-size: 52px; text-align: center; color: #343434; font-weight: normal; font-family: robotoregular,Helvetica,Arial,sans-serif;"><b>{{qrcodeclassnum}}</b></h4>
          <p>请参与者使用&nbsp;<i style="color:green">微信扫一扫</i> 、&nbsp;<i style="color:red"></i> &nbsp;<i style="color:black">浏览器</i>&nbsp;扫码参与互动</p>
      </div>
  </div>
</template>
<script>
import QRCode from 'qrcodejs2'
    export default {
        name: 'QRcode',
        components: {
            QRCode
        },
        data() {
            return {
                qrcodeclassnum: '',
                shareQRInfo: {
                    gn: '',
                    pin: '',
                    host: '',
                },
                shareQRCode: '',
                jointUrl:'',
            }
        },
        mounted(){
           setTimeout(()=>{
                this.init()
                 this.payOrder()
           },2000);
        },
        methods: {
            init() {
                let classnum = sessionStorage.getItem('classNum')
                console.log(classnum)
                this.qrcodeclassnum=classnum
            },
            payOrder() {
                let classnum = sessionStorage.getItem('classNum')
                // let classname = sessionStorage.getItem('classname')
                // let TeachDeviceId = sessionStorage.getItem('deviceId')
                this.shareQRInfo.gn = classnum //教室編號
                this.shareQRInfo.pin = "8888" //4數字驗證碼
                this.shareQRInfo.host = "CC" //必須是大寫的CC代表教師端類型
                this.shareQRCode = 'https://irs5.teammodel.cn/?s=' + this.encode64(JSON.stringify(this.shareQRInfo))  //大陸數據中心, 全球就要另外換
                this.innerVisible = true
                // 二维码内容 地址內容
                this.qrcode = this.shareQRCode
                // 使用$nextTick确保数据渲染
                this.$nextTick(() => {
                    this.crateQrcode()
                })
            },
            crateQrcode() {
                this.qr = new QRCode('qrcode', {
                    width: 480,
                    height: 480, // 高度
                    text: this.qrcode, // 二维码内容
                    // render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
                    // background: '#f0f'
                    // foreground: '#ff0'
                    correctLevel:QRCode.CorrectLevel.Q
                })
            },
            // 关闭弹框,清除已经生成的二维码
            closeCode() {
                this.$refs.qrcode.innerHTML = ''
            },
            //base64  
            decode64(input) {
                try {
                    return decodeURIComponent(escape(window.atob(unescape(input))));
                } catch (e) {
                    return ''
                }
            },
            encode64(input) {
                try {
                    return escape(window.btoa(unescape(encodeURIComponent(input))));
                } catch (e) {
                    return ''
                }
            },
        },
  }
</script>
<style scoped>
.code_box{
  display: flex;
  justify-content:center;
  padding-top:40px;
}
.code_box img{
  width:300px;
}
.QRcode{
  width: 100%;

}
.code_box p{
  width: 100%;
  color: #666;
  /*opacity: 0.8;*/
  background: #eee;
  padding:15px 10px;
  margin-top:30px;
}
    #qrcode {
     width:100%;
     margin:0 auto;
     padding-bottom:20px;
    }
</style>
