<template>
    <div class="pop_box">
     <div class="slider_box">

       <p style="text-align: left">大小：<b style="color: #0000ff;font-size: 14px">{{value}}</b></p>
       <Slider v-model="value"  @on-change="size" :max="10" show-tip="never"></Slider>
     </div>
     <div class="ColorPicker_box ">
       <p style="text-align: left">颜色：</p>
       <div class="q-gutter-sm" style="padding:10px 0;">
         <q-radio v-for="item in colorArr" :key="item.id" keep-color dense v-model="color" :val="item.val" :color="item.color" @input="chioceColor"/>
       </div>
       <!--<q-color v-model="hex" no-header no-footer default-view="palette" class="my-picker" @change="ss" :palette="[ '#eee','#0000ff', '#D9B801', 'rgb(23,120,0)', '#B2028A' ]"/>-->
     </div>
    </div>
</template>

<script>
import {color} from 'vuex'
export default {
  name: 'pop',
  data() {
    return {
      color: 'cyan',
      colorArr: [
        { val: '#0000ff', color: 'teal' },
        { val: '#ff9800', color: 'orange' },
        { val: '#f44336', color: 'red' },
        { val: '#00bcd4', color: 'cyan' }
      ],
      button4: 'brush',
      hex: '#FF00FF',
      standard: 2,
      value: 2
    }
  },
  methods: {
    chioceColor: function(e) {
      this.$store.state.laserColor = e
      console.log(e)
    },
    size: function(e) {
      this.$store.state.laserStrokeWidth = e
    }
  }
}
</script>
<style>
  .ivu-transfer-no-max-height{
    transform-origin: center top;
    position: absolute;
    will-change: top, left;
    top: 32px;
    left: 3px !important;
  }
  .ivu-color-picker-saturation-wrapper{
    padding-bottom: 0% !important;
  }
  .ivu-color-picker-picker-hue-slider{
    height:0px !important;;
  }
  .q-color-picker__cube,.col-auto{
    margin: 4px;
  }
  .q-color-picker{
    box-shadow:none !important;
  }
</style>
<style scoped>
.pop_box{padding:0 8px;overflow: hidden;}
.slider_box{
  width:190px;
  padding: 5px;
}
.ColorPicker_box{
  width:190px;
  padding: 5px;
}
  .secondary_title{
    text-align: left;
  }
</style>
