<template>
  <div class="button-timer" >
    <div v-show="isOpenTimeNumSetting == false">
      <div class="time-num-wrap" :class="{ 'light-timenum': minuteVal != '00' || secondVal != '00','small-timer': this.$store.state.timerIsStart == true }" >
        <span class="time-num-inner-wrap-left" @click="openTimeNumSetting(2)">{{ minuteVal }}&nbsp;</span>:<span @click="openTimeNumSetting(4)" class="time-num-inner-wrap-right" >&nbsp;{{ secondVal }}</span></div>
      <audio id="audio1" src="../../assets/audio/de001.wav"></audio>
      <audio id="audio2" src="../../assets/audio/buko001.wav"></audio>
      <div class="flex timer-tools">
        <Icon :type="this.$store.state.timerIsStart ? 'md-pause' : 'md-play'" :title="$t('board.toolbox.開始計時')" @click="countDown" :disable="second == 0 || isStart" v-if="this.$store.state.timerIsStart === false" />
        <Icon type="md-pause" :title="$t('board.toolbox.暫停計時')" @click="onhandlePause" v-if="this.$store.state.timerIsStart === true" />
        <Icon type="md-refresh" :title="$t('board.toolbox.重置')" @click="handleReset" />
        <!-- <Icon type="md-stopwatch" title="记次"  @click="handleCountItem"  :disable="!countTime.isTiming"/> -->
      </div>
      <div class="time-btn-wrap" v-show="!this.$store.state.timerIsStart">
        <div
          class="time-btn-grid"
          @click="
            minuteVal = '00'
            secondVal = '10'
          "
        >
          <div class="time-btn"><svg-icon icon-class="timer" class="stroke-icon" /> 10 {{ $t('board.toolbox.秒') }}</div>
        </div>
        <div
          class="time-btn-grid"
          @click="
            minuteVal = '00'
            secondVal = '30'
          "
        >
          <div class="time-btn"><svg-icon icon-class="timer" class="stroke-icon" /> 30 {{ $t('board.toolbox.秒') }}</div>
        </div>
        <div
          class="time-btn-grid"
          @click="
            minuteVal = '01'
            secondVal = '00'
          "
        >
          <div class="time-btn"><svg-icon icon-class="timer" class="stroke-icon" /> 1 {{ $t('board.toolbox.分') }}</div>
        </div>
        <div
          class="time-btn-grid"
          @click="
            minuteVal = '03'
            secondVal = '00'
          "
        >
          <div class="time-btn"><svg-icon icon-class="timer" class="stroke-icon" /> 3 {{ $t('board.toolbox.分') }}</div>
        </div>
      </div>
    </div>
    <div v-show="isOpenTimeNumSetting == true">
      <div class="time-num-wrap block">
        <div class="time-setbtn" :class="{ 'time-setEditbtn': currentEditVal == 1 }" @click="setCurrentEditVal(1)">{{ minuteVal[0] }}</div>
        <div class="time-setbtn" :class="{ 'time-setEditbtn': currentEditVal == 2 }" @click="setCurrentEditVal(2)">{{ minuteVal[1] }}</div>
        :
        <div class="time-setbtn" :class="{ 'time-setEditbtn': currentEditVal == 3 }" @click="setCurrentEditVal(3)">{{ secondVal[0] }}</div>
        <div class="time-setbtn" :class="{ 'time-setEditbtn': currentEditVal == 4 }" @click="setCurrentEditVal(4)">{{ secondVal[1] }}</div>
      </div>
      <div class="time-num-keyboard">
        <div v-for="item in 9" :key="item" class="time-numbtn-grid">
          <div class="time-numbtn" @click="setNum(item)" :class="{ 'disable-numbtn': timenumbtnShowhide(item) == false }">
            {{ item }}
          </div>
        </div>
        <div class="time-numbtn-grid">
          <div class="time-numbtn" @click="prebtn()">
            <svg-icon icon-class="prev" class="prev-icon" />
          </div>
        </div>
        <div class="time-numbtn-grid">
          <div class="time-numbtn" @click="setNum(0)">
            0
          </div>
        </div>
        <div class="time-numbtn-grid">
          <div class="time-numbtn" @click="nextbtn()">
            <svg-icon icon-class="next" class="next-icon" />
          </div>
        </div>
        <div class="time-numbtn-grid">
          <div class="ok-btn" @click="closeTimeNumSetting()">
            OK
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonTimer',
  data() {
    return {
      currentEditVal: 4,
      minuteVal: '00',
      secondVal: '00',
      second: 0,
      timeleft: 0,
      timer: null,
      timeStartCount: 0,
      isOpenTimeNumSetting: false
    }
  },
  computed: {
    timerIsStart() {
      return this.$store.state.timerIsStart
    }
  },
  watch: {
    timerIsStart(value) {
      if (value == false) {
        this.stopAudio('audio1')
        this.stopAudio('audio2')
      }
    }
  },
  methods: {
    prebtn() {
      if (this.currentEditVal > 1) {
        this.currentEditVal--
      } else {
        this.currentEditVal = 4
      }
    },
    nextbtn() {
      if (this.currentEditVal < 4) {
        this.currentEditVal++
      } else {
        this.currentEditVal = 1
      }
    },
    timenumbtnShowhide(item) {
      if (this.currentEditVal == 1 || this.currentEditVal == 3) {
        return item < 7
      } else {
        return true
      }
    },
    closeTimeNumSetting() {
      this.isOpenTimeNumSetting = false
    },
    openTimeNumSetting(val) {
      if (!this.$store.state.timerIsStart) {
        this.handleReset()
        this.isOpenTimeNumSetting = true
        this.currentEditVal=val
      }
    },
    setNum(item) {
      if (this.currentEditVal == 1 && item < 7) {
        this.minuteVal = item + this.minuteVal[1]
      }
      if (this.currentEditVal == 2) {
        this.minuteVal = this.minuteVal[0] + item
      }
      if (this.currentEditVal == 3 && item < 7) {
        this.secondVal = item + this.secondVal[1]
      }
      if (this.currentEditVal == 4) {
        this.secondVal = this.secondVal[0] + item
      }
    },
    setCurrentEditVal(val) {
      this.currentEditVal = val
    },
    // 计算剩余时间
    getTimeLeft() {
      // 计算剩余时间并返回
      this.timeleft = parseInt(this.minuteVal) * 60 + parseInt(this.secondVal)
    },
    countDown() {
      let that = this

      console.log('先重置')
      this.timeStartCount=0
      let timer = this.timer
      // 清除当前定时任务
      clearInterval(timer)
      // 清除原来的值, 还原计时器的初始值. 恢复默认的null
      this.timer = null
     
      this.$store.state.timerIsStart = false
      this.timeleft = 0
      this.stopAudio('audio1')
      this.stopAudio('audio2')

      // 获取剩余时间
      this.getTimeLeft()
      this.$store.state.timerIsStart = true
      // 开启是一个定时任务, 每秒执行一次
      this.timer = setInterval(function() {
        that.timeleft--
        that.timeStartCount++
        // 如果剩余时间, 小于0, 执行暂停按钮的逻辑, 停止定时任务
        if ((that.timeleft >= 1 && that.timeleft < 6) || that.timeStartCount < 6) {
            //確保顯示不是零才播
            if(that.minuteVal != '00'||that.secondVal != '00'){
                 that.playAudio('audio1')
            }
         
        }
        if (that.timeleft == 0) {
          that.stopAudio('audio1')
          that.playAudio('audio2')
        }
        if (that.timeleft < 0) {
          that.stopAudio('audio2')
          clearInterval(that.timer)
          that.timer = null
          that.$store.state.timerIsStart = false
          console.log('倒计时结束!') // 输出弹框
          that.timeStartCount = 0
          return // 终止函数
        }
        // 每一秒, 总的剩余时间变化的时候, 都会执行
        that.showTime()
      }, 1000)
    },
    onhandlePause() {
      console.log('暫停')
      let that = this
      this.getTimeLeft()
      this.$store.state.timerIsStart = false
      clearInterval(that.timer)
    },
    handleReset() {
      console.log('重置')
      this.timeStartCount=0
      let timer = this.timer
      // 清除当前定时任务
      clearInterval(timer)
      // 清除原来的值, 还原计时器的初始值. 恢复默认的null
      this.timer = null
      this.minuteVal = '00'
      this.secondVal = '00'
      this.$store.state.timerIsStart = false
      this.timeleft = 0
      this.stopAudio('audio1')
      this.stopAudio('audio2')
    },

    // 显示时间, 把剩余时间拆分成, 分钟数和秒数, 分别写入到span里面
    showTime() {
      let m = parseInt(this.timeleft / 60)
      let s = parseInt(this.timeleft % 60)
      this.minuteVal = m < 10 ? '0' + m : m + ''
      this.secondVal = s < 10 ? '0' + s : s + ''
    },
    playAudio(id) {
      let audio = document.getElementById(id)
      if (audio) {
        let isPlaying = audio.currentTime > 0 && !audio.paused && !audio.ended && audio.readyState > audio.HAVE_CURRENT_DATA
        if (!isPlaying) {
          audio.play()
        }else{
          this.stopAudio('audio1')
          this.stopAudio('audio2')
          audio.play()
        }
      }
    },
    stopAudio(id) {
      let audio = document.getElementById(id)
      if (audio) {
        let isPlaying = audio.currentTime > 0 && !audio.paused && !audio.ended && audio.readyState > audio.HAVE_CURRENT_DATA
        if (isPlaying) {
          audio.pause()
          audio.currentTime = 0
        }
      }
    }
  },
  beforeDestroy(){
      clearInterval(this.timer)
      this.timeleft= 0
      this.timer=null
  }
  
}
</script>

<style lang="less">
@import '../../assets/css/color.less';
.button-timer {
  .timer-tools {
    * {
      &:hover,
      &:active {
        color: @main-color;
      }
    }
  }
  .light-timenum {
    color: @main-color !important;
  }
  .small-timer{
      padding:2px 20px;
  }
  
  .time-num-wrap {
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    font-weight: 500;
    font-size: 50px;
    color: gray;
    text-align: center;
    cursor: pointer;
    display: flex;
    width: calc(~'100%-20px');
    margin: 10px;
    .time-num-inner-wrap-left{
      text-align: right;
      flex:1;
      display: inline-block;
    }
    .time-num-inner-wrap-right{
      text-align: left;
      flex:1;
      display: inline-block;
    }
    .time-setbtn {
      background-color: darken(rgb(255, 255, 255), 5%);
      display: inline-block;
      border: 2px solid gray;
      margin: 2px;
      padding: 0px 10px;
      border-radius: 5px;
      cursor: pointer;
    }
    .time-setEditbtn {
      background-color: rgb(255, 255, 255);
      color: @main-color;
      border: 2px solid @main-color;
    }
  }

  .time-btn-wrap {
    display: flex;
    flex-wrap: wrap;
    .time-btn-grid {
      flex: 1;
      min-width: 50%;
      padding: 10px;

      .time-btn {
        border-radius: 5px;
        padding: 10px;
        text-align: center;
        background-color: #fff;
        font-size: 20px;
        color: black;
        cursor: pointer;
        border: 1px solid transparent;
        font-weight: 500;
        .stroke-icon {
          stroke: black;
          fill: transparent !important;
        }
        &:hover {
          // background-color: darken(#fcfcfc, 10%);
          .stroke-icon {
            stroke: @main-color;
            fill: transparent !important;
          }
          color: @main-color;
          border: 1px solid @main-color !important;
        }
      }
    }
  }
  .time-num-keyboard {
    display: flex;
    flex-wrap: wrap;
    margin: 5px;

    .time-numbtn-grid {
      flex: 1;
      min-width: 33%;
      padding: 5px;
      .ok-btn {
        background-color: @main-color;
        color: white;
        text-align: center;
        font-weight: bold;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        font-size: 20px;
        margin-top: 20px;
      }
      .disable-numbtn {
        color: rgba(128, 128, 128, 0.3) !important;
        &:hover {
          color: rgba(128, 128, 128, 0.3);
          border: 2px solid transparent !important;
        }
      }
      .time-numbtn {
        border-radius: 5px;
        padding: 2px;
        text-align: center;
        background-color: #fff;
        font-size: 20px;
        color: black;
        cursor: pointer;
        border: 2px solid transparent;
        font-weight: 500;

        &:hover {
          color: @main-color;
          border: 2px solid @main-color;
        }
      }
      .prev-icon,
      .next-icon {
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
      }
    }
  }
}
</style>
