<template>
  <div class="pattern_box">
    <!--<p style="text-align: left">图形选择：</p>-->
    <!--<div class="shapes_box">-->
    <!--<div v-for="(items,index) in colorList"  class="shapes_icon" @click="shapesSelect(items.value,index)" :style="currentIndex==index?'color:'+hex:''">-->
    <!--<font-awesome-icon :icon="items.value" />-->
    <!--</div>-->
    <!--</div>-->
    <p class="menu-title">{{$t('board.toolbox["圖形"]')}}</p>
    <div class="shapes_box">
      
      <div v-for="(items, index) in shapeList" :key="index" class="shapes_icon" :title="items.title" @click="shapesSelect(items.value, items.icon, index)" :style="currentIndex == index ? 'color:' + color2 : ''">
        <svg-icon  v-if="items.value == 'long-arrow-alt-right'" icon-class="add_arrow2" class="fill-icon" style="position:relative;left:3px;font-size: 21px !important;"/>
        <q-icon :name="items.icon" v-if="items.value == 'line'" style="transform: rotate(-45deg)" />
        <q-icon :name="items.icon" v-else-if="items.value == 'ellipse'" style="transform: scaleX(1.3);font-size: 21px !important;" />
        <q-icon :name="items.icon" v-if="items.value != 'long-arrow-alt-right'&&items.value != 'ellipse'&&items.value != 'line'" />
      </div>
    </div>
    <br>
    <div class="ColorPicker_box">
      <p class="menu-title">{{$t('board.toolbox["邊框"]')}}</p>
      
        <div class="q-gutter-sm" style="padding:10px 0 2px 0;">
          <div class="color-btn" :style="{ 'border-color': item.val, 'border-width':color2 == item.val?'5px':'2px' }" v-for="(item, index) in colorArr2" :key="item.id" v-show="index < 5" @click="borderSelect(item)" />
        </div>
        <div class="q-gutter-sm" style="padding:2px 0;">
          <div class="color-btn" :style="{ 'border-color': item.val, 'border-width':color2 == item.val?'5px':'2px' }" v-for="(item, index) in colorArr2" :key="item.id" v-show="index >= 5" @click="borderSelect(item)" />
        </div>
    
      <!--<q-color no-header no-footer default-view="palette" class="my-picker" @change="borderColor" :palette="['#0000ff', '#D9B801', 'rgb(23,120,0)', '#B2028A' ]"/>-->
    </div>
    <br>
    <div class="ColorPicker_box ">
      <p class="menu-title">{{$t('board.toolbox["填充"]')}}</p>
     
        <div class="q-gutter-sm" style="padding:10px 0 2px 0;">
          <div class="color-btn" :class="{ 'select-color': color == item.val }" :style="{ 'background-color': item.val }" v-for="(item, index) in colorArr" :key="item.id" v-show="index < 5" @click="colorSelect(item)" />
        </div>
        <div class="q-gutter-sm" style="padding:2px 0;">
          <div class="color-btn" :class="{ 'select-color': color == item.val }" :style="{ 'background-color': item.val }" v-for="(item, index) in colorArr" :key="item.id" v-show="index >= 5" @click="colorSelect(item)" />
        </div>
    
      <!--<q-color v-model="hex" no-header no-footer default-view="palette" class="my-picker" @change="colorSelect" :palette="['#0000ff', '#D9B801', 'rgb(23,120,0)', '#B2028A' ]"/>-->
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      color: 'transparent',
      colorArr: [
        { val: 'red', color: 'red' },
        { val: 'orange', color: 'orange' },
        { val: 'yellow', color: 'yellow' },
        { val: 'cyan', color: 'cyan' },
        { val: 'transparent', color: 'transparent' },
        { val: 'brown', color: 'brown' },
        { val: 'purple', color: 'purple' },
        { val: 'green', color: 'green' },
        { val: '#0000ff', color: '#0000ff' },
        { val: 'black', color: 'black' }
      ],

      color2: 'red',
      colorArr2: [
        { val: 'red', color: 'red' },
        { val: 'orange', color: 'orange' },
        { val: 'yellow', color: 'yellow' },
        { val: 'cyan', color: 'cyan' },
        { val: '#e0e0e0', color: '#e0e0e0' },
        { val: 'brown', color: 'brown' },
        { val: 'purple', color: 'purple' },
        { val: 'green', color: 'green' },
        { val: '#0000ff', color: '#0000ff' },
        { val: 'black', color: 'black' }
      ],

      hex: 'red',
      colorList: [
        // 默认选中的图形
        { id: 5, value: 'long-arrow-alt-right', title: '' },
        { id: 2, value: 'circle', title: '' },
        { id: 3, value: 'ellipse', title: '' },
        { id: 4, value: 'square', title: '' },
        { id: 1, value: 'campground', title: '' },
        { id: 6, value: 'star', title: '' },
       
      ],
      shapeList: [
        { icon: 'trending_flat', value: 'long-arrow-alt-right', title: this.$t('board.toolbox.箭頭') },
        { icon: 'remove', value: 'line', title: this.$t('board.toolbox.直線') },
        { icon: 'panorama_fish_eye', value: 'circle', title: this.$t('board.toolbox.圓形') },
        { icon: 'panorama_fish_eye', value: 'ellipse', title: this.$t('board.toolbox.橢圓') },
        { icon: 'check_box_outline_blank', value: 'square', title: this.$t('board.toolbox.矩形') },
        { icon: 'change_history', value: 'campground', title: this.$t('board.toolbox.三角形') },
        { icon: 'star_border', value: 'star', title: this.$t('board.toolbox.星形') },
      
        
        // {icon:'text_fields',value:'text',title:'文字'},
      ],
      currentIndex: 0,
      shapeColor: 'red',
      borderColor: 'red'
    }
  },
  created() {},
  mounted(){
    // this.shapesSelect('long-arrow-alt-right','trending_flat',5)
  },
  methods: {
    shapesSelect: function(value, icon, index) {
      this.currentIndex = index
      this.$store.state.shapeType = value
      this.$store.state.shapeTypeIcon = icon
    },
    colorSelect: function(item) {
      this.$store.state.shapeColor = item.val
      this.color = item.val
    },
    borderSelect: function(item) {
      this.$store.state.shapeBorderColor = item.val
      this.color2 = item.val
    }
  }
}
</script>

<style lang="less" scoped>
.q-color-picker__cube {
  border-radius: 50%;
  box-sizing: border-box;
}
.pattern_box {
  width: 210px;
  padding:8px 12px
  /*height:130px;*/
}

.shapes_box {
  overflow: hidden;
  /*width:96%;*/
  /*height:45px;*/
  /*padding: 2%;*/
  margin-top: 10px;
}
.shapes_icon,
.shapes_icon_bg {
  width: 25%;
  height: 30px;
  display: block;
  float: left;
}
.shapes_icon > .svg-inline--fa {
  font-size: 18px;
}
.shapes_icon_bg > .svg-inline--fa {
  font-size: 20px;
}

.ColorPicker_box {
  width: 190px;
}

.color-btn {
  height: 24px;
  width: 24px;
  border: 2px solid white;
  margin: 8px;
  display: inline-block;
  box-shadow: 0px 0px 2px gray;
  border-radius: 50%;
}
.select-color {
  border: 0px solid rgba(223, 223, 223, 0);
  box-shadow: 0px 0px 2px gray;
}
.menu-title {
  font-weight: bolder;
  text-align: left;
}
</style>
