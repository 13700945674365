<template>
    <div class="vertical-tools">
      <!--<div class="slider_box">-->
        <!--<RadioGroup v-model="button4"  type="button" @on-change="penType" style="padding:10px 0;">-->
          <!--<Radio label="pencil-alt" title="通用画笔"><font-awesome-icon icon="pencil-alt" style="font-size: 20px;" /></Radio>-->
          <!--<Radio label="highlighter" title="高光笔"><font-awesome-icon icon="highlighter" style="font-size: 20px;"/></Radio>-->
        <!--</RadioGroup>-->
      <!--</div>-->
      <div class="shapes_box">
        <div v-for="(items,index) in shapeList" :key="index"  class="shapes_icon" @click="shapesSelect(items.value,index)" :style="currentIndex==index?'color:'+color:''">
          <i v-if="index<1" :title="items.title" :class="'fab fa-'+items.icon" ></i>
          <i v-else :title="items.title"> <font-awesome-icon :icon="items.icon"/></i>
        </div>
      </div>
    </div>
</template>

<script>
import {color} from 'vuex'
export default {
  name: 'pop',
  data() {
    return {
      color: '#0000ff',
      currentIndex: 0,
      colorArr: [
        { val: '#0000ff', color: 'teal' },
        { val: '#ff9800', color: 'orange' },
        { val: '#f44336', color: 'red' },
        { val: '#00bcd4', color: 'cyan' }
      ],
      shapeList: [
        { icon: 'quora', value: 'quora', title: '即问即答' },
        { icon: 'concierge-bell', value: 'concierge-bell', title: '抢权' },
        //{ icon: 'font', value: 'font', title: '文字' }

      ],
      button4: 'pencil-alt',
      hex: '#FF00FF',
      standard: 2,
      value: 2,
      strokeColor: '#0000ff;'
    }
  },
  methods: {
    shapesSelect: function(value, index) {
      let that = this
      this.currentIndex = index
      this.$store.state.ques = value
      if (index === 0) {

      } else if (index === 1) {

      } else if (index === 2) {
        that.$parent.$parent.irsNews()
      }
    }

  },
  created() {

  }
}
</script>
<style scoped>

</style>
