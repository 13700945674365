<template>
  <div class="pop_box" :class='{"textinputview-style":this.$store.state.showtextInuptCard}'>
    <div class="slider_box">
      <p  class="menu-title">
       {{$t('board.toolbox["字體大小"]')}}：<b style="color: blue;font-size: 14px">{{ value }}</b>
      </p>
      <Slider v-model="value" :step="1" @on-change="size" :min="20" :max="72"></Slider>
    </div>
    <div class="ColorPicker_box ">
      <p class="menu-title">{{$t('board.toolbox["顏色"]')}}</p>

      <div class="q-gutter-sm" style="padding:10px 0 2px 0;">
        <div class="color-btn" :class="{ 'select-color': color == item.val }" :style="{ 'background-color': item.val }" v-for="(item, index) in colorArr" :key="item.id" v-show="index < 5" @click="chioceColor(item)" />
      </div>
      <div class="q-gutter-sm" style="padding:2px 0;">
        <div class="color-btn" :class="{ 'select-color': color == item.val }" :style="{ 'background-color': item.val }" v-for="(item, index) in colorArr" :key="item.id" v-show="index >= 5" @click="chioceColor(item)" />
      </div>
      <!--<q-color v-model="hex" no-header no-footer default-view="palette" class="my-picker" @change="ss" @input="cc" :palette="['#2d8cf0', '#0000ff', '#D9B801', 'rgb(23,120,0)', '#B2028A' ]"/>-->
    </div>
  </div>
</template>

<script>
import { color } from 'vuex'
export default {
  name: 'pop',
  props:['updateTextPickerKey'],
  data() {
    return {
      button4: 'brush',
      hex: '#FF00FF',
      standard: 2,
      value: this.$store.state.textSize,
      color: this.$store.state.textColor,
      colorArr: [
        { val: 'red', color: 'red' },
        { val: 'orange', color: 'orange' },
        { val: 'yellow', color: 'yellow' },
        { val: 'cyan', color: 'cyan' },
        { val: '#e0e0e0', color: '#e0e0e0' },
        { val: 'brown', color: 'brown' },
        { val: 'purple', color: 'purple' },
        { val: 'green', color: 'green' },
        { val: '#0000ff', color: '#0000ff' },
        { val: 'black', color: 'black' }
      ]
    }
  },

  watch:{
    updateTextPickerKey:function(){
      this.value=this.$store.state.textSize
      this.size(this.value)
      this.color=this.$store.state.textColor
      // this.chioceColor(this.color)
    }
  },
  methods: {
    ss: function(e) {
      this.$store.state.laserColor = e
      // this.$emit('func',e)
    },
    size: function(e) {
      this.$store.state.textSize = e
    },
    chioceColor: function(item) {
      this.$store.state.textColor = item.val
      this.color = item.val
    }
  }
}
</script>
<style lang='less'>
.ivu-transfer-no-max-height {
  transform-origin: center top;
  position: absolute;
  will-change: top, left;
  top: 32px;
  left: 3px !important;
}
.ivu-color-picker-saturation-wrapper {
  padding-bottom: 0% !important;
}
.ivu-color-picker-picker-hue-slider {
  height: 0px !important;
}
.q-color-picker__cube,
.col-auto {
  margin: 4px;
}
.q-color-picker {
  box-shadow: none !important;
}

</style>

<style scoped lang='less'>
.pop_box {
  padding: 0 8px;
  overflow: hidden;
}

/* .ColorPicker_box {
  width: 190px;
  padding: 5px;
} */
.secondary_title {
  text-align: left;
}
.menu-title {
  font-weight: bolder;
  text-align: left;
}
.color-btn {
  height: 24px;
  width: 24px;
  border: 2px solid white;
  margin: 8px;
  display: inline-block;
  box-shadow: 0px 0px 2px gray;
  border-radius: 50%;
  cursor: pointer;
}
.slider_box {
  width:100%;
  padding: 5px;
}
.select-color {
  border: 0px solid rgba(223, 223, 223, 0);
  box-shadow: 0px 0px 2px gray;
}
.textinputview-style{
  .color-btn {
  height: 36px !important;
  width: 36px !important;
  border: 2px solid white;
  margin: 8px;
  display: inline-block;
  box-shadow: 0px 0px 2px gray;
  border-radius: 50%;
}
.select-color {
  border: 0px solid rgba(223, 223, 223, 0);
  box-shadow: 0px 0px 2px gray;
}
}
</style>
