<template>
  <div class="turn-table" :key="renderKey">
    <div id="pick-target" class="pick-target" v-show="isShowPickTarget == true">
      <div v-show="this.pickTarget.img != ''"><img crossOrigin="anonymous" class="pick-default-img" :src="this.pickTarget.img" /></div>
      <div v-show="this.pickTarget.img == ''" class="pick-default-img"></div>
      <div class="pick-info">
        <div class="pick-sort">{{ this.pickTarget.sort }}</div>
        <span v-show="this.pickTarget.name != ''" class="pick-text">{{ this.pickTarget.name }}</span>
      </div>
    </div>
    <div class="paste-btn" @click="pasteTargetMode()" v-show="isShowPickTarget == true"><svg-icon class="paste-icon" icon-class="HTPaste" /></div>
    <div class="container">
      <section class="turntable">
        <div class="list" id="list">
          <ul :style="{ transform: `rotate(${this.rotatedeg}deg)` }" :class="{ go: isRotating }">
            <li v-for="item in prize_list" :key="item.index">
              <p class="list-name">{{ item.name }}</p>
              <p>{{ item.sort }}</p>
            </li>
          </ul>
        </div>
        <button class="turntable_btn" type="button" @click="pickOne()">pick</button>
        <svg viewBox="-50 -50 100 100">
          <g class="polyline" :style="{ transform: `rotate(${this.rotatedeg}deg)` }" :class="{ go: isRotating }">
            <polyline points="-16,-38 16,-38 0,0"></polyline>
            <polyline points="16,-38 38,-16 0,0"></polyline>
            <polyline points="38,-16 38,16 0,0"></polyline>
            <polyline points="0,0 38,16 16,38"></polyline>
            <polyline points="0,0 16,38 -16,38"></polyline>
            <polyline points="0,0 -16,38 -38,16"></polyline>
            <polyline points="-38,-16 -38,16 0,0"></polyline>
            <polyline points="-16,-38 -38,-16 0,0"></polyline>
          </g>
          <circle cx="0" cy="0" r="40" :class="{ go: isRotating }"></circle>

          <g class="mark">
            <!--circle cx="0" cy="-43" r="4"></circle-->
            <polyline points="-5.5,-45 5.5,-45 0,-35"></polyline>
            <!--circle class="round" cx="0" cy="-43" r="1.5"></circle-->
          </g>
        </svg>
      </section>
    </div>
    <div v-show="$store.state.turnTableＭode != 'Normal'" @click="changeToNormal" class="refresh_btn"><svg-icon icon-class="refresh" /></div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import pickSound from '../../assets/audio/pick.mp3'
export default {
  name: 'TurnTable',
  data() {
    return {
      renderKey: 1,
      prize_list: [],
      nameList: this.$store.state.students,
      iEnd: -1,
      targetPrizeElement: '',
      rotatedeg: 0,
      isRotating: false,
      isShowPickTarget: false,
      pickTarget: {
        name: '',
        sort: '',
        img: ''
      }
    }
  },
  created() {
    for (let i = 1; i <= 8; i++) {
      this.prize_list.push({
        name: '',
        sort: i,
        img: ''
      })
    }
    console.log(this.prize_list, 'Init prize_list')
  },
  methods: {
    changeToNormal() {
      this.$Message.info(this.$t("toolbox['重設為一般挑人模式']"))
      this.$store.state.turnTableＭode = 'Normal'
      this.pickOne()
    },
    pasteTargetMode() {
      // this.$Message.loading(this.$t("board['物件轉換中請稍候']"))
      if (this.isShowPickTarget == true) {
        this.pasteTargetByKonvaObj()
      }
    },
    pasteTarget() {
      let that = this
      html2canvas(document.getElementById('pick-target'), {
        useCORS: true
      }).then(canvas => {
        let imageObj = new Image()
        imageObj.setAttribute('crossOrigin', 'Anonymous')
        imageObj.src = canvas.toDataURL('image/jpeg', 0.4)
        imageObj.onload = function() {
          let stage = that.$store.state.stage
          let layer = that.$store.state.layer

          Konva.Image.fromURL(imageObj.src, function(image) {
            // image is Konva.Image instance

            image.setAttrs({
              x: stage.width() * 0.2 + Math.floor(Math.random() * 10),
              y: stage.height() * 0.2,
              width: image.width(),
              height: image.height(),
              draggable: true,
              src: imageObj.src
            })

            // stage.find('Transformer').destroy()
            let tr = new Konva.Transformer({
              anchorStroke: '#00a6ff',
              anchorFill: '#fff',
              anchorSize: 12,
              anchorCornerRadius: 5,
              anchorStrokeWidth: 2,
              borderStroke: '#6ac9fc',
              borderStrokeWidth: 2,
              borderDash: [3, 3],
              padding: 10,
              name: 'default'
            })
            that.$toolbox.removeTransformer()
            layer.add(tr)
            tr.nodes([image])
            that.$parent.addMenuBtnToTr(tr, image) 
            that.$parent.mode = 'check'
            that.$store.state.mode = 'check'
            that.$store.state.checkvalue = 'checkbtn'

            layer.add(image)
            layer.draw()
          })
        }
      })
    },

    pasteTargetByKonvaObj() {
      let stage = this.$store.state.stage
      let layer = this.$store.state.layer
      let currentIndex=layer?.children ? JSON.parse(JSON.stringify(layer?.children)).length : 1
      this.$store.state.textColor = 'black'
      let text = this.pickTarget.sort + '\t\t\t' + this.pickTarget.name
      let uuid= this.$jsFn.getUUID()
      var circle = new Konva.Circle({
        radius: 16,
        x: 33,
        y: 33,
        fill: '#ffb929',
        draggable: false,
        listening: false,
        uuid:uuid
      })
      var rect = new Konva.Rect({
        radius: 20,
        cornerRadius: 5,
        width: 180,
        height: 50,
        x: 0,
        y: 10,
        fill: '#d8d8d8',
        draggable: false,
        listening: false,
        uuid:uuid
      })

      var group = new Konva.Group({
        x: stage.width() * 0.1 + Math.floor(Math.random() * 200),
        y: stage.height() * 0.2 + Math.floor(Math.random() * 200),
        draggable: true,
        listening: true,
        text: text,
        uuid:uuid,
        index:currentIndex,
      })

      group.add(rect)
      group.add(circle)
      group.add(
        new Konva.Text({
          x: 15,
          y: 13,
          text: text,
          fontStyle: 'bold',
          fontSize: 18,
          fill: this.$store.state.textColor,
          padding: 10,
          lineHeight: 1,
          wrap: 'none',
          ellipsis: true,
          width: 180,
          height: 'auto',
          name: 'pastTextPickName',
          draggable: false,
          listening: true,
          uuid:uuid
        })
      )

      layer.add(group)
      this.$toolbox.saveUndoHistory('add',group)//儲存undo記錄

      // stage.find('Transformer').destroy()
      let tr = new Konva.Transformer({
        anchorStroke: '#00a6ff',
        anchorFill: '#fff',
        anchorSize: 12,
        anchorCornerRadius: 5,
        anchorStrokeWidth: 2,
        borderStroke: '#6ac9fc',
        borderStrokeWidth: 2,
        borderDash: [3, 3],
        padding: 10,
        name: 'default'
      })
      this.$toolbox.removeTransformer()
      layer.add(tr)
      tr.nodes([group])
      this.$parent.addMenuBtnToTr(tr, group) 
      this.$parent.mode = 'check'
      this.$store.state.mode = 'check'
      this.$store.state.checkvalue = 'checkbtn'

      layer.batchDraw()
      this.$store.state.textColor = 'red'
      this.currentSelectedTextItems = []
      console.log(stage)
    },

    randomPrizelist() {
      if (this.$store.state.students.length == 0) {
        this.$Message.warning(this.$t("board['暂无成员参与挑人。']"))
      } else {
        if (this.$store.state.candidates_turnTable.length == 0) {
          this.$store.state.candidates_turnTable = [...this.$store.state.students]
          this.$store.state.alreadyPick_turnTable = []
        }
        let candi = []
        this.$store.state.candidates_turnTable.forEach(item => {
          candi.push({ picked: 0 })
        })
        this.prize_list.forEach((element, idx) => {
          let ramdomIndex = 0
          while (true) {
            ramdomIndex = Math.floor(Math.random() * this.$store.state.candidates_turnTable.length)
            if (candi[ramdomIndex].picked <= Math.floor(idx / candi.length)) break
          }
          candi[ramdomIndex].picked++
          // console.log(this.$store.state.candidates_turnTable, '待選8人')
          //console.log(this.nameList[ramdomIndex])
          element.img = this.$store.state.candidates_turnTable[ramdomIndex].headImg
          element.sort = this.$store.state.candidates_turnTable[ramdomIndex].sort
          element.name = this.$store.state.candidates_turnTable[ramdomIndex].studentName.length > 6 ? this.$store.state.candidates_turnTable[ramdomIndex].studentName.slice(0, 5) + '..' : this.$store.state.candidates_turnTable[ramdomIndex].studentName
        })
      }
    },
    //智慧挑人
    randomPrizelistInSmart() {
      if (this.$store.state.candidates_turnTable_InSmart.length == 0) {
        this.$store.state.candidates_turnTable_InSmart = [...this.$store.state.candidates_turnTable_InSmartOriginal]
        this.$store.state.alreadyPick_turnTable_InSmart = []
      }
      let candi = []
      this.$store.state.candidates_turnTable_InSmart.forEach(item => {
        candi.push({ picked: 0 })
      })
      this.prize_list.forEach((element, idx) => {
        let ramdomIndex = 0
        while (true) {
          ramdomIndex = Math.floor(Math.random() * this.$store.state.candidates_turnTable_InSmart.length)
          if (candi[ramdomIndex].picked <= Math.floor(idx / candi.length)) break
        }
        candi[ramdomIndex].picked++
        // console.log(this.$store.state.candidates_turnTable_InSmart, '待選8人')
        //console.log(this.nameList[ramdomIndex])
        element.img = this.$store.state.candidates_turnTable_InSmart[ramdomIndex].headImg
        element.sort = this.$store.state.candidates_turnTable_InSmart[ramdomIndex].sort
        element.name = this.$store.state.candidates_turnTable_InSmart[ramdomIndex].studentName.length > 6 ? this.$store.state.candidates_turnTable_InSmart[ramdomIndex].studentName.slice(0, 5) + '..' : this.$store.state.candidates_turnTable_InSmart[ramdomIndex].studentName
      })
    },
    //智慧挑人
    pickOneInSmart() {
      this.renderKey++
      this.pickTaget = ''
      this.isShowPickTarget = false

      this.playAudio()
      let that = this

      //先過濾之前智慧挑人已經挑過的學生
      this.$store.state.candidates_turnTable_InSmart = this.$store.state.candidates_turnTable_InSmart.filter(item => this.$store.state.alreadyPick_turnTable_InSmart.indexOf(item) == -1)
      //  console.log(this.$store.state.candidates_turnTable_InSmart ,this.$store.state.alreadyPick_turnTable_InSmart,'智慧挑人候選')

      this.randomPrizelistInSmart()

      if (this.isRotating == false) {
        this.iEnd = Math.floor(Math.random() * 8)
        this.isRotating = true

        let targetID = this.$store.state.candidates_turnTable_InSmart.findIndex(item => item.sort == this.prize_list[this.iEnd].sort)

        let pickItem = this.$store.state.candidates_turnTable_InSmart[targetID]

        // 過濾已經挑過的學生
        if (pickItem) {
          this.$store.state.candidates_turnTable_InSmart.splice(targetID, 1)
          if (this.$store.state.alreadyPick_turnTable_InSmart.indexOf(pickItem) == -1) {
            this.$store.state.alreadyPick_turnTable_InSmart.push(pickItem)
          }
        }

        setTimeout(() => {
          this.isRotating = false
          that.stopAudio('audio')
          this.isShowPickTarget = true
          this.pickTarget.img = this.prize_list[this.iEnd].img
          this.pickTarget.sort = this.prize_list[this.iEnd].sort
          this.pickTarget.name = this.prize_list[this.iEnd].name

          if (this.$store.state.turnTableInSmartAction.PickupType == 'PickupOption') {
            this.$store.state.timeLineEvent.push({
              Time: (Math.floor(Date.now()) - this.$store.state.startTime) / 1000,
              Pgid: this.$parent.nowpageRender.nowpageIds,
              EventId: 458762,
              Event: 'PickupResult',
              PickupTypeId: 655362,
              PickupType: 'PickupOption',
              PickupMemberId: '[\r\n' + this.pickTarget.sort + '\r\n]',
              PickupCount: 1,
              PickupOption: this.$store.state.turnTableInSmartAction.PickupOption
            })
          } else {
            this.$store.state.timeLineEvent.push({
              Time: (Math.floor(Date.now()) - this.$store.state.startTime) / 1000,
              Pgid: this.$parent.nowpageRender.nowpageIds,
              EventId: 458762,
              Event: 'PickupResult',
              PickupTypeId: 655362,
              PickupType: this.$store.state.turnTableInSmartAction.PickupType,
              PickupMemberId: '[\r\n' + this.pickTarget.sort + '\r\n]',
              PickupCount: 1
            })
          }
          // console.log(this.$store.state.timeLineEvent,'timeLineEvent')
          //   alert('選中' + this.prize_list[this.iEnd].sort + '' + this.prize_list[this.iEnd].name)
        }, 2000)

        this.rotatedeg = this.iEnd * 45
      }
    },
    //一般挑人
    pickOneInNormal() {
      this.renderKey++
      this.pickTaget = ''
      this.isShowPickTarget = false
      this.playAudio()
      let that = this
      this.randomPrizelist()
      if (this.isRotating == false) {
        this.iEnd = Math.floor(Math.random() * 8)
        this.isRotating = true

        let targetID = this.$store.state.candidates_turnTable.findIndex(item => item.sort == this.prize_list[this.iEnd].sort)

        let pickItem = this.$store.state.candidates_turnTable[targetID]

        // console.log(targetID)
        // console.log(this.$store.state.candidates_turnTable[targetID])

        // 過濾已經挑過的學生
        if (pickItem) {
          this.$store.state.candidates_turnTable.splice(targetID, 1)
          if (this.$store.state.alreadyPick_turnTable.indexOf(pickItem) == -1) {
            this.$store.state.alreadyPick_turnTable.push(pickItem)
          }
        }
        //加入後來加入的學生
        const arr1 = this.$store.state.students
        const arr2 = this.$store.state.candidates_turnTable.concat(this.$store.state.alreadyPick_turnTable)
        // console.log(arr1)
        // console.log(arr2)
        const filterArray = (arr1, arr2) => {
          const filtered = arr1.filter(el => {
            return arr2.indexOf(el) === -1
          })
          return filtered
        }
        this.$store.state.candidates_turnTable = this.$store.state.candidates_turnTable.concat(filterArray(arr1, arr2))

        // console.log(this.$store.state.alreadyPick_turnTable)
        // console.log(this.$store.state.candidates_turnTable)

        setTimeout(() => {
          this.isRotating = false
          that.stopAudio('audio')
          this.isShowPickTarget = true
          this.pickTarget.img = this.prize_list[this.iEnd].img
          this.pickTarget.sort = this.prize_list[this.iEnd].sort
          this.pickTarget.name = this.prize_list[this.iEnd].name
          this.$store.state.timeLineEvent.push({
            Time: (Math.floor(Date.now()) - this.$store.state.startTime) / 1000,
            Pgid: this.$parent.nowpageRender.nowpageIds,
            EventId: 458762,
            Event: 'PickupResult',
            PickupTypeId: 655362,
            PickupType: 'PickupNameLst',
            PickupMemberId: '[\r\n' + this.pickTarget.sort + '\r\n]',
            PickupCount: 1
          })
          //   alert('選中' + this.prize_list[this.iEnd].sort + '' + this.prize_list[this.iEnd].name)
        }, 2000)
        // console.log(this.iEnd);
        // let prizes = document.getElementById("list").getElementsByTagName('p')
        // for(let i=0; i<prizes.length;i++){
        //     if(parseInt(prizes[i].innerText)==this.iEnd){
        //         console.log(prizes[i])
        //     }
        // }
        this.rotatedeg = this.iEnd * 45
      }
    },
    pickOne() {
      this.$store.state.turnTableＭode == 'Normal' ? this.pickOneInNormal() : this.pickOneInSmart()
    },
    playAudio() {
      let audio = new Audio(pickSound)
      audio.play()
    },
    stopAudio(id) {
      let audio = new Audio(pickSound)
      audio.pause()
      audio.currentTime = 0
    }
  }
}
</script>

<style scoped lang="less">
@charset "UTF-8";
@keyframes shiny {
  0% {
    fill: white;
  }
  50% {
    fill: #ffc242;
  }
  100% {
    fill: white;
  }
}
@keyframes go {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
* {
  padding: 0;
  margin: 0;
  font-family: '微軟正黑體';
}

body {
  background-color: #3dbfbb;
}

.container {
  max-width: 1000px;
  min-width: 400px;
  margin: 0 auto;
  overflow: hidden;
}

header {
  text-align: center;
  padding: 50px;
}
.refresh_btn {
  background-color: #d8d8d8;
  border-radius: 50%;
  font-size: 28px;
  display: inline-block;
  line-height: 48px;
  text-align: center;
  height: 48px;
  width: 48px;
  cursor: pointer;
  position: absolute;
  right: 120px;
  bottom: 100px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
}
section.turntable {
  position: relative;
  min-width: 500px;
  min-height: 500px;

  button.turntable_btn {
    cursor: default;
    opacity: 1;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: none;
    border: none;
    outline: none;
    font-weight: 900;
    width: 30%;
    height: 30%;
    border-radius: 50%;
    font-size: 40px;
    color: #0080c0;
    background-color: #ffb929;
    cursor: pointer;
    &::after {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      content: '';
      width: 80%;
      height: 80%;
      border-radius: 50%;
      background-color: #ffe3a8;
      z-index: -1;
      box-shadow: 0px 2px 4px #754f00;
    }
    &:active::after {
      box-shadow: none;
      margin-top: 2px;
      background-color: #ffda8f;
      opacity: 1;
    }
  }
  .list {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 70%;
    height: 70%;
    text-align: center;
    ul {
      list-style: none;
      width: 100%;
      height: 100%;
      transform: rotate(0deg);
      li {
        color: #fff;
        font-size: 24px;
        line-height: 40px;
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
    .go {
      animation: go 0.8s linear 3;
      -webkit-animation: go 0.8s linear 3;
    }
  }
  svg > circle {
    fill: none;
    stroke: blue;
    stroke-width: 4px;
  }
  svg g.polyline.go {
    animation: go 0.8s linear 3;
    -webkit-animation: go 0.8s linear 3;
  }
  svg g.polyline polyline {
    fill: #0056f5;
  }
  svg g.polyline polyline:nth-child(odd) {
    fill: rgb(65, 130, 252);
  }
  svg g.circle circle {
    fill: #fff;
    stroke: none;
    box-shadow: 0px 2px 5px #ccc;
  }
  svg g.circle circle:nth-child(odd) {
    animation: shiny 0.8s linear infinite;
    -webkit-animation: shiny 0.8s linear infinite;
  }
  svg g.circle circle:nth-child(odd).go {
    animation: go 0.8s linear 2, shiny 0.8s linear infinite;
    -webkit-animation: go 0.8s linear 4, shiny 0.8s linear infinite;
  }
  svg g.circle circle:nth-child(even) {
    animation: shiny 0.8s 0.5s linear infinite;
    -webkit-animation: shiny 0.8s 0.5s linear infinite;
  }
  svg g.circle circle:nth-child(even).go {
    animation: go 0.8s 0.8s linear 2, shiny 0.8s 0.5s linear infinite;
    -webkit-animation: go 0.8s linear 2, shiny 0.8s 0.5s linear infinite;
  }
  svg g.mark circle {
    fill: #ffb10f;
  }
  svg g.mark circle.round {
    fill: none;
    stroke: #ffebc2;
  }
  svg g.mark polyline {
    fill: #ffb10f;
    stroke: none;
  }
}

.list ul li:nth-child(2) {
  transform: rotate(315deg);
}
.list ul li:nth-child(3) {
  transform: rotate(270deg);
}
.list ul li:nth-child(4) {
  transform: rotate(225deg);
}
.list ul li:nth-child(5) {
  transform: rotate(180deg);
}
.list ul li:nth-child(6) {
  transform: rotate(135deg);
}
.list ul li:nth-child(7) {
  transform: rotate(90deg);
}
.list ul li:nth-child(8) {
  transform: rotate(45deg);
}
.paste-btn {
  position: absolute;
  top: 210px;
  left: 40px;
  z-index: 100;
  cursor: pointer;
  background-color: #d8d8d8;
  border-radius: 50%;
  height: 34px;
  width: 34px;
  padding-top: 2px;
  line-height: 36px;
  text-align: center;

  .paste-icon {
    font-size: 20px;
  }
}
.pick-target {
  position: absolute;
  top: 210px;
  left: -80px;
  width: 150px;
  height: 180px;
  //background-color: #1c6bff;
  color: white;
  text-align: center;
  font-size: 20px;
  // border: 6px solid blue;
  border-radius: 5px;
  z-index: 99;
  img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }
  .pick-default-img {
    background: url('../../assets/img/profile-user.svg') no-repeat;
    background-size: contain;
    z-index: 100;
    border-radius: 50%;
    width: 150px;
    height: 150px;
  }
  .pick-info {
    position: absolute;
    width: 100%;
    padding: 5px;
    bottom: 0%;
    color: black;
    border-radius: 8px;
    line-height: 32px;
    font-weight: bolder;
    background-color: #d8d8d8;

    .pick-sort {
      display: inline-block;
      position: relative;
      background-color: #ffb929;
      color: black;
      width: 30px;
      height: 30px;
      top: 2px;
      border-radius: 50%;
    }
    .pick-text {
      margin-left: 10px;
      display: inline-block;
      position: relative;
      top: 2px;
    }
  }
}
</style>
