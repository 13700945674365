<template>
    <div class="vertical-tools">
        <!--<div class="slider_box">-->
        <!--<RadioGroup v-model="button4"  type="button" @on-change="penType" style="padding:10px 0;">-->
        <!--<Radio label="pencil-alt" title="通用画笔"><font-awesome-icon icon="pencil-alt" style="font-size: 20px;" /></Radio>-->
        <!--<Radio label="highlighter" title="高光笔"><font-awesome-icon icon="highlighter" style="font-size: 20px;"/></Radio>-->
        <!--</RadioGroup>-->
        <!--</div>-->
        <div class="shapes_box">
            <div v-for="items in shapeList"  :key='items.icon' :class='["shapes_icon",watchcss ==items.value ? "indexstyle":""]' @click="shapesSelect(items.value,items.id)">
                <!--<q-icon :name="items.icon"/>-->
                <i :title="items.title"><font-awesome-icon :icon="items.icon"/></i>
            </div>
        </div>
    </div>
</template>

<script>
    import { color } from 'vuex'
    export default {
        data() {
            return {
                color: '#0000ff',
                currentIndex: 0,
                colorArr: [
                    { val: '#0000ff', color: 'teal' },
                    { val: '#ff9800', color: 'orange' },
                    { val: '#f44336', color: 'red' },
                    { val: '#00bcd4', color: 'cyan' }
                ],
                shapeList: [
                    { id:0,icon: 'eraser', value: 'eraser',  title: this.$t('board.toolbox.刪除元素') },
                    { id:1,icon: 'trash-alt', value: 'clear', title: this.$t('board.toolbox.清空畫布') }
                ],
                button4: 'pencil-alt',
                hex: '#FF00FF',
                standard: 2,
                value: 2,
                strokeColor: '#0000ff;'
            }
        },
        methods: {
            shapesSelect: function (value, index) {
                this.currentIndex = index
                this.$store.state.eraser = value
            },
        },
        created() {

        },
        computed: {
            watchcss() {
                return this.$store.state.eraser
            },
        }
    }
</script>
<style scoped>
    .indexstyle {
        color:blue;
    }
</style>
