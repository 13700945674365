import html2canvas from 'html2canvas';
import echarts from 'echarts'
/*import { Item } from 'paper';*/
import totaljson from '../assets/test/test.json'
/**
 * 声明Y轴比例
 */
//var scalingY = sessionStorage.getItem('scalings') ? sessionStorage.getItem('scalings') : 1
//console.log(scalingY,'缩放的比例')
/**
 * 绘制矩形
 * @param layer 图层
 * @param item 绘制对象
 */
function drawRect(stage, layer, item, tr, jointUrl, hierarchy, scalingY) {
    /** 先声明组合 然后画图形 有文字则画文字 最后填充layer */
    return new Promise(async (r, j) => {
        console.log(item, jointUrl, '调用本次方法用的值')
        var rotaWidth = 0
        var rotaHeight=0
        if (item.style.position.rot <= 90 && item.style.position.rot !== 0) { console.log('进入90度的处理'); rotaWidth = item.style.position.cx; rotaHeight = -(item.style.position.cy / 3) } else if (item.style.position.rot <= 150 && item.style.position.rot !== 0) { console.log('进入150度的处理'); rotaWidth = item.style.position.cx; rotaHeight = item.style.position.cy / 3 }
        else if (item.style.position.rot <= 190 && item.style.position.rot !== 0) { console.log('进入190度的处理'); rotaWidth = item.style.position.cx; rotaHeight = item.style.position.cy } else if (item.style.position.rot <= 280 && item.style.position.rot !== 0) { console.log('进入280度的处理'); rotaWidth = -(item.style.position.cy / 2); rotaHeight = item.style.position.cy / 2 }
        var originalAttrs = {
            x: item.style.position.x + rotaWidth * scalingY,
            y: item.style.position.y + rotaHeight * scalingY,
            draggable: true,
            scaleX: scalingY,
            scaleY: scalingY,
            rotation: 0
        };
        console.log('调用了矩形渲染方法')
        var group = new Konva.Group(originalAttrs);
        //console.log(item.style.fill.gradientFill[0].colors.Color, '渐变的颜色')
        var color0 = ''
        var color5 = ''
        var color1 = ''
        var rotateNum = item.style.position.rot !== 0.0 || item.style.position.rot !== 0 ? item.style.position.rot:0
        console.log(item, '当前传进来的元素')
        if (item.style.fill && item.style.fill.gradientFill !== null) {
            console.log(item.style.fill.gradientFill.colors[0].Color, '颜色测试 ，是否取值正确')
            color0 = item.style.fill.gradientFill.colors[0].Color
            color5 = item.style.fill.gradientFill.colors[1].Color
            color1 = item.style.fill.gradientFill.colors[2].Color
        }
        //if (item.style.position.flipH === 1) { rotateNum = '180' } else if (item.style.position.flipV === 1) { rotateNum = '90' }
        var rect = new Konva.Rect({
            stroke: item.style.border !== null && item.style.border.color && item.style.border.color.solidFill ? item.style.border.color.solidFill : '',
            strokeWidth: item.style.border !== null && item.style.border.outline && item.style.border.outline.Width ? item.style.border.outline.Width : 1,
            fill: item.style.fill !== null && item.style.fill.solidFill !== null ? item.style.fill.solidFill : 'transparent',
          /*  fill:  'red',*/
            width: item.style.position.cx * scalingY,
            height: item.style.position.cy * scalingY,
            shadowColor: "black",
            draggable: true,
            scaleX: scalingY,
            scaleY: scalingY,
            fillLinearGradientStartPoint: item.style.fill !== null && item.style.fill.gradientFill == null ? { x: 0, y: 0 } : { x: -100, y: -100 },
            fillLinearGradientEndPoint: item.style.fill !== null && item.style.fill.gradientFill == null ? { x: 0, y: 0 } : { x: 100, y: 100 },
            fillLinearGradientColorStops: item.style.fill !== null && item.style.fill.gradientFill == null ? null : [0, color0, 0.5, color5, 1, color1],
            rotation: rotateNum ,
            onFinish: function () {
                // remove all references from Konva
                rect.destroy();
            }
        });
        var scale = 1;
        scale *= -1;
        if (item.style.position.flipH === 1) { Rect.scale({ x: scale, y: 1 }) }
        if (item.style.position.flipV === 1) { Rect.scale({ x: 1, y: scale }); rotaHeight = item.style.position.cy}
        //console.log(item.paragraph, '内容1')
        //console.log(item.paragraph[0].texts, '内容2')
        //if (item.paragraph && item.paragraph[0].texts.length) drawTextInfo(stage, layer, item, group, item.paragraph[0].style.position)
        group.add(rect);
        if (item.style.fill && item.style.fill.blipFill) {
            console.log('准备加载图片')
            var imageObj = new Image();
            imageObj.onload = function () {
                rect.fillPatternImage(imageObj);
                rect.fillPatternRepeat('no-repeat')
                rect.fillPatternScale()
                group.add(rect)
                group.zIndex(hierarchy)
                layer.add(group);
                console.log(item.index, '是图片的层级！')
                layer.draw();
            };
            //var imagesrc = images
            imageObj.crossOrigin = 'Anonymous';
            imageObj.src = joinUrl + item.style.fill.blipFill.url
            //imageObj.width = item.fill.blipFill.tile.sx
            //imageObj.height = item.fill.blipFill.tile.xy
            console.log(imageObj, '图片内容')
            //if (item.paragraph && item.paragraph[0].texts.length) drawText(stage, layer, item.paragraph, group)
        }
        if (item.textBody !== null && item.textBody.paragraphs[0].texts.length !== 0) { var renderText = await drawText(stage, layer, item, group, jointUrl, scalingY) }
        r(200)
    })
};

/** 绘制弧形 */
function drawArc(stage, layer, item, hierarchy, scalingY) {
    /** 先声明组合 然后画图形 有文字则画文字 最后填充layer */
    return new Promise(async (r, j) => {
        var originalAttrs = {
            x: item.style.position.x * scalingY,
            y: item.style.position.y * scalingY,
            scaleX: scalingY,
            scaleY: scalingY,
            draggable: true,
            rotation: 0
        };
        var group = new Konva.Group(originalAttrs);
        var arc = new Konva.Path({
            data: item.svg.d,
            stroke: "#" + item.style.border.color.solidFill,
            strokeWidth: item.style.border.outline.Width,
            draggable: true,
            onFinish: function () {
                // remove all references from Konva
                drawArc.destroy();
            }
        });
        group.add(arc);
        if (item.paragraph && item.paragraph[0].texts.length) drawTextInfo(stage, layer, item.paragraph, group, item.paragraph[0].style.position, scalingY)
        layer.add(group);
        group.zIndex(hierarchy)
        layer.batchDraw();
        r(200)
    })
};

/** 绘制弧形 */
function drawEllipse(stage, layer, item, hierarchy, scalingY) {
    return new Promise(async (r, j) => {
        console.log('调用了圆形绘图')
        /** 先声明组合 然后画图形 有文字则画文字 最后填充layer */
        var originalAttrs = {
            x: (item.style.position.x + item.style.position.cx / 2) * scalingY,
            y: (item.style.position.y + item.style.position.cy / 2) * scalingY,
            draggable: true,
            scaleX: scalingY,
            scaleY: scalingY,
            rotation: 0
        };
        var group = new Konva.Group(originalAttrs);
        var arc = new Konva.Ellipse({
            width: item.paragraph[0].style.position.cx * scalingY,
            height: item.paragraph[0].style.position.cy * scalingY,
            radiusX: item.svg.rx * scalingY,
            radiusY: item.svg.ry * scalingY,
            stroke: item.style.border.color.solidFill,
            scaleX: scalingY,
            scaleY: scalingY,
            fill: item.style.fill.solidFill ? item.style.fill.solidFill : '',
            strokeWidth: item.style.border.width ? item.style.border.width : 1,
            onFinish: function () {
                // remove all references from Konva
                drawEllipse.destroy();
            }
        });

        //绘制背景图+填充
        if (item.fill) {
            var imageObj = new Image();
            imageObj.onload = function () {
                arc.fillPatternImage(imageObj);
                group.add(arc);
                if (item.textBody && item.textBody.paragraphs[0].texts.length !== 0) drawText(stage, layer, item, group)
            };
            imageObj.src = item.fill.image
        }
        group.add(arc);
        layer.add(group);
        group.zIndex(hierarchy)
        layer.batchDraw();
        r(200)
    })
};
/** 绘制多边形Line */
function drawLine(stage, layer, item, hierarchy, scalingY) {
    /** 先声明组合 然后画图形 有文字则画文字 最后填充layer */
    return new Promise(async (r, j) => {
        console.log('进入线条')
        var rotateNum = 0
        var rotaWidth = 0
        var rotaHeight = 0
        if (item.style.position.flipH === 1) { rotateNum = 180 } else if (item.style.position.flipV === 1) { rotateNum = 90 }
        if (item.style.position.rot != 0 || item.style.position.rot != 0.0) { rotateNum += item.style.position.rot }
        if (rotateNum !== 0) { rotaWidth = item.style.position.cx; rotaHeight = item.style.position.cy }
        var originalAttrs = {
            x: (item.style.position.x + rotaWidth) * scalingY,
            y: (item.style.position.y + rotaHeight) * scalingY,
            scaleX: scalingY,
            scaleY: scalingY,
            draggable: true,
            //rotation: 0
        };
        var group = new Konva.Group(originalAttrs);
        let svgShape = item.svg
       
        console.log(svgShape, '最外层内容')
        //svgShape.points = []
        //svgShape.points.push(+svgShape.x1)
        //svgShape.points.push(+svgShape.x2)
        //svgShape.points.push(+svgShape.y1)
        //svgShape.points.push(+svgShape.y2)
        var poly = new Konva.Line({
            x: item.style.position.x * scalingY,
            y: item.style.position.y * scalingY,
            points: svgShape.type === 'polygon' || svgShape.type === 'polyline' ? svgShape.points : svgShape.points.replace(/\s+/g, ",").split(',').map(i => +i),
            //points: svgShape,
            fill: item.style.fill.solidFill ? item.style.fill.solidFill : '',
            stroke: '#' + item.style.border.color.solidFill,
            strokeWidth: item.style.border.outline.Width,
            //closed: svgShape.type !== 'polyline',
            rotation: rotateNum,
            scaleX: scalingY,
            scaleY: scalingY,
        });
        console.log(rotateNum,'旋转角度')
        group.add(poly);
        //if (item.paragraph && item.paragraph[0].texts.length) drawText(stage, layer, item.paragraph, group, item.paragraph[0].style.position)
        if (item.textBody && item.textBody.paragraphs[0].texts.length !== 0) drawText(stage, layer, item, group)
        layer.add(group);
        group.zIndex(hierarchy)
        layer.batchDraw();
        r(200)
    })
};

/** 绘制路径 */
function drawPath(stage, layer, item, tr, joinUrl, hierarchy, scalingY) {
    return new Promise(async (r, j) => {
        console.log(item, hierarchy, joinUrl, scalingY, item.subName,'调用的item值')
        console.log(item.formula, '查看公式')
    /** 先声明组合 然后画图形 有文字则画文字 最后填充layer */
        //var rotateNum = 0
        var rotaWidth=0
        var rotaHeight=0
        //if (item.style.position.flipH === 1) { rotateNum = 180} else if (item.style.position.flipV === 1) { rotateNum = 90 }
        //if (item.style.position.rot != 0 || item.style.position.rot != 0.0) { rotateNum = rotateNum + item.style.position.rot }
        if (item.style.position.rot <= 45 && item.style.position.rot !== 0) { rotaWidth = 0; rotaHeight = -(item.style.position.cy / 3) } else if (item.style.position.rot <= 90 && item.style.position.rot !== 0) { console.log('进入90度的处理'); rotaWidth = item.style.position.cx; rotaHeight = -(item.style.position.cy / 3) } else if (item.style.position.rot <= 150 && item.style.position.rot !== 0) { console.log('进入150度的处理'); rotaWidth = item.style.position.cx; rotaHeight = item.style.position.cy / 3 }
        else if (item.style.position.rot <= 190 && item.style.position.rot !== 0) { console.log('进入190度的处理'); rotaWidth = item.style.position.cx; rotaHeight = item.style.position.cy } else if (item.style.position.rot <= 280 && item.style.position.rot !== 0) { console.log('进入280度的处理');rotaWidth = -(item.style.position.cy/2); rotaHeight = item.style.position.cy/2 }
        //按照线的方式来处理翻转
        console.log(item.type, item.shapeType,'8888888888')
        let isLine = item.type == "CxnSp" || item.shapeType == "line" ? true : false
        console.log(isLine)
        if (item.style.position.flipH === 1 && isLine) { rotaWidth = item.style.position.cx;}
        if (item.style.position.flipV === 1 && isLine) { rotaHeight = item.style.position.cy;}
        //按照形状的方式来处理
        if (item.style.position.flipH === 1 && isLine == false && item.style.position.flipV !==1) { item.style.position.rot = item.style.position.rot +180;}
        if (item.style.position.flipV === 1 && isLine == false && item.style.position.flipH !==1) { item.style.position.rot = item.style.position.rot + 90;}
        if (item.style.position.flipH === 1 && item.style.position.flipV === 1 && isLine == false) { rotaWidth = 0; rotaHeight = item.style.position.cy}
        console.log(rotaWidth,'旋转的值')
        var originalAttrs = {
            x: (item.style.position.x + rotaWidth) * scalingY,
            y: (item.style.position.y + rotaHeight) * scalingY,
            width: item.style.position.cx * scalingY,
            height: item.style.position.cy * scalingY,
            draggable: true,
            //rotation: rotateNum
        };
        console.log(originalAttrs.x, originalAttrs.y,'group的位置')
        let svgShape = item.svg;
        var group = new Konva.Group(originalAttrs);
        //var color0 = ''
        //var color1 = ''
        //if (item.style.fill && item.style.fill.gradientFill !== null) {
        //    color0 = item.style.border.color.gradientFill.colors[0].color
        //    color1 = item.style.border.color.gradientFill.colors[1].color
        //}
        var opacityValue = item.opacity != 1.0 ? (Number(item.opacity) / 100).toFixed(1) : 1.0
        var sideColor = item.style.border !== null && item.style.border.color && item.style.border.color.gradientFill !== null ? item.style.border.color.gradientFill.colors[0].color : ''
        var lineColor = item.style.border !== null && item.style.border.color && item.style.border.color.solidFill !== null ? item.style.border.color.solidFill : ''
        var arc = new Konva.Path({
            //x: item.style.position.x,
            //y: item.style.position.y,
            data: svgShape.d,
            //fill: item.style.fill && svgShape.d && svgShape.d.substring(svgShape.d.length - 1) === 'z' ? item.style.fill.solidFill : '',
            width: item.style.position.cx,
            height: item.style.position.cy,
            fill: item.style.fill && svgShape.d && svgShape.close ? item.style.fill.solidFill : '',
           /* fill:'red',*/
            stroke: lineColor == '' ? sideColor : lineColor,
            strokeWidth: item.style.border !== null && item.style.border.outline && item.style.border.outline.width ? item.style.border.outline.width : '0',
            //rotation: svgShape.transform ? +svgShape.transform.substring(7, svgShape.transform.length - 1).split(',')[0] : 0,
            rotation: item.style.position.rot ? item.style.position.rot : 0,
            scaleX: scalingY,
            scaleY: scalingY,
            opacity: opacityValue,
            draggable: true,
        });
        var scale = 1;
        scale *= -1;
        if (item.style.position.flipH === 1) { arc.scale({ x: scale, y: 1 })}
        if (item.style.position.flipV === 1) { arc.scale({ x: 1, y: scale }); rotaHeight = item.style.position.cy }
        group.add(arc);
        if (item.style.fill && item.style.fill.blipFill) {
            if (item.style.fill.type === 3 || item.style.fill.type === 4) {
                var imageObj = new Image();
                imageObj.onload = function () {
                    arc.fillPatternImage(imageObj);
                    arc.fillPatternRepeat('no-repeat')
                    arc.fillPatternScale()
                    layer.add(group);
                    layer.draw();
                };
                imageObj.crossOrigin = 'Anonymous';
                imageObj.src = joinUrl + item.style.fill.blipFill.url;
            }
        }
        if (item.textBody && item.textBody.paragraphs.length !== 0) drawText(stage, layer, item, group, joinUrl, hierarchy, scalingY)
        group.add(arc)
        layer.add(group);
        group.zIndex(hierarchy)
        layer.batchDraw();
        r(200)
    })
};


/** 绘制图片 */
function drawImage(stage, layer, item, tr, imgUrl, hierarchy, scalingY) {
    return new Promise(async (r, j) => {
        var imageAttrs = {
            x: item.style.position.x * scalingY,
            y: item.style.position.y * scalingY,
            width: item.style.position.cx * scalingY,
            height: item.style.position.cy * scalingY,
            draggable: true,
            rotation: 0
        };
        var group = new Konva.Group(imageAttrs);
        var imageUrl = item.image.url
        var totalimages = imgUrl
        var imagesURLinfo = imgUrl + item.image.url
        var rotateNum = '0'
        if (item.style.position.flipH === 1) { rotateNum = '180' } else if (item.style.position.flipV === 1) { rotateNum = '90' } else if (item.style.position.rot === 0.0) { rotateNum = item.style.position.rot }
        console.log(imagesURLinfo, '图片的地址1111')
        var opacityValue = (Number(item.image.opacity)/100).toFixed(1)
        Konva.Image.fromURL(imagesURLinfo, function (darthNode) {
            darthNode.setAttrs({
                //x: item.style.position.x * scalingY,
                //y: item.style.position.y * scalingY,
                width: item.style.position.cx,
                height: item.style.position.cy,
                draggable: true,
                scaleX: scalingY,
                scaleY: scalingY,
                rotation: rotateNum,
                 opacity: opacityValue !== '0.0' ? opacityValue:1,
                /*opacity:0.2,*/
                onFinish: function () {
                    // remove all references from Konva
                    drawImage.destroy();
                }
            });
            //darthNode.setAttribute('crossOrigin', 'anonymous');
            group.add(darthNode)
            layer.add(group);
            group.zIndex(hierarchy)
            layer.draw();
        });
        r(200)
    })
}
function imageRender(item, value, group) {
    console.log(item, value, '调用方法')
    var keyinfo = value
    var groupsinfo = group
    var keys = '?sv=2019-02-02&st=2020-08-04T07%3A30%3A37Z&se=2024-08-05T07%3A30%3A00Z&sr=c&sp=rl&sig=x4PbJZ5qh8yjFdZwOy1EUca98MaLQyXKth2XGIZ%2Bt%2B4%3D'
    if (item.fill) {
        var imageObj = new Image();
        imageObj.setAttribute('crossOrigin', 'anonymous');
        imageObj.onload = function () {
            keyinfo.fillPatternImage(imageObj);
            groupsinfo.add(keyinfo);
            if (item.paragraph && item.paragraph[0].texts.length) drawTextInfo(stage, layer, item.paragraph, group, item.paragraph[0].style.position)
        };
        imageObj.src = images;
    }
}
/** 绘制文本 */
function drawText(stage, layer, paragraph, group, url, hierarchy, scalingY) {
    return new Promise(async (r, j) => {
        console.log(paragraph, url, group, hierarchy,'传送进文字方法内容')
        var allText = ''
        var nowtext = ''
        //关于样式
        var textstyle = paragraph.textBody.paragraphs[0].texts.length !== 0 && paragraph.textBody.paragraphs[0].texts[0].style ? paragraph.textBody.paragraphs[0].texts[0].style : ''
        console.log(textstyle,'样式字段的值')
        var textwidth = paragraph.style.position.cx
        var textheight = paragraph.style.position.cy
        //text line-height
        var lineValue = paragraph.textBody.paragraphs[0].texts.length !=0 ? paragraph.textBody.paragraphs.filter((item) => {return item.texts.length != 0 && item.style != null }):1
        console.log(lineValue,'行距循环的值')
        var lineHeights = lineValue == 1 ? 1 : lineValue[0].style.lnSpace.val >= 2.5 ? lineValue[0].style.lnSpace.val + 1.5 : lineValue[0].style.lnSpace.val+1
        //text padding
        var pds = paragraph.textBody.left + paragraph.textBody.right > paragraph.textBody.top + paragraph.textBody.bottom ? paragraph.textBody.left + paragraph.textBody.right : paragraph.textBody.top + paragraph.textBody.bottom
        var coefficient = paragraph.textBody.paragraphs[0].texts.length !== 0 && paragraph.textBody.paragraphs[0].texts[0].style ? Math.ceil(paragraph.textBody.paragraphs[0].texts[0].style.size / 18) : 1
        if (paragraph.textBody.paragraphs[0].texts.length === 1) {
            pds = 0; coefficient=0
        }
        for (var i = 0; i < paragraph.textBody.paragraphs.length; i++) {
            //当前下标里面的text
            var nowtextData = paragraph.textBody.paragraphs[i].texts
            var burs = paragraph.textBody.paragraphs[i].buChar != null && paragraph.textBody.paragraphs[i].buChar.buchar ? paragraph.textBody.paragraphs[i].buChar.buchar :''
            console.log(paragraph.textBody.paragraphs[i],'当前内容')
            for (var j = 0; j < nowtextData.length; j++) {
                console.log(nowtextData[j].content, '文字内容')
                nowtext = j == 0 ? nowtext + burs + nowtextData[j].content : nowtext + nowtextData[j].content
                console.log(nowtext,'结果')
            }
            nowtext = nowtext + '\n'
            allText = nowtext
        }
        console.log(textstyle.size, '渲染的字体大小')
        let textScla = paragraph.textBody.vert === "EastAsianVetical" ? 1 : scalingY
        lineHeights = paragraph.textBody.vert === "EastAsianVetical" ? 1 : lineHeights
                var textName = new Konva.Text({
                x: 0,
                y: 0,
                text: allText,
                width: textwidth * textScla,
                    height: textheight * scalingY,
               /* padding: pds * coefficient,*/
                align: paragraph.textBody.paragraphs[0].style.hori.toLowerCase(),
                verticalAlign: paragraph.textBody.paragraphs[0].style.vert === "Center" ? "middle": paragraph.textBody.paragraphs[0].style.vert.toLowerCase(),
               /* verticalAlign: "bottom",*/
               fontSize: paragraph.textBody.paragraphs.length !== 0 && textstyle ? Number(textstyle.size * scalingY) : 18,
                fontFamily: paragraph.textBody.paragraphs.length !== 0 && textstyle.family,
                fill: paragraph.textBody.paragraphs.length !== 0 && textstyle.color ? textstyle.color :"#000000",
                fontStyle: paragraph.textBody.paragraphs.length !== 0 && textstyle.bold === false ? 'normal' : 'bold',
                //rotation: rotateNum,
                    draggable: true,
                    lineHeight: lineHeights,
                })
        console.log(textName.width(), textName.height(), textName.fontSize())
            group.add(textName);
            group.zIndex(hierarchy);
        r(200)
    })
}
/*表格绘制方法*/
function drawTable(stage, layer, group, item, hierarchy, scalingY) {
    return new Promise(async (r, j) => {
        console.log(item, '表格传进来的值')
        console.log('调用到了绘制表格方法')
        var boxWidth = item.extstyle.columnWidth
        console.log(boxWidth, '内容')
        var tablewidth = 0
        var trHeight = ''
        boxWidth.forEach(item => {
            console.log(item, '循环的内容')
            tablewidth = tablewidth + item
        })
        //创建Table元素
        var createdDiv = document.createElement('table')
        var addCreated = document.getElementById("container").appendChild(createdDiv);
        var tableid = item.uid
        createdDiv.id = item.uid
        createdDiv.style.width = tablewidth + 'px'
        createdDiv.style.border = 2 + 'px solid #000';
        createdDiv.style.position = 'relative'
        createdDiv.style.top = 9999 + 'px'
        var createdbody = document.createElement('tbody')
        var addbody = document.getElementById(tableid).appendChild(createdbody);
        addbody.id = 'body' + item.uid
        var tbodyid = 'body' + item.uid
        if (item.tr) {
            for (var i = 0; i < item.tr.length; i++) {
                trHeight += item.tr[i].height
                var tdData = item.tr[i].td
                var trDiv = document.createElement('tr')
                var addtr = document.getElementById(tbodyid).appendChild(trDiv);
                var trDivid = item.uid + 'trinfo' + i
                trDiv.id = item.uid + 'trinfo' + i
                for (var f = 0; f < tdData.length; f++) {
                    var TableContent = tdData[f].textBody.paragraphs
                    var tdDiv = document.createElement('td')
                    var addtd = document.getElementById(trDivid).appendChild(tdDiv);
                    //获取高度给整体高度赋值
                    createdDiv.style.height = trHeight * item.tr.length + 'px'
                    //td元素样式
                    tdDiv.id = item.uid + 'info' + i + f
                    var tdDivid = item.uid + 'info' + i + f
                    tdDiv.style.flexDirection = 'row'
                    tdDiv.style.fontSize = TableContent[0].texts[0] ? TableContent[0].texts[0].style.size + 'px' : ''
                    tdDiv.style.fontFamily = TableContent[0].texts[0] ? TableContent[0].texts[0].style.family : ''
                    tdDiv.style.color = TableContent[0].texts[0] ? TableContent[0].texts[0].style.color : ''
                    tdDiv.style.textDecoration = TableContent[0].texts[0] ? TableContent[0].texts[0].style.underline : ''
                    tdDiv.style.width = boxWidth[f] 
                    tdDiv.style.height = trHeight 
                    tdDiv.style.textAlign = TableContent[0].style.hori
                    tdDiv.style.display = 'table-cell'
                    tdDiv.style.verticalAlign = 'inherit'
                    tdDiv.style.border = 1 + 'px solid #000'
                    tdDiv.style.padding = 5 + 'px'
                    //获取文字内容
                    var textDetails = TableContent[0].texts[0] ? TableContent[0].texts[0].content : ''
                    //var textDetails = '这是测试test'+i+f
                    var createdText = document.createTextNode(textDetails)
                    var addText = document.getElementById(tdDivid).appendChild(createdText)
                }
            }
        }
        html2canvas(document.getElementById(tableid), {
            backgroundColor: null,
        }).then((canvas) => {
            console.log(document.getElementById(tableid), '元素获取')
            document.getElementById(tableid).innerHTML = "";
            const img = canvas.toDataURL();
            var imageObj = new Image();
            imageObj.src = img;
            document.getElementById(tableid).remove()
            imageObj.setAttribute('crossOrigin', 'anonymous');
            imageObj.onload = function () {
                var tableInfo = new Konva.Image({
                    x: item.style.position.x * scalingY,
                    y: item.style.position.y * scalingY,
                    image: imageObj,
                    width: imageObj.width * scalingY,
                    height: imageObj.height * scalingY,
                    //scaleX: scalingY,
                    //scaleY: scalingY,
                    draggable: true,
                });
                tableInfo.on('dblclick', (e) => {
                    console.log('触发了dblclick!')
                    console.log(this, 'this指针内容')
                    //that.EditContent = paragraphall.paragraph[0].texts[0].content
                    //that.NowOption = exercise.option
                    //this.editorinfo = new Editors(this.$refs.editorElem)
                })
                // add the shape to the layer
                //group.add(tableInfo);
                layer.add(tableInfo);
                //group.zIndex(paragraphall.index+1)
                layer.batchDraw();
                //group.batchDraw();
            };
        });
        r(200)
    })
}
/*绘制echarts图表*/
function drawEchart(stage, layer, group, item,hierarchy, scalingY) {
    return new Promise(async (r, j) => {
        console.log('进入echarts的渲染方法')
        console.log(item, '传送进来的内容')
        var createdEchat = document.createElement('div')
        createdEchat.id = item.uid
        createdEchat.style.width = item.style.position.cx + 'px'
        createdEchat.style.height = item.style.position.cy + 'px'
        createdEchat.style.position = 'relative'
        createdEchat.style.top = 999 + 'px'
        var addEchatdiv = document.getElementById("container").appendChild(createdEchat);
        var Echatid = item.uid
        var htmldom = document.getElementById(Echatid)
        console.log(htmldom, '获取html结构')
        var titlename = []
        var LineseriesData = []
        var BarseriesData = []
        var AreaseriesData = []
        var ScatterseriesData = []
        var PieseriesData = []
        for (var i = 0; i < item.charts[0].datas.length; i++) {
            titlename.push(item.charts[0].datas[i].colName)
        }

        if (item.charts[0].chartType === "line") {  //判断折线图
            for (var l = 0; l < item.charts[0].datas.length; l++) {
                LineseriesData.push({
                    name: item.charts[0].datas[l].colName,
                    data: item.charts[0].datas[l].yAxis,
                    type: 'line',
                    itemStyle: { color: item.charts[0].datas[l].colStyle.border.color.solidFill, },
                    lineStyle: { width: item.charts[0].datas[l].colStyle.border.outline.Width }
                })
            }
            var myChart = echarts.init(document.getElementById(Echatid));
            var option = {
                legend: { data: titlename, bottom: 1 },
                animation: false,
                xAxis: {
                    type: 'category',
                    data: item.charts[0].datas[0].xAxis
                },
                yAxis: {
                    type: 'value'
                },
                series: LineseriesData
            };
        } else if (item.charts[0].chartType === "col") { //判断柱状图
            var myChart = echarts.init(document.getElementById(Echatid));
            if (item.charts[0].colType === "colChart-clustered") { //簇状柱状图 默认
                for (var l = 0; l < item.charts[0].datas.length; l++) {
                    BarseriesData.push({
                        name: item.charts[0].datas[l].colName,
                        data: item.charts[0].datas[l].yAxis,
                        type: 'bar',
                        itemStyle: { color: item.charts[0].datas[l].colStyle.fill.solidFill },
                        lineStyle: { width: item.charts[0].datas[l].colStyle.border.outline.Width ? item.charts[0].datas[l].colStyle.border.outline.Width : 3 }
                    })
                }
                console.log(BarseriesData, '数据内容')
                var option = {
                    legend: { data: titlename, bottom: 1 },
                    animation: false,
                    xAxis: {
                        type: 'category',
                        data: item.charts[0].datas[0].xAxis
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: BarseriesData
                };
            } else if (item.charts[0].colType === "colChart-stacked") { //堆积柱状图
                for (var l = 0; l < item.charts[0].datas.length; l++) {
                    BarseriesData.push({
                        name: item.charts[0].datas[l].colName,
                        data: item.charts[0].datas[l].yAxis,
                        type: 'bar',
                        stack: '总量',
                        itemStyle: { color: item.charts[0].datas[l].colStyle.fill.solidFill },
                        lineStyle: { width: item.charts[0].datas[l].colStyle.border.outline.Width ? item.charts[0].datas[l].colStyle.border.outline.Width : 3 }
                    })
                    console.log(item.charts[0].datas[l].colStyle.border.color.solidFill, '循环的颜色')
                }
                console.log(BarseriesData, '数据内容')
                var option = {
                    legend: { data: titlename, bottom: 1 },
                    animation: false,
                    xAxis: {
                        type: 'category',
                        data: item.charts[0].datas[0].xAxis
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: BarseriesData
                };
            }
        } else if (item.charts[0].chartType === "area") {
            var myChart = echarts.init(document.getElementById(Echatid));
            if (item.charts[0].areaType === "areaChart-standard" || item.charts[0].areaType === "areaChart-percentStacked") { //面积图 默认 或  百分比堆积面积图
                for (var l = 0; l < item.charts[0].datas.length; l++) {
                    AreaseriesData.push({
                        name: item.charts[0].datas[l].colName,
                        data: item.charts[0].datas[l].yAxis,
                        type: 'line',
                        stack: '总量',
                        areaStyle: {},
                        itemStyle: { color: item.charts[0].datas[l].colStyle.fill.solidFill },
                        //lineStyle: { width: item.charts[0].datas[l].colStyle.border.outline.Width ? item.charts[0].datas[l].colStyle.border.outline.Width : 3 }
                    })
                    console.log(item.charts[0].datas[l].colStyle.border.color.solidFill, '循环的颜色')
                }
                option = {
                    legend: { data: titlename, bottom: 1 },
                    animation: false,
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '10%',
                    },
                    xAxis: [
                        {
                            type: 'category',
                            boundaryGap: false,
                            data: item.charts[0].datas[0].xAxis
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value'
                        }
                    ],
                    series: AreaseriesData
                };

            }

        } else if (item.charts[0].chartType === "scatter") {
            var myChart = echarts.init(document.getElementById(Echatid));
            for (var i = 0; i < item.charts[0].datas[0].xAxis.length; i++) {
                var Datamark = []
                Datamark.push(item.charts[0].datas[0].xAxis[i], item.charts[0].datas[0].yAxis[i])
                ScatterseriesData.push(Datamark)
            }
            console.log(ScatterseriesData, '查看数据组装后的内容')
            if (item.charts[0].scatterType === "scatterChart-line") { //带直线和数据标记的散点图 默认
                option = {
                    animation: false,
                    xAxis: {},
                    yAxis: {},
                    series: [{
                        symbolSize: 15,
                        data: ScatterseriesData,
                        type: 'scatter'
                    }]
                };
            }

        } else if (item.charts[0].chartType === "pie") {
            var myChart = echarts.init(document.getElementById(Echatid));
            for (var y = 0; y < item.charts[0].datas[0].xAxis.length; y++) {
                PieseriesData.push({
                    value: item.charts[0].datas[0].yAxis[y],
                    name: item.charts[0].datas[0].xAxis[y],
                })
            }
            if (item.charts[0].pieType === "pieChart") { //饼图   默认
                option = {
                    title: {
                        text: item.charts[0].datas[0].colName,
                        left: 'center',
                        top: 20,
                    },
                    animation: false,
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b} : {c} ({d}%)'
                    },
                    legend: {
                        left: 'center',
                        top: 'bottom',
                        data: item.charts[0].datas[0].xAxis
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '10%',
                    },
                    //color: ['#37a2da', '#32c5e9', '#9fe6b8', '#ffdb5c', '#ff9f7f', '#fb7293', '#e7bcf3', '#8378ea'],
                    series: [
                        {
                            name: item.charts[0].datas[0].colName,
                            type: 'pie',
                            radius: '55%',
                            center: ['50%', '60%'],
                            data: PieseriesData,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                };
            } else if (item.charts[0].pieType === "pie3DChart") {   //三维饼图
                option = {
                    title: {
                        text: item.charts[0].datas[0].colName,
                        left: 'center',
                        top: 20,
                    },
                    animation: false,
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b} : {c} ({d}%)'
                    },
                    legend: {
                        left: 'center',
                        top: 'bottom',
                        data: item.charts[0].datas[0].xAxis
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '10%',
                    },
                    //color: ['#37a2da', '#32c5e9', '#9fe6b8', '#ffdb5c', '#ff9f7f', '#fb7293', '#e7bcf3', '#8378ea'],
                    series: [
                        {
                            name: item.charts[0].datas[0].colName,
                            type: 'pie',
                            radius: '55%',
                            center: ['50%', '60%'],
                            label: {
                                position: 'inner'
                            },
                            labelLine: {
                                show: false
                            },
                            data: PieseriesData,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                };
            }
        }
        myChart.setOption(option);
        if (option && typeof option === "object") {
            myChart.setOption(option, true);
        }
        var echartsinfo = myChart.getDataURL()//图片转码
        console.log(echartsinfo, '变成的图片内容')
        html2canvas(document.getElementById(Echatid), {
            backgroundColor: null,
        }).then((canvas) => {
            document.getElementById(Echatid).innerHTML = "";
            const img = canvas.toDataURL();
            var imageObj = new Image();
            imageObj.src = echartsinfo;
            document.getElementById(Echatid).remove()
            imageObj.setAttribute('crossOrigin', 'anonymous');
            imageObj.onload = function () {
                var EchartsInfo = new Konva.Image({
                    x: item.style.position.x * scalingY,
                    y: item.style.position.y * scalingY,
                    image: imageObj,
                    width: imageObj.width * scalingY,
                    height: imageObj.height * scalingY,
                    draggable: true,
                    //scaleX: scalingY,
                    //scaleY: scalingY,
                });
                // add the shape to the layer
                //group.add(tableInfo);
                layer.add(EchartsInfo);
                //group.zIndex(paragraphall.index+1)
                layer.batchDraw();
                //group.batchDraw();
            };
        });
        r(htmldom)
    })
}
/*Latex To Math公式渲染*/
function drawMath(stage, layer, group, item, url, hierarchy, scalingY) {
    return new Promise(async (r, j) => {
        console.log(item, '进入到Math公式渲染')
        var mathtext = item.formula.text
        var createMath = document.createElement('div')
        var addCreated = document.getElementById("container").append(createMath);
        createMath.id = item.uid
        var createMathId = item.uid
        createMath.style.width = item.style.position.cx
        createMath.style.height = item.style.position.cy
        createMath.style.position = 'relative'
        createMath.style.top = 0 + 'px'
        createMath.style.fontSize = item.pitchFamily + 'px'
        //createMath.style.background ='#CC00FF'
        let svg = window.MathJax.tex2svg(mathtext)
        let svgHtml = window.MathJax.tex2svg(mathtext, { em: 12, ex: 6 })
        var regex2 = /\<svg .*\>.*\<\/svg\>/;
        var arr2 = svgHtml.outerHTML.match(regex2);
        var mathhtml = arr2[0]
        document.getElementById(createMathId).innerHTML = mathhtml;
        console.log(mathhtml)
        html2canvas(document.getElementById(createMathId), {
            backgroundColor: null,
        }).then((canvas) => {
            console.log(document.getElementById(createMathId), '元素获取')
            document.getElementById(createMathId).innerHTML = "";
            const img = canvas.toDataURL();
            var imageObj = new Image();
            imageObj.src = img;
            document.getElementById(createMathId).remove()
            imageObj.setAttribute('crossOrigin', 'anonymous');
            imageObj.onload = function () {
                var mathInfo = new Konva.Image({
                    x: item.style.position.x * scalingY,
                    y: item.style.position.y * scalingY,
                    image: imageObj,
                    width: imageObj.width * scalingY,
                    height: imageObj.height * scalingY,
                    draggable: true,
                    scale: scalingY,
                });
                console.log(item.style.position.x, item.style.position.y,'坐标位置')
                layer.add(mathInfo);
                //group.zIndex(paragraphall.index+1)
                layer.batchDraw();
                //group.batchDraw();
            };
        });
        r(200)
    })
}
export {
    drawRect, drawArc, drawEllipse, drawPath, drawImage, drawLine, drawTable, drawEchart,drawMath
}