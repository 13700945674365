<template>
  <div class="pop_box">
    <div class="slider_box">
      <!--<p style="text-align: left"> &nbsp;</p>-->
      <!--<div v-for="(items,index) in shapeList"  class="shapes_icon" @click="penType(items.value,index)" :style="currentIndex==index?'color:'+color:''">-->
      <!--&lt;!&ndash;<q-icon :name="items.icon" />&ndash;&gt;-->
      <!--<font-awesome-icon :icon="items.icon" style="font-size: 24px;" />-->
      <!--</div>-->
      <p class="menu-title">{{ $t('board.toolbox["畫筆"]') }}</p>
      <RadioGroup v-model="button4" type="button" @on-change="penType" style="padding:10px 0;">
        <Radio label="pencil-alt" :title='$t("board.toolbox.通用畫筆")' :style="brushType == 'pencil-alt' ? 'color:' + color : 'color:#515a6e'"><font-awesome-icon icon="pencil-alt" style="font-size: 24px;"/></Radio>
        <Radio label="highlighter" :title='$t("board.toolbox.高光筆")' :style="brushType == 'highlighter' ? 'color:' + color : 'color:#515a6e '"><font-awesome-icon icon="highlighter" style="font-size: 24px;"/></Radio>
        <Radio label="magic" :title='$t("board.toolbox.鐳射筆")' :style="brushType == 'magic' ? 'color:' + color : 'color:#515a6e '"><font-awesome-icon icon="magic" style="font-size: 24px;"/></Radio>
      </RadioGroup>
    </div>
    <div class="slider_box">
      <p class="menu-title">
        {{ $t('board.toolbox["大小"]') }}：<b style="color: #0000ff;font-size: 14px">{{ strokeSize }}</b>
      </p>

      <Slider v-model="strokeSize" @on-change="size" :min="1" :step="1" :max="10" show-tip="never"></Slider>
    </div>
    <div class="ColorPicker_box ">
      <p class="menu-title">{{ $t('board.toolbox["顏色"]') }}</p>

      <div class="q-gutter-sm" style="padding:10px 0 2px 0;">
        <div class="color-btn" :class="{ 'select-color': color == item.val }" :style="{ 'background-color': item.val }" v-for="(item, index) in colorArr" :key="item.id" v-show="index < 5" @click="chioceColor(item)" />
      </div>
      <div class="q-gutter-sm" style="padding:2px 0;">
        <div class="color-btn" :class="{ 'select-color': color == item.val }" :style="{ 'background-color': item.val }" v-for="(item, index) in colorArr" :key="item.id" v-show="index >= 5" @click="chioceColor(item)" />
      </div>
      <!--<q-color v-model="hex" no-header no-footer default-view="palette" class="my-picker" @change="ss" @input="cc" :palette="['#2d8cf0', '#0000ff', '#D9B801', 'rgb(23,120,0)', '#B2028A' ]"/>-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'pop',
  data() {
    return {
      color: 'red',
      currentIndex: 0,
      colorArr: [
        { val: 'red', color: 'red' },
        { val: 'orange', color: 'orange' },
        { val: 'yellow', color: 'yellow' },
        { val: 'cyan', color: 'cyan' },
        { val: '#e0e0e0', color: '#e0e0e0' },
        { val: 'brown', color: 'brown' },
        { val: 'purple', color: 'purple' },
        { val: 'green', color: 'green' },
        { val: '#0000ff', color: '#0000ff' },
        { val: 'black', color: 'black' }
      ],
      shapeList: [
        { icon: 'pencil-alt', value: 'pencil-alt' },
        { icon: 'highlighter', value: 'highlighter' },
        { icon: 'magic', value: 'magic' }
      ],
      button4: 'pencil-alt',
      hex: '#FF00FF',
      standard: 2,
      strokeSize: 1,
      strokeColor: 'red;',
      brushType: 'pencil-alt',
      //是否已經讀取偏好值
      alreadyUsePREF: {
        pencilAlt: false,
        highlighter: false,
        magic: false
      }
    }
  },
  computed: {
    ...mapGetters({
      getPREFPenState: 'preferences/getPenState' // 取得預設畫筆設定檔
    }),
    neverSetPREFPenState: function() {
      return {
        pencilAlt: this.getPREFPenState('pencilAlt').color === '#ffffff',
        highlighter: this.getPREFPenState('highlighter').color === '#ffffff',
        magic: this.getPREFPenState('magic').color === '#ffffff'
      }
    }
  },
  mounted() {
    if (this.neverSetPREFPenState.pencilAlt == true) {
      //完全沒設定過回傳給白色，色盤沒有，故直接套用本地預設
      this.chioceColor({ val: 'red', color: 'red' })
      this.strokeSize = 1
      this.size(1)
    } else {
      this.setByPREFPenState(this.getPREFPenState('pencilAlt'))
      this.updateTempPREFPenState()
      this.alreadyUsePREF.pencilAlt = true
    }
  },
  watch: {
    strokeSize: function(value) {
      if (value) {
        this.updateTempPREFPenState()
      }
    },
    strokeColor: function(value) {
      if (value) {
        this.updateTempPREFPenState()
      }
    },
    brushType: function(value) {
      // console.log(this.getPREFPenState('pencilAlt'), 'pencilAlt預設值')
      // console.log(this.getPREFPenState('highlighter'), 'highlighter預設值')
      // console.log(this.getPREFPenState('magic'), 'magic預設值')

      // console.log(this.neverSetPREFPenState)
      if (value == 'pencil-alt') {
        if (this.neverSetPREFPenState.pencilAlt == true) {
          //完全沒設定過回傳給白色，色盤沒有，故直接套用本地預設
          this.chioceColor({ val: 'red', color: 'red' })
          this.strokeSize = 1
          this.size(1)
        } else {
          // Pencil 在mounted 已讀
          this.setByPREFPenState(this.$store.state.tempPenstate[0])
          this.updateTempPREFPenState()
        }
      } else if (value == 'highlighter') {
        if (this.neverSetPREFPenState.highlighter == true) {
          //完全沒設定過回傳給白色，色盤沒有，故直接套用本地預設
          this.chioceColor({ val: 'orange', color: 'orange' })
          this.strokeSize = 4
          this.size(4)
        } else if (this.alreadyUsePREF.highlighter == false && this.neverSetPREFPenState.highlighter == false) {
          this.setByPREFPenState(this.getPREFPenState('highlighter'))
          this.updateTempPREFPenState()
          this.alreadyUsePREF.highlighter = true
        } else {
          this.setByPREFPenState(this.$store.state.tempPenstate[1])
          this.updateTempPREFPenState()
        }
      } else if (value == 'magic') {
        // console.log(this.getPREFPenState('magic'))
        if (this.neverSetPREFPenState.magic == true) {
          //完全沒設定過回傳給白色，色盤沒有，故直接套用本地預設
          this.chioceColor({ val: '#0000ff', color: '#0000ff' })
          this.strokeSize = 2
          this.size(2)
        } else if (this.alreadyUsePREF.magic == false && this.neverSetPREFPenState.magic == false) {
          this.setByPREFPenState(this.getPREFPenState('magic'))
          this.updateTempPREFPenState()
          this.alreadyUsePREF.magic = true
        } else {
          this.setByPREFPenState(this.$store.state.tempPenstate[2])
          this.updateTempPREFPenState()
        }
      }
    }
  },
  methods: {
    updateTempPREFPenState() {
      if (this.brushType == 'pencil-alt') this.setVuexTempPenState('pencilAlt')
      else if (this.brushType == 'highlighter') this.setVuexTempPenState('highlighter')
      else {
        this.setVuexTempPenState('magic')
      }
    },
    setVuexTempPenState(penType) {
      let targetItem = this.$store.state.tempPenstate.find(item => item.type === penType)
      targetItem.size = this.strokeSize.toString()
      targetItem.color = this.strokeColor
      // console.log(this.$store.state.tempPenstate.find(item => item.type === penType))
    },
    setByPREFPenState(itemData) {
      this.chioceColor({ val: itemData.color, color: itemData.color })
      this.strokeSize = parseInt(itemData.size)
      this.size(parseInt(itemData.size))
    },
    chioceColor: function(item) {
      // console.log(item)
      this.$store.state.strokeColor = item.val
      this.strokeColor = item.val
      this.color = item.val
    },

    size: function(e) {
      this.$store.state.strokeWidth = e
    },
    penType: function(e, index) {
      this.currentIndex = index
      this.$store.state.penType = e
      this.brushType = e
    }
  },
  created() {}
}
</script>
<style lang="less">
/*.pop_box .ivu-poptip-popper{
    left:-270px !important;
    min-height: 0;
  }*/
.ivu-slider-button {
  width: 16px;
  height: 16px;
  position: relative;
  top: -1px;
  transition: all 0.2s;
  &:hover {
    transform: scale(1.1);
  }
}

.pop_box .ivu-radio-group-button .ivu-radio-wrapper-checked,
.ivu-radio-group-button .ivu-radio-wrapper:hover {
  color: #0000ff;
}
.pop_box .ivu-radio-group-button .ivu-radio-wrapper {
  padding: 0 20px 0 0 !important;
  background: none;
}
.pop_box .ivu-transfer-no-max-height {
  transform-origin: center top;
  position: absolute;
  will-change: top, left;
  top: 32px;
  left: 3px !important;
}
.pop_box .ivu-color-picker-saturation-wrapper {
  padding-bottom: 0% !important;
}
.pop_box .ivu-color-picker-picker-hue-slider {
  height: 0px !important;
}
</style>
<style scoped>
.pop_box {
  padding: 0 8px;
  overflow: hidden;
}
.slider_box {
  width: 190px;
  padding: 5px;
}
.ColorPicker_box {
  width: 190px;
  padding: 5px;
}
.secondary_title {
  text-align: left;
}
.color-btn {
  height: 24px;
  width: 24px;
  border: 2px solid white;
  margin: 8px;
  display: inline-block;
  box-shadow: 0px 0px 2px gray;
  border-radius: 50%;
}
.select-color {
  border: 0px solid rgba(223, 223, 223, 0);
  box-shadow: 0px 0px 2px gray;
}
.menu-title {
  font-weight: bolder;
  text-align: left;
}
</style>
