<template>
  <div class="move-box">
    <div class="flex content-around column">
      <div class="flex flex-center start-wrap">
        <Icon :type="playIcon" class="btn-start" :style="playIcon=='md-pause'?'margin:0':''" @click="handlePickAction"/>
        <!--<Icon type="md-play" class="btn-start" @click="handlePickAction" />-->
      </div>

      <div class="picker-conditions">
        <CheckboxGroup v-model="checkVal">
          <Checkbox label="repeat">重复挑人</Checkbox>
          <Checkbox label="quick">快速挑人</Checkbox>
        </CheckboxGroup>
        <div class="picker-select">
          <span>一次挑</span>
          <Select v-model="pickNum" style="width:70px" @on-change="handleSelectNum">
            <Option v-for="(item,index) in numList" :value="index" :key="item">{{ item }}</Option>
          </Select>
          <span>人</span>
        </div>
      </div>

      <div class="result-box">
        <div class="person-item" v-for="(item,index) in personList" :key="index" v-if="index < showLength">
          <div class="person-box flex flex-center" :style="'background-image:url('+item.headImg+')' ">
            <div class="person-name" v-if="item.studentName">{{item.studentName}}</div>
            <span class="person-id" v-if="item.sort">{{item.sort}}</span>
          </div>
        </div>
      </div>
      <div class='start-btn'  @click="handlePickAction" >開始挑人</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "BasePick",
    data() {
      return {
        // nameList: [
        //   {   sort:1,
        //     id:'123',
        //     studentName:'王明',
        //     sendTime:'123456546',
        //     headImg:'https://cdn.quasar.dev/img/avatar.png',
        //     singScore:10,
        //     totalScore:20,
        //     option:'A',},
        //   { headImg: require("../../assets/img/head1.jpg"), studentName: "张三", id: "01" },
        //   { headImg: require("../../assets/img/head2.jpg"), studentName: "李四", id: "02" },
        //   { headImg: require("../../assets/img/head3.jpg"), studentName: "王五", id: "03" },
        //   { headImg: require("../../assets/img/head4.jpg"), studentName: "孙六", id: "04" },
        //   { headImg: require("../../assets/img/head4.jpg"), studentName: "孙一", id: "07" },
        //   { headImg: require("../../assets/img/head4.jpg"), studentName: "李富贵", id: "08" },
        //   { headImg: require("../../assets/img/head4.jpg"), studentName: "张强", id: "09" },
        //   { headImg: require("../../assets/img/head4.jpg"), studentName: "朱珠", id: "10" },
        //   { headImg: require("../../assets/img/head4.jpg"), studentName: "黄九", id: "11" }
        // ],

        nameList: this.$store.state.students,
        pickNum: 0,
        mytime: "",
        checkVal: ["repeat", "quick"],
        btnLable: "开始挑人",
        numList: [1, 2, 3, 4, 5, 6],
        personList: [],
        showLength: 1,
        playIcon:'md-play',
      };
    },
    created() {
      // pickName={headImg: require('../../assets/img/head1.jpg'), studentName: '李四'};
      for (let i = 0; i < 6; i++) {
        this.personList.push({ headImg: require("../../assets/img/question.jpg"), studentName: "", sort: "" });
      }
    },
    methods: {
      show() {
        let that = this;
      },

      handleSelectNum(val) {
        this.showLength = val + 1;
        for (let i = 0; i < 6; i++) {
          if(!this.personList[i]){
            this.personList[i] = this.nameList[0]
          }
        }
      },
      handlePickAction() {
        let that = this;
        if(that.nameList.length == 0){
          this.$Message.warning('暂无成员参与，无法进行挑人。');
          return;
        }
        if (this.mytime == "") {
          this.btnLable = "停止";
          this.playIcon = "md-pause";
          this.mytime = setInterval(function() {
            that.personList = that.getArrItem(that.nameList,that.showLength);
          }, 100);
        }else {
          this.btnLable = "继续挑人";
          this.playIcon = "md-play";
          window.clearInterval(that.mytime);
          this.mytime = "";
        }

        if(this.checkVal.indexOf('quick') > -1){
          setTimeout(() => {
            this.btnLable = "继续挑人";
            this.playIcon = "md-play";
            window.clearInterval(that.mytime);
            this.mytime = "";
          }, 5000);
        }
      },
      refTest() {
        let refs = this.$refs;
        console.log(this.$refs);
      },

      getArrItem(arr, num) {
        var temp_array = new Array();
        for (var index in arr) {
          temp_array.push(arr[index]);
        }
        var return_array = new Array();
        for (var i = 0; i < num; i++) {
          if (temp_array.length > 0) {
            var arrIndex = Math.floor(Math.random() * temp_array.length);
            return_array[i] = temp_array[arrIndex];
            temp_array.splice(arrIndex, 1);
          } else {
            break;
          }
        }
        return return_array;
      }
    },
    mounted() {
      this.refTest();
    }
  };
</script>

<style lang='less'>
  .person-box {
    position: relative;
    background: url("../../assets/img/head0.jpg") no-repeat 0 0;
    background-size: cover;
    width: 100%;
    height: 100%;
    border: 3px solid #a8aaab;
    font-size: 26px;
    border-radius: 10px;
  }

  .start-wrap {
    width: 120px;
    height: 120px;
    background: blue;
    border-radius: 50%;
    font-size: 80px;
    margin: 0 auto;
    cursor: pointer;
  }
  .btn-start {
    color: #fff;
    margin-left: 12px;
  }

  .picker-conditions {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .picker-select {
    margin: 20px 0;
  }

  .picker-select .ivu-select {
    margin: 0 20px;
    height: 25px;
    line-height: 25px;
  }

  .picker-conditions .ivu-checkbox-wrapper {
    font-size: 14px;
  }

  .picker-select .ivu-select-selected-value {
    line-height: 25px !important;
    padding-left: 20px !important;
  }

  .picker-select .ivu-select-single .ivu-select-selection {
    height: 25px;
    line-height: 25px;
  }

  .picker-select .ivu-select-item {
    padding: 7px 32px;
  }

  .person-name {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    color: #fff;
    font-weight: bold;
    letter-spacing: 3px;
    background: rgba(113, 115, 116, 0.67);
    text-align: center;
  }

  .person-id {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: blue;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    line-height: 36px;
    //border: 2px solid rgb(153, 151, 151);
  }
  .start-btn {
    background: blue;
    color: white;
    text-align: center;
    font-weight: bolder;
    padding:10px;
    border-radius: 5px;
    cursor: pointer;
    &:hover{
      background: darken(blue,10%);
    }
  }

  .result-box {
    position: absolute;
    width: 450px;
    left: -450px;
    top: -2px;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  .person-item {
    width: 150px;
    height: 150px;
    /*border: 1px solid #fff;*/
  }
</style>
