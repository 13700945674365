<template>
  <div class="vertical-tools">
    <div class="shapes_box">
      <div v-for="(items, index) in shapeList" :key="index" class="shapes_icon" @click="shapesSelect(items.value, index)">
        <i :title="items.title">
          <svg-icon v-if="items.icon == 'mouse-pointer'" icon-class="select" class="stroke-icon" :style="currentIndex == 0 ? 'stroke:' + color : ''"/>
          <font-awesome-icon v-else icon="hand-rock" class="handrock-icon" :class="{ 'fill-handrock': currentIndex == 1 }"
        /></i>
      </div>
    </div>
  </div>
</template>

<script>
import { color } from 'vuex'
export default {
  name: 'pop',
  data() {
    return {
      color: 'blue',
      currentIndex: 0,
      colorArr: [
        { val: '#0000ff', color: 'teal' },
        { val: '#ff9800', color: 'orange' },
        { val: '#f44336', color: 'red' },
        { val: '#00bcd4', color: 'cyan' }
      ],
      shapeList: [
        { icon: 'mouse-pointer', value: 'checkbtn', title: '选择工具' },
        { icon: 'hand-rock', value: 'hand', title: '抓手工具' }
        //{ icon: 'draw-polygon', value: 'stretchbox', title: '拉框选择'},
      ],
      button4: 'pencil-alt',
      hex: '#FF00FF',
      standard: 2,
      value: 2,
      strokeColor: 'red'
    }
  },
  methods: {
    shapesSelect: function(value, index) {
      let num = index
      let name = value
      if (name === 'stretchbox') {
        this.$parent.$parent.$parent.framebox()
        this.$store.state.checkvalue = name
        this.currentIndex = num
      } else {
        this.currentIndex = num
        this.$store.state.checkvalue = name
      }
      console.log(this.$store.state.checkvalue, 'vuex里的值')
    }
  },
  created() {},
  computed: {
    watchcss() {}
  },
  watch: {
    watchcss(e) {
      this.currentIndex = 0
    }
  }
}
</script>
<style lang="less" scoped>
.stroke-icon {
  stroke: black;
  fill: transparent !important;
  font-size: 20px;
}
.handrock-icon {
  position: relative;
  left: 3px;
}
.fill-handrock {
  color: blue !important;
}
</style>
