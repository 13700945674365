<template>
  <!--左侧缩略图-->
  <div :class="['board-left',isOpen?'drawer' : '']">
    <span class="toggle-left" @click="isOpen = !isOpen"><font-awesome-icon icon="angle-double-right"/></span>
    <h2 class="tc">高新区智慧阅读
      <!--<Icon type="ios-arrow-down" class="fr"/>-->
    </h2>
    <ul>
      <li class="active">
        <img src="../../assets/img/1.png" alt="">
        <span>1/3</span>
      </li>
      <li>
        <img src="../../assets/img/2.png" alt="">
        <span>2/3</span>
      </li>
      <li>
        <img src="../../assets/img/3.png" alt="">
        <span>3/3</span>
      </li>
    </ul>
  </div>
</template>

<script>
    export default {
        name: "left",
      data(){
          return{
            isOpen: true,
        }
      }
    }
</script>

<style scoped>

</style>
